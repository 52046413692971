import React from 'react';

import apiKeyService from '../Services/settingService';
import settingService from '../Services/settingService';

class EditSettingData extends React.Component {
    constructor(props) {
        super(props);
        this.apiKeyService = new apiKeyService();
        this.settingService = new settingService();
        this.state = {
            key: '',
            secret: '',
        };
        this.getAllApiKey();
    }

    // get api keys
    getAllApiKey = () => {
      console.log(this.state.userId, 'get all key')
      this.apiKeyService.getApiKey(this.props.userId).then(response => {
        console.log('Response: ', response);
        this.setState({
          key: response.key,
          secret: response.secret
        });
      });
    }

    UpdateKey = (e) => {
        e.preventDefault();
        let updatedData = {
            // userId: .props.userId,
            key: this.state.key,
            secret: this.state.secret
        }
        this.settingService.addApiKey(this.props.userId, updatedData ).then(response => {
          //   this.setState({
          //     key:'',
          //     secret: ''
          //   });
            this.props.closeModal(false)
          })
    }

    handelInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        return (
            <div className="card">
                <h5 className="card-header info-color white-text text-center py-4">
                    <strong>Edit Key</strong>
                    <span className="cross"
                        onClick={this.props.closeModal} >
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </span>
                </h5>
                <div className="card-body px-lg-5 pt-0">
                    <form className="text-center">

                        <div className="col">
                            <div className="md-form">
                                <label className="label-float"> Public Key</label>
                                <input type="text" className="form-control" name="key" value={this.state.key}
                                    onChange={this.handelInputChange} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="md-form">
                                <label className="label-float">Secret Key</label>
                                <input type="text" className="form-control" name="secret" value={this.state.secret}
                                    onChange={this.handelInputChange} />
                            </div>
                        </div>
                        <button className="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0 width-30" type="submit"
                            onClick={(e) => this.UpdateKey(e)}
                            >
                            Update
                    </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default EditSettingData