export const STATIC_TABLE_DEFAULT_COLUMN = {
  data: {
    id: 'data',
    header: '##Column Header##',
    rows: ['##Column Data##', '##Column Data##'],
    footer: '##Column Footer##',
    switchBody: true    
  },
  settings: {
    id: 'settings',
    header: {
      colspan: 1
    },
    body: {
      colspan: 1,
    },
    footer: {
      colspan: 1
    },
  }
};

export const THEMES = {
  THEME_DEFAULT: {
    global: {
      table: {
      },
      header: {
        paddingTop: '5',
        paddingLeft: '5',
        paddingBottom: '5',
        paddingRight: '5',
        backgroundColor: '#cfcfcf',
        color: '',
        align: 'center',
        fontWeight: 'bold'
      },
      body: {
        paddingTop: '5',
        paddingLeft: '5',
        paddingBottom: '5',
        paddingRight: '5',
        backgroundColor: '',
        color: '',
        borderStyle: 'solid',
        borderColor: '#f1f1f1',
        borderTopSize: '1'
      },
      footer: {
        paddingTop: '5',
        paddingLeft: '5',
        paddingBottom: '5',
        paddingRight: '5',
        backgroundColor: '#f1f1f1',
        color: ''
      }
    }
  },
  THEME_BLUE: {
    global: {
      header: {
        paddingTop: '5',
        paddingLeft: '5',
        paddingBottom: '5',
        paddingRight: '5',
        backgroundColor: '#005EFF',
        color: '#ffffff',
        align: 'center'
      },
      body: {
        paddingTop: '5',
        paddingLeft: '5',
        paddingBottom: '5',
        paddingRight: '5',
        backgroundColor: '',
        color: ''
      },
      footer: {
        paddingTop: '5',
        paddingLeft: '5',
        paddingBottom: '5',
        paddingRight: '5',
        backgroundColor: '#E5E7FF',
        color: ''
      }
    }
  },
  THEME_BLUE_DARK: {
    global: {
      header: {
        paddingTop: '5',
        paddingLeft: '5',
        paddingBottom: '5',
        paddingRight: '5',
        backgroundColor: '#0740A2',
        color: '#ffffff',
        align: 'center'
      },
      body: {
        paddingTop: '5',
        paddingLeft: '5',
        paddingBottom: '5',
        paddingRight: '5',
        backgroundColor: '',
        color: ''
      },
      footer: {
        paddingTop: '5',
        paddingLeft: '5',
        paddingBottom: '5',
        paddingRight: '5',
        backgroundColor: '#E5E7FF',
        color: ''
      }
    }
  }
}