import * as React from "react";
import { useState, useEffect } from 'react';
import { useLocation, useParams, withRouter } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

import TemplateDataExtractorService from "../../Services/TemplateDataExtractorService";
import { SOURCE_TYPE } from "./TemplateDataExtractor";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { PREDEFINED_ROUTE } from "./PredefinedTemplate";

import { Button } from '@mui/material';

function TemplateDataExtractors(props) {

  const isPredefinedTemplate = props.predefinedTemplate;
  
  const templateDataExtractorService = new TemplateDataExtractorService();
  
  const [extractors, setExtractors] = useState(null);

  useEffect(() => {
    findDataExtractors();
  }, []);

  function findDataExtractors() {
    templateDataExtractorService.findAll(props.templateId).then(response => {
      setExtractors(response.data);
    });
  }

  function deleteExtractor(extractor) {
    Swal.fire({
      title: `Do you really want to delete the extractor: <span style="color: red">${extractor.code}</span>?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (extractor.sourceType === SOURCE_TYPE.JSON_REST_API) {
          templateDataExtractorService.deleteJSONExtractor(props.templateId, extractor.id)
            .then(() => {
              deleteSuccess();
            })
            .catch(() => {
              Swal.showValidationMessage(`Failed to delete the extractor.`);
            });
        } else {
          templateDataExtractorService.deleteSQLExtractor(props.templateId, extractor.id)
            .then(() => {
              deleteSuccess();
            })
            .catch(() => {
              Swal.showValidationMessage(`Failed to delete the extractor.`);
            });
        }

      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  function deleteSuccess() {
    Swal.fire({
      icon: 'success',
      title: 'Extractor Deleted',
      showConfirmButton: false,
      timer: 1500
    });

    findDataExtractors();
  }
  
  function importDataExtractor(selectedFile) {
	if (selectedFile) {
      templateDataExtractorService.importFile(props.templateId, selectedFile)
        .then(response => {
          if (!response.status == 200) {
            throw new Error(response.statusText)
          }

          findDataExtractors();

          Swal.fire({
            icon: 'success',
            title: 'Data Extractor imported',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Failed to import Data Extractor: ${error}`
          )
        });
    }
  }
  
  function exportDataExtractor(dataExtractorId, dataExtractorCode) {
    templateDataExtractorService.exportFile(props.templateId, dataExtractorId).then(response => {
	  if (response.status == 200) {
          const blob = new Blob([response.data], { type: "application/json;charset=utf-8" });
          const url = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = `export_${dataExtractorCode}.json`;
          a.click();
      }  
    })
        .catch(error => {
          Swal.showValidationMessage(
            `Failed to export Data Extractor: ${error}`
          )
        });
  }

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <div className="row" style={{ paddingLeft: 5 }}>
        <div className="col-12">
          <table style={{ width: "100%", overflow: "scroll !important" }}>
            <thead>
              <tr className="line">
                <th className="line-data">Order</th>
                <th className="line-data" width="25%">Name / Code</th>
                <th className="line-data">Hidden</th>
                <th className="line-data">Source Type</th>
                <th className="line-data">Field Count</th>
                <th className="line-data" width={200} style={{ textAlign: "right" }} >
                  <button type="button" className="btn btn-submit"
                    style={{ marginTop: 0, marginRight: 6 }}
                    onClick={() => props.history.push({
                      pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${props.customerId}/Template/`) + props.templateId + "/Extractor",
                      preSelectedTab: "0",
                      extractorsLength: extractors?.length || 0
                    })}>
                    Add
                  </button>
                  
                  <Button variant="contained" component="label" style={{ textTransform: "capitalize"}}>
                    Import
                    <input type="file" accept="application/json" onChange={(e) => importDataExtractor(e.target.files[0])} hidden />
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {extractors && extractors.length > 0 ?
                extractors.map((extractor, index) => (
                  <tr key={extractor.id} className="line">
                    <td className="line-data">{extractor.executionOrder}</td>
                    <td className="line-data">
                      <div>{extractor.name}</div>
                      <span className="text-black-50">{extractor.code}</span>
                    </td>
                    <td className="line-data" style={{ textTransform: "capitalize" }}>{extractor.isHidden ? 'Yes' : 'No'}</td>
                    <td className="line-data">{extractor.sourceType}</td>
                    <td className="line-data">{extractor.fields?.length}</td>
                    <td align="right">
                      <button type="button" className="btn btn-light"
                        style={{ marginTop: 0, marginRight: 14 }}
                        onClick={() => exportDataExtractor(extractor.id, extractor.code) }>
                        Export
                      </button>
                      
                      <button type="button" className="btn btn-light"
                        style={{ marginTop: 0, marginRight: 14 }}
                        onClick={() => props.history.push({
                          pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${props.customerId}/Template/`) + props.templateId + "/Extractor/" + extractor.id
                        })}>
                        Edit
                      </button>

                      <a href="#" onClick={() => deleteExtractor(extractor)} title="Delete Extractor">
                        <FontAwesomeIcon icon={faTrashCan} color="#6c6c6f" />
                      </a>
                    </td>
                  </tr>))
                :
                <tr>
                  <td colSpan={4}>
                    {(extractors && extractors.length === 0) ? "No extractors found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                  </td>
                </tr>
              }

            </tbody>
          </table>
        </div>
      </div>

    </div>
  );
}

export default withRouter(TemplateDataExtractors);
