export const alignmentOptions = [
  {
    value: 'left',
    label: 'Left',
  },
  {
    value: 'center',
    label: 'Center',
  },
  {
    value: 'right',
    label: 'Right',
  },
];

export const vAlignmentOptions = [
  {
    value: 'top',
    label: 'Top',
  },
  {
    value: 'middle',
    label: 'Middle',
  },
  {
    value: 'bottom',
    label: 'Bottom',
  },
];

export const borderStyleOptions = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'solid',
    label: 'Solid',
  },
  {
    value: 'dashed',
    label: 'Dashed',
  },
];

export const fontWeightoptions = [
  {
    value: 'normal',
    label: 'Normal',
  },
  {
    value: 'bold',
    label: 'Bold',
  },
  {
    value: '100',
    label: '100',
  },
  {
    value: '200',
    label: '200',
  },
  {
    value: '300',
    label: '300',
  },
  {
    value: '400',
    label: '400',
  },
  {
    value: '500',
    label: '500',
  },
  {
    value: '600',
    label: '600',
  },
  {
    value: '700',
    label: '700',
  },
  {
    value: '800',
    label: '800',
  },
  {
    value: '900',
    label: '900',
  },
];

export const fontStyleoptions = [
  {
    value: 'normal',
    label: 'Normal',
  },
  {
    value: 'italic',
    label: 'Italic',
  },
];

export function getBorderWidth(top, right, bottom, left, style) {
  if(!style || style === 'none') {
    return;
  }
  
  return (top ? top + 'px ' : '0px ')
    + (right ? right + 'px ' : '0px ')
    + (bottom ? bottom + 'px ' : '0px ')
    + (left ? left + 'px ' : '0px ');
}

export function getSingleValueInPx(value, global) {
  return value || value === 0 ? `${value}px`
    : global || global === 0 ? `${global}px`
      : '';
}

export function getSingleValueInPxWithRestriction(value, global, restriction, restrictionGlobal) {
  const restricted = restriction || restrictionGlobal;
  if(!restricted || restriction === 'none') {
    return;
  }
  
  return getSingleValueInPx(value, global);
}