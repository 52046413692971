export const CustomBlocksType = {
  CUSTOM_TABLE: 'CUSTOM_TABLE',
  BASIC_TABLE: 'BASIC_TABLE',
  STATIC_TABLE: 'STATIC_TABLE',
  CONDITIONAL: 'CONDITIONAL',
  BAR_CHART: 'BAR_CHART',
  LINE_CHART: 'LINE_CHART',
  RADIAL_GAUGE_CHART: 'RADIAL_GAUGE_CHART',
  PIE_CHART: 'PIE_CHART',
}

export const CustomBlocksTitle = {
  COLUMN_TEXT: 'COLUMN_TEXT'
}

export const CustomParams = {
  CLASS_ENGAGE_CHART: 'engage-chart',
  STYLE_ENGAGE_CHART: 'border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;'
}

export const ChartType = {
  LINE: 'line',
  BAR: 'bar',
  HORIZONTAL_BAR: 'horizontalBar',
  PIE: 'pie',
  OUTLABELED: 'outlabeledPie',
  RADIAL_GAUGE: 'radialGauge'
}

export const BehaviourWhenNoData = {
  SHOW_MISSING_DATA_PLACEHOLDER: 'showMissingData',
  HIDE_CHART: 'hideChart'
}

export const ChartTags = {
  LABELS: '##CHART_LABELS##',
  DATASETS: '##CHART_DATASETS##',
  OPTIONS: '##CHART_OPTIONS##',
  HIDE_WHEN_NO_DATA: '##HIDE_WHEN_NO_DATA##',
}

export const debounceTime = 1500;