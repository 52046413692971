import axios from 'axios';
import { environment } from '../environment';

class TemplateComponentService {
  
  // Get All Users by Customer
  getAllTemplateComponents = () => {
    return axios.get(environment.API_URL + `template-components`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error;
      });
  }
  
  getTemplateComponent = (templateComponentid) => {
	return axios.get(environment.API_URL + `template-components/${templateComponentid}`)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }
  
  createTemplateComponent = (templateComponentData) => {
	return axios.post(environment.API_URL + `template-components`, templateComponentData)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }
  
  updateTemplateComponent = (templateComponentData) => {
	return axios.put(environment.API_URL + `template-components/${templateComponentData.id}`, templateComponentData)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }
  
  deleteTemplateComponent = (templateComponentid) => {
	return axios.delete(environment.API_URL + `template-components/${templateComponentid}`)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }  	
}

export default TemplateComponentService;