import * as React from "react";
import { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import SideBar from "./SideBar";
import TopBar from "./TopBar";
import authService from '../../Services/authServices'
import TemplateService from '../../Services/TemplateService';
import AnimatedBody from "../../Wrappers/AnimatedBody";

import { FormControl, Breadcrumbs, FormControlLabel, FormHelperText, Link, Tab, TextField, TextareaAutosize, Typography, InputLabel, MenuItem, Select } from '@mui/material';

import Swal from 'sweetalert2';

import Switch from '@mui/material/Switch';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Skeleton from "react-loading-skeleton";
import AddApiService from "../../Services/addApiService";
import Designer from "./Designer";
import TemplateDataExtractors from "./TemplateDataExtractors";
import TemplateParameters from "./TemplateParameters";
import TemplateFilters from "./TemplateFilters";

import TemplateDataExtractorService from "../../Services/TemplateDataExtractorService";

function Template(props) {

  let isValidUser = true;

  const addApiService = new AddApiService();
  const templateService = new TemplateService();
  const myServiceInstance = new authService();
  const templateDataExtractorService = new TemplateDataExtractorService();

  const customerId = props.match.params.customerId;
  const templateId = props.match.params.id;
  const [customerName, setCustomerName] = useState('');
  const [newDesignerAllowed, setNewDesignerAllowed] = useState(false);
  const [editDataextractorsAllowed, setEditDataextractorsAllowed] = useState(false);

  // Validating if user was logged in
  const loggedInCustomer = myServiceInstance.getCurrentCustomer();
  if (!loggedInCustomer?.SUPERADMIN
    && (!customerId
      || !loggedInCustomer?.admin
      || (customerId !== (loggedInCustomer?.customerId?.toString())))) {
    isValidUser = false;
    props.history.push({
      pathname: "/Customer/" + loggedInCustomer?.customerId,
      preSelectedTab: "2"
    });
  }

  const [preSelectedTab] = useState(props.location.preSelectedTab);
  const [selectedTab, setSelectedTab] = useState(preSelectedTab ? preSelectedTab : "0");

  const [validForm, setValidForm] = useState(true);
  const [errors, setErrors] = useState({});


  const [templateName, setTemplateName] = useState('');
  const [currentTemplateName, setCurrentTemplateName] = useState('');
  const [templateVersionId, setTemplateVersionId] = useState('');
  const [templateVersionSubject, setTemplateVersionSubject] = useState('');
  const [currentTemplateVersionSubject, setCurrentTemplateVersionSubject] = useState('');
  const [dropdownLabel, setDropdownLabel] = useState('');
  const [useNewTemplateEditor, setUseNewTemplateEditor] = useState(false);

  const [isSendToOwner, setIsSendToOwner] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);

  const [selectedOwnerExtractorId, setSelectedOwnerExtractorId] = useState('');
  const [selectedOwnerExtractor, setSelectedOwnerExtractor] = useState(null);
  const [ownerNameExtractorField, setOwnerNameExtractorField] = useState('');
  const [ownerEmailExtractorField, setOwnerEmailExtractorField] = useState('');

  const [extractors, setExtractors] = useState([]);

  useEffect(() => {
    if (isValidUser && customerId !== undefined && templateId !== undefined) {
      addApiService.getCustomer(customerId).then(response => {
        if (!response) {
          loggedInCustomer?.SUPERADMIN ?
            props.history.push({ pathname: "/Customers" }) :
            props.history.push({ pathname: "/EmailSchedule" });
            return;
          }
          
          let editAllowed = response.editDataextractorsAllowed || false;
          
          setCustomerName(response.customerName || '');
          setNewDesignerAllowed(response.newDesignerAllowed || false);
          setEditDataextractorsAllowed(editAllowed);
        
          templateService.findById(customerId, templateId, true, false).then(response => {
	
            if (response.status !== 200) {
              errorMessage(!response.data.message ? 'No template found' : response.data.message);
              return;
            }
            
          setTemplateName(response.data.templateName || '');
          setCurrentTemplateName(response.data.templateName || '');
          setTemplateVersionId(response.data.templateVersionId || '');
          setTemplateVersionSubject(response.data.subjectExpression || '');
          setCurrentTemplateVersionSubject(response.data.subjectExpression || '');
          setDropdownLabel(response.data.dropdownLabel || '');

          setOwnerNameExtractorField(response.data.ownerNameExtractorField || '');
          setOwnerEmailExtractorField(response.data.ownerEmailExtractorField || '');

          let sendToOwner = response.data.sendToOwner || false;

          setIsDropdown(response.data.dropdown || false);
          setIsSendToOwner(sendToOwner);

          setUseNewTemplateEditor(response.data.useNewTemplateEditor || false);

          if (editAllowed && sendToOwner) {
            templateDataExtractorService.findAll(templateId).then(extractorResponse => {
              setExtractors(extractorResponse.data);
              handleChangeOwnerExtractor(extractorResponse.data, response.data.ownerDataExtractorId || '');

              setOwnerNameExtractorField(response.data.ownerNameExtractorField || '');
              setOwnerEmailExtractorField(response.data.ownerEmailExtractorField || '');
            });
          }
        });
      });
    }
  }, []);

  function updateSendToOwner(sendToOwner) {
    setIsSendToOwner(sendToOwner);

    if (sendToOwner) {
      templateDataExtractorService.findAll(templateId).then(extractorResponse => {
        setExtractors(extractorResponse.data);

        if (selectedOwnerExtractorId !== '') {
          handleChangeOwnerExtractor(extractorResponse.data, selectedOwnerExtractorId);
        }
      });
    } else {
      setExtractors([]);
      setSelectedOwnerExtractorId('');
      setOwnerNameExtractorField('');
      setOwnerEmailExtractorField('');
    }
  }

  function errorMessage(message) {
    Swal.fire({
      title: message,
      icon: 'error',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        props.history.push({
          pathname: "/Customer/" + customerId,
          preSelectedTab: "2"
        });
      }
    });
  }

  function save() {

    validate("templateName", templateName, false, false, false);
    validate("dropdownLabel", dropdownLabel, false, false, false);
    let valid = validate("templateVersionSubject", templateVersionSubject, false, false, false);

    if (isSendToOwner && (selectedOwnerExtractorId === '' || ownerNameExtractorField === '' || ownerEmailExtractorField === '')) {
      valid = false;
      Swal.fire({
        icon: 'warning',
        title: 'You must select a valid extractor and fields to use the "Send to owner" functionality'
      });
    }

    if (!valid) {
      return;
    }

    const request = {
      customerId,
      templateId,
      templateName,
      templateVersionId,
      templateVersionSubject,
      subjectExpression: templateVersionSubject, 
      dropdownLabel,
      isDropdown,
      sendToOwner: isSendToOwner,
      updateTemplate: templateName !== currentTemplateName,
      updateTemplateVersion: templateVersionSubject !== currentTemplateVersionSubject,
      ownerDataExtractorId: selectedOwnerExtractorId,
      ownerNameExtractorField,
      ownerEmailExtractorField
    }

    Swal.fire({
      title: 'Ready to save?',
      showCancelButton: true,
      confirmButtonText: 'Save',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return templateService.update(request)
          .then(response => {
            if (!response.statusCode == 200) {
              throw new Error(response.statusText)
            }

            Swal.fire({
              icon: 'success',
              title: 'Template Updated',
              showConfirmButton: false,
              timer: 1500
            })
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Failed to update the template.`
            )
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {

    });

  }

  function validate(field, value, isRequired, isLettersOnly, isNumericOnly) {
    let vErrors = errors ?? {};

    if (isRequired && !value) {
      vErrors[field] = "This field is required";
    } else if (isLettersOnly && value && !value.match(/^[a-zA-Z]+(\.[a-zA-Z]+)*$/)) {
      vErrors[field] = "Letters only";
    } else if (isNumericOnly && value && typeof value === "string" && !value.match(/^\d+$/)) {
      vErrors[field] = "Numeric only";
    } else {
      delete vErrors[field];
    }

    if (Object.keys(vErrors).length) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }

    setErrors(vErrors);
    return !Object.keys(vErrors).length;
  }

  function handleChangeOwnerExtractor(extractors, extractorId) {
    const extractorIndex = extractors.findIndex(extractor => extractor.id === extractorId);
    const extractor = extractors.at(extractorIndex);
    setSelectedOwnerExtractor(extractor)
    setSelectedOwnerExtractorId(extractorId);
    setOwnerNameExtractorField('');
    setOwnerEmailExtractorField('');
  };

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <SideBar
        sideBarExpanded={props.sideBarExpanded}
        setSideBarExpanded={props.setSideBarExpanded}
      />
      <div id="main">
        <div className="container-fluid custom-container">

          <AnimatedBody
            sideBarExpanded={props.sideBarExpanded}
            setSideBarExpanded={props.setSideBarExpanded}
            className="container-fluid custom-container"
          >
            <TopBar title={"Customer"} customer={customerName} customerId={customerId} />

            <div className="row row-margin">
              <Breadcrumbs className="pb-3" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="#"
                  onClick={() => props.history.push({
                    pathname: "/Customer/" + customerId,
                    preSelectedTab: "2"
                  })}>Templates</Link>
                <Typography color="text.primary">{templateName}</Typography>
              </Breadcrumbs>

              {
                templateId && !templateName ? <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" /> :

                  <TabContext value={selectedTab}>
                    <TabList onChange={(e, value) => setSelectedTab(value)}>
                      <Tab label="Properties" value="0" />

                      {
                        (useNewTemplateEditor && ((loggedInCustomer.newDesignerAllowed && editDataextractorsAllowed) || (loggedInCustomer?.SUPERADMIN && newDesignerAllowed))) &&
                        [
                          <Tab key="tab-data-extractors" label="Data Extractors" value="1" />,
                          <Tab key="tab-paramters" label="Parameters" value="2" />,
                          <Tab key="tab-filters" label="Filters" value="3" />,
                        ]
                      }
                      {
                        (useNewTemplateEditor && (loggedInCustomer.newDesignerAllowed || (loggedInCustomer?.SUPERADMIN && newDesignerAllowed))) &&
                        [
                          <Tab key="tab-designer" label="Designer" value="4" />
                        ]
                      }
                    </TabList>
                    <TabPanel value="0">
                      <form>
                        <div className="row">
                          <div className="col-6">
                            <TextField variant="outlined" label="Template Id" margin="normal" size="small" fullWidth
                              value={templateId} disabled />

                          </div>
                          <div className="col-6">
                            <TextField variant="outlined" label="Template Name" margin="normal" size="small" fullWidth
                              required 
                              value={templateName}
                              onChange={(e) => { validate("templateName", e.target.value, true, false, false); setTemplateName(e.target.value) }}
                              error={errors["templateName"] ? true : false}
                              helperText={errors["templateName"] ? errors["templateName"] : ""} />
                          </div>
                          <div className="col-12 mt-2">
                            <TextField label="Email Subject" className="form-control" id="inputJsonContent" required multiline minRows="4"
                              placeholder="Enter the email subject" value={templateVersionSubject}
                              onChange={(e) => { validate("templateVersionSubject", e.target.value, true, false, false); setTemplateVersionSubject(e.target.value) }}
                              error={errors["templateVersionSubject"] ? true : false}
                              helperText={errors["templateVersionSubject"] ? errors["templateVersionSubject"] : "To set the email subject = Template name + Multiselect Filter (If configured), then use the expression {{default_subject}}; it should not be combined with other expressions. Or use expressions that combine fixed text with filters/parameters values such as ${<<FILTER_CODE>>}, ${<<PARAM_NAME>>} or any expression for extractors used in the designer to customize the subject"} />
                          </div>
                          <div className="col-6">
                            {
                              !useNewTemplateEditor &&
                              <FormControlLabel className="left-aligned" label="Dropdown" labelPlacement="top"
                                control={<Switch className="switch-primary"
                                  checked={isDropdown}
                                  disabled />} />
                            }

                            <FormControlLabel style={{ marginLeft: 10 }} className="left-aligned" label="Send to Owner" labelPlacement="top"
                              control={<Switch className="switch-primary"
                                checked={isSendToOwner} onChange={(e) => { updateSendToOwner(e.target.checked) }}
                                disabled={!loggedInCustomer?.admin} />} />
                          </div>
                          <div className="col-6">
                            {
                              isDropdown && 
                              <TextField variant="outlined" label="Dropdown Label" margin="normal" size="small" fullWidth
                                required
                                value={dropdownLabel}
                                onChange={(e) => { validate("dropdownLabel", e.target.value, false, false, false); setDropdownLabel(e.target.value) }}
                                disabled={!isDropdown}
                                error={errors["dropdownLabel"] ? true : false}
                                helperText={errors["dropdownLabel"] ? errors["dropdownLabel"] : ""} />
                            }
                          </div>

                          <div className="col-6">
                            {
                              useNewTemplateEditor && isSendToOwner && newDesignerAllowed && editDataextractorsAllowed &&
                              <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                                <InputLabel id="filterExtractorLabel" required>Owner Data Extractor</InputLabel>
                                <Select
                                  labelId="filterExtractorLabel"
                                  label="Owner Data Extractor"
                                  value={selectedOwnerExtractorId}
                                  onChange={(e) => handleChangeOwnerExtractor(extractors, e.target.value)}
                                  MenuProps={{ disableScrollLock: true }}
                                >
                                  <MenuItem value="">None</MenuItem>
                                  {extractors.map((extractor) => (
                                    <MenuItem key={extractor.id} value={extractor.id}>{extractor.name}</MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>{errors["selectedOwnerExtractorId"] ? errors["selectedOwnerExtractorId"] : ""}</FormHelperText>
                              </FormControl>
                            }
                          </div>

                          <div className="col-3">
                            {
                              useNewTemplateEditor && isSendToOwner && newDesignerAllowed && editDataextractorsAllowed &&
                              <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                                <InputLabel id="ownerNameField" required>Owner Name Field</InputLabel>
                                <Select
                                  labelId="ownerNameField"
                                  label="Owner Name Field"
                                  value={ownerNameExtractorField}
                                  onChange={(e) => {
                                    setOwnerNameExtractorField(e.target.value);
                                  }}
                                  MenuProps={{ disableScrollLock: true }}
                                >
                                  <MenuItem value="">None</MenuItem>
                                  {selectedOwnerExtractor && selectedOwnerExtractor.fields && selectedOwnerExtractor.fields.length
                                    ? selectedOwnerExtractor.fields.map((field) => (
                                      <MenuItem key={field.id} value={field.code}>{field.name}</MenuItem>
                                    )) : []}
                                </Select>
                                <FormHelperText>{errors["ownerNameField"] ? errors["ownerNameField"] : ""}</FormHelperText>
                              </FormControl>
                            }
                          </div>

                          <div className="col-3">
                            {
                              useNewTemplateEditor && isSendToOwner && newDesignerAllowed && editDataextractorsAllowed &&
                              <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                                <InputLabel id="ownerEmailField" required>Owner Email Field</InputLabel>
                                <Select
                                  labelId="ownerEmailField"
                                  label="Owner Email Field"
                                  value={ownerEmailExtractorField}
                                  onChange={(e) => {
                                    setOwnerEmailExtractorField(e.target.value);
                                  }}
                                  MenuProps={{ disableScrollLock: true }}
                                >
                                  <MenuItem value="">None</MenuItem>
                                  {selectedOwnerExtractor && selectedOwnerExtractor.fields && selectedOwnerExtractor.fields.length
                                    ? selectedOwnerExtractor.fields.map((field) => (
                                      <MenuItem key={field.id} value={field.code}>{field.name}</MenuItem>
                                    )) : []}
                                </Select>
                                <FormHelperText>{errors["ownerEmailField"] ? errors["ownerEmailField"] : ""}</FormHelperText>
                              </FormControl>
                            }
                          </div>
                        </div>

                        <div style={{ marginLeft: 0, marginTop: 5 }}>
                          <button type="button" style={{ marginTop: 0 }} className="btn btn-submit"
                            onClick={() => save()}
                            disabled={!validForm}>Save</button>

                          <button type="button" style={{ marginLeft: 5 }} className="btn btn-cancel"
                            onClick={() => props.history.push({
                              pathname: "/Customer/" + customerId,
                              preSelectedTab: "2"
                            })}>Cancel</button>
                        </div>
                      </form>
                    </TabPanel>

                    {
                      (useNewTemplateEditor && (loggedInCustomer.newDesignerAllowed || (loggedInCustomer?.SUPERADMIN && newDesignerAllowed))) &&
                      <>
                        <TabPanel value="1">
                          <TemplateDataExtractors customerId={customerId} templateId={templateId} />
                        </TabPanel>
                        <TabPanel value="2">
                          <TemplateParameters customerId={customerId} templateId={templateId} />
                        </TabPanel>
                        <TabPanel value="3">
                          <TemplateFilters customerId={customerId} templateId={templateId} />
                        </TabPanel>
                        <TabPanel value="4" className="ps-0 pb-0 pe-3">
                          <Designer customerId={customerId} templateId={templateId} />
                        </TabPanel>
                      </>
                    }
                  </TabContext>
              }
            </div>

          </AnimatedBody>
        </div>
      </div >
    </div >
  );

}

export default withRouter(Template);
