import * as React from "react";
import { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import SideBar from "./SideBar";
import TopBar from "./TopBar";
import authService from '../../Services/authServices'
import AddApiService from '../../Services/addApiService';
import AnimatedBody from "../../Wrappers/AnimatedBody";

import { Button, FormControlLabel, Tab, TextField } from '@mui/material';

import Swal from 'sweetalert2';

import Switch from '@mui/material/Switch';
import CustomerUsers from "./CustomerUsers";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomerTemplates from "./CustomerTemplates";
import Skeleton from "react-loading-skeleton";
import CustomerRecipients from "./CustomerRecipients";

function Customer(props) {

  const addApiService = new AddApiService();
  const myServiceInstance = new authService();

  const [customerId, setCustomerId] = useState(props.match.params.id);

  // Validating if user was logged in
  const loggedInCustomer = myServiceInstance.getCurrentCustomer();
  if (!loggedInCustomer?.SUPERADMIN
    && (!customerId
      || !loggedInCustomer?.admin
      || (customerId !== (loggedInCustomer?.customerId?.toString())))) {
    props.history.push("/EmailSchedule");
  }

  const [preSelectedTab, setPreSelectedTab] = useState(props.location.preSelectedTab);
  const [selectedTab, setSelectedTab] = useState(customerId ? (preSelectedTab ? preSelectedTab : "1") : "0");

  const [validForm, setValidForm] = useState(true);
  const [errors, setErrors] = useState({});


  const [customerName, setCustomerName] = useState('');
  const [domain, setDomain] = useState('');
  const [project, setProject] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [environment, setEnvironment] = useState('');

  const [customerApptio, setCustomerApptio] = useState('');
  const [region, setRegion] = useState('');
  const [regionIndex, setRegionIndex] = useState('');
  const [company, setCompany] = useState('');

  const [attachmentAllowed, setAttachmentAllowed] = useState(false);
  const [sendGridIntegration, setSendGridIntegration] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);

  const [newDesignerAllowed, setNewDesignerAllowed] = useState(false);
  const [editDataextractorsAllowed, setEditDataextractorsAllowed] = useState(false);
  const [sendGridEmailFrom, setSendGridEmailFrom] = useState('');
  const [dbInstances, setDbInstances] = useState([]);

  useEffect(() => {
    if (customerId !== undefined) {
      addApiService.getCustomer(customerId, loggedInCustomer?.SUPERADMIN || loggedInCustomer?.admin).then(response => {
        if (!response) {
          loggedInCustomer?.SUPERADMIN ?
            props.history.push({ pathname: "/Customers" }) :
            props.history.push({ pathname: "/EmailSchedule" });
          return;
        }
        setCustomerName(response.customerName || '');
        setDomain(response.domain || '');
        setProject(response.project || '');
        setBaseUrl(response.baseURL || '');
        setEnvironment(response.environment || '');

        setCustomerApptio(response.customer || '');
        setRegion(response.region || '');
        setRegionIndex(response.regionIndex || '');
        setCompany(response.company || '');

        setAttachmentAllowed(response.attachmentAllowed || false);
        setNewDesignerAllowed(response.newDesignerAllowed || false);
        setEditDataextractorsAllowed(response.editDataextractorsAllowed || false);
        setSendGridEmailFrom(response.sendGridEmailFrom || '');

        setSendGridIntegration(response.isSendGridIntegrated || false);

        setLogoUrl(response.logoURL ? (response.logoURL + "?" + Date.now()) : '');
        setDbInstances(response.dbInstances || []);
      });
    }
  }, []);

  function onTabChange(value) {
    setSelectedTab(value);
  }

  function uploadLogo(selectedLogo) {
    if (selectedLogo) {
      addApiService.uploadCustomerLogo(customerId, selectedLogo)
        .then(response => {
          if (!response.statusCode == 200) {
            throw new Error(response.statusText)
          }

          setLogoUrl(response + "?" + Date.now());

          Swal.fire({
            icon: 'success',
            title: 'Logo Updated',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Failed to update the logo.`
          )
        });
    }
  }

  function save() {

    validate("customerName", customerName, true, true, false);
    const valid = validate("regionIndex", regionIndex, false, false, true);

    if (!valid) {
      return;
    }

    const request = {
      id: customerId,
      customerName,
      domain,
      project,
      baseURL: baseUrl,

      customer: customerApptio,
      region,
      regionIndex,
      company,
      environment,

      isSendGridIntegrated: sendGridIntegration,

      attachmentAllowed,
      newDesignerAllowed,
      editDataextractorsAllowed,
      sendGridEmailFrom
    }

    Swal.fire({
      title: 'Ready to save?',
      showCancelButton: true,
      confirmButtonText: 'Save',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (!customerId) {
          return addApiService.createCustomer(request)
            .then(response => {
              if (!response.statusCode == 200) {
                throw new Error(response.statusText)
              }

              setCustomerId(response);
              props.history.push({
                pathname: "/Customer/" + response,
              });

              Swal.fire({
                icon: 'success',
                title: 'Customer Created',
                showConfirmButton: false,
                timer: 1500
              })
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Failed to create the customer.`
              )
            });
        } else {
          return addApiService.updateCustomer(request)
            .then(response => {
              if (!response.statusCode == 200) {
                throw new Error(response.statusText)
              }

              Swal.fire({
                icon: 'success',
                title: 'Customer Updated',
                showConfirmButton: false,
                timer: 1500
              })
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Failed to update the customer.`
              )
            });
        }

      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {

    });

  }

  function validate(field, value, isRequired, isLettersOnly, isNumericOnly) {
    let vErrors = errors ?? {};

    if (isRequired && !value) {
      vErrors[field] = "This field is required";
    } else if (isLettersOnly && value && !value.match(/^[a-zA-Z\-]+(\.[a-zA-Z\-]+)*$/)) {
      vErrors[field] = "Letters only";
    } else if (isNumericOnly && value && typeof value === "string" && !value.match(/^\d+$/)) {
      vErrors[field] = "Numeric only";
    } else {
      delete vErrors[field];
    }

    if (Object.keys(vErrors).length) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }

    setErrors(vErrors);
    return !Object.keys(vErrors).length;
  }

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <SideBar
        sideBarExpanded={props.sideBarExpanded}
        setSideBarExpanded={props.setSideBarExpanded}
      />
      <div id="main">
        <div className="container-fluid custom-container">

          <AnimatedBody
            sideBarExpanded={props.sideBarExpanded}
            setSideBarExpanded={props.setSideBarExpanded}
            className="container-fluid custom-container"
          >
            <TopBar title={"Customer"} customer={customerName} customerId={customerId} />

            <div className="row row-margin">
              <TabContext value={selectedTab}>
                <TabList onChange={(e, value) => onTabChange(value)}>
                  <Tab label="Properties" value="0" />
                  <Tab label="Users" value="1" disabled={!customerId} />
                  <Tab label="Templates" value="2" disabled={!customerId} />
                  <Tab label="Recipients" value="3" disabled={!customerId} />
                  
                  {loggedInCustomer?.admin && //loggedInCustomer?.SUPERADMIN && loggedInCustomer?.customerId === undefined &&                   
                    <Tab label="Temporary Storage" value="4" disabled={!customerId} />
                  }  
                </TabList>
                <TabPanel value="0">
                  {customerId && !customerName ? <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" /> :
                    <form>
                      <div className="row">
                        <div className="col-12 col-lg-5 col-md-6">
                          <TextField variant="outlined" label="Customer Name" margin="normal" size="small" fullWidth
                            required
                            value={customerName}
                            onChange={(e) => { validate("customerName", e.target.value, true, true, false); setCustomerName(e.target.value) }}
                            error={errors["customerName"] ? true : false}
                            helperText={errors["customerName"] ? errors["customerName"] : ""} />
                          <TextField variant="outlined" label="Domain" margin="normal" size="small" fullWidth
                            value={domain}
                            onChange={(e) => setDomain(e.target.value)} />
                          <TextField variant="outlined" label="Project" margin="normal" size="small" fullWidth
                            value={project}
                            onChange={(e) => setProject(e.target.value)} />
                          <TextField variant="outlined" label="Base URL" margin="normal" size="small" fullWidth
                            value={baseUrl}
                            onChange={(e) => setBaseUrl(e.target.value)} />
                          <TextField variant="outlined" label="Default Environment Id" margin="normal" size="small" fullWidth
                            value={environment}
                            onChange={(e) => setEnvironment(e.target.value)} />
                          <TextField variant="outlined" label="Email used in 'From' field" margin="normal" size="small" fullWidth
                            value={sendGridEmailFrom}
                            onChange={(e) => setSendGridEmailFrom(e.target.value)} />  
                        </div>

                        <div className="col-12 col-lg-4 col-md-6">
                          <TextField variant="outlined" label="Customer (for Apptio)" margin="normal" size="small" fullWidth
                            value={customerApptio}
                            onChange={(e) => { setCustomerApptio(e.target.value) }} />
                          <TextField variant="outlined" label="Region" margin="normal" size="small" fullWidth
                            value={region}
                            onChange={(e) => { setRegion(e.target.value) }} />
                          <TextField variant="outlined" label="Region Index" margin="normal" size="small" fullWidth
                            value={regionIndex}
                            onChange={(e) => { validate("regionIndex", e.target.value, false, false, true); setRegionIndex(e.target.value) }}
                            error={errors["regionIndex"] ? true : false}
                            helperText={errors["regionIndex"] ? errors["regionIndex"] : ""} />
                          <TextField variant="outlined" label="Company" margin="normal" size="small" fullWidth
                            value={company}
                            onChange={(e) => { setCompany(e.target.value) }} />


                          {
                            customerId &&
                            <div style={{ paddingTop: 10 }}>
                              <Button variant="contained" component="label" >
                                Upload Logo
                                <input type="file" accept="image/jpeg, image/png" onChange={(e) => uploadLogo(e.target.files[0])} hidden />
                              </Button>
                              <img id="logoImg" className="data-img" style={{ marginLeft: 10 }} src={logoUrl} />
                            </div>
                          }

                        </div>

                        <div className="col-12 col-lg-3 col-md-12">
                          <FormControlLabel className="left-aligned pe-2"
                            label="Pdf Attachment allowed" labelPlacement="top"
                            componentsProps={{ typography: { className: 'w-100 d-inline-block text-truncate' } }}

                            control={<Switch className="switch-primary"
                              checked={attachmentAllowed}
                              onChange={(e) => { setAttachmentAllowed(e.target.checked) }} />} />

                          <FormControlLabel className="left-aligned pe-2"
                            label="Send Grid Integration" labelPlacement="top"
                            componentsProps={{ typography: { className: 'w-100 d-inline-block text-truncate' } }}
                            control={<Switch className="switch-primary" color="secondary"
                              checked={sendGridIntegration}
                              disabled={sendGridIntegration}
                              onChange={(e) => { setSendGridIntegration(e.target.checked) }} />} />

                          {
                            loggedInCustomer?.SUPERADMIN &&
                            <>
                              <FormControlLabel className="left-aligned pe-2"
                                label="New Designer Allowed" labelPlacement="top"
                                componentsProps={{ typography: { className: 'w-100 d-inline-block text-truncate' } }}
                                control={<Switch className="switch-primary"
                                  checked={newDesignerAllowed}
                                  onChange={(e) => { setNewDesignerAllowed(e.target.checked) }} />} />
                              <FormControlLabel className="left-aligned pe-2"
                                label="Edit DataExtractors Allowed" labelPlacement="top"
                                componentsProps={{ typography: { className: 'w-100 d-inline-block text-truncate' } }}
                                control={<Switch className="switch-primary"
                                  checked={editDataextractorsAllowed}
                                  onChange={(e) => { setEditDataextractorsAllowed(e.target.checked) }} />} />
                            </>
                          }
                        </div>
                      </div>

                      <div style={{ marginLeft: '0px' }}>
                        <button type="button" style={{ marginTop: '0px' }} className="btn btn-submit"
                          onClick={() => save()}
                          disabled={!validForm}>Save</button>

                        {loggedInCustomer?.SUPERADMIN ?
                          <button type="button" style={{ marginLeft: '5px' }} className="btn btn-cancel"
                            onClick={() => props.history.push({ pathname: "/Customers" })}>Cancel</button>
                          : <div> </div>
                        }
                      </div>
                    </form>
                  }
                </TabPanel>
                <TabPanel value="1">
                  <CustomerUsers customerId={customerId} />
                </TabPanel>
                <TabPanel value="2">
                  <CustomerTemplates customerId={customerId} customerName={customerName} newDesignerAllowed={newDesignerAllowed} editDataextractorsAllowed={editDataextractorsAllowed} />
                </TabPanel>
                <TabPanel value="3">
                  <CustomerRecipients customerId={customerId} />
                </TabPanel>
                <TabPanel value="4">
                  {customerId && !customerName ? <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" /> :
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="col-12">
			                <table style={{ width: "100%", overflow: "scroll !important" }}>
			                  <thead>
			                    <tr className="line">
			                      <th className="line-data">Template ID</th>
			                      <th className="line-data">Last Used</th>
			                      <th className="line-data">Table Count</th>
			                      <th className="line-data">Table Description</th>
			                    </tr>
			                  </thead>
			                  <tbody>
			                    {dbInstances && dbInstances.length >= 0 ?
			                      dbInstances.map((dbInstance) => (
			                        <tr key={dbInstance.templateId} className="line">
			                          <td className="line-data" title={dbInstance.jdbcUrl}>{dbInstance.templateId}</td>
			                          <td className="line-data">{dbInstance.lastUsed}</td>
			                          <td className="line-data">{dbInstance.tables?.length || 0}</td>
			                          <td className="line-data">{dbInstance.tables?.length > 0 ? 
			                            dbInstance.tables.map((dbTable) => (
				                          <span>{` { name = '${dbTable.name}', # rows = ${dbTable.numberOfRows}, # columns = ${dbTable.numberOfColumns}}`}</span> 
				                        ))
			                          : ''}</td>
			                        </tr>))
			                      :
			                      <tr><td>
			                        {(dbInstances && dbInstances.length === 0) ?
			                          "No Databases found" : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
			                      </td></tr>
			                    }
			
			                  </tbody>
			                </table>
			              </div>
                        </div>
                      </div>
                    </form>
                  }      
                </TabPanel>
              </TabContext>
            </div>

          </AnimatedBody>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Customer);
