import React from 'react';
// import '../Style/AddApiData.css';
import settingService from '../Services/settingService';

class AddSettingData extends React.Component {
  constructor(props) {
    super(props);
    this.settingService = new settingService();
    this.state = {
      key: '',
      secret: ''
    }
  }

  handelChangeSubmit = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  submitKeyData = () => {
    const userId =  this.props.userId
    const keyData = {
      key: this.state.key,
      secret: this.state.secret
    }
    this.settingService.addApiKey(userId, keyData).then(response => {
    //   this.setState({
    //     key:'',
    //     secret: ''
    //   });
      this.props.closeModal(false)
    })
  }

  render() {

    return (

      <div className="card">
        <h5 className="card-header info-color white-text text-center py-4">
          <strong>Add Key</strong>
          <span className="cross"
            onClick={() => this.props.closeModal(true)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </span>
        </h5>
        <div className="card-body px-lg-5 pt-0">
          {/* <form className="text-center"> */}
            {/* <div className="col">
                            <div className="md-form">
                                <label className="label-float">Customer</label>
                                <input type="text" className="form-control" name="customer" value={this.state.customerId} onChange={this.handelChangeSubmit} />
                            </div>
                        </div> */}
            <div className="col">
              <div className="md-form">
                <label className="label-float"> Public Key </label>
                <input type="text" className="form-control" name="key" value={this.state.key} onChange={this.handelChangeSubmit} />
              </div>
            </div>
            <div className="col">
              <div className="md-form">
                <label className="label-float">Secret Key</label>
                <input type="text" className="form-control" name="secret" value={this.state.secret} onChange={this.handelChangeSubmit} />

              </div>
            </div>
            <button className="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0 width-30" type="submit"
              onClick={() => this.submitKeyData()} >
              Add
            </button>
          {/* </form> */}
        </div>
      </div>
    )
  }
}

export default AddSettingData