import * as React from "react";
import { withRouter } from "react-router-dom";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { useState, useEffect } from 'react';
import authService from '../../Services/authServices'
import UserService from '../../Services/UserService';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import UserModal from "./UserModal";

import Swal from 'sweetalert2';

function CustomerUsers(props) {

  const userService = new UserService();
  const myServiceInstance = new authService();

  const [customerId, setCustomerId] = useState(props.customerId);
  const [users, setUsers] = useState(null);

  const [showUserModal, setShowUserModal] = useState(false);

  const loggedInCustomer = myServiceInstance.getCurrentCustomer();
  if (!loggedInCustomer) {
    props.history.push("/Login")
  }

  if (!customerId) {
    props.history.push("/Customer")
  }

  useEffect(() => {
    if (customerId) {
      findUsersByCustomer();
    }
  }, [customerId]);

  function findUsersByCustomer() {
    userService.findUsersByCustomer(customerId).then(response => {
      setUsers(response);
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  function closeUserModal() {
    findUsersByCustomer();
    setShowUserModal(false);
  }

  function showHideOTP(user) {
    user.displayOTP = !user.displayOTP;
  }

  function confirmResetPassword(userId) {
    Swal.fire({
      title: 'Do you really want to reset the Password of this user?',
      showCancelButton: true,
      confirmButtonText: 'Reset Password',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return userService.resetPassword(userId)
          .then(response => {
            if (!response.statusCode == 200) {
              throw new Error(response.statusText)
            }

            Swal.fire({
              icon: 'success',
              title: 'Password was changed',
              showConfirmButton: false,
              timer: 1500
            });

            findUsersByCustomer();
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Failed to reset the password`
            )
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {

    });
  }

  function confirmDeleteUser(userId) {
    Swal.fire({
      title: 'Do you really want to delete this user?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return userService.deleteUser(userId)
          .then(response => {
            if (!response.statusCode == 200) {
              throw new Error(response.statusText)
            }

            Swal.fire({
              icon: 'success',
              title: 'User Deleted',
              showConfirmButton: false,
              timer: 1500
            });

            findUsersByCustomer();
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Failed to delete the user.`
            )
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {

    });
  }


  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <div className="row" style={{ paddingLeft: 5 }}>
        <div className="col-12">
          <table style={{ width: "100%", overflow: "scroll !important" }}>
            <thead>
              <tr className="line">
                <th className="line-data">Users</th>
                <th className="line-data" width={120}>Registered</th>
                <th className="line-data" width={120}>Admin</th>
                <th className="line-data" width={120}>Apptio Profile</th>
                <th className="line-data" width={100}></th>
                <th className="line-data" width={250} style={{ textAlign: "right" }}>
                  <button type="button" className="btn btn-submit"
                    style={{ marginTop: 0, marginRight: 6 }}
                    onClick={() => setShowUserModal(true)}>
                    Add User
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {users && users.length > 0 ?
                users.map((user, index) => (
                  <tr key={user.id} className="line">
                    <td className="line-data">{user.userName}</td>
                    <td className="line-data" style={{ textTransform: "capitalize" }}>{user.isRegistered}</td>
                    <td className="line-data" style={{ textTransform: "capitalize" }}>{user.admin ? 'Yes' : 'No'}</td>
                    <td className="line-data" style={{ textTransform: "capitalize" }}>{user.hasApptioProfile ? 'Yes' : 'No'}</td>
                    <td align="right">
                      {user.displayOTP ? user.otp : ''}
                    </td>
                    <td className="line-data" style={{ textAlign: "right" }}>
                      {
                        user.otp ?
                          <a href={"#u" + index} onClick={() => showHideOTP(user)} title={user.displayOTP ? "Hide OTP" : "Show OTP"}>
                            {
                              user.displayOTP ?
                                <FontAwesomeIcon icon={faEyeSlash} color="#6c6c6f" />
                                :
                                <FontAwesomeIcon icon={faEye} color="#6c6c6f" />
                            }
                          </a>
                          : <span />
                      }
                      <button type="button" className="btn btn-light"
                        style={{ margin: "0 20px" }}
                        onClick={() => confirmResetPassword(user.id)}>
                        Reset Password
                      </button>
                      <a href="#" onClick={() => confirmDeleteUser(user.id)}>
                        <FontAwesomeIcon icon={faTrashCan} color="#6c6c6f" />
                      </a>
                    </td>
                  </tr>))
                :
                <tr>
                  <td colSpan={2}>
                    {(users && users.length === 0) ? "No users found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                  </td>
                </tr>
              }

            </tbody>
          </table>
        </div>
      </div>

      {loggedInCustomer?.SUPERADMIN ?
        <div className="row-margin" style={{ marginBottom: 5 }}>
          <button type="button" className="btn btn-cancel"
            onClick={() => props.history.push({ pathname: "/Customers" })}>Cancel</button>
        </div>
        : <div> </div>
      }
      <UserModal customerId={customerId} open={showUserModal} close={() => closeUserModal()} />
    </div>
  );
}

export default withRouter(CustomerUsers);
