import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Skeleton from "react-loading-skeleton";

import SetDefaultFilters from "./SetDefaultFilters";

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC'
}

export default function PreviewDataModal(props) {
  
  const [currentMaxRows, setCurrentMaxRows] = useState(props.maxRows);
  const [orderByFieldCode, setOrderByFieldCode] = useState('');
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ASC);
  
  const [showSetDefaultFiltersModal, setShowSetDefaultFiltersModal] = useState(false);
  
  useEffect(() => {
	setCurrentMaxRows(props.maxRows);
    setOrderByFieldCode('');
  }, [props.show]);
  
  function handleCloseModalSetFilters() {
    setShowSetDefaultFiltersModal(false);
  }
  
  function defaultFiltersUpdated() {
	props.onRefreshPreviewData(); 
  }
  
  return (
	<>
	<Modal show={props.show} onHide={props.onHide} backdrop="static" keyboard={true} size={'xl'}>
	  <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       {props.rowCount !== 0 ? <>
        <form>
          <div className="row">
            <div className="col-5">
              <p className="mb-1">
                {props.introText} {props.dataSetHasFilters && <span className="font-italic">(The dataset has <a href="#" onClick={() => setShowSetDefaultFiltersModal(true) }>filters</a> set)</span>}
              </p>
              {!props.dataLoaded && <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
            </div>  
            {props.requestedRowsOptions &&
            <div className="col-2">
              <FormControl variant="outlined" margin="normal" size="small" fullWidth className="mt-0">
                <InputLabel id="currentMaxRows">Max rows</InputLabel>
                <Select labelId="currentMaxRows" MenuProps={{ disableScrollLock: true }} value={currentMaxRows} label="Max rows"
                  onChange={(e) => { setCurrentMaxRows(e.target.value); props.onRequestedRowsUpdated(e.target.value); }}>
                  {props.requestedRowsOptions.map((opt) => (
                    <MenuItem key={opt} value={opt}>{`${opt} rows`}</MenuItem>
                  ))}
                </Select>
              </FormControl>  
            </div>
            }
            {props.requestedOrderByOptions && <>
	        <div className="col-3">
	          <FormControl variant="outlined" margin="normal" size="small" fullWidth className="mt-0">
                <InputLabel id="orderByFieldCode">Order By</InputLabel>
                <Select labelId="orderByFieldCode" MenuProps={{ disableScrollLock: true }} value={orderByFieldCode} label="Order By"
                  onChange={(e) => { setOrderByFieldCode(e.target.value); if(e.target.value !== ''){ props.onRequestedOrderByUpdated(e.target.value + ' ' + sortDirection)} } }>
                    <MenuItem key={'none'} value={''}>{'None'}</MenuItem>
                  {props.requestedOrderByOptions.map((opt) => (
                    <MenuItem key={opt.code} value={opt.code}>{opt.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>    
            </div>
            <div className="col-2">
	          <FormControl variant="outlined" margin="normal" size="small" fullWidth className="mt-0">
                <InputLabel id="sortDirection">Sort Direction</InputLabel>
                <Select labelId="sortDirection" MenuProps={{ disableScrollLock: true }} value={sortDirection} label="Sort Direction"
                  onChange={(e) => { setSortDirection(e.target.value); if(orderByFieldCode !== ''){ props.onRequestedOrderByUpdated(orderByFieldCode + ' ' + e.target.value)}} }>
                    
                    <MenuItem value={SORT_DIRECTION.ASC} title={'Ascending'}>Asc</MenuItem>
                    <MenuItem value={SORT_DIRECTION.DESC} title={'Descending'}>Desc</MenuItem>
                    
                </Select>
              </FormControl>    
            </div>  
            </>
            }
          </div>
        </form>
        
        <div className="table-responsive" >
          <table className="table table-sm" style={{ whiteSpace: "pre" }}>
            <thead>
              <tr className="line text-center">
                {Object.keys(props.firstRow).map(rowColumn => (
                  <th className="line-data" key={rowColumn}>{rowColumn}</th>
                ))}
              </tr>
                  
            {props.showPickColumnButton &&
              <tr className="line text-center">
                {Object.keys(props.firstRow).map(rowColumn => (
                  <th className="line-data" key={rowColumn + '-0'}>
                    <button type="button" className="btn btn-submit mt-0 py-1 px-2" onClick={() => props.onPickColumn(rowColumn)} title="Pick Column">Pick</button>
                  </th>
                ))}
              </tr>
            }
            </thead>
            <tbody>
              {props.dataSet.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(rowData).map((rowValues, rowColIndex) => (
                    <td key={rowIndex + '-' + rowColIndex} style={{ maxWidth: "30vw" }}>
                      {(typeof rowValues === 'object') ?
                        <div style={{ maxHeight: 200, overflowY: "auto", whiteSpace: "pre-wrap" }}>
                          {JSON.stringify(rowValues, null, 4)}
                        </div>
                        : (rowValues === true || rowValues === false ? rowValues.toString() : rowValues)
                      }
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </> :
        <p>
          <strong>No records found</strong>
        </p>
       }
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-cancel" data-dismiss="modal" onClick={props.onHide}>Close</button>
      </Modal.Footer>
    </Modal>
    
    <SetDefaultFilters show={showSetDefaultFiltersModal && props.templateId} onHide={() => handleCloseModalSetFilters() } 
        title={'Template Filters'} introText={`Set the new default values for template filters, these values are used in 'Preview' option and 'Designer'`}
        onSave={() => defaultFiltersUpdated() } templateId={props.templateId}/>
        
     </>   
  );	
}