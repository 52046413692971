import * as React from "react";
import { useState, useEffect } from 'react';
import { useParams, withRouter } from "react-router-dom";
import SideBar from "./SideBar";
import TopBar from "./TopBar";
import authService from '../../Services/authServices'
import TemplateService from '../../Services/TemplateService';
import AnimatedBody from "../../Wrappers/AnimatedBody";

import { Avatar, Breadcrumbs, Card, CardContent, CardHeader, FormControl, FormControlLabel, InputLabel, Link, MenuItem, Select, Tab, TextField, Typography, Switch } from '@mui/material';

import Swal from 'sweetalert2';

import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import Skeleton from "react-loading-skeleton";
import AddApiService from "../../Services/addApiService";
import TemplateDataExtractorService from "../../Services/TemplateDataExtractorService";
import TemplateDataExtractorFieldService from "../../Services/TemplateDataExtractorFieldService";
import TemplateParameterService from '../../Services/TemplateParameterService';
import TemplateFilterService from '../../Services/TemplateFilterService';

import PredefinedTemplateService from "../../Services/PredefinedTemplateService";
import { PREDEFINED_ROUTE } from "./PredefinedTemplate";

import PreviewDataModal from "./PreviewDataModal";

export const SOURCE_TYPE = {
  JSON_REST_API: 'JSON_REST_API',
  STAGING: 'STAGING'
}

export const FIELD_TYPE = {
  STRING: 'STRING', 
  NUMERIC: 'NUMERIC',
  BOOLEAN: 'BOOLEAN',
  JSON: 'JSON',
  TIMESTAMP: 'TIMESTAMP'
}

function TemplateDataExtractorField(props) {

  let isValidUser = true;

  const addApiService = new AddApiService();
  const myServiceInstance = new authService();
  const templateService = new TemplateService();
  const predefinedTemplateService = new PredefinedTemplateService();
  const templateDataExtractorService = new TemplateDataExtractorService();
  const templateDataExtractorFieldService = new TemplateDataExtractorFieldService();
  const templateParameterService = new TemplateParameterService();
  const templateFilterService = new TemplateFilterService();

  const { customerId, templateId, extractorId, fieldId } = useParams();
  const [recordId, setRecordId] = useState(fieldId);
  const [customerName, setCustomerName] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [extractorName, setExtractorName] = useState('');
  const [extractorSourceType, setExtractorSourceType] = useState('');

  const isPredefinedTemplate = !customerId || customerId === undefined;

  // Validating if user was logged in
  const loggedInCustomer = myServiceInstance.getCurrentCustomer();
  if (!loggedInCustomer?.SUPERADMIN
    && (!customerId
      || !loggedInCustomer?.admin
      || (customerId !== (loggedInCustomer?.customerId?.toString())))) {
    isValidUser = false;
    props.history.push({
      pathname: "/Customer/" + loggedInCustomer?.customerId,
      preSelectedTab: "2"
    });
  }

  const [selectedTab, setSelectedTab] = useState("0");

  const [validForm, setValidForm] = useState(true);
  const [errors, setErrors] = useState({});


  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [dataType, setDataType] = useState(FIELD_TYPE.STRING);
  const [sortOrder, setSortOrder] = useState(Number(props.location?.fieldsLength || 0) + 1);
  const [sizeLimit, setSizeLimit] = useState(2000);
  const [precision, setPrecision] = useState(0);
  const [scale, setScale] = useState(0);
  const [inputFormat, setInputFormat] = useState('');
  const [outputFormat, setOutputFormat] = useState('');
  const [filterCodeRelated, setFilterCodeRelated] = useState('');
  const [fieldHidden, setFieldHidden] = useState(false);
  const [saveEmptyZeroWhenNull, setSaveEmptyZeroWhenNull] = useState(true);
  const [createWithIndex, setCreateWithIndex] = useState(false);

  // JSON
  const [jsonPath, setJsonPath] = useState('None');

  const DEFAULT_MAX_ROWS_RAW_DATA = 15;
  const [previewRawData, setPreviewRawData] = useState({ rawData: [], loaded: false });
  const [enablePreviewRawDataButton, setEnablePreviewRawDataButton] = useState(true);
  const [showRequestPreviewRawModal, setShowRequestPreviewRawModal] = useState(false);
  const [showPickRawColumnButton, setShowPickRawColumnButton] = useState(true);
  const [maxRowsRawData, setMaxRowsRawData] = useState(DEFAULT_MAX_ROWS_RAW_DATA);
  const [orderByCodeRawData, setOrderByCodeRawData] = useState('');
  
  const [parametersFilters, setParametersFilters] = useState([{ label: 'None', value: '' }]);
  
  useEffect(() => {
    if (isValidUser) {
      if (templateId !== undefined) {
        if (customerId !== undefined) {
	        addApiService.getCustomer(customerId).then(response => {
	          if (!response) {
	            loggedInCustomer?.SUPERADMIN ?
	              props.history.push({ pathname: "/Customers" }) :
	              props.history.push({ pathname: "/EmailSchedule" });
	            return;
	          }
	          setCustomerName(response.customerName || '');
	        });
	    }

        if (isPredefinedTemplate && loggedInCustomer?.SUPERADMIN) {
          predefinedTemplateService.findById(templateId).then(response => {
            if (response.status !== 200) {
              errorMessage(!response.data.message ? 'No template found' : response.data.message);
              return;
            }

            setTemplateName(response.data.name || '');
          });
        } else {
          templateService.findById(customerId, templateId).then(response => {
            if (response.status !== 200) {
              errorMessage(!response.data.message ? 'No template found' : response.data.message);
              return;
            }
            setTemplateName(response.data.templateName || '');
          });
        }
      }

      if (templateId !== undefined && extractorId !== undefined) {
        
        let paramsFilters = [];
        paramsFilters.push({ label: 'None', value: '' });
                
        //loading parameters
        templateParameterService.findAll(templateId).then(paramResponse => {
          let parameters = paramResponse.data;
            
          //loading filters
          templateFilterService.findAll(templateId).then(filterResponse => {
	        let filters = filterResponse.data;
	          
	        parameters.map(param => {
		      paramsFilters.push({ label: param.code, value: '${' + param.code + '}'});
	        });
	          
	        filters.map(filter => {
		      paramsFilters.push({ label: filter.code, value: '${' + filter.code  + '}'});
	        });
	          
	        setParametersFilters(paramsFilters);
          });
        }).then(() => {
	      templateDataExtractorService.findById(templateId, extractorId).then(response => {
            if (response.status !== 200) {
              errorMessage(!response.data.message ? 'No extractor found' : response.data.message);
              return;
            }
            setExtractorName(response.data.name);
            setExtractorSourceType(response.data.sourceType);
          
            if (recordId !== undefined && recordId !== '') {
              templateDataExtractorFieldService.findById(templateId, extractorId, recordId, response.data.sourceType).then(response => {
	            if (response.status !== 200) {
	              errorMessage(!response.data.message ? 'No extractor field found' : response.data.message);
	              return;
	            }
	            setCode(response.data.code);
	            setName(response.data.name);
	            setDataType(response.data.dataType);
	            setSortOrder(response.data.sortOrder);
	            setSizeLimit(response.data.sizeLimit);
	            setPrecision(response.data.precision || '');
	            setScale(response.data.scale || '');
	            setInputFormat(response.data.inputFormat || '');
	            setOutputFormat(response.data.outputFormat || '');
	            setFilterCodeRelated(response.data.filterCodeRelated || '');
	            setFieldHidden(response.data.isHidden);
	            setSaveEmptyZeroWhenNull(response.data.saveEmptyZeroWhenNull);
	            setCreateWithIndex(response.data.createWithIndex);
	            setJsonPath(response.data.jsonPath);
	          });
	        }
          });  
        });
      }
    }
  }, []);
  
  function handleChangeName(name) {
	if (name !== '') {
	  let nameAsCode = name.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/[\W_]+/g, '');
      nameAsCode = nameAsCode.substring(0, 30);
      
	  setCode(nameAsCode);
	}
	
	setName(name);
  }

  function errorMessage(message) {
    Swal.fire({
      title: message,
      icon: 'error',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        props.history.push({
          pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + `${templateId}/Extractor/${extractorId}`,
          preSelectedTab: "3"
        });
      }
    });
  }

  function save() {
    validate("dataType", dataType, true, false, false);
    validate("name", name, true, false, false);
    validate("code", code, true, false, false);
    const valid = validate("sortOrder", sortOrder, false, false, true);

    if (!valid) {
      return;
    }

    if (extractorSourceType === SOURCE_TYPE.JSON_REST_API) {
      saveJSONExtractorField();
    } else {
      saveSQLExtractorField();
    }

  }

  function saveJSONExtractorField() {
    const request = {
      code,
      name,
      dataType,
      sortOrder,
      sizeLimit,
      precision,
      scale,
      inputFormat,
      outputFormat,
      filterCodeRelated,
      isHidden: fieldHidden,
      saveEmptyZeroWhenNull,
      createWithIndex,
      jsonPath,
      dataExtractor: {
        id: extractorId,
        sourceType: extractorSourceType
      }
    }
    if (!recordId) {
      templateDataExtractorFieldService.saveJSONField(templateId, extractorId, request).then(({ data }) => {
        saveSuccess('Field Saved');
        setRecordId(data.id);
        props.history.push({
          pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + `${templateId}/Extractor/${extractorId}`,
          preSelectedTab: "3"
        });
      }).catch((error) => {
        errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to update the field`);
      });
    } else {
      templateDataExtractorFieldService.updateJSONField(templateId, extractorId, recordId, request).then(() => {
        saveSuccess('Field Saved');
        props.history.push({
          pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + `${templateId}/Extractor/${extractorId}`,
          preSelectedTab: "3"
        });
      }).catch((error) => {
        errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to update the field`);
      });
    }
  }

  function saveSQLExtractorField() {
    const request = {
      code,
      name,
      dataType,
      sortOrder,
      sizeLimit,
      precision,
      scale,
      inputFormat,
      outputFormat,
      filterCodeRelated,
      isHidden: fieldHidden,
      saveEmptyZeroWhenNull,
      createWithIndex,
      dataExtractor: {
        id: extractorId,
        sourceType: extractorSourceType
      }
    }
    
    if (!recordId) {
      templateDataExtractorFieldService.saveSQLField(templateId, extractorId, request).then(({ data }) => {
        saveSuccess('Field Saved');
        setRecordId(data.id);
        props.history.push({
          pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + `${templateId}/Extractor/${extractorId}`,
          preSelectedTab: "3"
        });
      }).catch((error) => {
        errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to update the field`);
      });
    } else {
      templateDataExtractorFieldService.updateSQLField(templateId, extractorId, recordId, request).then(() => {
        saveSuccess('Field Saved');
        props.history.push({
          pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + `${templateId}/Extractor/${extractorId}`,
          preSelectedTab: "3"
        });
      }).catch((error) => {
        errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to update the field`);
      });
    }
  }

  function saveSuccess(message, warning) {
    Swal.fire({
      icon: warning ? 'warning' : 'success',
      title: message,
      showConfirmButton: false,
      timer: 1500
    });
  }
  
  function errorMessageSaving(message) {
    Swal.fire({
      title: message,
      icon: 'error',
      allowOutsideClick: false
    });
  }

  function validate(field, value, isRequired, isLettersOnly, isNumericOnly) {
    let vErrors = errors ?? {};

    if (isRequired && !value) {
      vErrors[field] = "This field is required";
    } else if (isLettersOnly && value && !value.match(/^[a-zA-Z]+(\.[a-zA-Z]+)*$/)) {
      vErrors[field] = "Letters only";
    } else if (isNumericOnly && value && typeof value === "string" && !value.match(/^\d+$/)) {
      vErrors[field] = "Numeric only";
    } else {
      delete vErrors[field];
    }

    if (Object.keys(vErrors).length) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }

    setErrors(vErrors);
    return !Object.keys(vErrors).length;
  }
  
  function onChangeDataType(value) {
    setDataType(value);
    
    if(value === FIELD_TYPE.NUMERIC) {
	  setPrecision(12);
	  setScale(2);
	  setInputFormat("'$'#,##0.00");
	  setSizeLimit(null);
    } else if(value === FIELD_TYPE.STRING) {
	  setPrecision(null);
	  setScale(null);
	  setInputFormat('');
	  setSizeLimit(2000);
    } else {
	  setPrecision(null);
	  setScale(null);
	  setInputFormat('');
	  setSizeLimit(null);
    }
  }
  
  function showPreviewRawData() {
    if (templateId !== undefined && extractorId !== undefined) {
      setEnablePreviewRawDataButton(false);
      
      // Avoid to loadinf raw data multiple times
      if(!previewRawData.loaded){
        const request = {
          userApptioProfile: JSON.parse(sessionStorage.getItem('currentCustomer')).userName,
          forceDBUse: false,
          runPreviousExtractors: extractorSourceType === SOURCE_TYPE.STAGING,
          maxRows: maxRowsRawData,
          orderByFields: [orderByCodeRawData]
        };

        templateDataExtractorService.previewRawDataExtractor(templateId, extractorId, request).then(response => {
          if(response) {
            response.data.loaded = true;
            setPreviewRawData(response.data);
            setShowRequestPreviewRawModal(true);
            setShowPickRawColumnButton(true);
          } else {
	        setPreviewRawData({ rawData: [], loaded: true });
          }
          
          setEnablePreviewRawDataButton(true);
        }).catch((error) => {
          setPreviewRawData({ rawData: [], loaded: false });
          
          console.log('ERROR: ', error);
        });
      } else {
	    setShowRequestPreviewRawModal(true);
	    setShowPickRawColumnButton(true);
	    setEnablePreviewRawDataButton(true);
      }
    } else {
      Swal.fire({
        icon: 'warn',
        title: 'Unable to preview raw data, make sure you have saved the latest changes',
      });
    }
  }

  function handleCloseModalPreviewRaw() {
    setShowRequestPreviewRawModal(false);
    setEnablePreviewRawDataButton(true);
  }
  
  function handlePickRawColumn(columnId) {
    if(extractorSourceType === SOURCE_TYPE.JSON_REST_API) {	
	  setJsonPath(columnId);
    } else {
	  setCode(columnId.toLowerCase());
    }
    
	handleCloseModalPreviewRaw();
  }
  
  useEffect(() => {
	if(showRequestPreviewRawModal){
	  previewRawData.loaded = false;	
	  showPreviewRawData();
	}
  }, [maxRowsRawData, orderByCodeRawData]);	

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <SideBar
        sideBarExpanded={props.sideBarExpanded}
        setSideBarExpanded={props.setSideBarExpanded}
      />
      <div id="main">
        <div className="container-fluid custom-container">

          <AnimatedBody
            sideBarExpanded={props.sideBarExpanded}
            setSideBarExpanded={props.setSideBarExpanded}
            className="container-fluid custom-container"
          >

            {isPredefinedTemplate ? '' :
              <TopBar title={"Customer"} customer={customerName} customerId={customerId} />
            }

            <div className="row row-margin">
              {templateId && !templateName ? <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" /> :
                <>

                  <Breadcrumbs className="pb-3" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="#"
                      onClick={() => props.history.push({
                        pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}`),
                        preSelectedTab: "2"
                      })}>Templates</Link>
                    <Link underline="hover" color="inherit" href="#"
                      onClick={() => props.history.push({
                        pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + templateId,
                        preSelectedTab: "1"
                      })}>{templateName}</Link>
                    <Link underline="hover" color="inherit" href="#"
                      onClick={() => props.history.push({
                        pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + `${templateId}/Extractor/${extractorId}`,
                        preSelectedTab: "3"
                      })}>{extractorName}</Link>
                    <Typography color="text.primary">{recordId ? name : 'Create new field'}</Typography>
                  </Breadcrumbs>

                  <TabContext value={selectedTab}>

                    <TabList onChange={(e, value) => setSelectedTab(value)}>
                      <Tab label="Properties" value="0" />
                    </TabList>

                    <TabPanel value="0">
                      <form>
                        <div className="row">
                          <div className="col-6">
                            <TextField variant="outlined" label="Name" margin="normal" size="small" fullWidth
                              required
                              value={name}
                              onChange={(e) => {
                                validate("name", e.target.value, true, false, false);
                                handleChangeName(e.target.value);
                              }}
                              error={errors["name"] ? true : false}
                              helperText={errors["name"] ? errors["name"] : ""} />
                          </div>
                          
                          {extractorSourceType === SOURCE_TYPE.JSON_REST_API &&
                            <div className="col-6">
                              <TextField variant="outlined" label="Code" margin="normal" size="small" fullWidth
                                required value={code}
                                onChange={(e) => {
                                  validate("code", e.target.value, true, false, false);
                                  setCode(e.target.value);
                                }}
                                error={errors["code"] ? true : false}
                                helperText={errors["code"] ? errors["code"] : ""} />
                            </div>
                          }
                          
                          {extractorSourceType === SOURCE_TYPE.STAGING && <>
                            <div className="col-4">
                              <TextField variant="outlined" label="Code" margin="normal" size="small" fullWidth
                                required value={code}
                                onChange={(e) => {
                                  validate("code", e.target.value, true, false, false);
                                  setCode(e.target.value);
                                }}
                                error={errors["code"] ? true : false}
                                helperText={errors["code"] ? errors["code"] : ""} />
                            </div>
                            
                            <div className="col-2">
                              <LoadingButton type="button" className="btn btn-submit mt-3" disabled={!enablePreviewRawDataButton}
                                onClick={() => showPreviewRawData()} fullWidth
                                loading={!enablePreviewRawDataButton}
                                loadingPosition="end"
                                style={{ textTransform: "inherit" }}>
                                Raw Data
                              </LoadingButton>
                            </div>
                          </>}  

                          <div className="col-6">
                            <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                              <InputLabel id="dataTypeLabel" required>Data Type</InputLabel>
                              <Select labelId="dataTypeLabel"
                                required MenuProps={{ disableScrollLock: true }}
                                value={dataType}
                                label="Data Type"
                                onChange={(e) => {
                                  validate("dataType", e.target.value, true, false, false);
                                  onChangeDataType(e.target.value);
                                }}
                              >
                                <MenuItem key={FIELD_TYPE.STRING} value={FIELD_TYPE.STRING}>String</MenuItem>
                                <MenuItem key={FIELD_TYPE.NUMERIC} value={FIELD_TYPE.NUMERIC}>Numeric</MenuItem>
                                <MenuItem key={FIELD_TYPE.BOOLEAN} value={FIELD_TYPE.BOOLEAN}>Boolean</MenuItem>
                                <MenuItem key={FIELD_TYPE.JSON} value={FIELD_TYPE.JSON}>Json</MenuItem>
                                <MenuItem key={FIELD_TYPE.TIMESTAMP} value={FIELD_TYPE.TIMESTAMP}>Timestamp</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          
                          <div className="col-6">
                            <TextField variant="outlined" label="Sort Order" margin="normal" size="small" 
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} fullWidth required
                              value={sortOrder}
                              onChange={(e) => {
                                validate("sortOrder", e.target.value, true, false, true);
                                setSortOrder(e.target.value);
                              }}
                              error={errors["sortOrder"] ? true : false}
                              helperText={errors["sortOrder"] ? errors["sortOrder"] : ""} />
                          </div>
                          
                          <div className="col-2">
                              <FormControlLabel className="left-aligned" label="Hidden" labelPlacement="top"
                                control={<Switch className="switch-primary"
                                  checked={fieldHidden}
                                  onChange={(e) => { setFieldHidden(e.target.checked) }} />} />
                          </div>
                          
                          <div className="col-2">
                              <FormControlLabel className="left-aligned" label="Save Empty/Zero when NULL" labelPlacement="top"
                                control={<Switch className="switch-primary" title="If field value is NULL, zero will be saved for NUMERIC and empty string for STRING"
                                  checked={saveEmptyZeroWhenNull}
                                  onChange={(e) => { setSaveEmptyZeroWhenNull(e.target.checked) }} />} />
                          </div>
                          
                          <div className="col-2">
                              <FormControlLabel className="left-aligned" label="Create With Index" labelPlacement="top"
                                control={<Switch className="switch-primary" title="When enabled, the table column will have an INDEX"
                                  checked={createWithIndex}
                                  onChange={(e) => { setCreateWithIndex(e.target.checked) }} />} />
                          </div>
                          
                          <div className="col-4">
                            {extractorSourceType === SOURCE_TYPE.JSON_REST_API &&
                              <TextField variant="outlined" label="JSON Path" margin="normal" size="small" fullWidth
                                value={jsonPath}
                                onChange={(e) => {
                                  validate("jsonpath", e.target.value, true, false, false);
                                  setJsonPath(e.target.value);
                                }}
                                error={errors["jsonpath"] ? true : false}
                                helperText={errors["jsonpath"] ? errors["jsonpath"] : "Set 'None' when not using the JSON source"} />
                            }    
                          </div>
                          
                          <div className="col-2">
                            {extractorSourceType === SOURCE_TYPE.JSON_REST_API &&
                              <LoadingButton type="button" className="btn btn-submit mt-3" disabled={!enablePreviewRawDataButton}
                                onClick={() => showPreviewRawData()} fullWidth
                                loading={!enablePreviewRawDataButton}
                                loadingPosition="end"
                                style={{ textTransform: "inherit" }}>
                                Raw Data
                              </LoadingButton>
                            }  
                          </div>
                          
                          {dataType === FIELD_TYPE.STRING ? <>
	                          <div className="col-6">
	                            <TextField variant="outlined" label="Size Limit" margin="normal" size="small" 
	                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} fullWidth
	                              value={sizeLimit}
	                              onChange={(e) => {
	                                validate("sizeLimit", e.target.value, false, false, true);
	                                setSizeLimit(e.target.value);
	                              }}
	                              error={errors["sizeLimit"] ? true : false}
	                              helperText={errors["sizeLimit"] ? errors["sizeLimit"] : ""} />
	                          </div>
	                          <div className="col-6"></div>
	                      </> : ''}    
                          
                          {dataType === FIELD_TYPE.NUMERIC ? <>
	                          <div className="col-6">
	                            <TextField variant="outlined" label="Precision" margin="normal" size="small" 
	                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} fullWidth
	                              value={precision}
	                              onChange={(e) => {
	                                validate("precision", e.target.value, false, false, true);
	                                setPrecision(e.target.value);
	                              }}
	                              error={errors["precision"] ? true : false}
	                              helperText={errors["precision"] ? errors["precision"] : ""} />
	                          </div>
	                          
	                          <div className="col-6">
	                            <TextField variant="outlined" label="Scale" margin="normal" size="small" 
	                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} fullWidth
	                              value={scale}
	                              onChange={(e) => {
	                                validate("scale", e.target.value, false, false, true);
	                                setScale(e.target.value);
	                              }}
	                              error={errors["scale"] ? true : false}
	                              helperText={errors["scale"] ? errors["scale"] : ""} />
	                          </div>
                          </> : ''}
                          
                          <div className="col-6">
                            <TextField variant="outlined" label="Input Format" margin="normal" size="small" 
                              fullWidth
                              value={inputFormat}
                              onChange={(e) => {
                                setInputFormat(e.target.value);
                              }}
                              error={errors["inputFormat"] ? true : false}
                              helperText={errors["inputFormat"] ? errors["inputFormat"] : ""} />
                          </div>
                          
                          <div className="col-6">
                            <TextField variant="outlined" label="Output Format" margin="normal" size="small" 
                              fullWidth
                              value={outputFormat}
                              onChange={(e) => {
                                setOutputFormat(e.target.value);
                              }}
                              error={errors["outputFormat"] ? true : false}
                              helperText={errors["outputFormat"] ? errors["outputFormat"] : ""} />
                          </div>
                          
                          <div className="col-6">
                            <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                              <InputLabel id="filterCodeLabel">Filter/Parameter Related</InputLabel>
                              <Select labelId="filterCodeLabel"
                                defaultValue=""
                                value={filterCodeRelated}
                                label="Filter/Parameter Related"
                                onChange={(e) => {
                                  setFilterCodeRelated(e.target.value);
                                }}>
                                {parametersFilters && parametersFilters.map((opt) => (
                                  <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                              
                          </div>
                          
                        </div>
                      </form>
                    </TabPanel>
                  </TabContext>

                  {selectedTab !== "3" ?
                    <div className="px-4">
                      <button type="button" style={{ marginTop: 0 }} className="btn btn-submit"
                        onClick={() => save()}
                        disabled={!validForm}>Save</button>

                      <button type="button" style={{ marginLeft: 5 }} className="btn btn-cancel"
                        onClick={() => props.history.push({
                          pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + `${templateId}/Extractor/${extractorId}`,
                          preSelectedTab: "3"
                        })}>Cancel</button>
                    </div>
                    : ''}
                </>
              }
            </div>
          </AnimatedBody>
        </div>
      </div>
      
      <PreviewDataModal show={showRequestPreviewRawModal} onHide={() => handleCloseModalPreviewRaw()} maxRows={maxRowsRawData}
        rowCount={previewRawData && previewRawData?.rawData?.length || 0} showPickColumnButton={showPickRawColumnButton} dataLoaded={previewRawData.loaded}
        requestedRowsOptions={[5, 15, 25, 50, 100]} onRequestedRowsUpdated={(requestedRows) => setMaxRowsRawData(requestedRows)}
        requestedOrderByOptions={previewRawData?.rawData[0] && Object.keys(previewRawData?.rawData[0]).map(rowColumn => ({code: rowColumn, label: `Field ${rowColumn}`}))} 
        onRequestedOrderByUpdated={(requestedOrderBy) => setOrderByCodeRawData(requestedOrderBy)}
        firstRow={previewRawData?.rawData[0]} dataSet={previewRawData?.rawData} onPickColumn={handlePickRawColumn} 
        title={'Preview Raw Data'} introText={(extractorSourceType === SOURCE_TYPE.JSON_REST_API ? 'Starting Path: ' + previewRawData?.jsonStartingPath + '. ' : '') + `Showing ${previewRawData?.rawData?.length} rows`} />  

    </div>
  );
}

export default withRouter(TemplateDataExtractorField);
