import React from "react";
import { useState, useEffect } from 'react';
import authService from '../../Services/authServices'
import AddApiService from '../../Services/addApiService';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import EmailerService from "../../Services/EmailerService";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Unsubscribe } from "@mui/icons-material";

function TopBar(props) {

  //for api stats
  const [stats, setStats] = useState(null);
  const [open, setOpen] = useState(false);

  //creating the auth service
  const myServiceInstance = new authService();
  //creating add api service
  const addApiService = new AddApiService();

  //getting the customer from the session for the Top bar
  const customer = myServiceInstance.getCurrentCustomer();
  if (!customer) {
    props.history.push("/Login")
  }

  const emailerService = new EmailerService();
  const [emailProgress, setEmailProgress] = useState([]);

  const progressStyle = { color: "#979797", fontSize: 12, lineHeight: 1.5 };

  useEffect(() => {
    //getting the emailer stats for header
    if (customer.customerId) {
      addApiService.emailStatValue('month', customer.customerId).then(response => {
        setStats(response);

      }).catch((error) => {
        console.log('ERROR: ', error);
      });
    }
  }, []);

  /* Used to open popup from another components */
  useEffect(() => {
    if (props.checkEmailStatus) {
      setOpen(true);
    }
  }, [props.checkEmailStatus]);

  useEffect(() => {
    checkStatus();
  }, [open]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkStatus();
    }, 1000);

    return () => clearInterval(interval);
  }, [emailProgress]);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function checkStatus() {
    if (open) {
      emailerService.checkStatus(customer.customerId).then(response => {
        setEmailProgress(response);
      });
    }
  }

  function stop(id) {
    emailerService.stop(customer.customerId, id).then(response => {
      console.log(response);
    });
  }

  return (
    <div className="row top-bar">
      <div className="col-lg-6">
        <div>
          <h6 className="bar-title">{props.title}</h6>
          {customer.customerId
            ? <span className="bar-link">{customer.customerUrl ? customer.customerUrl : props.customer}</span>
            : <span />
          }
          <p>Create your first automated emails</p>
        </div>
      </div>
      <div className="col-lg-2 text-end">
        <button
          type="button"
          className="btn btn-light"
          style={{ marginTop: "0", marginLeft: "8px" }}
          onClick={() => handleOpen()}
        >
          Emails Queue
          <FontAwesomeIcon
            icon={faPaperPlane}
            style={{ marginLeft: 5, fontSize: "20px" }}
          />
        </button>

        <Dialog open={open} onClose={() => handleClose()} fullWidth disableScrollLock>
          <DialogTitle>Emails Queue</DialogTitle>
          <DialogContent>
            {emailProgress.length
              ? emailProgress.map((progressData, index) => (
                <div key={index} className="pb-4">
                  <div className="row">
                    <div className="col-lg-8 text-start text-truncate fw-bold" title={progressData.requestUsername} style={progressStyle}>{progressData.scheduled ? 'SCHEDULED ' : ''}Request from: {progressData.requestUsername}</div>
                    <div className="col-lg-4 text-end fw-bold" style={progressStyle}>{moment(progressData.requestTimestamp).format("MM/DD/YYYY HH:mm:ss")}</div>
                  </div>
                  <div className="row">

                    <div className="col-lg-6 text-start text-truncate" title={progressData.name}>{progressData.name}</div>

                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-10 text-end pe-0 text-truncate" title={progressData.message} style={progressStyle}>{progressData.message}  </div>
                        <div className="col-lg-2 text-end ps-0" style={progressStyle}> {"   "} {progressData.progress.toFixed(1)}%</div>
                      </div>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <Box sx={{ display: 'flex', alignItems: 'center', height: 10 }}>
                        <Box sx={{ width: '100%' }}>
                          <LinearProgress variant="determinate" value={progressData.progress}
                            color={progressData.running && progressData.canceled ? 'warning'
                              : progressData.canceled ? 'error'
                                : progressData.running || progressData.finished ? 'primary'
                                  : 'inherit'} />
                        </Box>
                        {
                          !progressData.canceled && !progressData.finished ?
                            <Box sx={{ ml: 1, minWidth: 20, lineHeight: 1 }}>
                              <IconButton title="Cancel Process" className="p-0" size="small" color="error" component="label"
                                onClick={() => stop(progressData.id)}>
                                <Unsubscribe fontSize="small" />
                              </IconButton>
                            </Box>
                            : ''
                        }
                      </Box>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 text-start text-truncate" title={progressData.date} style={progressStyle}>Date: {progressData.date}</div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-10 text-end pe-0 text-truncate" title={progressData.message} style={progressStyle}>{progressData.running && progressData.canceled ? 'Canceling Process' : progressData.message}</div>
                        <div className="col-lg-2 text-end ps-0" style={progressStyle}>{progressData.progress}%</div>
                      </div>
                    </div>

                    <div className="col-lg-12 text-start text-truncate" title={progressData.applications} style={progressStyle}>Applications: {progressData.applications}</div>
                    <div className="col-lg-12 text-start text-truncate" title={progressData.recipients} style={progressStyle}>Recipients: {progressData.recipients}</div>
                  </div>
                </div>
              ))
              : <span>There are no emails in the queue</span>
            }
          </DialogContent>
        </Dialog>
      </div>
      {customer.customerId ?
        <div className="col-lg-4 col-md-5">
          <div className="row">
            <div className="col-4">
              <span className="stat-div">{stats ? (Math.round(stats.delivered_percent * 100) / 100).toFixed(2) + '%' : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}</span>
            </div>
            <div className="col-4">
              <span className="stat-div">{stats ? (Math.round(stats.bounces_percent * 100) / 100).toFixed(2) + '%' : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}</span>
            </div>
            <div className="col-4">
              <span className="stat-div">{stats ? (Math.round(stats.spam_reports_percent * 100) / 100).toFixed(2) + '%' : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}</span>
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <span className="stat-div">Delivered</span>
            </div>
            <div className="col-4">
              <span className="stat-div">Bounces</span>
            </div>
            <div className="col-4">
              <span className="stat-div">Spam Reports</span>
            </div>
          </div>
        </div>
        :
        <div></div>
      }
    </div>
  );
}

export default TopBar;
