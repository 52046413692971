import axios from 'axios';
import { environment } from '../environment';

class TemplateFilterService {

  find = (templateId, filterId) => {
    return axios.get(environment.API_URL + `templates/${templateId}/filters/${filterId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  findAll = (templateId, fetchDropdownValues = false) => {
    return axios.get(environment.API_URL + `templates/${templateId}/filters?fetchDropdownValues=${fetchDropdownValues}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  saveCalendarFilter = (templateId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/calendar-filters`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  updateCalendarFilter = (templateId, id, data) => {
    data.id = id;
    return axios.put(environment.API_URL + `templates/${templateId}/calendar-filters/${id}`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  deleteCalendarFilter = (templateId, id) => {
    return axios.delete(environment.API_URL + `templates/${templateId}/calendar-filters/${id}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  saveDropdownFilter = (templateId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/dropdown-filters`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  updateDropdownFilter = (templateId, id, data) => {
    data.id = id;
    return axios.put(environment.API_URL + `templates/${templateId}/dropdown-filters/${id}`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  deleteDropdownFilter = (templateId, id) => {
    return axios.delete(environment.API_URL + `templates/${templateId}/dropdown-filters/${id}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  findFilterValue = (templateId, filterCode) => {
    return axios.get(environment.API_URL + `templates/${templateId}/filter-values/${filterCode}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }
  
  findAllFilterValues = (templateId) => {
    return axios.get(environment.API_URL + `templates/${templateId}/filter-values`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  saveFilterValue = (templateId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/filter-values`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  deleteFilterValue = (templateId, id) => {
    return axios.delete(environment.API_URL + `templates/${templateId}/filter-values/${id}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

}

export default TemplateFilterService;