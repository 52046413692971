import { CustomParams } from "../Components/designer/CustomBlocks/constants";
import TemplateDataExtractorService from "../Services/TemplateDataExtractorService";

export const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function designerCheckCharts(templateId, html, preloadedDataExtractors) {
  const templateDataExtractorService = new TemplateDataExtractorService();

  var dom = document.createElement('div');
  dom.innerHTML = html;
  const charts = dom.getElementsByClassName(CustomParams.CLASS_ENGAGE_CHART);

  let promises = [];

  if (charts) {
    for (let chart of charts) {
      const extractorId = chart.getAttribute("data-source");
      if(extractorId) {
        const request = {
          userApptioProfile: JSON.parse(sessionStorage.getItem('currentCustomer')).userName,
          forceDBUnique: true,
          chartConfig: {
            backgroundColor: "#fff",
            width: 500,
            height: 300,
            devicePixelRatio: 1,
            chart: chart.getAttribute("data-c"),
            encoded: true
          },
          preloadedDataExtractors
        }
        
        // Sending all request at the same time
        const promise = templateDataExtractorService.getChartLink(templateId, extractorId, request).then(({data}) => {
          chart.src = data.chartLink;
        });
        
        promises.push(promise); 
      } else {
        chart.src = chart.getAttribute("src-preview");
      }
    }
  }

  // return the promises to the designer
  return Promise.all(promises).then(() => {
    return dom.innerHTML;
  });

}

export function designerRemoveCurrencyBehavior(text) {
  return text.replace(/\$/g, "$$$$");
}

export function designerCleanCallbackFunction(text) {
   let fixCurrency = designerRemoveCurrencyBehavior(text);
   let startCallbackTagRemoved = fixCurrency.replace(/\"##/g, "");
   let endCallbackTagRemoved = startCallbackTagRemoved.replace(/\##"/g, "");
   let newLineRemoved = endCallbackTagRemoved.replace(/\\n/g, "");
   return newLineRemoved;
}

export const loadingPreviewHTML = `<!DOCTYPE html>
<html>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1">
<style>
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #FF7A00;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
</head>
<body>

<div align="center">
  <div class="loader"></div>
  <h2>Loading Preview Data...</h2>
</div>

</body>
</html>`;