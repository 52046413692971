import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Emailer from "./Components/Emailer";
// import EditEmailer from './Components/EditEmailer';
import Login from './Components/Login';
import MultifactorAuthentication from './Components/MultifactorAuthentication';
import Setting from './Components/Setting';
import Email from './Components/v2/Email';
import CalenderV2 from './Components/v2/CalenderV2';
import ReportsViewV2 from './Components/v2/ReportsViewV2';
import EmailSchedule from "./Components/v2/EmailSchedule";
import Customers from "./Components/v2/Customers";
import Customer from "./Components/v2/Customer";
import Users from "./Components/v2/Users";
import Logs from "./Components/v2/Logs";
import Template from "./Components/v2/Template";
import TemplateComponents from "./Components/v2/TemplateComponents";
import TemplateComponent from "./Components/v2/TemplateComponent";
import TemplateDataExtractor from "./Components/v2/TemplateDataExtractor";
import TemplateDataExtractorField from "./Components/v2/TemplateDataExtractorField";
import PredefinedTemplate from "./Components/v2/PredefinedTemplate";
import PredefinedTemplates from "./Components/v2/PredefinedTemplates";
import TemplatePredefinedTemplates from "./Components/v2/TemplatePredefinedTemplates";
import PrivateRoute from "./Routes/PrivateRoute";

class Routes extends React.Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Redirect exact from="/" to="/Login" />
            <Route path="/Login">
              <Login />
            </Route>
            <Route path="/authenticate">
              <MultifactorAuthentication />
            </Route>
            <Route path="/Emailer">
              <Emailer
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </Route>
            <Route path="/Reports">
              <ReportsViewV2
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded} />
            </Route>
            <Route path="/setting">
              <Setting />
            </Route>
            <Route path="/EmailSchedule">
              <EmailSchedule
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </Route>
            <Route path="/Email">
              <Email
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </Route>
            <Route path="/Calendar">
              <CalenderV2
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </Route>
            <Route path="/Customers">
              <Customers
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </Route>
            <PrivateRoute newDesignerRestricted path="/Customer/:customerId/Template/:templateId/Extractor/:extractorId/Fields/:fieldId?" >
              <TemplateDataExtractorField
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </PrivateRoute>
            <PrivateRoute newDesignerRestricted path="/Customer/:customerId/Template/:templateId/Extractor/:extractorId?" >
              <TemplateDataExtractor
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </PrivateRoute>
            <Route path="/Customer/:customerId/Template/:id">
              <Template
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </Route>
            <Route path="/Customer/:id?">
              <Customer
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </Route>
            <Route path="/Users">
              <Users
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </Route>
            {false && [
              <Route path="/TemplateComponents">
                <TemplateComponents
                  sideBarExpanded={this.props.sideBarExpanded}
                  setSideBarExpanded={this.props.setSideBarExpanded}
                />
              </Route>, 
              <Route path="/TemplateComponent/:id?">
                <TemplateComponent
                  sideBarExpanded={this.props.sideBarExpanded}
                  setSideBarExpanded={this.props.setSideBarExpanded}
                />
              </Route> ]
            }
            <Route path="/PredefinedTemplates">
              <PredefinedTemplates
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </Route>
            <Route path="/PredefinedTemplate/:templateId/Extractor/:extractorId/Fields/:fieldId?" >
              <TemplateDataExtractorField
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </Route>
            <Route path="/PredefinedTemplate/:templateId/Extractor/:extractorId?" >
              <TemplateDataExtractor
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </Route>
            <Route path="/PredefinedTemplate/:id?">
              <PredefinedTemplate
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </Route>
            <Route path="/LogFiles">
              <Logs
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </Route>
            <PrivateRoute newDesignerRestricted path="/TemplateCatalog/:customerId">
              <TemplatePredefinedTemplates
                sideBarExpanded={this.props.sideBarExpanded}
                setSideBarExpanded={this.props.setSideBarExpanded}
              />
            </PrivateRoute>
            {/* v2 routes end here */}
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Routes;
