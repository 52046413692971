import axios from 'axios';
import { environment } from '../environment';

class RecipientService {

  // Get All Users by Customer
  findRecipientsByCustomer = (customerId) => {
    return axios.get(environment.API_URL + `recipient?customerId=${customerId}`)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  
  // Add Recipients to Template
  addRecipients = (templateId, recipients) => {
    return axios.post(environment.API_URL + `recipient/addRecipients?templateId=${templateId}`, recipients)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        console.log(error);
      });
  }

}

export default RecipientService;