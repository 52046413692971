import { AdvancedType, BasicType, components, createCustomBlock, getPreviewClassName } from 'easy-email-core';
import { ChartTags, ChartType, CustomBlocksType, CustomParams, BehaviourWhenNoData } from '../constants';
import React from 'react';
import { merge } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { now } from 'moment';
import { designerCleanCallbackFunction } from '../../../../Shared/common-utils';

const { Table, Raw } = components;

export const TAG_NAME = 'charts.pie-chart-';

const DEFAULT_LABEL = `['Red', 'Orange', 'Yellow', 'Green', 'Blue']`;
const DEFAULT_DATASET = [84, 28, 57, 19, 97];

const defaultChartConfig = `{
  type: '${ChartType.PIE}',
  behaviorWhenNoData: ${ChartTags.HIDE_WHEN_NO_DATA},
  data: {
    labels: ${ChartTags.LABELS},
    datasets: ${ChartTags.DATASETS},
  },
  options: ${ChartTags.OPTIONS},
}`;

const defaultOutlabeledChartConfig = `{
  type: '${ChartType.OUTLABELED}',
  behaviorWhenNoData: ${ChartTags.HIDE_WHEN_NO_DATA},
  data: {
    labels: ${ChartTags.LABELS},
    datasets: ${ChartTags.DATASETS},
  },
  options: ${ChartTags.OPTIONS}
}`;

export const PieChart = createCustomBlock({
  name: 'Pie Chart',
  type: CustomBlocksType.PIE_CHART,
  create(payload) {
    const idx = now();

    const defaultData = {
      type: CustomBlocksType.PIE_CHART,
      data: {
        value: {
          id: `${TAG_NAME}${idx}`,
          type: ChartType.PIE,
          source: '',
          labels: DEFAULT_LABEL, // Extractor should return this as an array
          isArray: false,
          behaviorWhenNoData: BehaviourWhenNoData.SHOW_MISSING_DATA_PLACEHOLDER,
          datasets: [
            {
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
              ],
              data: [84, 28, 57, 19, 97], // Extractor should return this as an array
            }
          ],
          options: {
            title: {
              display: true,
              text: 'Chart Title'
            },
            legend: {
              display: true
            },
            scales: {
              xAxes: [{
                display: true,
                gridLines: {
                  display: true
                },
              }],
              yAxes: [{
                display: true,
                gridLines: {
                  display: true
                },
                ticks: {}
              }]
            },
            plugins: {
              datalabels: {
                display: false
              },
              outlabels: {
                text: "%l %p",
                color: "#ffffff",
                font: {
                  minSize: 12
                }
              }
            }
          }
        }
      },
      attributes: {
        src: `{{${TAG_NAME}${idx}}}`,
        padding: '0px 0px 0px 0px'
      },
      children: [],
    }
    return merge(defaultData, payload);
  },
  validParentType: [BasicType.COLUMN, BasicType.HERO, AdvancedType.COLUMN, AdvancedType.HERO],
  render(params) {
    const component = params.data;
    console.log('PieChart');

    let chartConfig;
    if (component.data.value.type === ChartType.PIE) {
      chartConfig = cloneDeep(defaultChartConfig);
    } else {
      chartConfig = cloneDeep(defaultOutlabeledChartConfig);
    }

    // We need to cloneDeep because we dont want to change current status of component
    const datasets = cloneDeep(component.data.value.datasets);
    
    chartConfig = chartConfig.replace(ChartTags.HIDE_WHEN_NO_DATA, `'` + (!component.data.value.behaviorWhenNoData ? BehaviourWhenNoData.SHOW_MISSING_DATA_PLACEHOLDER : component.data.value.behaviorWhenNoData) + `'`);
    chartConfig = !component.data.value.labels ? chartConfig.replace(ChartTags.LABELS, '[]')
      : chartConfig.replace(ChartTags.LABELS, component.data.value.labels);
    chartConfig = chartConfig.replace(ChartTags.DATASETS, JSON.stringify(datasets));
    chartConfig = !component.data.value.options ? chartConfig.replace(ChartTags.OPTIONS, '{}')
      : chartConfig.replace(ChartTags.OPTIONS, designerCleanCallbackFunction(JSON.stringify(component.data.value.options)));

    // Following is to convert labels,dataset.data,dataset.backgroundColor as object {} instead of string with quote
    let chartConfigEncodedForDesign = encodeURIComponent(chartConfig);
    let chartConfigEncodedForPreview = encodeURIComponent(chartConfig);
    if (component.data.value.labels && component.data.value.labels !== DEFAULT_LABEL) {
      chartConfigEncodedForDesign = chartConfigEncodedForDesign.replace(encodeURIComponent(`${component.data.value.labels}`), `['${component.data.value.labels}']`);
      //chartConfigEncodedForPreview = chartConfigEncodedForPreview.replace(encodeURIComponent(`${component.data.value.labels}`), component.data.value.labels);
    }
    if (datasets) {
      let indexForDummyValue = 0;
      datasets.forEach((dataset) => {
        if (dataset.backgroundColor && dataset.backgroundColor.includes('getGradientFillHelper')) {
          chartConfigEncodedForDesign = chartConfigEncodedForDesign.replace(encodeURIComponent(`"${dataset.backgroundColor}"`), encodeURIComponent(dataset.backgroundColor));
          chartConfigEncodedForPreview = chartConfigEncodedForPreview.replace(encodeURIComponent(`"${dataset.backgroundColor}"`), encodeURIComponent(dataset.backgroundColor));
        }
        if (dataset.data) {
          chartConfigEncodedForDesign = chartConfigEncodedForDesign.replace(encodeURIComponent(`"${dataset.data}"`), `[${DEFAULT_DATASET[indexForDummyValue]}]`);
          chartConfigEncodedForPreview = chartConfigEncodedForPreview.replace(encodeURIComponent(`"${dataset.data}"`), encodeURIComponent(dataset.data));

          indexForDummyValue++;
          if (indexForDummyValue >= DEFAULT_DATASET.length) {
            indexForDummyValue = 0;
          }
        }
      });
    }

    const img = `<div style="padding: ${component.attributes.padding}">
      <img height="auto" width="100%"
        data-source="${component.data.value.source}"
        data-c="${chartConfigEncodedForPreview}"
        src-preview="https://quickchart.io/chart?c=${chartConfigEncodedForPreview}"
        src="${params.mode === 'testing' ? 'https://quickchart.io/chart?c=' + chartConfigEncodedForDesign : component.attributes.src}"
        class="${CustomParams.CLASS_ENGAGE_CHART}" style="${CustomParams.STYLE_ENGAGE_CHART}" />
    </div>`;

    const designerInstance = (
      <Table key={`pie-chart-${params.idx}`}
        css-class={params.mode === 'testing' ? getPreviewClassName(params.idx, component.type) : ''}
        value={{ content: `<tr><td style="padding: ${component.attributes.padding}">${img}</td></tr>` }}
        role="presentation" padding="0px 0px 0px 0px" align="center">
      </Table>
    );

    const previewInstance = (
      <Raw key={`pie-chart-${params.idx}`} value={{ content: img }} />
    );

    return params.mode === 'testing' ? designerInstance : previewInstance;
  },
});


export { Panel } from './Panel'