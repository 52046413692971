import React from 'react';
import { withRouter } from 'react-router-dom';
import AddApiService from '../Services/addApiService';
import authService from '../Services/authServices';
import '../Style/Emailer.css';
import * as moment from 'moment';
import EmailerTable from './EmailerTable.jsx';
import Header from './Header';
import Modal from 'react-awesome-modal';
import EmailerAddUser from '../Forms/EmailerAddUser';
import Swal from 'sweetalert2';
import * as Loader from 'react-loader-spinner';
import { Offcanvas, Row } from 'react-bootstrap';
// import withReactContent from 'sweetalert2-react-content';
import apiKeyService from '../Services/settingService';
import settingService from '../Services/settingService';
import LeftMenu from './LeftMenu';

class Emailer extends React.Component {

  constructor(props) {
    super(props);
    this.addApiService = new AddApiService();
    this.authService = new authService();
    this.apiKeyService = new apiKeyService();
    this.settingService = new settingService();

    this.state = {
      selectedOption: "",
      // customer: [],
      customerId: 0,
      emailStats: {},
      selector: '',
      templates: [],
      convertables: {},
      visible: false,
      settingsOffCanvas: false,
      key: "",
      secret: "",
      userId: this.authService.getCurrentCustomer().id
    };

    // Redirect
    // if (!this.authService.getToken('token')) {
    //   props.history.push('/Login');
    // }
  }

  //on load function call
  componentDidMount() {

    // Get All Customer
    // this.getAllCustomer();

    //single send Api
    //  this.singleSends();

    // Redirect
    if (!this.authService.getToken('token')) {
      this.props.history.push('/Login');
    } else {
      // email stats based on day
      this.emailStatValue('month');
    }
  }

  // Get CustomerId From Header


  // Get All Customer
  // getAllCustomer() {
  //   this.addApiService.getAllCustomer().then(customerList => {
  //     this.setState({ options: customerList });
  //     this.setState({ customer: customerList })
  //   })
  // }

  // Selected Customer
  selectedCustomer(custId) {
    this.setState({
      selectedOption: custId,
      customerId: custId
    });

    // after select customer get All related email templates.
    this.getEmailTemplates(custId);
  }

  // Routing towards AddApi view
  onApiClick = () => {
    this.props.history.push("/AddApi")
  };

  //Routing towards Schedular
  onEmailerSchedular = () => {
    this.props.history.push("/EmailerScheduler")
  };

  // Routing towards mobile view
  onMobileView = () => {
    this.props.history.push("/mobile")
  };

  //Routing towards Settings
  onSetting = () => {
    // this.props.history.push("/Setting")
    if (this.state.settingsOffCanvas === true) {
      this.setState({ settingsOffCanvas: false })
    }
    else {
      this.setState({ settingsOffCanvas: true })
      this.getAllApiKey()
    }

  }

  onSettingOffcanvasClose = () => {
    // this.props.history.push("/Setting")
    this.setState({ settingsOffCanvas: false })
  }

  //email stat api call
  emailStatValue = (selector) => {
    this.setState({
      convertables: {
        start_date: '',
        end_date: ''
      }
    })

    this.setState({ selector: selector });

    this.addApiService.emailStatValue(selector).then(response => {
      this.setState({
        convertables: {
          unique_opens_percent: response.unique_opens_percent.toFixed(2),
          unique_clicks_percent: response.unique_clicks_percent.toFixed(2),
          delivered_percent: response.delivered_percent.toFixed(2),
          bounces_percent: response.bounces_percent.toFixed(2),
          unsubscribes_percent: response.unsubscribes_percent.toFixed(2),
          spam_reports_percent: response.spam_reports_percent.toFixed(2),
          start_date: moment(new Date(response.start_date)).format('MMM-DD'),
          end_date: moment(new Date(response.end_date)).format('MMM-DD')

        }
      })
      this.setState({ emailStats: response });

    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  // Get Email Templates
  getEmailTemplates(customerId) {
    this.addApiService.getEmailTemplates(customerId).then(response => {
      console.log('Response: ', response)
      // response.updated_at = moment(new Date(response.updated_at)).format('MM-DD');
      this.setState({ templates: response });
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  newUser(userName) {
    this.addApiService.addUserEmailer(userName, this.state.customerId).then(response => {
      console.log(response, 'new user')
      if (response === 'success') {
        Swal.fire(
          'Added!',
          'New User has been added.',
          'success'
        );
      } else {
        Swal.fire(
          'Error',
          'Username already exists.',
          'warning'
        );
      }
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
    this.closeModal();
  }

  // TEMP Logout
  logout = () => {
    // Clear Session Storage
    sessionStorage.clear();
    // Redirect to Login
    this.props.history.push('/');
  }

  // get api keys
  getAllApiKey = () => {
    console.log(this.state.userId.id, 'get all key')
    this.apiKeyService.getApiKey(this.state.userId).then(response => {
      console.log('Response: ', response);
      this.setState({
        key: response.key,
        secret: response.secret
      });
    });
  }

  render() {
    return (
      <div>
        <section className="dashboard-outer">
          <div className="container-fluid">
            <Header selectCustomer={(customerId) => { this.selectedCustomer(customerId) }} />

            <div className="row">
              <LeftMenu activeMenu = {"Emailer"}/>

              {this.state.templates.length === 0 || !this.state.emailStats ?
                <div className="container col align-self-center">



                  <Loader
                    type="Bars"
                    color="#ed7d31"
                    height={50}
                    width={50}
                  />

                </div> :
                <div className="col-md-11 col-sm-9 p-0">

                  <div className={`side-right"${this.state.settingsOffCanvas ? "  disable-div-click-events" : ""}`}>
                    <div className="bottom-outer">
                      <div className="col-md-12 col-sm-12">
                        <div className="button-outer emailer">
                          <div className="text-left email padding-left-10px">
                            <p>Emailers</p>

                            <div className="btn-group btn-group-toggle" data-toggles="buttons">
                              <button className={"btn" + (this.state.selector === 'day' ? "email-stat-range active" : "")} onClick={() => this.emailStatValue('day')}>
                                Dy
                              </button>
                              <button className={"btn " + (this.state.selector === 'week' ? " email-stat-range active" : "")} onClick={() => this.emailStatValue('week')}>
                                Wk
                              </button>
                              <button className={"btn" + (this.state.selector === 'month' ? " email-stat-range active" : "")} onClick={() => this.emailStatValue('month')}>
                                Mo
                              </button>
                            </div>
                            <div className="btn-group btn-group-toggle date-range text-center selected-email-stat-range" data-toggles="buttons" >
                              {
                                this.state.selector === 'day' ?
                                  <span>{this.state.convertables.start_date}</span> :
                                  <div>
                                    <span>{this.state.convertables.start_date}</span>
                                    <span>-</span>
                                    <span>{this.state.convertables.end_date}</span>
                                  </div>
                              }

                            </div>

                          </div>

                          <div className="box-total">
                            <div className="box">
                              <div className="box-content">
                                <p>
                                  Requests
                                </p>
                                <h3 className="text-color-requests">
                                  {this.state.emailStats.requests}
                                </h3>
                              </div>
                            </div>

                            <div className="box">
                              <div className="box-content">
                                <p>
                                  Unique Opens
                                </p>
                                <h3 className="text-color-unique-opens">
                                  {this.state.convertables.unique_opens_percent}%
                                </h3>
                                <h5 className="text-color-unique-opens">
                                  {this.state.emailStats?.unique_opens}
                                </h5>
                              </div>
                            </div>
                            <div className="box">
                              <div className="box-content">
                                <p>
                                  Unique Clicks
                                </p>
                                <h3 className="text-color-unique-clicks">
                                  {this.state.convertables.unique_clicks_percent}%

                                </h3>
                                <h5 className="text-color-unique-clicks">
                                  {this.state.emailStats?.unique_clicks}
                                </h5>
                              </div>
                            </div>
                            <div className="box">
                              <div className="box-content">
                                <ul className="circle">
                                  <li className="text-color-delivered"></li>
                                  <li className="text-color-bounces"></li>

                                  <li className="text-color-spam"></li>
                                </ul>
                                <ul className="stat-circle-padding">
                                  <li>Delivered</li>
                                  <li>Bounces</li>

                                  <li>Spam Reports</li>
                                </ul>
                                <ul className="right">
                                  <li>{this.state.convertables.delivered_percent}%</li>
                                  <li>{this.state.convertables.bounces_percent}%</li>

                                  <li>{this.state.convertables.spam_reports_percent}%</li>
                                </ul>
                              </div>
                            </div>

                          </div>

                        </div>
                        <EmailerTable
                          templates={this.state.templates}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Emailer)