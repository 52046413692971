import axios from 'axios';
import { environment } from '../environment';

class EmailerSchedulerService {

  fetchScheduledEmailers = () => {
    return axios.get(environment.API_URL + `emailerSchedular/getAll`,{headers:{
      'customerId': JSON.parse(sessionStorage.getItem('currentCustomer')).customerId
    }})
      .then(function (response) {
        console.log(response)
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  createOrUpdateEmailerSchedular = (body) => {

    return axios.post(environment.API_URL + `emailerSchedular/saveOrUpdate`, body,{headers:{
      'customerId': JSON.parse(sessionStorage.getItem('currentCustomer')).customerId
    }})
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  deleteEmailerSchedular = (body) => {

    return axios.post(environment.API_URL + `emailerSchedular/delete`, body,{headers:{
      'customerId': JSON.parse(sessionStorage.getItem('currentCustomer')).customerId
    }})
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  downloadUpcomingSchedulars = () => {
    return axios.get(environment.API_URL + `emailerSchedular/downloadExcelForUpcomingEmails`,{ responseType: 'blob',headers:{
      'customerId': JSON.parse(sessionStorage.getItem('currentCustomer')).customerId
    }})
      .then(function (response) {
        console.log(response)
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }
}



export default EmailerSchedulerService;