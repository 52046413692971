import React from "react";
import { Modal } from "react-bootstrap";
import email from "../../images/email.png";

export default function ScheduleModal(props) {
  return (
    <Modal show={props.open} onHide={props.close}>
      <Modal.Body>
        <div className="form-group">
          <img
            style={{ display: "block", margin: "auto", width: "auto" }}
            src={email}
          />
          <h5 style={{ textAlign: "center", marginTop: "20px" }}>
            {props.message}
          </h5>
        </div>
      </Modal.Body>
    </Modal>
  );
}
