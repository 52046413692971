import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import auth from './Services/authServices';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap'
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

axios.interceptors.request.use(request => {
    const token = new auth().getToken();
    // console.log(request);
    if (token) {
        request.headers.Authorization = 'Bearer ' + token;
    }
    //edit Request config
    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    console.log('response: ', response);
    return response;
}, error => {
    console.log('Interceptor: ', error.response)
    if (error.response.status === 401) {
        sessionStorage.clear();
        MySwal.fire({
            title: 'Your session has expired',
            text: 'Please login again to continue.',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Go to Login',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                window.location.href = '/';
            }
        })
    }

    if (error.response.status === 500) {
        // sessionStorage.clear();
        MySwal.fire({
            title: error.response.data.error || 'Oops...',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Cancel',
            allowOutsideClick: false
        }).then((result) => {
            // if (result.value) {
            //     window.location.href = '/';
            // }
        })
    }
    return Promise.reject(error);
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
