import React, { useState } from 'react';

import { SwitchField } from 'easy-email-extensions';
import { AttributesPanelWrapper } from 'easy-email-extensions';
import { SelectField } from 'easy-email-extensions';

import { styled } from '@mui/material/styles';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useEffect } from 'react';
import { Stack, useBlock, useFocusIdx } from 'easy-email-editor';
import TemplateDataExtractorService from '../../../../Services/TemplateDataExtractorService';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AbcIcon from '@mui/icons-material/Abc';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ToggleOffOutlined from '@mui/icons-material/ToggleOffOutlined';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightSharpIcon />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'var(--color-neutral-1)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export function Panel() {

  const templateDataExtractorService = new TemplateDataExtractorService();

  const templateId = document.getElementById('designerTemplateId').value;

  const { focusBlock } = useBlock();
  const { focusIdx } = useFocusIdx();

  const [active, setActive] = useState('settings')
  const [expanded, setExpanded] = useState(true);

  const [extractors, setExtractors] = useState([]);
  const [extractorsAsOptions, setExtractorsAsOptions] = useState([]);
  const [selectedExtractorId, setSelectedExtractorId] = useState('');
  const [selectedExtractorFieldsAsOptions, setSelectedExtractorFieldsAsOptions] = useState([]);

  useEffect(() => {
    templateDataExtractorService.findAllNotHidden(templateId).then(response => {
      setExtractors(response.data);

      const extractorsOpt = response.data.map(extractor => {
        return { label: extractor.name, value: extractor.id }
      });
      extractorsOpt.unshift({ label: 'None', value: '' });
      setExtractorsAsOptions(extractorsOpt);

      if (focusBlock && focusBlock.data.value.source) {
        handleChangeExtractor(response.data, focusBlock.data.value.source, false);
      }
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }, []);

  useEffect(() => {
    handleChangeExtractor(extractors, selectedExtractorId, true);
  }, [selectedExtractorId]);

  function onChangeAccordion(tab) {
    if (tab !== active) {
      setExpanded(true);
    } else {
      setExpanded(!expanded);
    }
    setActive(tab);
  }

  function handleChangeExtractor(extractors, extractorId, _cleanPreSelectedField) {
    if (extractorId) {
      const extractorIndex = extractors.findIndex(extractor => extractor.id === extractorId);
      const extractor = extractors.at(extractorIndex);
      const extractorsFieldsOpt = extractor.fields.map(field => {
        return {
          label: <ListItem key={field.id} className="p-0">
            <ListItemAvatar sx={{ width: 24, minWidth: 34 }}>
              <Avatar sx={{ width: 24, height: 24 }} style={{ fontSize: 9, fontWeight: 900 }}>
                {field.dataType === 'STRING' ? <AbcIcon />
                  : field.dataType === 'NUMERIC' ? 123
                    : field.dataType === 'BOOLEAN' ? <ToggleOffOutlined />
                      : <MoreHorizIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              title={field.name}
              primary={field.name} primaryTypographyProps={{ fontSize: 12, noWrap: true }}
              secondary={field.dataType} secondaryTypographyProps={{ fontSize: 10, noWrap: true }}
              style={{ overflowWrap: "anywhere" }} />
          </ListItem>,
          value: `{{${extractor.code}.data.[0].${field.code}}}`
        }
      });
      extractorsFieldsOpt.unshift({
        label: <div style={{ padding: "6px 0px" }}>None</div>,
        value: ''
      });
      setSelectedExtractorFieldsAsOptions(extractorsFieldsOpt);
    }
  }

  return (
    <AttributesPanelWrapper>
      <Accordion expanded={active === 'settings' && expanded} onChange={() => onChangeAccordion('settings')}>
        <AccordionSummary>Setting</AccordionSummary>
        <AccordionDetails>
          <Stack
            vertical
            spacing='tight'
          >
            <SelectField
              label='Datasource'
              name={`${focusIdx}.data.value.source`}
              options={extractorsAsOptions}
              onChangeAdapter={(value) => {
                setSelectedExtractorId(value);
                return value;
              }}
            />

            <SelectField
              label='Field'
              name={`${focusIdx}.data.value.content`}
              options={selectedExtractorFieldsAsOptions}
              helpText="true: to display | false: to hide"
              className="dropdownWithSecondaryLabel"
            />

            <SwitchField
              label='Reverse'
              name={`${focusIdx}.data.value.reverse`}
              helpText="if value is true then return false"
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </AttributesPanelWrapper>
  );
}