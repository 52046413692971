import axios from 'axios';
import { environment } from '../environment';

class authServices {

  //login api http://localhost:8080/api/login
  login = (body) => {
    return axios.post(environment.AUTH_URL + 'login', body)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error.data;
      })
  }

  adminLogin = (body) => {
    return axios.post(environment.AUTH_URL + 'admin/login', body)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error.data;
      })
  }

  verifyMfa = (body) => {
    return axios.post(environment.AUTH_URL + 'verifyMFA', body)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error.data;
      })
  }

  logout = () => {
    return axios.get(environment.AUTH_URL + 'logout')
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error.data;
      })
  }

  updatePassword = (body) => {
    return axios.post(environment.AUTH_URL + 'api/user/updatePassword', body)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
    return error.data
    })
  }

  updateMfa = (body) => {
    return axios.post(environment.AUTH_URL + 'api/user/updateMfaSettings', body)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
    return error.data
    })
  }

  //validate user with otp http://localhost:8080/api/customer/validateOTP
  validateWithOtp = (body) => {
    return axios.post(environment.API_URL + 'user/validate', body)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  //set new password once validated http://localhost:8080/api/customer/update
  setPassword = (body) => {
    return axios.post(environment.API_URL + 'user/register', body)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  // Set Token
  setToken(token) {
    sessionStorage.setItem('token', token);
  }

  // Get Token
  getToken() {
    return sessionStorage.getItem('token');
  }

  // set user Details
  setCurrentCustomer(users) {
    sessionStorage.setItem('currentCustomer', JSON.stringify(users));
  }

  // get user Details
  getCurrentCustomer() {
    return JSON.parse(sessionStorage.getItem('currentCustomer'));
  }

}
export default authServices;