import { AdvancedType, components, createCustomBlock, getPreviewClassName } from 'easy-email-core';
import { CustomBlocksType } from '../constants';
import React from 'react';
import { merge } from 'lodash';

import { cloneDeep } from 'lodash';
import { BASIC_TABLE_DEFAULT_COLUMN, THEMES } from './Theme';
import { getBorderWidth, getSingleValueInPx, getSingleValueInPxWithRestriction } from '../utils/Utils';

const { Table } = components;

// Following is a Helper field to know if the index in the loop is Even or Odd
const HELPER_EVEN_ODD_FIELD = 'ENGAGE_HELPER_IS_EVEN_INDEX';

export const BasicTable = createCustomBlock({
  name: 'Data Table',
  type: CustomBlocksType.BASIC_TABLE,
  create(payload) {
    const defaultData = {
      type: CustomBlocksType.BASIC_TABLE,
      data: {
        value: {
          settings: {
            header: true,
            footer: true
          },
          global: cloneDeep(THEMES.THEME_DEFAULT.global),
          columns: [
            cloneDeep(BASIC_TABLE_DEFAULT_COLUMN),
            cloneDeep(BASIC_TABLE_DEFAULT_COLUMN),
          ],
        }
      },
      attributes: {},
      children: [],
    }
    return merge(defaultData, payload);
  },
  validParentType: [AdvancedType.COLUMN],
  render(params) {

    const component = params.data;

    const settings = component.data.value.settings;
    const global = component.data.value.global;
    const columns = component.data.value.columns;

    const columnsMap = (
      columns.map((column, index) => (
        <td key={`basic-table-${params.idx}-col-body-${index}`}
          align={column.settings.body?.align || global.body.align || 'left'}
          valign={column.settings.body?.valign || global.body.valign || 'middle'}
          height={(global.body?.height || 20) + 'px'}
          colSpan={column.settings.body?.colspan}
          //rowSpan={column.settings.rowspan}
          style={{
            paddingTop: getSingleValueInPx(column.settings.body?.paddingTop, global.body.paddingTop),
            paddingRight: getSingleValueInPx(column.settings.body?.paddingRight, global.body.paddingRight),
            paddingBottom: getSingleValueInPx(column.settings.body?.paddingBottom, global.body.paddingBottom),
            paddingLeft: getSingleValueInPx(column.settings.body?.paddingLeft, global.body.paddingLeft),

            backgroundColor: column.settings.body?.backgroundColor,
            borderStyle: column.settings.body?.borderStyle || global.body.borderStyle || 'none',
            borderColor: column.settings.body?.borderColor || global.body.borderColor,

            msoBorderTopAlt: !column.settings.body?.borderTopSize && !global.body.borderTopSize ? 'none #FFFFFF 0' : '',
            msoBorderRightAlt: !column.settings.body?.borderRightSize && !global.body.borderRightSize ? 'none #FFFFFF 0' : '',
            msoBorderBottomAlt: !column.settings.body?.borderBottomSize && !global.body.borderBottomSize ? 'none #FFFFFF 0' : '',
            msoBorderLeftAlt: !column.settings.body?.borderLeftSize && !global.body.borderLeftSize ? 'none #FFFFFF 0' : '',

            borderTopWidth: getSingleValueInPxWithRestriction(column.settings.body?.borderTopSize, global.body.borderTopSize, column.settings.body?.borderStyle, global.body.borderStyle),
            borderRightWidth: getSingleValueInPxWithRestriction(column.settings.body?.borderRightSize, global.body.borderRightSize, column.settings.body?.borderStyle, global.body.borderStyle),
            borderBottomWidth: getSingleValueInPxWithRestriction(column.settings.body?.borderBottomSize, global.body.borderBottomSize, column.settings.body?.borderStyle, global.body.borderStyle),
            borderLeftWidth: getSingleValueInPxWithRestriction(column.settings.body?.borderLeftSize, global.body.borderLeftSize, column.settings.body?.borderStyle, global.body.borderStyle),

          }}>
          <div style={{
            lineHeight: 1.2,
            fontFamily: column.settings.body?.fontFamily || global.body.fontFamily,
            fontWeight: column.settings.body?.fontWeight || global.body.fontWeight,
            fontStyle: column.settings.body?.fontStyle || global.body.fontStyle,
            fontSize: (column.settings.body?.fontSize || global.body.fontSize || 13) + 'px',
            color: column.settings.body?.color || global.body.color || 'inherit'
          }}>
            {column.data.body}
          </div>
        </td>
      ))
    )

    const designerInstance = (
      <Table key={`basic-table-${params.idx}`}
        css-class={(params.mode === 'testing' ? getPreviewClassName(params.idx, component.type) : '')}
        value={{
          content:
            <>
              <tr><td style={{
                borderStyle: global.table?.borderStyle || 'inherit',
                borderColor: global.table?.borderColor || 'inherit',
                padding: 0,
                borderTopWidth: global.table?.borderTopSize ? global.table.borderTopSize + 'px' : '0',
                borderRightWidth: global.table?.borderRightSize ? global.table.borderRightSize + 'px' : '0',
                borderBottomWidth: global.table?.borderBottomSize ? global.table.borderBottomSize + 'px' : '0',
                borderLeftWidth: global.table?.borderLeftSize ? global.table.borderLeftSize + 'px' : '0'
              }}>
                <table border={0} cellSpacing={0} cellPadding={0} style={{
                  width: '100%',
                  borderCollapse: 'collapse'
                }}>
                  {columns && columns.length > 0 ?
                    <>
                      {settings.header &&
                        <thead><tr>
                          {columns.map((column, index) => {

                            const headerStyle = {
                              paddingTop: getSingleValueInPx(column.settings.header?.paddingTop, global.header.paddingTop),
                              paddingRight: getSingleValueInPx(column.settings.header?.paddingRight, global.header.paddingRight),
                              paddingBottom: getSingleValueInPx(column.settings.header?.paddingBottom, global.header.paddingBottom),
                              paddingLeft: getSingleValueInPx(column.settings.header?.paddingLeft, global.header.paddingLeft),

                              backgroundColor: column.settings.header?.backgroundColor || global.header.backgroundColor || 'inherit',
                              borderStyle: column.settings.header?.borderStyle || global.header.borderStyle || 'inherit',
                              borderColor: column.settings.header?.borderColor || global.header.borderColor || 'inherit',

                              msoBorderTopAlt: !column.settings.header?.borderTopSize && !global.header.borderTopSize ? 'none #FFFFFF 0' : '',
                              msoBorderRightAlt: !column.settings.header?.borderRightSize && !global.header.borderRightSize ? 'none #FFFFFF 0' : '',
                              msoBorderBottomAlt: !column.settings.header?.borderBottomSize && !global.header.borderBottomSize ? 'none #FFFFFF 0' : '',
                              msoBorderLeftAlt: !column.settings.header?.borderLeftSize && !global.header.borderLeftSize ? 'none #FFFFFF 0' : '',

                              borderTopWidth: getSingleValueInPxWithRestriction(column.settings.header?.borderTopSize, global.header.borderTopSize, column.settings.header?.borderStyle, global.header.borderStyle),
                              borderRightWidth: getSingleValueInPxWithRestriction(column.settings.header?.borderRightSize, global.header.borderRightSize, column.settings.header?.borderStyle, global.header.borderStyle),
                              borderBottomWidth: getSingleValueInPxWithRestriction(column.settings.header?.borderBottomSize, global.header.borderBottomSize, column.settings.header?.borderStyle, global.header.borderStyle),
                              borderLeftWidth: getSingleValueInPxWithRestriction(column.settings.header?.borderLeftSize, global.header.borderLeftSize, column.settings.header?.borderStyle, global.header.borderStyle),

                            };

                            const headerDivStyle = {
                              lineHeight: 1.2,
                              fontFamily: column.settings.header?.fontFamily || global.header.fontFamily,
                              fontSize: (column.settings.header?.fontSize || global.header.fontSize || 13) + 'px',
                              color: column.settings.header?.color || global.header.color || 'inherit',
                              fontWeight: column.settings.header?.fontWeight || global.header.fontWeight,
                              fontStyle: column.settings.header?.fontStyle || global.header.fontStyle
                            };

                            return <th key={`basic-table-${params.idx}-col-header-${index}`}
                              align={column.settings.header?.align || global.header.align || 'left'}
                              valign={column.settings.header?.valign || global.header.valign || 'middle'}
                              width={column.settings.header?.width ? column.settings.header?.width + '%' : ''}
                              height={(global.header.height || 20) + 'px'}
                              style={headerStyle}
                            >
                              <div style={headerDivStyle}>
                                {column.data.header}
                              </div>
                            </th>
                          })}</tr>
                        </thead>
                      }
                      <tbody>
                        {params.mode !== 'testing' && `{{#each ${settings.datasource} }}`}
                        <tr style={{
                          backgroundColor:
                            params.mode === 'testing'
                              ? global.body.backgroundColorOdd
                              : (`{{#if ${HELPER_EVEN_ODD_FIELD} }} ${global.body.backgroundColorEven} {{else}} ${global.body.backgroundColorOdd} {{/if}}`),
                          color:
                            params.mode === 'testing'
                              ? global.body.fontColorOdd
                              : (`{{#if ${HELPER_EVEN_ODD_FIELD} }} ${global.body.fontColorEven} {{else}} ${global.body.fontColorOdd} {{/if}}`)
                        }}>
                          {columnsMap}
                        </tr>

                        {params.mode === 'testing' &&
                          <tr style={{
                            backgroundColor: global.body.backgroundColorEven,
                            color: global.body.fontColorEven
                          }}>
                            {columnsMap}
                          </tr>
                        }

                        {params.mode !== 'testing' && `{{/each}}`}
                      </tbody>
                      {settings.footer &&
                        <tfoot><tr>
                          {columns.map((column, index) => {

                            const footerStyle = {
                              paddingTop: getSingleValueInPx(column.settings.footer?.paddingTop, global.footer.paddingTop),
                              paddingRight: getSingleValueInPx(column.settings.footer?.paddingRight, global.footer.paddingRight),
                              paddingBottom: getSingleValueInPx(column.settings.footer?.paddingBottom, global.footer.paddingBottom),
                              paddingLeft: getSingleValueInPx(column.settings.footer?.paddingLeft, global.footer.paddingLeft),

                              backgroundColor: column.settings.footer?.backgroundColor || global.footer.backgroundColor || 'inherit',
                              borderStyle: column.settings.footer?.borderStyle || global.footer.borderStyle || 'inherit',
                              borderColor: column.settings.footer?.borderColor || global.footer.borderColor || 'inherit',

                              msoBorderTopAlt: !column.settings.footer?.borderTopSize && !global.footer.borderTopSize ? 'none #FFFFFF 0' : '',
                              msoBorderRightAlt: !column.settings.footer?.borderRightSize && !global.footer.borderRightSize ? 'none #FFFFFF 0' : '',
                              msoBorderBottomAlt: !column.settings.footer?.borderBottomSize && !global.footer.borderBottomSize ? 'none #FFFFFF 0' : '',
                              msoBorderLeftAlt: !column.settings.footer?.borderLeftSize && !global.footer.borderLeftSize ? 'none #FFFFFF 0' : '',

                              borderTopWidth: getSingleValueInPxWithRestriction(column.settings.footer?.borderTopSize, global.footer.borderTopSize, column.settings.footer?.borderStyle, global.footer.borderStyle),
                              borderRightWidth: getSingleValueInPxWithRestriction(column.settings.footer?.borderRightSize, global.footer.borderRightSize, column.settings.footer?.borderStyle, global.footer.borderStyle),
                              borderBottomWidth: getSingleValueInPxWithRestriction(column.settings.footer?.borderBottomSize, global.footer.borderBottomSize, column.settings.footer?.borderStyle, global.footer.borderStyle),
                              borderLeftWidth: getSingleValueInPxWithRestriction(column.settings.footer?.borderLeftSize, global.footer.borderLeftSize, column.settings.footer?.borderStyle, global.footer.borderStyle),
                            };

                            const footerDivStyle = {
                              lineHeight: 1.2,
                              fontFamily: column.settings.footer?.fontFamily || global.footer.fontFamily,
                              fontSize: (column.settings.footer?.fontSize || global.footer.fontSize || 13) + 'px',
                              color: column.settings.footer?.color || global.footer.color || 'inherit',
                              fontWeight: column.settings.footer?.fontWeight || global.footer.fontWeight,
                              fontStyle: column.settings.footer?.fontStyle || global.footer.fontStyle
                            }

                            return <td key={`basic-table-${params.idx}-col-footer-${index}`}
                              align={column.settings.footer?.align || global.footer.align || 'left'}
                              valign={column.settings.footer?.valign || global.footer.valign || 'middle'}
                              height={(global.footer.height || 20) + 'px'}
                              style={footerStyle}
                            >
                              <div style={footerDivStyle}>
                                {column.data.footer}
                              </div>
                            </td>
                          })}</tr>
                        </tfoot>
                      }
                    </>

                    :
                    <tr><td></td></tr>
                  }
                </table>
              </td></tr>
            </>
        }}
        role="presentation" padding="0px 0px 0px 0px" align="center">
      </Table>
    );

    return designerInstance;
  },
});


export { Panel } from './Panel'