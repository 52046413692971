import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { AttributesPanelWrapper, SwitchField, SelectField, ColorPickerField, TextAreaField, enhancer } from 'easy-email-extensions';
import { useFocusIdx, Stack, TextStyle, useBlock } from 'easy-email-editor';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useState } from 'react';
import TemplateDataExtractorService from '../../../../Services/TemplateDataExtractorService';
import { Grid, Space } from '@arco-design/web-react';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Tab } from '@mui/material';
import { cloneDeep } from 'lodash';
import { EditTab } from '../../Fields/EditTab';
import { BASIC_TABLE_DEFAULT_COLUMN, THEMES } from './Theme';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AbcIcon from '@mui/icons-material/Abc';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ToggleOffOutlined from '@mui/icons-material/ToggleOffOutlined';
import UndoIcon from '@mui/icons-material/Undo';

import ThemeBlue from '../../../../images/designerThemes/designer-table-theme-blue.png';
import ThemeBlueDark from '../../../../images/designerThemes/designer-table-theme-blue-dark.png';
import { MultiPixel } from '../../Fields/MultiPixel';

import { get } from 'lodash';
import { useFocusedInput } from '../utils/useFocusedInput';
import { borderStyleOptions } from '../utils/Utils';
import { TableOptions } from '../../Common/TableOptions';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightSharpIcon />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'var(--color-neutral-1)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const EditTabField = enhancer(EditTab, (e) => e);

const debounceTime = 1500;

export function Panel() {

  const templateDataExtractorService = new TemplateDataExtractorService();

  const templateId = document.getElementById('designerTemplateId').value;

  const [active, setActive] = useState('');
  const [expanded, setExpanded] = useState(true);

  const { focusIdx } = useFocusIdx();
  const { change, values } = useBlock();
  const { setFocusedInput } = useFocusedInput();

  const [selectedTab, setSelectedTab] = useState("0");
  const [selectedTabGlobal, setSelectedTabGlobal] = useState("0");
  const [extractors, setExtractors] = useState([]);
  const [extractorsAsOptions, setExtractorsAsOptions] = useState([]);
  const [selectedExtractorId, setSelectedExtractorId] = useState('');
  const [selectedExtractorFieldsAsOptions, setSelectedExtractorFieldsAsOptions] = useState([]);

  useEffect(() => {
    templateDataExtractorService.findAllNotHidden(templateId).then(response => {
      setExtractors(response.data);

      const extractorsOpt = response.data.map(extractor => {
        return { value: `${extractor.code ? extractor.code + '.data' : ''}`, label: extractor.name }
      });
      extractorsOpt.unshift({ label: 'None', value: '' });

      setExtractorsAsOptions(extractorsOpt);

      const datasource = get(values, `${focusIdx}.data.value.settings.datasource`);
      if (datasource) {
        handleChangeExtractor(response.data, datasource, false);
      }

    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }, []);

  // Datasource
  useEffect(() => {
    handleChangeExtractor(extractors, selectedExtractorId, true);
  }, [selectedExtractorId]);

  function onChangeAccordion(tab) {
    if (tab !== active) {
      setExpanded(true);
    } else {
      setExpanded(!expanded);
    }
    setActive(tab);
  }

  function onThemeSelected(theme) {
    change(focusIdx + `.data.value.global`, theme.global);
  }

  function handleChangeExtractor(extractors, extractorId, _cleanPreSelectedField) {
    if (extractorId) {
      const extractorIndex = extractors.findIndex(extractor => (extractor.code + '.data') === extractorId);
      const extractor = extractors.at(extractorIndex);
      const extractorsFieldsOpt = extractor.fields.map(field => {
        return {
          label: <ListItem key={field.id} className="p-0">
            <ListItemAvatar sx={{ width: 24, minWidth: 34 }}>
              <Avatar sx={{ width: 24, height: 24 }} style={{ fontSize: 9, fontWeight: 900 }}>
                {field.dataType === 'STRING' ? <AbcIcon />
                  : field.dataType === 'NUMERIC' ? 123
                    : field.dataType === 'BOOLEAN' ? <ToggleOffOutlined />
                      : <MoreHorizIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              title={field.name}
              primary={field.name} primaryTypographyProps={{ fontSize: 12, noWrap: true }}
              secondary={field.dataType} secondaryTypographyProps={{ fontSize: 10, noWrap: true }}
              style={{ overflowWrap: "anywhere" }} />
          </ListItem>,
          value: `{{{this.${field.code}}}}`
        }
      });
      extractorsFieldsOpt.unshift({
        label: <div style={{ padding: "6px 0px" }}>None</div>,
        value: ''
      });
      setSelectedExtractorFieldsAsOptions(extractorsFieldsOpt);

      /* 
      // cleaning pre-selected values
      if (cleanPreSelectedField) {
        change(focusIdx + `.data.value.columns.data.body`, '');
      }
      */
    }
  };

  return (
    <AttributesPanelWrapper>
      <Accordion expanded={active === 'settings' && expanded} onChange={() => onChangeAccordion('settings')}>
        <AccordionSummary>Settings</AccordionSummary>
        <AccordionDetails>
          <Stack vertical>


            <SwitchField
              label='Header'
              name={`${focusIdx}.data.value.settings.header`}
              defaultChecked
              inline
              checkedText='ON'
              uncheckedText='OFF'
            />

            <SelectField
              label='Datasource'
              name={`${focusIdx}.data.value.settings.datasource`}
              options={extractorsAsOptions}
              inline
              helpText='Data to display in rows'
              onChangeAdapter={(value) => {
                setSelectedExtractorId(value);
                return value;
              }}
            />

            <SwitchField
              label='Footer'
              name={`${focusIdx}.data.value.settings.footer`}
              defaultChecked
              inline
              checkedText='ON'
              uncheckedText='OFF'
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={active === 'dimension' && expanded} onChange={() => onChangeAccordion('dimension')}>
        <AccordionSummary>Global Style</AccordionSummary>
        <AccordionDetails className='px-2, py-0'>
          <Stack vertical spacing='tight'>
            <TabContext value={selectedTabGlobal}>
              <TabList onChange={(_e, value) => setSelectedTabGlobal(value)} className='w-100'>
                <Tab label="Table" value="0" style={{ minWidth: 50, padding: '12px 14px' }} />
                <Tab label="Header" value="1" style={{ minWidth: 50, padding: '12px 14px' }} />
                <Tab label="Body" value="2" style={{ minWidth: 50, padding: '12px 14px' }} />
                <Tab label="Footer" value="3" style={{ minWidth: 50, padding: '12px 14px' }} />
              </TabList>
              <TabPanel value="0" className='ps-3 pe-0 py-3'>
                <Stack vertical spacing='tight'>
                  <Stack vertical spacing='extraTight'>
                    <TextStyle variation='strong'>Border</TextStyle>
                    <Grid.Row>
                      <Grid.Col span={11}>
                        <SelectField
                          label='Style'
                          name={`${focusIdx}.data.value.global.table.borderStyle`}
                          options={borderStyleOptions}
                          allowClear
                        />
                      </Grid.Col>
                      <Grid.Col offset={1} span={11}>
                        <ColorPickerField
                          label='Color'
                          name={`${focusIdx}.data.value.global.table.borderColor`}
                        />
                      </Grid.Col>
                    </Grid.Row>

                    <MultiPixel
                      label="Border Size (px)"
                      title="Remove all border"
                      nameTop={`${focusIdx}.data.value.global.table.borderTopSize`}
                      nameRight={`${focusIdx}.data.value.global.table.borderRightSize`}
                      nameBottom={`${focusIdx}.data.value.global.table.borderBottomSize`}
                      nameLeft={`${focusIdx}.data.value.global.table.borderLeftSize`}
                    />

                  </Stack>
                  <hr style={{ width: '96%' }} />
                </Stack>
              </TabPanel>
              <TabPanel value="1" className='ps-3 pe-0 py-3'>
                <TableOptions settings={`${focusIdx}.data.value.global.header`} showHeight />
              </TabPanel>

              <TabPanel value="2" className='ps-3 pe-0 py-3'>
                <TableOptions settings={`${focusIdx}.data.value.global.body`} showEvenOdd showHeight />
              </TabPanel>

              <TabPanel value="3" className='ps-3 pe-0 py-3'>
                <TableOptions settings={`${focusIdx}.data.value.global.footer`} showHeight />
              </TabPanel>
            </TabContext>
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={active === 'columns' && expanded} onChange={() => onChangeAccordion('columns')}>
        <AccordionSummary>Columns</AccordionSummary>
        <AccordionDetails>
          <Stack vertical spacing='tight'>
            <EditTabField
              tabPosition='top'
              labelTab='Column'
              name={`${focusIdx}.data.value.columns`}
              additionItem={cloneDeep(BASIC_TABLE_DEFAULT_COLUMN)}
              renderItem={(_item, index) => (
                <>
                  <TabContext value={selectedTab}>
                    <TabList onChange={(_e, value) => setSelectedTab(value)}>
                      <Tab label="Data" value="0" style={{ minWidth: 45, padding: '12px 12px' }} />
                      <Tab label="Header" value="1" style={{ minWidth: 45, padding: '12px 12px' }} />
                      <Tab label="Body" value="2" style={{ minWidth: 45, padding: '12px 12px' }} />
                      <Tab label="Footer" value="3" style={{ minWidth: 45, padding: '12px 12px' }} />
                    </TabList>
                    <TabPanel value="0" className='p-0 pb-3'>
                      <Stack
                        vertical
                        spacing='tight'
                      >
                        <Grid.Row>
                          <Grid.Col span={23}>
                            <TextAreaField label='Header'
                              name={`${focusIdx}.data.value.columns.[${index}].data.header`}
                              className="textarea-no-resize" rows={2}
                              onFocus={setFocusedInput}
                              debounceTime={debounceTime}
                            />
                          </Grid.Col>
                        </Grid.Row>


                        <Grid.Row>
                          <Grid.Col span={23}>
                            <Space align='center'>
                              <TextStyle>Body</TextStyle>
                              <SwitchField
                                name={`${focusIdx}.data.value.columns.[${index}].data.advancedMode`}
                                defaultChecked
                                inline
                                checkedText='Advanced'
                                uncheckedText='Basic'
                              />
                            </Space>

                            {get(values, `${focusIdx}.data.value.columns.[${index}].data.advancedMode`) ?
                              <TextAreaField
                                name={`${focusIdx}.data.value.columns.[${index}].data.body`}
                                className="textarea-no-resize" rows={3}
                                onFocus={setFocusedInput}
                                debounceTime={debounceTime}
                              />
                              :
                              <SelectField labelHidden
                                name={`${focusIdx}.data.value.columns.[${index}].data.body`}
                                options={selectedExtractorFieldsAsOptions}
                                className='dropdownWithSecondaryLabel'
                              />
                            }
                          </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Col span={23}>
                            <TextAreaField label='Footer'
                              name={`${focusIdx}.data.value.columns.[${index}].data.footer`}
                              className="textarea-no-resize" rows={3}
                              onFocus={setFocusedInput}
                              debounceTime={debounceTime}
                            />
                          </Grid.Col>
                        </Grid.Row>
                      </Stack>
                    </TabPanel>

                    <TabPanel value="1" className='p-0 pb-3'>
                      <TableOptions settings={`${focusIdx}.data.value.columns.[${index}].settings.header`} showWidth />
                    </TabPanel>
                    <TabPanel value="2" className='p-0 pb-3'>
                      <TableOptions settings={`${focusIdx}.data.value.columns.[${index}].settings.body`} />
                    </TabPanel>

                    <TabPanel value="3" className='p-0 pb-3'>
                      <TableOptions settings={`${focusIdx}.data.value.columns.[${index}].settings.footer`} />
                    </TabPanel>
                  </TabContext>

                </>
              )}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={active === 'themes' && expanded} onChange={() => onChangeAccordion('themes')}>
        <AccordionSummary>Themes</AccordionSummary>
        <AccordionDetails className='p-4'>
          <Button variant="contained" className="mb-4" fullWidth endIcon={<UndoIcon />}
            onClick={() => onThemeSelected(THEMES.THEME_DEFAULT)}>Reset Theme</Button>

          <img className='image-clickable' src={ThemeBlue} onClick={() => onThemeSelected(THEMES.THEME_BLUE)} />
          <img className='image-clickable' src={ThemeBlueDark} onClick={() => onThemeSelected(THEMES.THEME_BLUE_DARK)} />
        </AccordionDetails>
      </Accordion>

    </AttributesPanelWrapper >

  );
}