import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faCalendar,
  faFileAlt,
  faSignOutAlt,
  faUserGear,
  faUsersGear,
  faPuzzlePiece,
  faPager
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import SettingNav from "./SettingNav";
import icon from "../../images/icon.png";

import authServices from "../../Services/authServices";
import withSidebarAnimation from "../../Wrappers/withSidebarAnimation";


function SideBar(props) {
  const [settingsPanelVisible, setSettingsPanelVisible] = useState(false);
  const [mobileSliderOpen, setMobileSliderOpen] = useState(false);

  const [customerId, setCustomerId] = useState(JSON.parse(sessionStorage.getItem('currentCustomer')).customerId);
  const [admin, setAdmin] = useState(JSON.parse(sessionStorage.getItem('currentCustomer')).admin);

  const [superAdmin, setsuperAdmin] = useState(JSON.parse(sessionStorage.getItem('currentCustomer')).SUPERADMIN);

  const [styles, animate] = useSpring(() => ({
    width: "80px",
    zIndex: 10,
    marginRight: '-12px',
    config: { duration: 3 },
  }));
  const authService = new authServices();
  const [stylesSlider, animateSlider] = useSpring(() => ({
    height: "0",
    config: { duration: 30 },
  }))

  useEffect(() => {
    if (props.checkApiKey) {
      setSettingsPanelVisible(true);
    }
  }, [props.checkApiKey]);
  
  useEffect(() => {
    if (mobileSliderOpen) {
      animateSlider({ height: "100%" });
    } else {
      animateSlider({ height: "0" });
    }
  }, [mobileSliderOpen]);

  function logout() {

    // executeLogout();
    // Clear Session Storage
    sessionStorage.removeItem("currentCustomer");
    sessionStorage.removeItem("token");
    // Redirect to Login
    props.history.push('/Login');
    window.location.reload();

  }

  function executeLogout() {
    authService.logout();
  }

  return (
    <>
      <animated.div
        style={{ ...props.sidebarStyles }}
        id="mySidebar"
        className="sidebar"
        onMouseEnter={() => props.setSideBarExpanded(true)}
        onMouseLeave={() => props.setSideBarExpanded(false)}
        onClick={() => setMobileSliderOpen(!mobileSliderOpen)}
      >
        <img className="sq-icon" src={icon} />


        <animated.div style={stylesSlider}>

          {!superAdmin ?
            <><a href="#" onClick={() => props.history.push("/EmailSchedule")}>
              <span className="flex items-center py-2">
                <FontAwesomeIcon icon={faEnvelope} className="sec-color icons" />
                <span className="menu-icon-text">Mail</span>
              </span>
            </a><a href="#" onClick={() => props.history.push("/Calendar")}>
                <span className="flex items-center py-2">
                  <FontAwesomeIcon icon={faCalendar} className="sec-color icons" />
                  <span className="menu-icon-text">Calendar</span>
                </span>
              </a><a href="#" onClick={() => props.history.push("/Reports")}>
                <span className="flex items-center py-2">
                  <FontAwesomeIcon icon={faFileAlt} className="sec-color icons" />
                  <span className="menu-icon-text">Reports</span>
                </span>
              </a></>
            : ""}

          <a href="#" onClick={() => setSettingsPanelVisible(true)}>
            <span className="flex items-center py-2">
              <FontAwesomeIcon icon={faCog} className="sec-color icons" />
              <span className="menu-icon-text">Settings</span>
            </span>
          </a>

          {admin ?
            <a href="#" onClick={() => props.history.push("/Customer/" + customerId)}>
              <span className="flex items-center py-2">
                <FontAwesomeIcon icon={faUserGear} className="sec-color icons" />
                <span className="menu-icon-text">Management</span>
              </span>
            </a>
            : ""}


          {superAdmin ?
            <>
              <a href="#" onClick={() => props.history.push("/Customers")}>
                <span className="flex items-center py-2">
                  <FontAwesomeIcon icon={faUsersGear} className="sec-color icons" />
                  <span className="menu-icon-text">Customers</span>
                </span>
              </a>

              <a href="#" onClick={() => props.history.push("/Users")}>
                <span className="flex items-center py-2">
                  <FontAwesomeIcon icon={faUserGear} className="sec-color icons" />
                  <span className="menu-icon-text">Users</span>
                </span>
              </a>
              
              <a href="#" onClick={() => props.history.push("/PredefinedTemplates")}>
                <span className="flex items-center py-2">
                  <FontAwesomeIcon icon={faPager} className="sec-color icons" />
                  <span className="menu-icon-text">Templates</span>
                </span>
              </a>
            </>
            : ""}

          {false &&
            <a href="#" onClick={() => props.history.push("/TemplateComponents")}>
              <span className="flex items-center py-2">
                <FontAwesomeIcon icon={faPuzzlePiece} className="sec-color icons" />
                <span className="menu-icon-text">Components</span>
              </span>
            </a>
          }

          <a href="#" onClick={() => logout()}>
            <span className="flex items-center py-2">
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="sec-color icons fa-rotate-180"
              />
              <span className="menu-icon-text">Logout</span>
            </span>
          </a>
        </animated.div>
      </animated.div>
      <SettingNav
        open={settingsPanelVisible}
        close={() => setSettingsPanelVisible(false)}
        checkApiKey={props.checkApiKey}
      />
    </>
  );
}

export default withSidebarAnimation(withRouter(SideBar));
