import React, { useState } from "react";
import "./App.css";
import Routes from "./routes";

function App() {
  const [sideBarExpanded, setSideBarExpanded] = useState(false);

  return (
    <div className="App">
      <Routes
        sideBarExpanded={sideBarExpanded}
        setSideBarExpanded={setSideBarExpanded}
      />
    </div>
  );
}

export default App;
