import * as React from 'react';
import * as Loader from 'react-loader-spinner';
import OshkoshApplicationService from '../../Services/oshkoshApplicationService';
import Paper from '@material-ui/core/Paper';
import { darken, alpha, lighten } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import Switch from "react-switch";
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import {
  Scheduler,
  MonthView,
  WeekView,
  Appointments,
  Toolbar,
  DateNavigator,
  AppointmentTooltip,
  AppointmentForm,
  EditRecurrenceMenu,
  DayView
} from '@devexpress/dx-react-scheduler-material-ui';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DownloadIcon from '@mui/icons-material/Download';
import { withStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import * as moment from 'moment-timezone';

// import { appointments } from "../../../demo-data/appointments";
import {
  FormControl,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core";
import AddApiService from '../../Services/addApiService';
import authServices from '../../Services/authServices';
import EmailerSchedulerService from '../../Services/EmailerSchedulerService';
import SideBar from './SideBar';
import TopBar from "./TopBar";
import AnimatedBody from '../../Wrappers/AnimatedBody';
import PromptModal from './PromptModal';
import DeleteSchedulerModal from './DeleteSchedulerModal';
import Skeleton from 'react-loading-skeleton';

import { FILTER_TYPE } from "./TemplateFilter";
import TemplateFilterService from "../../Services/TemplateFilterService";
import TemplateDataExtractorService from "../../Services/TemplateDataExtractorService";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const selectionDropdownStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color ? data.color : "#EAEAEA"
    };
  }
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 567,
    maxWidth: 567,
    width: "100%"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

const getBorder = theme => (`1px solid ${theme.palette.type === 'light'
  ? lighten(alpha(theme.palette.divider, 1), 0.88)
  : darken(alpha(theme.palette.divider, 1), 0.68)
  }`);

const DayScaleCell = props => (
  <MonthView.DayScaleCell {...props} style={{ textAlign: 'center', fontWeight: 'bold' }} />
);

const styles = theme => ({
  cell: {
    color: '#78909C!important',
    position: 'relative',
    userSelect: 'none',
    verticalAlign: 'top',
    padding: 0,
    height: 100,
    borderLeft: getBorder(theme),
    '&:first-child': {
      borderLeft: 'none',
    },
    '&:last-child': {
      paddingRight: 0,
    },
    'tr:last-child &': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
      outline: 0,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
  },
  text: {
    padding: '0.5em',
    textAlign: 'center',
  },
  sun: {
    color: '#FFEE58',
  },
  cloud: {
    color: '#90A4AE',
  },
  rain: {
    color: '#4FC3F7',
  },
  sunBack: {
    backgroundColor: '#FFFDE7',
  },
  cloudBack: {
    backgroundColor: '#ECEFF1',
  },
  rainBack: {
    backgroundColor: '#E1F5FE',
  },
  opacity: {
    opacity: '0.5',
  },
  appointment: {
    borderRadius: '10px',
    '&:hover': {
      opacity: 0.6,
    },
  },
  apptContent: {
    '&>div>div': {
      whiteSpace: 'normal !important',
      lineHeight: 1.2,
    },
  },
  flexibleSpace: {
    flex: 'none',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipContent: {
    padding: theme.spacing(3, 1),
    paddingTop: 0,
    backgroundColor: theme.palette.background.paper,
    boxSizing: 'border-box',
    width: '400px',
  },
  tooltipText: {
    ...theme.typography.body2,
    display: 'inline-block',
  },
  title: {
    ...theme.typography.h6,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  icon: {
    color: theme.palette.action.active,
    verticalAlign: 'middle',
  },
  circle: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    verticalAlign: 'super',
  },
  textCenter: {
    textAlign: 'center',
  },
  dateAndTitle: {
    lineHeight: 1.1,
  },
  titleContainer: {
    paddingBottom: theme.spacing(2),
  },
  container: {
    paddingBottom: theme.spacing(1.5),
  },
});

const Appointment = withStyles(styles, { name: 'Appointment' })(({ classes, ...restProps }) => (
  <Appointments.Appointment
    {...restProps}
    className={classes.appointment}
  />
));

const AppointmentContent = withStyles(styles, { name: 'AppointmentContent' })(({ classes, ...restProps }) => (
  <Appointments.AppointmentContent {...restProps} className={classes.apptContent} />
));

const FlexibleSpace = withStyles(styles, { name: 'ToolbarRoot' })(({ classes, onDownloadClick, ...restProps }) => (
  <Toolbar.FlexibleSpace {...restProps} className={classes.flexibleSpace}>
    <div className={classes.flexContainer}>
      <CalendarMonthIcon fontSize="small" htmlColor="#FF7043" />
      <Typography variant="h5" style={{ marginLeft: '10px', marginRight: '10px', inlineSize: 'max-content' }}>Emailer Scheduler</Typography>
      <Tooltip title="Download Upcoming Schedulers">
        <DownloadIcon fontSize="small" htmlColor="#FF7043" onClick={onDownloadClick} // Call the callback function when DownloadIcon is clicked
          style={{ cursor: 'pointer' }} />
      </Tooltip>

    </div>
  </Toolbar.FlexibleSpace>
));

const formatYmd = date => date.toISOString().slice(0, 10);

class CalenderV2 extends React.PureComponent {

  constructor(props) {
    super(props);
    this.addApiService = new AddApiService();
    this.authService = new authServices();
    this.oshkoshApplicationService = new OshkoshApplicationService();
    this.emailerSchedulerService = new EmailerSchedulerService();
    this.templateFilterService = new TemplateFilterService();
    this.templateDataExtractorService = new TemplateDataExtractorService();

    this.state = {
      custId: this.authService.getCurrentCustomer().customerId,
      data: [],
      showPromptModal: false,
      showDeleteModal: false,
      idToDelete: 0,
      customerId: null,
      oshkoshApplications: [],
      costCenters: [],
      showLoader: true,
      users: [],
      templates: [],
      sendToOwner: false,
      selectedApplications: [],
      owners: [],
      ownersFetched: false,
      selectedApplicationValues: [],
      selectedTemplateValue: {},
      selectedUserValues: [],
      selectedUsers: [],
      addedAppointment: {

        title: 'Send to Owners test',
        description: 'heelo',
        startDate: new Date(2018, 6, 23, 9, 30),
        endDate: new Date(2018, 6, 23, 11, 30),
        ownerId: 1,
        sendToOwner: true,
        applications: []

      },
      isAppSelected: false,
      selectedAppointment: null,
      props: props,
      label: 'Cost Centers',
      hint: 'Please select Cost Center(s)...',
      costCenterForDropDown: 'hide',
      repeatMonth: [{ label: 'Current Month', value: 1 }, { label: 'Previous Month', value: 0 }],
      filters: [],
      filterValues: new Map(),
      selectedFilterValues: [],
      filterRequestData: new Map(),
      useNewTemplateEditor: false,
      sendToOwnerShow: false,
      schedulerView: 'Month'
    };


    this.commitChanges = this.commitChanges.bind(this);
    this.sendToOwnerChange = this.sendToOwnerChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.changeAddedAppointment = this.changeAddedAppointment.bind(this);
    this.changeAppointmentChanges = this.changeAppointmentChanges.bind(this);
    this.changeEditingAppointment = this.changeEditingAppointment.bind(this);
    this.handleUserDropdownChange = this.handleUserDropdownChange.bind(this);
    this.handleSelectedApplication = this.handleSelectedApplication.bind(this);
    this.handleTemplateSelection = this.handleTemplateSelection.bind(this);
    this.fetchScheduledEmailers = this.fetchScheduledEmailers.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.onChangeDynamicDropdownFilter = this.onChangeDynamicDropdownFilter.bind(this);

    this.applicationsForOshkoshTemplateTemplate = this.applicationsForOshkoshTemplateTemplate.bind(this);
    this.fetchScheduledEmailers = this.fetchScheduledEmailers.bind(this);
    this.downloadUpcomingSchedulars = this.downloadUpcomingSchedulars.bind(this);

    this.onSaveComplete = this.onSaveComplete.bind(this);
    this.handleDropdownChangeForCostCenter = this.handleDropdownChangeForCostCenter.bind(this);



  }

  componentDidMount() {
    // Get all Users
    // this.getAllUser(this.state.custId);
    // after select customer get All related email templates.
    this.getEmailTemplates(this.state.custId);
    // this.applicationsForOshkoshTemplateTemplate();
    // this.costCenterForDropDown();
    this.fetchScheduledEmailers();
    this.setState({ showLoader: false })
  }

  switchScheduler(selectedView) {
    this.setState({
      schedulerView: selectedView
    });
    console.log(this.state);
  }

  onSaveComplete() {

    this.setState({
      data: [],
      selectedApplications: [],
      owners: [],
      selectedApplicationValues: [],
      filterValues: new Map(),
      filterRequestData: new Map(),
      selectedTemplateValue: {},
      selectedUserValues: [],
      selectedUsers: [],
      isAppSelected: false,
      selectedAppointment: null
    })
    this.fetchScheduledEmailers();
  }

  applicationsForOshkoshTemplateTemplate() {

    this.oshkoshApplicationService.getAllOshkoshTemplatesForUser().then(response => {

      if (response === undefined) {
        this.setState({
          selectionLoading: 'show'
        })
      }
      if (response) {
        this.setState({
          oshkoshApplications: response,
          hint: 'Please select Application(s)...',
          label: 'Applications',
          selectionLoading: 'show'
        })

      }
    }).catch((error) => {


      console.log('ERROR: ', error);
    });

  }

  costCenterForDropDown() {

    this.oshkoshApplicationService.getCostCenterList().then(response => {

      if (response === undefined) {
        this.setState({
          selectionLoading: 'show'
        })
      }
      if (response) {
        this.setState({
          oshkoshApplications: response,
          hint: 'Please select Cost Center(s)...',
          label: 'Cost Centers',
          selectionLoading: 'show'
        })

      }
    }).catch((error) => {


      console.log('ERROR: ', error);
    });

  }

  getVPPortfolioList() {

    this.oshkoshApplicationService.getVPPortfolioList().then(response => {

      if (response === undefined) {
        this.setState({
          selectionLoading: 'show'
        })
      }
      if (response) {
        this.setState({
          oshkoshApplications: response,
          selectionLoading: 'show',
          hint: 'Please select VP(s)...',
          label: 'Vps'
        })

      }
    }).catch((error) => {


      console.log('ERROR: ', error);
    });

  }


  getMarketGroups() {

    this.oshkoshApplicationService.getMarketGroups().then(response => {

      if (response === undefined) {
        this.setState({
          selectionLoading: 'show'
        })
      }
      if (response) {
        this.setState({
          oshkoshApplications: response,
          selectionLoading: 'show',
          hint: 'Please select Market(s)...',
          label: 'Markets'
        })

      }
    }).catch((error) => {


      console.log('ERROR: ', error);
    });

  }

  getFacilities() {

    this.oshkoshApplicationService.getFacilities().then(response => {

      if (response === undefined) {
        this.setState({
          selectionLoading: 'show'
        })
      }
      if (response) {
        this.setState({
          oshkoshApplications: response,
          selectionLoading: 'show',
          hint: 'Please select Business Unit(s)...',
          label: 'BusinessUnit'
        })

      }
    }).catch((error) => {


      console.log('ERROR: ', error);
    });

  }

  getCloudServices() {

    this.oshkoshApplicationService.getCloudServices().then(response => {

      if (response === undefined) {
        this.setState({
          selectionLoading: 'show'
        })
      }
      if (response) {
        this.setState({
          oshkoshApplications: response,
          selectionLoading: 'show',
          hint: 'Please select Cloud Service(s)...',
          label: 'CloudServices'
        })

      }
    }).catch((error) => {


      console.log('ERROR: ', error);
    });

  }

  findOwnersInArray(applications) {
    const selectedApplications = [];
    const appsRemovedFromAPI = [];
    const selectedApplicationValues = [];

    if (this.state.preselectedApplicationsWithoutOwners) {
      this.state.preselectedApplicationsWithoutOwners.forEach(selectedApp => {
        const found = applications.find(app => app.label === selectedApp.label && app.value === selectedApp.value);
        if (found) {

          selectedApp.owner = found.owner;
          selectedApp.emailerAddress = found.emailerAddress;

          const item = { ...selectedApp };
          item.owner = found.owner;
          item.emailerAddress = found.emailerAddress;

          selectedApplications.push(item);
          selectedApplicationValues.push(item);
        } else {
          const item = { ...selectedApp };
          item.color = "red";
          appsRemovedFromAPI.push(selectedApp);
          selectedApplications.push(item);
          selectedApplicationValues.push(item);
        }
      });

      this.setState({
        selectedApplications: selectedApplications,
        selectedApplicationValues: selectedApplicationValues,
        appsRemovedFromAPI: appsRemovedFromAPI
      });

      if (appsRemovedFromAPI.length !== 0) {
        Swal.fire({
          icon: "warning",
          title: 'Something Wrong with Selections',
          text: 'Following selection are no longer valid as per Apptio response: ' + Array.prototype.map.call(appsRemovedFromAPI, function (item) { return item.label; }).join(",") + '.\nPlease remove these values from selection and update the Schedular.',
          showConfirmButton: true,
          allowOutsideClick: false,
          scrollbarPadding: false,

        });
      }

    }

  }

  fetchDropdownValues(template) {

    let body = {
      templateId: template.id,
      templateName: template.name
    }

    this.oshkoshApplicationService.getDropDownValues(body).then(response => {
      if (response === undefined) {
        this.setState({
          selectionLoading: 'show'
        })
      }
      if (response) {
        this.setState({
          oshkoshApplications: response,
          selectionLoading: 'show',
          hint: 'Please select ' + template.dropdownLabel + '...',
          label: template.dropdownLabel
        });

        this.findOwnersInArray(response);
      }
    }).catch(() => {
      Swal.fire({
        icon: "warning",
        title: 'Something Wrong with API Key',
        text: 'Not able to get dropdown lists, Please check the API Key and try again',
        showConfirmButton: true,
        allowOutsideClick: false,
        scrollbarPadding: false,
        preConfirm: () => {
          this.setState({
            selectionLoading: 'hide',
            users: []
          });
        }
      });
    });

  }



  fetchScheduledEmailers() {

    let parsedData = [];
    this.emailerSchedulerService.fetchScheduledEmailers().then(response => {

      response.forEach((item) => {
        item.startDate = moment(item.startDate).toDate();
        parsedData.push(item);
      })
      this.setState({
        data: parsedData
      })

    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  downloadUpcomingSchedulars() {


    this.emailerSchedulerService.downloadUpcomingSchedulars().then(response => {
      debugger
      // Create a URL for the binary data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response]));

      // Create a temporary <a> element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'upcomingScheduledEmails.xlsx');
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);

    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  //populate dropdown
  applicationsForTemplate() {

    let applications = [];
    applications.push({ label: 'Select All', value: true })
    this.state.oshkoshApplications.forEach(function (app) {
      applications.push({ label: app.label, value: app.value });
    });

    return applications;

  }

  //populate dropdown
  costCenterForTemplate() {

    let applications = [];
    applications.push({ label: 'Select All', value: true })
    this.state.costCenters.forEach(function (app) {
      applications.push({ label: app.label, value: app.value });
    });

    return applications;

  }

  //templates dropdown
  templatesForSchedular() {

    let applications = [];
    this.state.templates.forEach(function (app) {
      applications.push({ label: app.name, value: app.id });
    });

    return applications;

  }

  //populate dropdown
  usersDropDownForTemplate() {

    let dropDownUsers = [];
    // dropDownUsers.push({ label: 'Select All', value: true })
    this.state.users.forEach(function (app) {
      dropDownUsers.push({ label: app.userName, value: app.userName });
    });

    return dropDownUsers;

  }

  sendToOwnerChange(checked) {
    this.setState(() => ({
      sendToOwner: checked,
      selectedAppointment: {
        sendToOwner: checked,
        applications: this.state.selectedApplicationValues,
        users: this.state.selectedUserValues,
        template: this.state.selectedTemplateValue,
        dynamicFilterValues: Array.from(this.state.filterRequestData.values()),
        ...this.state.selectedAppointment,
        ...this.state.filters
      }
    }));

    if (checked) {
      if (!this.state.ownersFetched && this.state.owners.length === 0) {
        // Query for owners
        if (this.state.ownerDataExtractorId && this.state.ownerNameExtractorField && this.state.ownerEmailExtractorField) {
          const previewRequest = {
            userApptioProfile: JSON.parse(sessionStorage.getItem('currentCustomer')).userName,
            ignoredFilters: ["ALL"],
            selectFields: [this.state.ownerNameExtractorField, this.state.ownerEmailExtractorField],
            orderByFields: [this.state.ownerNameExtractorField]
          };

          this.templateDataExtractorService.previewDataExtractor(this.state.selectedTemplateValue.value, this.state.ownerDataExtractorId, previewRequest).then(response => {
            let ownerRecords = [];
            let ownerNameList = [];
            let arrData = Object.values(response.data)[0];

            if (arrData !== undefined && arrData.rowCount > 0) {
              Array.from(arrData.data).forEach((rowData) => {
                if (rowData[this.state.ownerNameExtractorField] !== null && ownerNameList.indexOf(rowData[this.state.ownerNameExtractorField]) === -1) {
                  ownerNameList.push(rowData[this.state.ownerNameExtractorField])

                  ownerRecords.push({
                    name: rowData[this.state.ownerNameExtractorField],
                    emailAddress: rowData[this.state.ownerEmailExtractorField]
                  });
                }
              });
            }

            this.setState({
              owners: ownerRecords,
              ownersFetched: true,
            });
          });
        }
      }
    }
  }

  //on visibility change
  onVisibilityChange() {

    // 
    // this.setState({
    //   selectedApplications: [],
    //   selectedApplicationValues: [],
    //   selectedTemplateValue: {},
    //   selectedUserValues: [],
    //   selectedUsers: [],
    //   isAppSelected: false,
    //   selectedAppointment: null
    // })

  }



  //populate dropdown
  sendtowonervalue() {
    return this.state.sendToOwner;
  }

  changeAddedAppointment(addedAppointment) {
    this.setState({ addedAppointment });
  }

  changeAppointmentChanges(appointmentChanges) {

    this.setState({ appointmentChanges });
  }

  changeEditingAppointment(editingAppointment) {

    this.setState({ editingAppointment });
  }

  commitChanges({ added, changed, deleted }) {

    //appointment updation cases
    if (changed !== undefined) {

      if (added !== undefined) {
        var updatedOnlyThisAppointment = {};

        updatedOnlyThisAppointment.title = added.title;
        updatedOnlyThisAppointment.startDate = added.startDate
        updatedOnlyThisAppointment.endDate = added.endDate
        updatedOnlyThisAppointment.allDay = added.allDay
        updatedOnlyThisAppointment.rRule = added.rRule
        updatedOnlyThisAppointment.repeatMonth = added.repeatMonth
        updatedOnlyThisAppointment.exDate = added.exDate
        updatedOnlyThisAppointment.timezone = added.timezone
        if (!added.rRule) {
          updatedOnlyThisAppointment.month = added.month
        }
        updatedOnlyThisAppointment.sendToOwner = this.state.sendToOwner
        updatedOnlyThisAppointment.users = this.state.selectedUserValues
        updatedOnlyThisAppointment.applications = this.state.selectedApplicationValues
        updatedOnlyThisAppointment.template = this.state.selectedTemplateValue

        this.state.selectedAppointment.exDate = changed[this.state.selectedAppointment.id].exDate ? changed[this.state.selectedAppointment.id].exDate : this.state.selectedAppointment.exDate
        this.state.selectedAppointment.rRule = changed[this.state.selectedAppointment.id].rRule ? changed[this.state.selectedAppointment.id].rRule : this.state.selectedAppointment.rRule
        this.state.selectedAppointment.changedThisAppointment = updatedOnlyThisAppointment
      } else {

        var appId = Object.keys(changed).map(Number)[0];
        if (this.state.selectedAppointment === null) {
          this.state.selectedAppointment = {}
        }
        this.state.selectedAppointment.id = appId
        this.state.selectedAppointment.deleteThisAppointment = true
        this.state.selectedAppointment.exDate = changed[this.state.selectedAppointment.id].exDate ? changed[this.state.selectedAppointment.id].exDate : this.state.selectedAppointment.exDate
        this.state.selectedAppointment.rRule = changed[this.state.selectedAppointment.id].rRule ? changed[this.state.selectedAppointment.id].rRule : this.state.selectedAppointment.rRule
        this.state.selectedAppointment.repeatMonth = changed[this.state.selectedAppointment.id].repeatMonth ? changed[this.state.selectedAppointment.id].repeatMonth : this.state.selectedAppointment.repeatMonth
        this.state.selectedAppointment.title = changed[this.state.selectedAppointment.id].title ? changed[this.state.selectedAppointment.id].title : this.state.selectedAppointment.title
        this.state.selectedAppointment.startDate = changed[this.state.selectedAppointment.id].startDatechanged ? changed[this.state.selectedAppointment.id].startDatechanged : this.state.selectedAppointment.startDatechanged
        this.state.selectedAppointment.endDate = changed[this.state.selectedAppointment.id].endDatechanged ? changed[this.state.selectedAppointment.id].endDatechanged : this.state.selectedAppointment.endDatechanged
        this.state.selectedAppointment.allDay = changed[this.state.selectedAppointment.id].allDaychanged ? changed[this.state.selectedAppointment.id].allDaychanged : this.state.selectedAppointment.allDaychanged
        this.state.selectedAppointment.timezone = changed[this.state.selectedAppointment.id].timezonechanged ? changed[this.state.selectedAppointment.id].timezonechanged : this.state.selectedAppointment.timezonechanged
      }
    }

    if (deleted !== undefined) {


      this.setState({
        showDeleteModal: true,
        idToDelete: deleted
      })

    } else {

      //saving schedular
      this.setState({
        showPromptModal: true
      })
    }


  }

  //get template users
  getTemplateUsers = (template) => {
    this.addApiService.getTemplateUser(template).then(response => {
      if (response && response.length !== 0) {
        this.setState({ users: response })
      }
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  //all user api
  getAllUser = (customerId) => {
    this.addApiService.getAllUser(customerId).then(response => {
      console.log(response, 'all users ofthe customer')
      this.setState({ users: response })
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  selectedCustomer(custId) {
    this.setState({
      customerId: custId
    });


    // Get all Users
    this.getAllUser(custId);

    // after select customer get All related email templates.
    this.getEmailTemplates(custId);

    // Get all the oshkosh templates for oshkosh template
    this.applicationsForOshkoshTemplateTemplate();

    // Get all the cost centers for the Cost Center template
    this.costCenterForDropDown();

    // Get all scheduled Emailers 
    this.fetchScheduledEmailers();

  }

  // Get Email Templates
  getEmailTemplates(customerId) {

    this.addApiService.getEmailTemplates(customerId, true).then(response => {
      // response.updated_at = moment(new Date(response.updated_at)).format('MM-DD');
      this.setState({ templates: response });
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }



  handleSelectedApplication(appointment) {

    this.setState({
      sendToOwner: appointment.sendToOwner,
      selectedApplicationValues: appointment.applications
    })
  }

  handleDropdownChange(_value, action) {

    let ac = action.action;
    if (ac === 'select-option') {
      if (action.option.label !== 'Select All') {
        let obj = this.state.oshkoshApplications.find((app) => app.label === action.option.label);
        this.state.selectedApplications.push(obj);
        this.state.selectedApplicationValues.push(obj);
      } else {
        this.state.selectedApplications = this.state.oshkoshApplications;
        this.state.selectedApplicationValues = this.state.oshkoshApplications;
        this.state.oshkoshApplications.forEach(e => e.isDisabled = true);
      }
    } else if (ac === 'remove-value') {
      if (action.removedValue.label !== 'Select All') {

        this.state.selectedApplications = this.state.selectedApplications.filter((app) => app.label !== action.removedValue.label);
        this.state.selectedApplicationValues = this.state.selectedApplications.filter((app) => app.label !== action.removedValue.label);
      } else {
        this.state.selectedApplications = []
        this.state.selectedApplicationValues = []
        this.state.oshkoshApplications.forEach(e => e.isDisabled = false);

      }
    } else if (ac === 'clear') {
      this.state.selectedApplications = []
      this.state.selectedApplicationValues = []
      this.state.oshkoshApplications.forEach(e => e.isDisabled = false);
    }
  }

  onChangeDynamicDropdownFilter(filterObj, value, actionObj) {
    if (actionObj.action === 'select-option') {
      if (actionObj.option.label !== 'Select All') {
        this.state.filterValues.set(filterObj.code, { code: filterObj.code, isMultiselectable: filterObj.isMultiselectable, isRequired: filterObj.isRequired, values: value.map(item => item.value) });
        this.state.selectedFilterValues.push(actionObj.option);
      } else {
        this.state.filterValues.set(filterObj.code, { code: filterObj.code, isMultiselectable: filterObj.isMultiselectable, isRequired: filterObj.isRequired, values: filterObj.dropdownPreviewData.map(item => item.value) });
        this.state.selectedFilterValues.push(filterObj.dropdownPreviewData);
      }
    } else if (actionObj.action === 'remove-value') {
      this.state.filterValues.set(filterObj.code, { code: filterObj.code, isMultiselectable: filterObj.isMultiselectable, isRequired: filterObj.isRequired, values: value.length > 0 ? value.map(item => item.value) : [] });
      console.log(actionObj.removedValue);
      this.state.selectedFilterValues.filter((value) => value !== actionObj.removedValue.value);
    } else if (actionObj.action === 'clear') {
      this.state.filterValues.delete(filterObj.code);
      this.state.selectedFilterValues = [];
    }

    // checking required filters
    this.state.filters.forEach((fl) => {
      let formattedValue = this.state.filterValues.get(fl.code) && this.state.filterValues.get(fl.code).values.length > 0
        ? this.state.filterValues.get(fl.code).values[0] : '';

      let filterData = {
        filterCode: fl.code,
        comparator: fl.isMultiselectable ? 'CONTAIN' : 'EQUAL_TO',
        value: fl.isMultiselectable ? this.state.filterValues.get(fl.code).values.join(';') : formattedValue
      };

      this.state.filterRequestData.set(fl.code, filterData);
    });

  }

  handleDropdownChangeForCostCenter(e) {
    let apps = [];
    let selectedApps = [];
    if (e.length > 0) {

      if (e.length === 1 && e[0].value === true) {
        apps = this.state.costCenters;
        this.state.costCenters.forEach((item) => {
          selectedApps.push({ label: item.costCenterAndId, value: item.costCenterAndId })
        })
      } else {
        e = e.filter((s) => {
          return s.value !== true
        })
        e.forEach((item) => {
          apps.push(this.state.costCenters.find(obj => obj.costCenterAndId === item.value));
          selectedApps.push(item);
        })

        this.setState({ [e]: e })
      }
    }

    this.setState({
      selectedApplications: apps,
      selectedApplicationValues: selectedApps,
      selectedAppointment: {
        sendToOwner: this.state.sendToOwner,
        applications: selectedApps,
        users: this.state.selectedUserValues,
        template: this.state.selectedTemplateValue,
        ...this.state.selectedAppointment
      }
    }, () => {
      console.log(this.state.selectedApplicationValues, 'selected application values')
    })

  }

  handleTemplateSelection(e) {

    let templateValue = { value: e.value, label: e.label };

    this.getTemplateUsers(this.state.templates.find(temp => temp.id === e.value).id);

    let template = this.state.templates.find(temp => temp.id === e.value);

    // TODO: Jairo: ownerDataExtractorId, ownerNameExtractorField, ownerEmailExtractorField are NULL, so this OwnerList is Not Displayed
    this.setState({
      selectedTemplateValue: templateValue,
      selectionLoading: 'load',
      useNewTemplateEditor: template.useNewTemplateEditor,
      ownerDataExtractorId: template.ownerDataExtractorId,
      ownerNameExtractorField: template.ownerNameExtractorField,
      ownerEmailExtractorField: template.ownerEmailExtractorField
    })

    if (!template.useNewTemplateEditor) {
      if (template.isDropdown) {
        this.fetchDropdownValues(template);
      } else {
        this.setState({ selectionLoading: 'hide' });
      }
    } else {
      this.findTemplateFilters(template.id);
    }


    if (template.isSendToOwner) {
      this.setState({ sendToOwnerShow: 'show' });
    } else {
      this.setState({ sendToOwnerShow: 'hide' });
    }
  }

  findTemplateFilters(templateId) {
    this.templateFilterService.findAll(templateId, true).then(response => {
      let dropdownCount = 0;

      response.data.forEach((item) => {
        if (item.type === FILTER_TYPE.DATA_EXTRACTOR_DROPDOWN) {
          dropdownCount++;

          this.setState({
            oshkoshApplications: item.dropdownPreviewData,
            selectionLoading: 'show',
            hint: 'Please select ' + item.displayLabel + '...',
            label: item.displayLabel,
            filters: [
              item,
              ...this.state.filters
            ]
          });
        }
      });

      if (dropdownCount == 0) {
        this.setState({ selectionLoading: 'hide' })
      }
    });
  }

  handleUserDropdownChange(e) {

    let users = [];
    let selectedUsers = [];
    if (e.length > 0) {

      if (e.length === 1 && e[0].value === true) {
        users = this.state.users;
      } else {
        e = e.filter((s) => {
          return s.value !== true
        })
        e.forEach((item) => {
          users.push(this.state.users.find(obj => obj.application === item.value));
          selectedUsers.push(item);
        })

        this.setState({ [e]: e })
      }
    }

    this.setState({
      selectedUsers: users,
      selectedUserValues: selectedUsers,
      selectedAppointment: {
        sendToOwner: this.state.sendToOwner,
        users: selectedUsers,
        applications: this.state.selectedApplicationValues,
        dynamicFilterValues: Array.from(this.state.filterRequestData.values()),
        ...this.state.selectedAppointment
      }
    }, () => {

    })

  }

  // Routing towards AddApi view
  onApiClick = () => {
    this.props.history.push("/AddApi")
  };

  // Routing towards Mobile view
  onMobileView = () => {
    this.props.history.push("/mobile")
  };

  //Routing towards Emailer
  onEmailer = () => {
    this.props.history.push("/Emailer")
  };

  //Routing towards Settings
  onSetting = () => {
    this.props.history.push("/Setting")
  }

  // TEMP Logout
  logout = () => {
    // Clear Session Storage
    sessionStorage.clear();
    // Redirect to Login
    this.props.history.push('/');
  }


  render() {

    const Layout = ({ ...restProps }) => {

      return (

        <AppointmentForm.Layout
          {...restProps}
          className="overflow-hidden scheduler-popup-content-overflow"
        >

        </AppointmentForm.Layout>
      )
    }

    const DateEditor = ({ excludeTime, ...restProps }) => {

      const dateFormat = excludeTime ? "MM/DD/YYYY" : "MM/DD/YYYY HH:mm";

      return (
        <AppointmentForm.DateEditor
          {...restProps}
          excludeTime={excludeTime}
          format={dateFormat}
        />
      );
    };

    // for recurrence form layout.
    const RecurrenceLayout = ({ onFieldChange, appointmentData, ...restProps }) => {

      return (

        <AppointmentForm.RecurrenceLayout
          appointmentData={appointmentData}
          onFieldChange={onFieldChange}
          style={{ height: 700 }}
          {...restProps}
        >

        </AppointmentForm.RecurrenceLayout>
      )
    }

    // Appointment form layout
    const BasicLayout = ({ onFieldChange, appointmentData }) => {

      const titleFeildChange = (nextValue) => {
        onFieldChange({ title: nextValue });
      };

      const dateFeildChange = (nextValue) => {
        onFieldChange({
          startDate: nextValue,
          endDate: nextValue
        });
      };

      const repeatFeildChange = (nextValue) => {
        if (nextValue) {
          onFieldChange({ rRule: "RRULE:INTERVAL=1;FREQ=DAILY;COUNT=30" });
        } else {
          onFieldChange({ rRule: undefined });
        }
      };

      const timezoneChage = (nextValue) => {

        onFieldChange({ timezone: nextValue })
      }

      const monthDropDownChange = (nextValue) => {

        onFieldChange({ month: nextValue.target.value })
      }

      const repeatMonthDropdownChange = (nextValue) => {

        onFieldChange({ repeatMonth: nextValue })
      }

      const enableSave = (nextValue) => {
        onFieldChange({ sendToOwnerChange: this.state.sendToOwner });
      };

      const classes = useStyles();
      if (typeof (appointmentData.title) === 'undefined') {
        this.state.sendToOwner = false
        this.state.selectedApplicationValues = []
        this.state.filterValues = new Map()
        this.state.selectedUserValues = []
        this.state.selectedTemplateValue = {}
        this.state.selectedAppointment = null

      }
      // first time, appointment change

      if (this.state.selectedAppointment === null) {

        this.state.selectedAppointment = appointmentData;
        this.state.selectedAppointment.id = appointmentData.id;
        this.state.selectedAppointment.title = appointmentData.title;
        this.state.selectedAppointment.startDate = appointmentData.startDate;
        this.state.selectedAppointment.endDate = appointmentData.endDate;
        this.state.selectedAppointment.allDay = appointmentData.allDay;
        this.state.selectedAppointment.rRule = appointmentData.rRule;
        this.state.selectedAppointment.exDate = appointmentData.exDate;
        this.state.selectedAppointment.sendToOwner = appointmentData.sendToOwner;
        this.state.selectedAppointment.users = appointmentData.users;
        this.state.selectedAppointment.applications = appointmentData.applications;
        this.state.selectedAppointment.template = appointmentData.template;
        this.state.selectedAppointment.timezone = appointmentData.timezone;
        this.state.selectedAppointment.month = appointmentData.month;
        this.state.selectedUserValues = this.state.selectedAppointment.users;
        this.state.selectedTemplateValue = this.state.selectedAppointment.template;
        this.state.selectedAppointment.repeatMonth = appointmentData.repeatMonth;

        this.state.selectedApplicationValues = this.state.selectedAppointment.applications || [];
        this.state.selectedApplications = this.state.selectedAppointment.applications || [];
        this.state.preselectedApplicationsWithoutOwners = this.state.selectedAppointment.applications || [];

        this.state.selectedAppointment.dynamicFilterValues = appointmentData.dynamicFilterValues || [];

        if (appointmentData.template !== undefined) {
          this.handleTemplateSelection(appointmentData.template);
        }

      }

      else if (typeof (this.state.selectedAppointment.id) === 'undefined' && typeof (appointmentData.id) !== 'undefined') {
        this.state.selectedAppointment = appointmentData;
        this.state.selectedAppointment.id = appointmentData.id;
        this.state.selectedAppointment.title = appointmentData.title;
        this.state.selectedAppointment.startDate = appointmentData.startDate;
        this.state.selectedAppointment.endDate = appointmentData.endDate;
        this.state.selectedAppointment.allDay = appointmentData.allDay;
        this.state.selectedAppointment.rRule = appointmentData.rRule;
        this.state.selectedAppointment.exDate = appointmentData.exDate;
        this.state.selectedAppointment.sendToOwner = appointmentData.sendToOwner;
        this.state.selectedAppointment.users = appointmentData.users;
        this.state.selectedAppointment.applications = appointmentData.applications;
        this.state.selectedAppointment.template = appointmentData.template;
        this.state.selectedAppointment.timezone = appointmentData.timezone;
        this.state.selectedAppointment.month = appointmentData.month;
        this.state.selectedAppointment.repeatMonth = appointmentData.repeatMonth;

        this.state.selectedAppointment.dynamicFilterValues = appointmentData.dynamicFilterValues;
      }
      else if (typeof (this.state.selectedAppointment.id) !== 'undefined' && typeof (appointmentData.id) !== 'undefined' && this.state.selectedAppointment.id !== appointmentData.id) {
        this.state.selectedAppointment = appointmentData;
        this.state.selectedAppointment.id = appointmentData.id;
        this.state.selectedAppointment.title = appointmentData.title;
        this.state.selectedAppointment.startDate = appointmentData.startDate;
        this.state.selectedAppointment.endDate = appointmentData.endDate;
        this.state.selectedAppointment.allDay = appointmentData.allDay;
        this.state.selectedAppointment.rRule = appointmentData.rRule;
        this.state.selectedAppointment.exDate = appointmentData.exDate;
        this.state.selectedAppointment.sendToOwner = appointmentData.sendToOwner;
        this.state.selectedAppointment.users = appointmentData.users;
        this.state.selectedAppointment.applications = appointmentData.applications;
        this.state.selectedAppointment.template = appointmentData.template;
        this.state.selectedAppointment.timezone = appointmentData.timezone;
        this.state.selectedAppointment.month = appointmentData.month;
        this.state.selectedAppointment.repeatMonth = appointmentData.repeatMonth;

        this.state.selectedAppointment.dynamicFilterValues = appointmentData.dynamicFilterValues;

        this.state.preselectedApplicationsWithoutOwners = this.state.selectedAppointment.applications;
        this.handleTemplateSelection(this.state.selectedTemplateValue);
      }
      else {

        this.state.selectedAppointment.id = appointmentData.id;
        this.state.selectedAppointment.title = appointmentData.title;
        this.state.selectedAppointment.startDate = appointmentData.startDate;
        this.state.selectedAppointment.endDate = appointmentData.endDate;
        this.state.selectedAppointment.allDay = appointmentData.allDay;
        this.state.selectedAppointment.rRule = appointmentData.rRule;
        this.state.selectedAppointment.exDate = appointmentData.exDate;
        this.state.selectedAppointment.timezone = appointmentData.timezone;
        this.state.selectedAppointment.month = appointmentData.month;
        this.state.selectedAppointment.repeatMonth = appointmentData.repeatMonth;
        this.state.selectedAppointment.sendToOwner = this.state.sendToOwner;

        appointmentData.sendToOwner = this.state.sendToOwner;
        this.state.selectedAppointment.users = this.state.selectedUserValues;
        appointmentData.users = this.state.selectedUserValues;
        this.state.selectedAppointment.applications = this.state.selectedApplicationValues;
        appointmentData.applications = this.state.selectedApplicationValues;
        this.state.selectedAppointment.template = this.state.selectedTemplateValue;
        appointmentData.template = this.state.selectedTemplateValue;
      }

      if (typeof (this.state.selectedAppointment.sendToOwner) === 'undefined') {
        this.state.selectedAppointment.sendToOwner = false;
      }
      if (typeof (this.state.selectedAppointment.applications) === 'undefined') {
        this.state.selectedAppointment.applications = [];
      }
      if (typeof (this.state.selectedAppointment.users) === 'undefined') {
        this.state.selectedAppointment.users = [];
      }
      if (typeof (this.state.selectedAppointment.template) === 'undefined') {
        this.state.selectedAppointment.template = {};
      }
      if (typeof (this.state.selectedAppointment.dynamicFilterValues) === 'undefined') {
        this.state.selectedAppointment.dynamicFilterValues = [];
      }
      this.state.sendToOwner = this.state.selectedAppointment.sendToOwner
      this.state.selectedApplicationValues = this.state.selectedAppointment.applications;
      this.state.selectedUserValues = this.state.selectedAppointment.users;
      this.state.selectedTemplateValue = this.state.selectedAppointment.template;


      return (


        <div onClick={enableSave} className="px-4 pb-4" style={{ minHeight: 500 }}>
          <AppointmentForm.Label text="Details" type="title" />
          <AppointmentForm.TextEditor id="title" type="titleTextEditor" placeholder="Enter Title here..." value={appointmentData.title} onValueChange={titleFeildChange} />

          <div className="text-align-left">

            <DateEditor id="startDate" value={appointmentData.startDate} onValueChange={dateFeildChange} className="text-align-left" format={'en-US'} />
            <TimezonePicker id="timezone-drop-down"
              absolute={true}
              placeholder="Select timezone..."
              onChange={timezoneChage}
              value={appointmentData.timezone}
            />
          </div>


          <div className="text-align-left">
            <AppointmentForm.BooleanEditor id="repeat" label="Repeat" value={appointmentData.rRule} onValueChange={repeatFeildChange} className="text-align-left" />
            {appointmentData.rRule ?
              <Select value={appointmentData.repeatMonth}
                options={this.state.repeatMonth}
                onChange={repeatMonthDropdownChange}
                placeholder="Please select repeat month..."
              /> :
              <TextField
                id="schedular-month-drop-down"
                size="small"
                label="Month"
                type="month"
                className="align-month-dropdown-right"
                value={appointmentData.month}
                onChange={monthDropDownChange}
                // defaultValue={this.state.datepicker}
                minRows="1"
                sx={{ width: 200 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />}
          </div>

          <AppointmentForm.Label text="Emailer" type="title" />
          <FormControl className={classes.formControl + ' width-100-perc'}>

            <Select value={this.state.selectedTemplateValue} options={this.templatesForSchedular()} onChange={this.handleTemplateSelection} placeholder="Please select Emailer template..." />
          </FormControl>

          {
            this.state.useNewTemplateEditor &&
            <>
              {
                this.state.selectionLoading === 'show' ?
                  <>
                    <AppointmentForm.Label text={this.state.label} type="title" />
                    {
                      this.state.filters && this.state.filters.map((item, index) => (
                        <div key={index} className="col">
                          {
                            item.type === FILTER_TYPE.DATA_EXTRACTOR_DROPDOWN &&
                            <Select key={item.code} id={item.code} value={this.state.selectedFilterValues}
                              closeMenuOnSelect={false} placeholder={item.displayLabel}
                              isMulti={item.isMultiselectable}
                              options={item.dropdownPreviewData}
                              onChange={(value, action) => this.onChangeDynamicDropdownFilter(item, value, action)}
                              style={"position: absolute"}
                            />
                          }
                        </div>
                      ))
                    }
                  </>
                  : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />
              }
            </>
          }

          {!this.state.useNewTemplateEditor ?
            <>
              {this.state.selectionLoading === 'show' ? <AppointmentForm.Label text={this.state.label} type="title" />
                : this.state.selectionLoading === 'load' ? <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />
                  : this.state.selectionLoading === 'hide' ? "" : ""
              }

              {this.state.selectionLoading === 'show' ?
                <FormControl id="dropdown-display-table-captions" className={classes.formControl + ' width-100-perc'}>

                  <Select id="dropdown-display-table-captions" isMulti styles={selectionDropdownStyles} value={this.state.selectedApplicationValues} options={this.applicationsForTemplate()} onChange={(e, action) => this.handleDropdownChange(e, action)} placeholder={this.state.hint} />
                </FormControl>
                : this.state.selectionLoading === 'load' ? <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />
                  : this.state.selectionLoading === 'hide' ? "" : ""}

            </>
            : ""}

          {this.state.sendToOwnerShow === 'show' ?
            <AppointmentForm.Label text="Send To Owner" type="title" />
            : ""}
          {this.state.sendToOwnerShow === 'show' ?
            <FormControl className={classes.formControl + ' width-100-perc'}>

              <Switch checked={this.state.sendToOwner} onChange={this.sendToOwnerChange}
                className="react-switch" />
            </FormControl> : ""
          }

          {this.state.sendToOwner === false ?
            <AppointmentForm.Label text="Select Users" type="title" />
            : ""}

          {this.state.sendToOwner === false ?
            <FormControl className={classes.formControl + ' width-100-perc mb-5'} >
              <Select isMulti value={this.state.selectedUserValues} options={this.usersDropDownForTemplate()} onChange={this.handleUserDropdownChange} placeholder="Please select user..." />
            </FormControl> :
            <>
              {!this.state.useNewTemplateEditor ?
                <>
                  <table className="table-reci" style={{ width: '100%', overflow: 'scroll !important' }}>
                    <tbody style={{ fontSize: 'smaller' }}>
                      <tr className="line">
                        <th className="line-data">
                          {this.state.label}
                        </th>
                        <th className="line-data"> Owner(s)</th>
                        <th className="line-data" style={{ textAlign: 'center' }}> Email Address(s)</th>
                      </tr>

                      {this.state.selectedApplications.map((item) => (
                        <tr className="line" key={item.value}>
                          <td className="line-data">{item.label}</td>
                          <td className="line-data">{item.owner}</td>
                          <td className="line-data" style={{ textAlign: 'center' }}>{item.emailerAddress}</td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
                : <>
                  <table className="table-reci" style={{ width: '100%', overflow: 'scroll !important' }}>
                    <tbody style={{ fontSize: 'smaller' }}>
                      <tr className="line">
                        <th className="line-data"> Owner(s)</th>
                        <th className="line-data" style={{ textAlign: 'center' }}> Email Address(s)</th>
                      </tr>

                      {this.state.owners.map((owner) => (
                        <tr className="line" key={owner.emailAddress}>
                          <td className="line-data">{owner.name}</td>
                          <td className="line-data" style={{ textAlign: 'center' }}>{owner.emailAddress}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {!this.state.ownersFetched && <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                </>}
            </>
          }

        </div>

      );
    };

    let applicationsDropDown = null;
    if (this.state.showLoader) {

      applicationsDropDown = (

        <div className="position-absolute top-50 start-50">



          <Loader.Audio
            type="Bars"
            color="#ed7d31"
            height={50}
            width={50}
          />

        </div>


      );
    } else {
      applicationsDropDown = (
        <div className="row">
          <div className="side-right">
            <ToggleButtonGroup className='my-2' color="primary" value={this.state.schedulerView}
              exclusive onChange={(_event, valueSelected) => { this.switchScheduler(valueSelected) }} aria-label="Platform">
              <ToggleButton value="Month">Month</ToggleButton>
              <ToggleButton value="Week">Week</ToggleButton>
              <ToggleButton value="Day">Day</ToggleButton>
            </ToggleButtonGroup>

            <Paper style={{ height: "calc(100vh - 190px)" }}>
              <Scheduler
                data={this.state.data}
              // showAllDayPanel={false}
              >
                <ViewState
                  defaultCurrentDate={formatYmd(new Date())}
                  currentViewName={this.state.schedulerView}
                />
                <EditingState
                  onCommitChanges={this.commitChanges}
                />

                <DayView
                  startDayHour={5}
                  endDayHour={18}
                />

                <WeekView startDayHour={0} endDayHour={24} />

                <MonthView
                  // timeTableCellComponent={TimeTableCell}
                  dayScaleCellComponent={DayScaleCell}
                  intervalCount={1}
                />

                <Appointments
                  appointmentComponent={Appointment}
                  appointmentContentComponent={AppointmentContent}
                />

                <Toolbar
                  flexibleSpaceComponent={(props) => <FlexibleSpace {...props} onDownloadClick={this.downloadUpcomingSchedulars} />}
                />

                <DateNavigator />

                <EditRecurrenceMenu />
                <AppointmentTooltip
                  showCloseButton={true}
                  showOpenButton={true}
                  showDeleteButton={true}

                />
                <AppointmentForm
                  basicLayoutComponent={BasicLayout}
                  recurrenceLayoutComponent={RecurrenceLayout}
                  onVisibilityChange={this.onVisibilityChange}
                  dateEditorComponent={DateEditor}
                  layoutComponent={Layout}
                />
              </Scheduler>
            </Paper>
          </div>
        </div>
      );
    }





    return (

      <div className="custom-box">
        <SideBar sideBarExpanded={this.props.sideBarExpanded}
          setSideBarExpanded={this.props.setSideBarExpanded} />
        <div id="main">
          <AnimatedBody
            sideBarExpanded={this.props.sideBarExpanded}
            setSideBarExpanded={this.props.setSideBarExpanded}
            className="container-fluid custom-container"
          >
            <div className='container'></div>
            <div className="container-fluid custom-container">
              <TopBar title={"Calendar"} />
              {applicationsDropDown}

            </div>
          </AnimatedBody>
        </div>
        <PromptModal
          open={this.state.showPromptModal}
          close={() => this.setState({ showPromptModal: false })}
          appointment={this.state.selectedAppointment}
          onSave={this.onSaveComplete}

        />
        <DeleteSchedulerModal
          open={this.state.showDeleteModal}
          close={() => this.setState({ showDeleteModal: false })}
          appointment={this.state.idToDelete}
          onSave={this.onSaveComplete}
        />
      </div>
    );
  }
}

export default withRouter(CalenderV2)