import * as React from "react";
import { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

import TemplateFilterService from "../../Services/TemplateFilterService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

import TemplateFilter, { FILTER_TYPE } from "./TemplateFilter";
import Swal from "sweetalert2";

function TemplateFilters(props) {

  const templateFilterService = new TemplateFilterService();

  const [filters, setFilters] = useState(null);

  const [selectedFilterId, setSelectedFilterId] = useState(null);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    findTemplateFilters();
  }, []);


  function findTemplateFilters() {
    templateFilterService.findAll(props.templateId).then(response => {
      setFilters(response.data);
    });
  }

  function edit(filterId) {
    setSelectedFilterId(filterId);
    setShowModal(true);
  }

  function closeModal() {
    setSelectedFilterId(null);
    findTemplateFilters();
    setShowModal(false);
  }

  function deleteFilter(filter) {
    Swal.fire({
      title: `Do you really want to delete the filter: <span style="color: red">${filter.code}</span>?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (filter.type === FILTER_TYPE.CALENDAR) {
          templateFilterService.deleteCalendarFilter(props.templateId, filter.id)
            .then(() => {
              deleteSuccess();
            })
            .catch(() => {
              Swal.showValidationMessage(`Failed to delete the filter.`);
            });
        } else {
          templateFilterService.deleteDropdownFilter(props.templateId, filter.id)
            .then(() => {
              deleteSuccess();
            })
            .catch(() => {
              Swal.showValidationMessage(`Failed to delete the filter.`);
            });
        }

      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  function deleteSuccess() {
    Swal.fire({
      icon: 'success',
      title: 'Filter Deleted',
      showConfirmButton: false,
      timer: 1500
    });

    findTemplateFilters();
  }

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <div className="row" style={{ paddingLeft: 5 }}>
        <div className="col-12">
          <table style={{ width: "100%", overflow: "scroll !important" }}>
            <thead>
              <tr className="line">
                <th className="line-data" width={100}>Order</th>
                <th className="line-data">Code</th>
                <th className="line-data">Label</th>
                <th className="line-data">Type</th>
                <th className="line-data">Required</th>
                <th className="line-data" width={350}>Metadata</th>
                <th className="line-data" width={100} style={{ textAlign: "right" }} >
                  <button type="button" className="btn btn-submit w-100"
                    style={{ marginTop: 0, marginRight: 6 }}
                    onClick={() => setShowModal(true)}>
                    Add Filter
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {filters && filters.length > 0 ?
                filters.map((filter, index) => (
                  <tr key={filter.id} className="line">
                    <td className="line-data">{filter.displayOrder}</td>
                    <td className="line-data">{filter.code}</td>
                    <td className="line-data">{filter.displayLabel}</td>
                    <td className="line-data">{filter.type}</td>
                    <td className="line-data">{filter.isRequired ? 'Yes' : 'No'}</td>
                    <td className="line-data">
                      {filter.outputFormat ? <div><strong>Output Format: </strong>{filter.outputFormat}</div> : ''}

                      {filter.isMultiselectable ? <div><strong>Multi-select: </strong>{filter.isMultiselectable ? 'Yes' : 'No'}</div> : ''}
                      {filter.dropdownDataExtractor ? <div><strong>Extractor: </strong>{filter.dropdownDataExtractor.name}</div> : ''}
                      {filter.dropdownLabel ? <div><strong>Dropdown Label: </strong>{filter.dropdownLabel}</div> : ''}
                      {filter.dropdownValue ? <div><strong>Dropdown Value: </strong>{filter.dropdownValue}</div> : ''}
                    </td>
                    <td align="right">
                      <button type="button" className="btn btn-light"
                        style={{ marginTop: 0, marginRight: 20 }}
                        onClick={() => edit(filter.id)}
                        title="Edit Filter">
                        Edit
                      </button>

                      <a href="#" onClick={() => deleteFilter(filter)} title="Delete Filter">
                        <FontAwesomeIcon icon={faTrashCan} color="#6c6c6f" />
                      </a>
                    </td>
                  </tr>))
                :
                <tr>
                  <td colSpan={4}>
                    {(filters && filters.length === 0) ? "No filters found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                  </td>
                </tr>
              }

            </tbody>
          </table>
        </div>
      </div>

      <TemplateFilter {...props} filterId={selectedFilterId} open={showModal} close={() => closeModal()} />

    </div>
  );
}

export default withRouter(TemplateFilters);
