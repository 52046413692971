import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, tabsClasses } from '@mui/material';
import { Stack, TextStyle, useBlock } from "easy-email-editor";

import { Grid } from '@arco-design/web-react';
import { ColorPickerField, InputWithUnitField, NumberField, SelectField, SwitchField, TextAreaField } from "easy-email-extensions";
import { ChartType, debounceTime } from "../CustomBlocks/constants";
import { useState } from "react";
import { useFocusedInput } from "../CustomBlocks/utils/useFocusedInput";

const OPTIONS_AXIS_NUMBER_FORMATS = {
  NONE: {
    id: 'NONE',
    callback: function(value) { return Number(value); }
  },
  THOUSAND_SEPARATOR: {
    id: 'THOUSAND_SEPARATOR',
    callback: function(value) { return Number(value).toLocaleString('en'); }
  },
  CURRENCY: {
    id: 'CURRENCY',
    callback: function(value) { return '$' + Number(value).toLocaleString('en'); }
  },
  CURRENCY_THOUSAND_BASED: {
    id: 'CURRENCY_THOUSAND_BASED',
    callback: function(value) {
      return Math.abs(Number(value)) > 1000000
        ? '$' + (Number(value) / 1000000) + 'M'
        : Math.abs(Number(value)) >= 1000
          ? '$' + (Number(value) / 1000) + 'K'
          : '$' + Number(value);
    }
  },
  PERCENTAGE: {
    id: 'PERCENTAGE',
    callback: function(value) { return (Number(value) / 100) + '%'; }
  }
}

const OPTIONS_DATALABELS_NUMBER_FORMATS = {
  NONE: function(value, context) { return context.datasetIndex <= 1 ? Number(value) : value },
  THOUSAND_SEPARATOR: function(value, context) {
    return context.datasetIndex <= 1 ? Number(value).toLocaleString('en') : value;
  },
  CURRENCY: function(value, context) {
    return context.datasetIndex <= 1 ? '$' + Number(value).toLocaleString('en') : value;
  },
  CURRENCY_THOUSAND_BASED: function(value, context) {
    return context.datasetIndex <= 1 ?
      (Math.abs(Number(value)) > 1000000
        ? '$' + (Number(value) / 1000000) + 'M'
        : Math.abs(Number(value)) >= 1000
          ? '$' + (Number(value) / 1000) + 'K'
          : '$' + Number(value)) : value;
  },
  PERCENTAGE: function(value, context) {
    return context.datasetIndex <= 1 ? (Number(value) / 100) + '%' : value;
  }
}

export function ChartOptions(props) {

  const { focusIdx } = props;

  const datalabels = props.outlabeled ? 'outlabels' : 'datalabels';

  const { change } = useBlock();
  const { setFocusedInput } = useFocusedInput();

  const [selectedTabOptions, setSelectedTabOptions] = useState("0");

  function onNumberFormatIdChanged(numberFormatId) {
    try {
      if (numberFormatId) {
        let ticksCallback = undefined;
        let dataLabelsCallback = undefined;
        if (numberFormatId === OPTIONS_AXIS_NUMBER_FORMATS.NONE.id) {
          ticksCallback = `##${OPTIONS_AXIS_NUMBER_FORMATS.NONE.callback}##`;
          dataLabelsCallback = `##${OPTIONS_DATALABELS_NUMBER_FORMATS.NONE}##`;
        } else if (numberFormatId === OPTIONS_AXIS_NUMBER_FORMATS.THOUSAND_SEPARATOR.id) {
          ticksCallback = `##${OPTIONS_AXIS_NUMBER_FORMATS.THOUSAND_SEPARATOR.callback}##`;
          dataLabelsCallback = `##${OPTIONS_DATALABELS_NUMBER_FORMATS.THOUSAND_SEPARATOR}##`;
        } else if (numberFormatId === OPTIONS_AXIS_NUMBER_FORMATS.CURRENCY.id) {
          ticksCallback = `##${OPTIONS_AXIS_NUMBER_FORMATS.CURRENCY.callback}##`;
          dataLabelsCallback = `##${OPTIONS_DATALABELS_NUMBER_FORMATS.CURRENCY}##`;
        } else if (numberFormatId === OPTIONS_AXIS_NUMBER_FORMATS.CURRENCY_THOUSAND_BASED.id) {
          ticksCallback = `##${OPTIONS_AXIS_NUMBER_FORMATS.CURRENCY_THOUSAND_BASED.callback}##`;
          dataLabelsCallback = `##${OPTIONS_DATALABELS_NUMBER_FORMATS.CURRENCY_THOUSAND_BASED}##`;
        } else if (numberFormatId === OPTIONS_AXIS_NUMBER_FORMATS.PERCENTAGE.id) {
          ticksCallback = `##${OPTIONS_AXIS_NUMBER_FORMATS.PERCENTAGE.callback}##`;
          dataLabelsCallback = `##${OPTIONS_DATALABELS_NUMBER_FORMATS.PERCENTAGE}##`;
        }

        change(`${focusIdx}.data.value.options.scales.yAxes.[0].ticks.numberFormatId`, numberFormatId);
        change(`${focusIdx}.data.value.options.scales.yAxes.[0].ticks.callback`, ticksCallback);
        change(`${focusIdx}.data.value.options.plugins.${datalabels}.formatter`, dataLabelsCallback);

        //setValueByIdx(focusIdx, { ...focusBlock });
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <TabContext value={selectedTabOptions}>
      <TabList onChange={(_e, value) => setSelectedTabOptions(value)} className='w-100' variant="scrollable"
        scrollButtons="auto" sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }} >
        <Tab label="Title" value="0" style={{ minWidth: '25%', maxWidth: '25%' }} />
        <Tab label="Legend" value="1" style={{ minWidth: '25%', maxWidth: '25%' }} />
        <Tab label="Data Labels" value="2" style={{ minWidth: '25%', maxWidth: '25%' }} />
        <Tab label="Axis" value="3" style={{ minWidth: '25%', maxWidth: '25%' }} />
      </TabList>
      <TabPanel value="0" className='ps-3 pe-0 py-3'>
        <Stack vertical spacing='extraTight'>
          <Grid.Row>
            <Grid.Col span={23}>
              <SwitchField
                label='Show Title'
                name={`${focusIdx}.data.value.options.title.display`}
                checkedText='ON'
                uncheckedText='OFF'
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={23}>
              <TextAreaField
                label='Title Text'
                name={`${focusIdx}.data.value.options.title.text`}
                className="textarea-no-resize" rows={2}
                onFocus={setFocusedInput}
                debounceTime={debounceTime}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={23}>
              <SelectField
                label='Position'
                name={`${focusIdx}.data.value.options.title.position`}
                options={[
                  { label: 'Top', value: 'top' },
                  { label: 'Left', value: 'left' },
                  { label: 'Bottom', value: 'bottom' },
                  { label: 'Right', value: 'right' }
                ]}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={6}>
              <NumberField
                label='Font size (px)'
                name={`${focusIdx}.data.value.options.title.fontSize`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
            <Grid.Col offset={1} span={8}>
              <ColorPickerField
                label='Color'
                name={`${focusIdx}.data.value.options.title.fontColor`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
            <Grid.Col offset={1} span={7}>
              <SelectField
                label='Font style'
                name={`${focusIdx}.data.value.options.title.fontStyle`}
                options={[
                  { label: 'Normal', value: 'normal' },
                  { label: 'Bold', value: 'bold' },
                  { label: 'Italic', value: 'italic' }
                ]}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={11}>
              <NumberField
                label='Padding (px)'
                name={`${focusIdx}.data.value.options.title.padding`}
                debounceTime={debounceTime}
              />
            </Grid.Col>

            <Grid.Col offset={1} span={11}>
              <NumberField
                label='Line Height'
                name={`${focusIdx}.data.value.options.title.lineHeight`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
            
          </Grid.Row>
          
          <Grid.Row>
            <Grid.Col span={23}>
              <InputWithUnitField
                label='Font Family'
                name={`${focusIdx}.data.value.options.title.fontFamily`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
          </Grid.Row>
        </Stack>
      </TabPanel>

      <TabPanel value="1" className='ps-3 pe-0 py-3'>
        <Stack vertical spacing='extraTight'>
          <Grid.Row>
            <Grid.Col span={11}>
              <SwitchField
                label='Show Legend'
                name={`${focusIdx}.data.value.options.legend.display`}
                checkedText='ON'
                uncheckedText='OFF'
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <SwitchField
                label='Full Width'
                name={`${focusIdx}.data.value.options.legend.fullWidth`}
                checkedText='ON'
                uncheckedText='OFF'
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={11}>
              <SelectField
                label='Position'
                name={`${focusIdx}.data.value.options.legend.position`}
                options={[
                  { label: 'Top', value: 'top' },
                  { label: 'Left', value: 'left' },
                  { label: 'Bottom', value: 'bottom' },
                  { label: 'Right', value: 'right' }
                ]}
              />
            </Grid.Col>
            <Grid.Col offset={1} span={11}>
              <SelectField
                label='Align'
                name={`${focusIdx}.data.value.options.legend.align`}
                options={[
                  { label: 'Start', value: 'start' },
                  { label: 'Center', value: 'center' },
                  { label: 'End', value: 'end' }
                ]}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <NumberField
                label='Font size (px)'
                name={`${focusIdx}.data.value.options.legend.labels.fontSize`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
            <Grid.Col offset={1} span={8}>
              <ColorPickerField
                label='Color'
                name={`${focusIdx}.data.value.options.legend.labels.fontColor`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
            <Grid.Col offset={1} span={7}>
              <SelectField
                label='Font style'
                name={`${focusIdx}.data.value.options.legend.labels.fontStyle`}
                options={[
                  { label: 'Normal', value: 'normal' },
                  { label: 'Bold', value: 'bold' },
                  { label: 'Italic', value: 'italic' }
                ]}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={23}>
              <NumberField
                label='Padding (px)'
                name={`${focusIdx}.data.value.options.legend.labels.padding`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
          </Grid.Row>
          
          <Grid.Row>
            <Grid.Col span={23}>
              <SwitchField
                label='Use Point Style'
                name={`${focusIdx}.data.value.options.legend.labels.usePointStyle`}
                checkedText='ON'
                uncheckedText='OFF'
              />
            </Grid.Col>
          </Grid.Row>
          
        </Stack>
        
        
      </TabPanel>

      <TabPanel value="2" className='ps-3 pe-0 py-3'>

        <Stack vertical spacing='extraTight'>
          <Grid.Row>
            <Grid.Col span={23}>
              <SwitchField
                label='Show Data Labels'
                name={`${focusIdx}.data.value.options.plugins.${datalabels}.display`}
                debounceTime={debounceTime}
                checkedText='ON'
                uncheckedText='OFF'
              />
            </Grid.Col>
          </Grid.Row>

          {!props.outlabeled &&
            <Grid.Row>
              <Grid.Col span={11}>
                <SelectField
                  label='Anchor'
                  name={`${focusIdx}.data.value.options.plugins.${datalabels}.anchor`}
                  options={[
                    { label: 'Start', value: 'start' },
                    { label: 'Center', value: 'center' },
                    { label: 'End', value: 'end' }
                  ]}
                />
              </Grid.Col>
              <Grid.Col offset={1} span={11}>
                <SelectField
                  label='Align'
                  name={`${focusIdx}.data.value.options.plugins.${datalabels}.align`}
                  options={[
                    { label: 'Top', value: 'top' },
                    { label: 'Center', value: 'center' },
                    { label: 'Right', value: 'right' },
                    { label: 'Bottom', value: 'bottom' },
                    { label: 'Left', value: 'left' }
                  ]}
                />
              </Grid.Col>
            </Grid.Row>
          }

          <Grid.Row>
            <Grid.Col span={23}>
              <ColorPickerField
                label='Background Color'
                name={`${focusIdx}.data.value.options.plugins.${datalabels}.backgroundColor`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={6}>
              <NumberField
                label='Border Width'
                name={`${focusIdx}.data.value.options.plugins.${datalabels}.borderWidth`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
            <Grid.Col offset={1} span={8}>
              <ColorPickerField
                label='Border Color'
                name={`${focusIdx}.data.value.options.plugins.${datalabels}.borderColor`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
            <Grid.Col offset={1} span={7}>
              <NumberField
                label='Border Radius'
                name={`${focusIdx}.data.value.options.plugins.${datalabels}.borderRadius`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={6}>
              <NumberField
                label='Font size (px)'
                name={`${focusIdx}.data.value.options.plugins.${datalabels}.font.${!props.outlabeled ? 'size' : 'minSize'}`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
            <Grid.Col offset={1} span={8}>
              <ColorPickerField
                label='Color'
                name={`${focusIdx}.data.value.options.plugins.${datalabels}.color`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
            <Grid.Col offset={1} span={7}>
              <SelectField
                label='Font style'
                name={`${focusIdx}.data.value.options.plugins.${datalabels}.font.style`}
                options={[
                  { label: 'Normal', value: 'normal' },
                  { label: 'Bold', value: 'bold' },
                  { label: 'Italic', value: 'italic' }
                ]}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={23}>
              <NumberField
                label='Padding (px)'
                name={`${focusIdx}.data.value.options.plugins.${datalabels}.padding`}
                debounceTime={debounceTime}
              />
            </Grid.Col>
          </Grid.Row>
        </Stack>

      </TabPanel>

      <TabPanel value="3" className='ps-3 pe-0 py-3'>
        <Stack vertical spacing='extraTight'>
          <TextStyle variation='strong'>X-Axis</TextStyle>
          <Grid.Row>
            <Grid.Col span={8}>
              <SwitchField
                label='Display'
                name={`${focusIdx}.data.value.options.scales.xAxes.[0].display`}
                checkedText='ON'
                uncheckedText='OFF'
              />
            </Grid.Col>
            <Grid.Col offset={1} span={7}>
              <SwitchField
                label='Stacked'
                name={`${focusIdx}.data.value.options.scales.xAxes.[0].stacked`}
                checkedText='ON'
                uncheckedText='OFF'
              />
            </Grid.Col>
            <Grid.Col offset={1} span={7}>
              <SwitchField
                label='Gridlines'
                name={`${focusIdx}.data.value.options.scales.xAxes.[0].gridLines.display`}
                checkedText='ON'
                uncheckedText='OFF'
              />
            </Grid.Col>
          </Grid.Row>

          <hr style={{ width: '96%' }} />
          <TextStyle variation='strong'>Y-Axis</TextStyle>
          <Grid.Row>
            <Grid.Col span={8}>
              <SwitchField
                label='Display'
                name={`${focusIdx}.data.value.options.scales.yAxes.[0].display`}
                checkedText='ON'
                uncheckedText='OFF'
              />
            </Grid.Col>
            <Grid.Col offset={1} span={7}>
              <SwitchField
                label='Stacked'
                name={`${focusIdx}.data.value.options.scales.yAxes.[0].stacked`}
                checkedText='ON'
                uncheckedText='OFF'
              />
            </Grid.Col>
            <Grid.Col offset={1} span={7}>
              <SwitchField
                label='Gridlines'
                name={`${focusIdx}.data.value.options.scales.yAxes.[0].gridLines.display`}
                checkedText='ON'
                uncheckedText='OFF'
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={23}>
              <SelectField
                label='Number Format'
                name={`${focusIdx}.data.value.options.scales.yAxes[0].ticks.numberFormatId`}
                options={[
                  { label: 'None', value: OPTIONS_AXIS_NUMBER_FORMATS.NONE.id },
                  { label: 'Thousand Separator (999 - 9,999)', value: OPTIONS_AXIS_NUMBER_FORMATS.THOUSAND_SEPARATOR.id },
                  { label: 'Currency ($999 - $9,999 - $9,999,999)', value: OPTIONS_AXIS_NUMBER_FORMATS.CURRENCY.id },
                  { label: 'Currency K/M Based ($999 - $1K - $1M)', value: OPTIONS_AXIS_NUMBER_FORMATS.CURRENCY_THOUSAND_BASED.id },
                  { label: 'Percentage (0% - 100%)', value: OPTIONS_AXIS_NUMBER_FORMATS.PERCENTAGE.id }
                ]}
                onChangeAdapter={(value) => {
                  onNumberFormatIdChanged(value);
                  return value;
                }}
              />
            </Grid.Col>
          </Grid.Row>
          
          <Grid.Row>
          <Grid.Col span={23}>
              <SwitchField
                label='Begin At Zero'
                name={`${focusIdx}.data.value.options.scales.yAxes.[0].ticks.beginAtZero`}
                checkedText='ON'
                uncheckedText='OFF'
              />
            </Grid.Col>
          </Grid.Row>
          

        </Stack>
      </TabPanel>
    </TabContext>
  );
}