import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { AttributesPanelWrapper, SwitchField, NumberField, SelectField, Padding } from 'easy-email-extensions';
import { useFocusIdx, Stack } from 'easy-email-editor';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useState } from 'react';
import TemplateDataExtractorService from '../../../../Services/TemplateDataExtractorService';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightSharpIcon />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'var(--color-neutral-1)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export function Panel() {

  const templateDataExtractorService = new TemplateDataExtractorService();

  const templateId = document.getElementById('designerTemplateId').value;

  const [active, setActive] = useState('settings');
  const [expanded, setExpanded] = useState(true);
  const { focusIdx } = useFocusIdx();

  const [extractors, setExtractors] = useState([]);

  useEffect(() => {
    templateDataExtractorService.findAllNotHidden(templateId).then(response => {
      response.data.unshift({ code: '', name: "None" });

      const extractorsOpt = response.data.map(extractor => { return { value: `${extractor.code ? extractor.code + '.data' : ''}`, label: extractor.name } });

      setExtractors(extractorsOpt);
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }, []);

  function onChangeAccordion(tab) {
    if (tab !== active) {
      setExpanded(true);
    } else {
      setExpanded(!expanded);
    }
    setActive(tab);
  }

  return (
    <AttributesPanelWrapper>
      <Accordion expanded={active === 'dimension' && expanded} onChange={() => onChangeAccordion('dimension')}>
        <AccordionSummary>Dimension</AccordionSummary>
        <AccordionDetails>
          <Stack vertical spacing='tight'>
            <Padding />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={active === 'settings' && expanded} onChange={() => onChangeAccordion('settings')}>
        <AccordionSummary>Settings</AccordionSummary>
        <AccordionDetails>
          <Stack vertical>
            <NumberField
              label='# Columns'
              name={`${focusIdx}.data.value.columns`}
              inline
              mode='button'
              max={10}
              min={1}
              helpText='Number of columns to display'
            />

            <SwitchField
              label='Header'
              name={`${focusIdx}.data.value.header`}
              defaultChecked
              inline
              checkedText='ON'
              uncheckedText='OFF'
            />

            <SelectField
              label='Datasource'
              name={`${focusIdx}.data.value.body`}
              options={extractors}
              inline
              helpText='Data to display in rows'
            />

            <SwitchField
              label='Footer'
              name={`${focusIdx}.data.value.footer`}
              defaultChecked
              inline
              checkedText='ON'
              uncheckedText='OFF'
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

    </AttributesPanelWrapper>

  );
}
