import { AdvancedType, BasicType, BlockManager, components, createCustomBlock } from 'easy-email-core';
import { CustomBlocksTitle, CustomBlocksType } from '../constants';
import React from 'react';
import { merge } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

const { BlockRenderer } = components;
const DATA_TO_LOOP = '##DATA_TO_LOOP##';
const FOR_STATEMENT = `{{#each ${DATA_TO_LOOP} }}`;
const FOR_STATEMENT_END = '{{/each}}';

const defaultHeaderColumn = BlockManager.getBlockByType(AdvancedType.COLUMN).create({
  'children': [
    BlockManager.getBlockByType(AdvancedType.TEXT).create({
      'data': {
        'value': {
          'content': '##Column Header##'
        }
      },
      'attributes': {
        'padding': '5px',
        'align': 'center',
        'color': '#ffffff',
        'font-size': '12px'
      }
    })
  ]
});
const customHeaderJson = BlockManager.getBlockByType(AdvancedType.SECTION).create({
  'title': 'HEADER',
  'data': {
    value: {
      noWrap: false, // If true, all columns will be moved inside a GROUP
    },
  },
  'attributes': {
    'background-color': '#0a6cdb',
    'width': '100%'
  },
  'children': [defaultHeaderColumn]
});

const defaultBodyColumn = BlockManager.getBlockByType(AdvancedType.COLUMN).create({
  'children': [
    BlockManager.getBlockByType(AdvancedType.TEXT).create({
      'title': CustomBlocksTitle.COLUMN_TEXT,
      'data': {
        'value': {
          'content': '{{this.tdata}}'
        }
      },
      'attributes': {
        'padding': '5px',
        'font-size': '12px'
      }
    })
  ]
});
const customBodyJson = BlockManager.getBlockByType(AdvancedType.SECTION).create({
  'title': 'BODY',
  'data': {
    value: {
      noWrap: false, // If true, all columns will be moved inside a GROUP
    },
  },
  'attributes': {
    'width': '100%'
  },
  'children': [defaultBodyColumn]
});

const defaultFooterColumn = BlockManager.getBlockByType(AdvancedType.COLUMN).create({
  'children': [
    BlockManager.getBlockByType(AdvancedType.TEXT).create({
      'data': {
        'value': {
          'content': '##Column Footer##'
        }
      },
      'attributes': {
        'padding': '5px',
        'font-size': '12px'
      }
    })
  ]
});
const customFooterJson = BlockManager.getBlockByType(AdvancedType.SECTION).create({
  'title': 'FOOTER',
  'data': {
    value: {
      noWrap: false, // If true, all columns will be moved inside a GROUP
    },
  },
  'attributes': {
    'width': '100%',
    'background-color': '#f7f8fa'
  },
  'children': [defaultFooterColumn]
});

function addRemoveColumns(columns, array, itemToInclude) {
  const delta = columns - array.length;
  if (delta > 0) {
    for (var i = 0; i < delta; i++) {
      array.push(cloneDeep(itemToInclude));
    }
  } else if (delta < 0) {
    array.splice(delta);
  }
}

export const CustomTable = createCustomBlock({
  name: 'Table',
  type: CustomBlocksType.CUSTOM_TABLE,
  create(payload) {
    const defaultData = {
      type: CustomBlocksType.CUSTOM_TABLE,
      data: {
        value: {
          columns: 2,
          header: true,
          body: '',
          footer: false
        },
      },
      attributes: {
        padding: '10px 25px 10px 25px',
      },
      children: [
        customHeaderJson,
        BlockManager.getBlockByType(BasicType.RAW).create({
          'data': {
            'value': {
              'content': FOR_STATEMENT
            }
          }
        }),
        customBodyJson,
        BlockManager.getBlockByType(BasicType.RAW).create({
          'data': {
            'value': {
              'content': FOR_STATEMENT_END
            }
          }
        }),
        customFooterJson],
    }
    return merge(defaultData, payload);
  },
  validParentType: [BasicType.PAGE, BasicType.WRAPPER],
  render(params) {
    const component = params.data;
    let { columns } = component.data.value;
    const { header, body, footer } = component.data.value;

    if (columns) {
      // Header
      const headerSection = component.children[0];
      if (header && headerSection.title !== 'HEADER') {
        // If header: true and the group does not exist, then create the group
        component.children.unshift(customHeaderJson);
        // Inside SECTION or a GROUP
        const headerGroup = !headerSection.data.value.noWrap ? customHeaderJson : customHeaderJson.children[0];
        addRemoveColumns(columns, headerGroup.children, defaultHeaderColumn);
      } else if (header) {
        // If header: true and the group exists, then work with columns
        // Inside SECTION or a GROUP
        const headerGroup = !headerSection.data.value.noWrap ? headerSection : headerSection.children[0];
        if (columns != headerGroup.children.length) {
          addRemoveColumns(columns, headerGroup.children, defaultHeaderColumn);
        }
      } else if (!header && headerSection.title === 'HEADER') {
        // If header: false and group exist, then delete the group
        component.children.shift();
      }
      
      // Checks the data to loop in the RAW component
      const rawIndex = header ? 1 : 0;
      const dataToLoop = FOR_STATEMENT.replace(DATA_TO_LOOP, body);
      if (component.children[rawIndex].data.value.content !== dataToLoop) {
        component.children[rawIndex].data.value.content = dataToLoop;
      }

      // Body
      const bodyIndex = header ? 2 : 1;
      const bodySection = component.children[bodyIndex];
      // Inside SECTION or a GROUP
      const bodyGroup = !bodySection.data.value.noWrap ? component.children[bodyIndex] : component.children[bodyIndex].children[0];

      // Checks for Column amount
      if (columns != bodyGroup.children.length) {
        const delta = columns - bodyGroup.children.length;
        if (delta > 0) {
          for (var i = 0; i < delta; i++) {
            bodyGroup.children.splice(bodyGroup.children.length - 1, 0, cloneDeep(defaultBodyColumn));
          }
        } else {
          bodyGroup.children.splice(bodyGroup.children.length - Math.abs(delta - 1), Math.abs(delta));
        }
      }

      // Footer
      const footerSection = component.children[component.children.length - 1];
      if (footer && footerSection.title !== 'FOOTER') {
        // If header: true and the group does not exist, then create the group
        component.children.push(customFooterJson);
        // Inside SECTION or a GROUP
        const footerGroup = !footerSection.data.value.noWrap ? customFooterJson : customFooterJson.children[0];
        addRemoveColumns(columns, footerGroup.children, defaultFooterColumn);
      } else if (footer) {
        // If header: true and the group exists, then work with columns
        // Inside SECTION or a GROUP
        const footerGroup = !footerSection.data.value.noWrap ? footerSection : footerSection.children[0];
        if (columns != footerGroup.children.length) {
          addRemoveColumns(columns, footerGroup.children, defaultFooterColumn);
        }
      } else if (!footer && footerSection.title === 'FOOTER') {
        // If header: false and group exist, then delete the group
        component.children.splice(-1);
      }
    }

    const customColumnJson = BlockManager.getBlockByType(AdvancedType.WRAPPER).create({
      'data': {
        'value': {}
      },
      'attributes': component.attributes,
      'children': component.children
    });

    const instance = (
      <BlockRenderer {...params} key={`block-rendered-${params.idx}`} idx={params.idx} data={customColumnJson} keepClassName={true} />
    )
    return instance;
  },
});


export { Panel } from './Panel'