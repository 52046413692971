import axios from 'axios';
import { environment } from '../environment';

class TemplateService {

  // Get All Users by Customer
  findById = (customerId, templateId, fetchSendGridMetaData = true, fetchDesignData = true) => {
    return axios.get(environment.API_URL + `template/${customerId}/${templateId}?fetchSendGridMetaData=${fetchSendGridMetaData}&fetchDesignData=${fetchDesignData}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }

  findVersions = (customerId, templateId) => {
    return axios.get(environment.API_URL + `template/${customerId}/${templateId}/versions`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }

  restoreVersion = (customerId, templateId, templateVersionId, templateData) => {
    return axios.post(environment.API_URL + `template/${customerId}/${templateId}/versions/${templateVersionId}/restore`, templateData)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }

  deleteVersion = (customerId, templateId, templateVersionId) => {
    return axios.delete(environment.API_URL + `template/${customerId}/${templateId}/versions/${templateVersionId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }

  saveAsCopy = (templateData) => {
    return axios.post(environment.API_URL + 'template', templateData)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  update = (templateData) => {
    return axios.put(environment.API_URL + 'template', templateData)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      })
  }

  saveDesign = (templateData) => {
    return axios.put(environment.API_URL + 'template/design', templateData)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      })
  }

  toogle = (customerId, templateId) => {
    return axios.put(environment.API_URL + `template/${customerId}/${templateId}/toggle-status`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }

  importFile = (customerId, file) => {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(environment.API_URL + `template/${customerId}/import`, formData)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      })
  }

  exportFile = (customerId, templateId) => {
    return axios.get(environment.API_URL + `template/${customerId}/${templateId}/export`, { responseType: 'blob' })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }

  cloneTemplate = (customerId, templateId) => {
    return axios.put(environment.API_URL + `template/clone/${customerId}/${templateId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error.response;
      });
  }
  
    deleteTemplate = (customerId, templateId, templateName) => {
    return axios.put(environment.API_URL + `template/delete/${customerId}/${templateId}`, templateName)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error.response;
      });
  }
  
}

export default TemplateService;