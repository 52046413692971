import axios from 'axios';
import { environment } from '../environment';

class TemplateDataExtractorFieldService {

  findAllJSON = (templateId, extractorId) => {
    return axios.get(environment.API_URL + `templates/${templateId}/json-template-data-extractors/${extractorId}/fields`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }
  
  findAllJSONNotHidden = (templateId, extractorId) => {
    return this.findAllJSON(templateId, extractorId).then((response) => {
      if(response.data) {
        response.data = response.data.filter(extractor => !extractor.isHidden);
      }
      return response;
    });
  }
  
  findAllSQL = (templateId, extractorId) => {
    return axios.get(environment.API_URL + `templates/${templateId}/sql-template-data-extractors/${extractorId}/fields`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }
  
  findAllSQLNotHidden = (templateId, extractorId) => {
    return this.findAllSQL(templateId, extractorId).then((response) => {
      if(response.data) {
        response.data = response.data.filter(extractor => !extractor.isHidden);
      }
      return response;
    });
  }

  findJSONById = (templateId, extractorId, fieldId) => {
    return axios.get(environment.API_URL + `templates/${templateId}/json-template-data-extractors/${extractorId}/fields/${fieldId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }
  
  findSQLById = (templateId, extractorId, fieldId) => {
    return axios.get(environment.API_URL + `templates/${templateId}/sql-template-data-extractors/${extractorId}/fields/${fieldId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }
  
  findById = (templateId, extractorId, fieldId, extractorType) => {
	return extractorType === 'JSON_REST_API' ? this.findJSONById(templateId, extractorId, fieldId) : this.findSQLById(templateId, extractorId, fieldId);
  }

  saveJSONField = (templateId, extractorId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/json-template-data-extractors/${extractorId}/fields`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  updateJSONField = (templateId, extractorId, fieldId, data) => {
    data.id = fieldId;
    return axios.put(environment.API_URL + `templates/${templateId}/json-template-data-extractors/${extractorId}/fields/${fieldId}`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  deleteJSONField = (templateId, extractorId, fieldId) => {
    return axios.delete(environment.API_URL + `templates/${templateId}/json-template-data-extractors/${extractorId}/fields/${fieldId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  saveSQLField = (templateId, extractorId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/sql-template-data-extractors/${extractorId}/fields`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  updateSQLField = (templateId, extractorId, fieldId, data) => {
    data.id = fieldId;
    return axios.put(environment.API_URL + `templates/${templateId}/sql-template-data-extractors/${extractorId}/fields/${fieldId}`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  deleteSQLField = (templateId, extractorId, fieldId) => {
    return axios.delete(environment.API_URL + `templates/${templateId}/sql-template-data-extractors/${extractorId}/fields/${fieldId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }  

}

export default TemplateDataExtractorFieldService;