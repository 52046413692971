import * as React from "react";
import { useState, useEffect } from 'react';

import { DataGrid, GridActionsCellItem, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter, useGridApiContext } from '@mui/x-data-grid';
import { Button, MenuItem, Select } from '@mui/material';

import TemplateDataExtractorFieldService from "../../Services/TemplateDataExtractorFieldService";

import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { SOURCE_TYPE } from "./TemplateDataExtractor";

import Swal from 'sweetalert2';

import { isEqual } from 'lodash';
import TemplateParameterService from "../../Services/TemplateParameterService";
import TemplateFilterService from "../../Services/TemplateFilterService";
import TemplateDataExtractorService from "../../Services/TemplateDataExtractorService";
import { Col, Row } from "react-bootstrap";

const FIELD_TYPE = {
  STRING: 'STRING',
  NUMERIC: 'NUMERIC',
  BOOLEAN: 'BOOLEAN',
  JSON: 'JSON',
  TIMESTAMP: 'TIMESTAMP'
}

export function TemplateDataExtractorFields(props) {

  const templateDataExtractorService = new TemplateDataExtractorService();
  const templateDataExtractorFieldService = new TemplateDataExtractorFieldService();
  const templateParameterService = new TemplateParameterService();
  const templateFilterService = new TemplateFilterService();

  const [rows, setRows] = useState([]);

  const [parametersFilters, setParametersFilters] = useState([{ label: 'None', value: '' }]);

  const dataGridColumns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ row }) => {

        return [
          <GridActionsCellItem
            icon={<ContentCopyIcon />}
            label="Clone"
            onClick={() => cloneExtractorField(row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => deleteField(row)}
            color="inherit"
          />,
        ];
      },
    },
    { field: 'sortOrder', headerName: 'Order', type: 'number', width: 80, editable: true },
    { field: 'name', headerName: 'Name', width: 200, editable: true },
    { field: 'code', headerName: 'Code', width: 200, editable: true },
    {
      field: 'jsonPath', headerName: 'JSON Path', width: 100, editable: true,
      description: `Set 'None' when not using the JSON source`
    },
    {
      field: 'dataType', headerName: 'Datatype', width: 120, editable: true,
      renderEditCell: (params) =>
        <DataGridSelectEditInputCell
          {...params}
          options={[
            { label: 'String', value: FIELD_TYPE.STRING },
            { label: 'Numeric', value: FIELD_TYPE.NUMERIC },
            { label: 'Boolean', value: FIELD_TYPE.BOOLEAN },
            { label: 'Json', value: FIELD_TYPE.JSON },
            { label: 'Timestamp', value: FIELD_TYPE.TIMESTAMP }
          ]} />
    },
    { field: 'sizeLimit', headerName: 'Size Limit', type: 'number', width: 80, editable: true },
    { field: 'precision', headerName: 'Precision', type: 'number', width: 80, editable: true },
    { field: 'scale', headerName: 'Scale', type: 'number', width: 80, editable: true },
    {
      field: 'isHidden', headerName: 'Hidden', width: 80, editable: true,
      renderCell: (params) =>
        <div>{params.value ? 'Yes' : 'No'}</div>,
      renderEditCell: (params) =>
        <DataGridSelectEditInputCell
          {...params}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]} />
    },
    {
      field: 'saveEmptyZeroWhenNull', headerName: 'Save Empty/Zero when NULL', width: 140, editable: true,
      description: 'If field value is NULL, zero will be saved for NUMERIC and empty string for STRING',
      renderCell: (params) =>
        <div>{params.value ? 'Yes' : 'No'}</div>,
      renderEditCell: (params) =>
        <DataGridSelectEditInputCell
          {...params}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]} />
    },
    {
      field: 'createWithIndex', headerName: 'Create With Index', width: 140, editable: true,
      description: 'When enabled, the table column will have an INDEX',
      renderCell: (params) =>
        <div>{params.value ? 'Yes' : 'No'}</div>,
      renderEditCell: (params) =>
        <DataGridSelectEditInputCell
          {...params}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]} />
    },
    { field: 'inputFormat', headerName: 'inputFormat', width: 180, editable: true },
    { field: 'outputFormat', headerName: 'outputFormat', width: 180, editable: true },
    {
      field: 'filterCodeRelated', headerName: 'Filter related', width: 250, editable: true,
      renderEditCell: (params) =>
        <DataGridSelectEditInputCell
          {...params}
          options={parametersFilters} />
    }

  ];

  useEffect(() => {

    findFields();

    let paramsFilters = [];
    paramsFilters.push({ label: 'None', value: '' });

    //loading parameters
    templateParameterService.findAll(props.templateId).then(paramResponse => {
      let parameters = paramResponse.data;

      //loading filters
      templateFilterService.findAll(props.templateId).then(filterResponse => {
        let filters = filterResponse.data;

        parameters.map(param => {
          paramsFilters.push({ label: param.code, value: '${' + param.code + '}' });
        });

        filters.map(filter => {
          paramsFilters.push({ label: filter.code, value: '${' + filter.code + '}' });
        });

        setParametersFilters(paramsFilters);
      });
    })
  }, []);

  function findFields() {
    templateDataExtractorService.findById(props.templateId, props.extractorId).then(response => {
      if (response.status !== 200) {
        return;
      }

      setRows(response.data.fields);
    });
  }

  function CustomToolbar(props) {
    const rowIndex = rows.length + 1;
    const handleClick = () => {
      const newRow = {
        code: `newField ${rowIndex}`,
        name: `New Field ${rowIndex}`,
        dataType: FIELD_TYPE.STRING,
        sortOrder: rowIndex,
        isHidden: false,
        saveEmptyZeroWhenNull: true,
        createWithIndex: false,
        jsonPath: `[${rowIndex}]`,
        dataExtractor: {
          id: props.extractorId,
          sourceType: props.extractorSourceType
        }
      }

      onDataTypeChanged(newRow);

      save(newRow, null).then(() => {
        findFields();
      });
    };

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton />

        <GridToolbarQuickFilter />

        <Col className="d-flex justify-content-end">
          <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add record
          </Button>
        </Col>
      </GridToolbarContainer>
    );
  }

  function DataGridSelectEditInputCell(props) {
    const { id, value, field, options } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <Select
        value={value}
        onChange={handleChange}
        size="small"
        sx={{
          height: 1,
          [`& .MuiOutlinedInput-notchedOutline`]: {
            border: 'none',
          }
        }}
        autoFocus
        fullWidth
      >
        {options.map((option, index) => (
          <MenuItem key={`${id}-${index}`} value={option.value} title={option.label}>{option.label}</MenuItem>
        ))}
      </Select>
    );
  }

  function onCellChanged(newRow, oldRow) {
    console.log(oldRow);
    console.log(newRow);

    if (!isEqual(newRow, oldRow)) {
      if (!isEqual(newRow.name, oldRow.name)) {
        onNameChanged(newRow, oldRow);
      } else if (!isEqual(newRow.dataType, oldRow.dataType)) {
        onDataTypeChanged(newRow)
      }
      return save(newRow, oldRow);
    } else {
      return newRow;
    }
  }

  function onNameChanged(newRow, oldRow) {
    let nameAsCode = newRow.name.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/[\W_]+/g, '');

    // Checks if the code was manually changed, if so, then no change will happen
    if (!oldRow.code || nameAsCode.includes(oldRow.code) || oldRow.code.includes(nameAsCode)) {
      newRow.code = nameAsCode.substring(0, 30);
    }
  }

  function onDataTypeChanged(newRow) {
    if (newRow.dataType === FIELD_TYPE.NUMERIC) {
      newRow.precision = 12;
      newRow.scale = 2;
      newRow.inputFormat = "'$'#,##0.00";
      newRow.sizeLimit = null;
    } else if (newRow.dataType === FIELD_TYPE.STRING) {
      newRow.precision = null;
      newRow.scale = null;
      newRow.inputFormat = '';
      newRow.sizeLimit = 2000;
    } else {
      newRow.precision = null;
      newRow.scale = null;
      newRow.inputFormat = '';
      newRow.sizeLimit = null;
    }
  }

  function isCellEditable(params) {
    if (params.row.dataType === FIELD_TYPE.STRING) {
      if (params.field === 'precision' || params.field === 'scale') {
        return false;
      }
    } else if (params.row.dataType === FIELD_TYPE.NUMERIC) {
      if (params.field === 'sizeLimit') {
        return false;
      }
    } else if (params.field === 'precision' || params.field === 'scale' || params.field === 'sizeLimit') {
      return false;
    }
    return true;
  }

  function save(data, oldData) {
    if (props.extractorSourceType === SOURCE_TYPE.JSON_REST_API) {
      return saveJSONExtractorField(data, oldData);
    } else {
      return saveSQLExtractorField(data, oldData);
    }
  }

  async function saveJSONExtractorField(field, oldData) {
    const request = {
      id: field.id,
      code: field.code,
      name: field.name,
      dataType: field.dataType,
      sortOrder: field.sortOrder,
      sizeLimit: field.sizeLimit,
      precision: field.precision,
      scale: field.scale,
      inputFormat: field.inputFormat,
      outputFormat: field.outputFormat,
      filterCodeRelated: field.filterCodeRelated,
      isHidden: field.isHidden,
      saveEmptyZeroWhenNull: field.saveEmptyZeroWhenNull,
      createWithIndex: field.createWithIndex,
      jsonPath: field.jsonPath,
      dataExtractor: {
        id: props.extractorId,
        sourceType: props.extractorSourceType
      }
    }
    if (!request.id) {
      return templateDataExtractorFieldService.saveJSONField(props.templateId, props.extractorId, request).then(({ data }) => {
        saveSuccess('Field Saved');
        return data;
      }).catch((error) => {
        errorMessageSaving(error.response.data);
        return oldData;
      });
    } else {
      return templateDataExtractorFieldService.updateJSONField(props.templateId, props.extractorId, field.id, request).then(({ data }) => {
        saveSuccess('Field Saved');
        return data;
      }).catch((error) => {
        errorMessageSaving(error.response.data);
        return oldData;
      });
    }
  }

  async function saveSQLExtractorField(field, oldData) {
    const request = {
      code: field.code,
      name: field.name,
      dataType: field.dataType,
      sortOrder: field.sortOrder,
      sizeLimit: field.sizeLimit,
      precision: field.precision,
      scale: field.scale,
      inputFormat: field.inputFormat,
      outputFormat: field.outputFormat,
      filterCodeRelated: field.filterCodeRelated,
      isHidden: field.isHidden,
      saveEmptyZeroWhenNull: field.saveEmptyZeroWhenNull,
      createWithIndex: field.createWithIndex,
      dataExtractor: {
        id: props.extractorId,
        sourceType: props.extractorSourceType
      }
    }

    if (!props.extractorId) {
      return templateDataExtractorFieldService.saveSQLField(props.templateId, props.extractorId, request).then(({ data }) => {
        saveSuccess('Field Saved');
        return data;
      }).catch((error) => {
        errorMessageSaving(error.response.data);
        return oldData;
      });
    } else {
      return templateDataExtractorFieldService.updateSQLField(props.templateId, props.extractorId, field.id, request).then(({ data }) => {
        saveSuccess('Field Saved');
        return data;
      }).catch((error) => {
        errorMessageSaving(error.response.data);
        return oldData;
      });
    }
  }

  function saveSuccess(message, warning) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: warning ? 'warning' : 'success',
      title: message,
    });
  }

  function errorMessageSaving(error) {
    let message = 'Failed to update the field';
    if (error.errors) {
      message = `${error.errors[0].field}: ${error.errors[0].reason}`;
    } else if (error.message) {
      message = error.message;
    }

    Swal.fire({
      title: message,
      icon: 'error',
      allowOutsideClick: false
    });
  }

  function cloneExtractorField(field) {
    return Swal.fire({
      title: `Do you really want to clone the field: <span style="color: red">${field.code}</span>?`,
      showCancelButton: true,
      confirmButtonText: 'Clone',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        if (props.extractorSourceType === SOURCE_TYPE.JSON_REST_API) {
          return cloneJSONExtractorField(field);
        } else {
          return cloneSQLExtractorField(field);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  async function cloneJSONExtractorField(field) {
    const request = {
      code: 'copy_' + field.code,
      name: 'copy of ' + field.name,
      dataType: field.dataType,
      sortOrder: field.sortOrder + 1,
      sizeLimit: field.sizeLimit,
      precision: field.precision,
      scale: field.scale,
      inputFormat: field.inputFormat,
      outputFormat: field.outputFormat,
      filterCodeRelated: field.filterCodeRelated,
      isHidden: field.isHidden,
      jsonPath: field.jsonPath,
      saveEmptyZeroWhenNull: field.saveEmptyZeroWhenNull,
      createWithIndex: field.createWithIndex,
      dataExtractor: {
        id: props.extractorId,
        sourceType: props.extractorSourceType
      }
    }

    return templateDataExtractorFieldService.saveJSONField(props.templateId, props.extractorId, request).then(() => {
      saveSuccess('Field Cloned!');
      findFields();
    }).catch((error) => {
      errorMessageSaving(error.response.data);
    });
  }

  async function cloneSQLExtractorField(field) {
    const request = {
      code: 'copy_' + field.code,
      name: 'copy of ' + field.name,
      dataType: field.dataType,
      sortOrder: field.sortOrder + 1,
      sizeLimit: field.sizeLimit,
      precision: field.precision,
      scale: field.scale,
      inputFormat: field.inputFormat,
      outputFormat: field.outputFormat,
      filterCodeRelated: field.filterCodeRelated,
      isHidden: field.isHidden,
      saveEmptyZeroWhenNull: field.saveEmptyZeroWhenNull,
      createWithIndex: field.createWithIndex,
      dataExtractor: {
        id: props.extractorId,
        sourceType: props.extractorSourceType
      }
    }

    return templateDataExtractorFieldService.saveSQLField(props.templateId, props.extractorId, request).then(() => {
      saveSuccess('Field Cloned!');
      findFields();
    }).catch((error) => {
      errorMessageSaving(error.response.data);
    });
  }

  function deleteField(field) {
    return Swal.fire({
      title: `Do you really want to delete the field: <span style="color: red">${field.code}</span>?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        if (props.extractorSourceType === SOURCE_TYPE.JSON_REST_API) {
          return templateDataExtractorFieldService.deleteJSONField(props.templateId, props.extractorId, field.id).then(() => {
            deleteSuccess();
            findFields();
          }).catch(() => {
            errorMessageSaving({ message: `Failed to delete the field.` });
          });
        } else {
          return templateDataExtractorFieldService.deleteSQLField(props.templateId, props.extractorId, field.id).then(() => {
            deleteSuccess();
            findFields();
          }).catch(() => {
            errorMessageSaving({ message: `Failed to delete the field.` });
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  function deleteSuccess() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: 'Field Deleted',
    });
  }

  return (
    <>
      <DataGrid
        columns={dataGridColumns}
        rows={rows}
        isCellEditable={(params) => isCellEditable(params)}
        components={{
          Toolbar: CustomToolbar,
        }}
        processRowUpdate={(newRow, oldRow) => onCellChanged(newRow, oldRow)}
        onProcessRowUpdateError={(error) => console.error(error)}
        experimentalFeatures={{ newEditingApi: true }}
        autoHeight
        hideFooter
      />
    </>
  )
}