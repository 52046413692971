import React from 'react';
import secretKey from "../images/secretKey.png";
import $ from 'jquery';
import { Table } from '@material-ui/core';

class UserSettingList extends React.Component {

  constructor(props) {
    super(props);
      $('html').click((e) => {
        // e.stopPropagation();
        if (e.target.type != 'checkbox') {
          $('#test').prop('checked', false);
          console.log('Event :', e.target.type);
        }
      });
  }

  render() {
    return (
      <div>
      {this.props.apiList && this.props.apiList.key && this.props.apiList.secret ? (
      //  this.props.apiList.length > 0 ? this.props.apiList.map((apiKey, index) => (
      <div className="">
        
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              {/* <th className="first-col">KEY NAME</th> */}
              <th className="first-col">PUBLIC KEY</th>
              <th className="first-col">SECRET KEY</th>
              <th className="first-col">ACTION</th>
            </tr>
          </thead>
          
          <tbody>
            {/* {this.props.apiList && this.props.apiList.length > 0 ? this.props.apiList.map((apiKey, index) => ( */}
              {/* <tr key={index}> */}
              <tr>
                {/* <td>
                    <span className="key-img"><i className="fa fa-key" aria-hidden="true"></i></span>
                      {apiKey.key}
                    </td> */}
                <td>{this.props.apiList.key}</td>
                <td>
                  <img className="secretkey" src={secretKey} alt="key" />
                  {/* ********************************** */}
                  {
                    this.props.apiList.secret !== '' ? '******************' : ''
                  }
                </td>
                <td>
                  <label className="dropdown">
                    <div className="dd-button more-dropdown">
                      <span className="gear-img"><i className="fa fa-cog" aria-hidden="true"></i></span>
                    </div>
                    <input type="checkbox" className="dd-input" id="test"></input>
                    <ul className="dd-menu">
                      <li className="edit-icon"
                      // onClick={() => this.props.editCustomer(cusDetail)}
                      onClick={() => this.props.editKey()}
                      >
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                              Edit Api Key
                          </li>
                      <li className="edit-icon"
                      // onClick={() => this.props.deleteCustomer(cusDetail.id)}
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                              Delete Api Key
                          </li>
                    </ul>
                  </label>
                </td>
              </tr>
          </tbody>
        
        </Table>
        </div>
        // )) 
     ) : 
        (
          <div className="no-key">
          <p className="image-key"><img className="secretkey" src={secretKey} alt="key" /></p>
          <p className="pb-1 top-line">Add your Frontdoor API Key</p>
          <p className="last-line">
            API keys are used to manage user identity and access to Apptio applications. 
            Before you can log into the mobile app, run emailers, or view Apptio data, your Frontdoor API key must be added.
          </p>
          </div>
        )}
      </div>

    )
  }
}

export default UserSettingList;