import * as React from "react";
import { withRouter } from "react-router-dom";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { useState, useEffect } from 'react';
import authService from '../../Services/authServices'
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import RecipientService from "../../Services/RecipientService";
import AddApiService from "../../Services/addApiService";

function CustomerRecipients(props) {

  const recipientService = new RecipientService();
  const addApiService = new AddApiService();
  const myServiceInstance = new authService();

  const [customerId, setCustomerId] = useState(props.customerId);
  const [templates, setTemplates] = useState(null);
  const [recipients, setRecipients] = useState(null);

  const loggedInCustomer = myServiceInstance.getCurrentCustomer();
  if (!loggedInCustomer) {
    props.history.push("/Login");
  }

  if (!customerId) {
    props.history.push("/Customer");
  }

  useEffect(() => {
    if (customerId) {
      findTemplatesByCustomer();
      findRecipientsByCustomer();
    }
  }, [customerId]);

  function findTemplatesByCustomer() {
    addApiService.getEmailTemplates(customerId).then(response => {
      setTemplates(response);
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  function findRecipientsByCustomer() {
    recipientService.findRecipientsByCustomer(customerId).then(response => {
      const result = Object.entries(response.reduce((acc, { email, id, template }) =>
        (acc[email] = [...(acc[email] || []), { id, template }], acc)
        , {})).map(([key, value]) => ({ email: key, data: value }));
      setRecipients(result);
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  function findTemplateData(templateId) {
    if (templates) {
      return templates.find(template => template.id === templateId)?.name;
    }
  }

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <div className="row" style={{ paddingLeft: 5 }}>
        <div className="col-12">
          <table style={{ width: "100%", overflow: "scroll !important" }}>
            <thead>
              <tr className="line">
                <th className="line-data">Recipient</th>
                <th className="line-data">Templates</th>
              </tr>
            </thead>
            <tbody>
              {recipients && recipients.length > 0 ?
                recipients.map((recipient) => (
                  <tr key={recipient.email} className="line">
                    <td className="line-data" width="30%">{recipient.email}</td>
                    <td className="line-data" style={{ textTransform: "capitalize" }}>
                      {templates
                        ? recipient.data.map(da => findTemplateData(da.template)).sort().join(', ')
                        : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                    </td>
                  </tr>))
                :
                <tr>
                  <td colSpan={2}>
                    {(recipients && recipients.length === 0) ? "No recipient found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                  </td>
                </tr>
              }

            </tbody>
          </table>
        </div>
      </div>

      {loggedInCustomer?.SUPERADMIN ?
        <div className="row-margin" style={{ marginBottom: 5 }}>
          <button type="button" className="btn btn-cancel"
            onClick={() => props.history.push({ pathname: "/Customers" })}>Cancel</button>
        </div>
        : <div> </div>
      }
    </div>
  );
}

export default withRouter(CustomerRecipients);
