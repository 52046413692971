import React from 'react';
import '../Style/AddApiData.css';
import AddApiService from '../Services/addApiService';

class EmailerAddUser extends React.Component {
  constructor(props) {
    super(props);
    this.addApiService = new AddApiService();
    this.state = {
        userName : ''
    }
    }

    handelChangeSubmit = (event) => {
        this.setState({ [event.target.name]: event.target.value })
      }

  render() {
    return (
      <div className="card">
        <h5 className="card-header info-color white-text text-center py-4">
          <strong>New Recipient</strong>
          <span className="cross"
            onClick={this.props.closeModal}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </span>
        </h5>
        <div className="card-body px-lg-5">
          <div className="text-center">
            <div className="col">
              <div className="md-form">
                <label className="label-float">Recipient Name</label>
                <input type="text" className="form-control" name="userName" value={this.state.userName} onChange={this.handelChangeSubmit}/>
              </div>
            </div>
            <button className="btn btn-outline-info btn-rounded my-4 waves-effect z-depth-0 width-30" 
            onClick={() => {this.props.newUser(this.state.userName)} }>
              Save
            </button>
            
          </div>
        </div>
      </div>
    )
  }
}

export default EmailerAddUser