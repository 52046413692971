import React, { useState, useEffect } from "react";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faSortAlphaAsc, faSortAlphaDesc, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";
import TopBar from "./TopBar";
import AddRecepientModal from "./AddRecepientModal";
import DeleteModal from "./DeleteModal";
import OshkoshApplicationService from "../../Services/oshkoshApplicationService";
import AddApiService from '../../Services/addApiService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { TextField } from '@mui/material';
import * as moment from 'moment-timezone';
import AnimatedBody from "../../Wrappers/AnimatedBody";
import "../../Style/Emailer.css";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import SearchBar from "material-ui-search-bar";
import { FILTER_TYPE } from "./TemplateFilter";

import TemplateFilterService from "../../Services/TemplateFilterService";
import TemplateDataExtractorService from "../../Services/TemplateDataExtractorService";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 200,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

function Email(props) {
  const MySwal = withReactContent(Swal);
  const [showModal, setShowModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);

  const [customerId, setCustomerId] = useState(props.location.customerId);
  const [template, setTemplate] = useState(props.location.template);

  if (!template) {
    props.history.push("/EmailSchedule");
  }
  
  const templateFilterService = new TemplateFilterService();
  const templateDataExtractorService = new TemplateDataExtractorService();
  const [filters, setFilters] = useState([]);
  const [showDynamicFilters, setShowDynamicFilters] = useState(false);
  const [filterValues, setFilterValues] = useState(new Map());

  const [showApplicationDropDown, setApplicationDropDwon] = useState(false);
  const [showCostCenterDropDown, setShowCostCenterDropDown] = useState(false);
  const [hintFroDropDown, setHintForDropDown] = useState('')
  const [delEmail, setDelEmail] = useState(null);
  const [selectedApplications, setSelectedApplications] = useState([]);
  const [owners, setOwners] = useState([]);
  const [ownersFetched, setOwnersFetched] = useState(false);
  // const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectAllUsers, setSelectAllUsers] = useState(false)
  const [oshkoshApplications, setOshkoshApplications] = useState([]);
  const [attachment, setAttachment] = useState(false);

  //if we need applications/(any other selection for the emailer)
  const [applications, setApplications] = useState(null);
  const [emails, setEmails] = useState([]);
  const [emailsData, setEmailsData] = useState([]);
  //for showing the application owners
  const [sendToOwner, setSendToOwner] = useState(false);
  const [attachmentAllowed, setAttachmentAllowed] = useState(JSON.parse(sessionStorage.getItem('currentCustomer')).attachmentAllowed);
  //oshkosh application service
  const oshkoshApplicationService = new OshkoshApplicationService();
  //creating add api service
  const addApiService = new AddApiService();
  const [month, setMonth] = useState(moment(new Date()).subtract(1, 'months').format('YYYY-MM'))
  const [sort, setSort] = useState("ASC");

  const [checkEmailStatus, setCheckEmailStatus] = useState(0);
  const [enableSendEmailButton, setEnableSendEmailButton] = useState(true); 

  // for dropdowns
  useEffect(() => {
    setApplications(null)
    setSelectedApplications([])
    let applications = [];

    if(!template.useNewTemplateEditor) {
      if (template?.isDropdown) {
        let body = {
          month: month,
          templateId: template.id,
          templateName: template.name
        }
        setShowCostCenterDropDown(true);
        setApplicationDropDwon(true);
        setHintForDropDown('Select ' + template?.dropdownLabel);
        oshkoshApplicationService.getDropDownValues(body).then(response => {
  
          if (response) {
            setOshkoshApplications(response);
            applications.push({ label: 'Select All', value: 'Select All' });
            response.forEach(function(app) {
              applications.push({ label: app.label, value: app.value });
            });
            setApplications(applications);
          }
        }).catch((error) => {
          Swal.fire({
            icon: "warning",
            title: 'Something Wrong with API Key',
            text: 'Not able to get dropdown lists, Please check the API Key and try again',
            showConfirmButton: true,
            allowOutsideClick: false,
            preConfirm: () => {
              props.history.push("/EmailSchedule");
            }
          });
        });
      }

    } else {
	  setEnableSendEmailButton(false);
	  findTemplateFilters(template.id);
    }

  }, [month]);

  function findTemplateFilters(templateId) {
    templateFilterService.findAll(templateId, true).then(response => {
      response.data.forEach((item) => {
	    if(item.type === FILTER_TYPE.CALENDAR) {
          filterValues.set(item.code, { code: item.code, isMultiselectable: false, isRequired: item.isRequired, values: new Array(month) });
	      setFilterValues(filterValues);		  
	    } else if(item.type === FILTER_TYPE.DATA_EXTRACTOR_DROPDOWN) {
		  let dropdownData = [];
	      dropdownData.push({ label: 'Select All', value: 'Select All' });
	      dropdownData.push.apply(dropdownData, item.dropdownPreviewData);
	    
	      item.dropdownPreviewData = dropdownData;
		}
	  });
      
      setFilters(response.data);
      setShowDynamicFilters(true);
      setEnableSendEmailButton(true);
    });
  }

  useEffect(() => {
    findRecipientsByTemplate();
  }, []);

  function findRecipientsByTemplate() {
    addApiService.getTemplateUser(template?.id).then(response => {
      if (response && response.length !== 0) {
        let emails = [];
        response.forEach(function(app) {
          app.selected = false;
          emails.push(app);
        });
        setEmails(emails);
        setEmailsData(emails);
      }
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  function closeAddRecipientModal() {
    setShowModal(false);
    findRecipientsByTemplate();
  }

  function closeDeleteRecipientModal() {
    setShowDelModal(false);
    findRecipientsByTemplate();
  }

  function requestSearch(searchedVal) {

    if (searchedVal) {
      let filteredRows = emails.filter((row) => {
        return row.userName?.toLowerCase().includes(searchedVal.toLowerCase());
      });

      setEmails(filteredRows)
    } else {
      setEmails(emailsData);
    }
  };

  function cancelSearch() {
    setEmails(emailsData);
  };

  function deleteEmailFromTemplate(email) {

    setShowDelModal(true)
    setDelEmail(email.id)

  }

  function sortEmails() {

    if (sort == "DESC") {
      emails.sort((a, b) => a?.userName?.localeCompare(b?.userName))
      setSort("ASC")
    } else if (sort == "ASC") {
      emails.sort((a, b) => b?.userName?.localeCompare(a?.userName))
      setSort("DESC")
    } else {
      emails.sort((a, b) => a?.userName?.localeCompare(b?.userName))
      setSort("ASC")
    }

    setEmails(emails)
  }

  function sendToOwnerChange() {
    if (sendToOwner) {
      setSendToOwner(false);
    } else {
      setSendToOwner(true);
      
      if(!ownersFetched && owners.length === 0){
	    // Query for owners
	    if(template.ownerDataExtractorId && template.ownerNameExtractorField && template.ownerEmailExtractorField) {
          const previewRequest = {
            userApptioProfile: JSON.parse(sessionStorage.getItem('currentCustomer')).userName,
            ignoredFilters: [ "ALL" ],
            selectFields: [ template.ownerNameExtractorField, template.ownerEmailExtractorField ],
            orderByFields: [ template.ownerNameExtractorField ]
          };
		  
		  templateDataExtractorService.previewDataExtractor(template.id, template.ownerDataExtractorId, previewRequest).then(response => {
		    let ownerRecords = [];
		    let ownerNameList = [];
		    let arrData = Object.values(response.data)[0];
		    
		    if(arrData !== undefined && arrData.rowCount > 0) {
		      Array.from(arrData.data).forEach((rowData) => {
			    if(rowData[template.ownerNameExtractorField] !== null && ownerNameList.indexOf(rowData[template.ownerNameExtractorField]) === -1) {
			      ownerNameList.push(rowData[template.ownerNameExtractorField])
			    
			      ownerRecords.push({
				    name: rowData[template.ownerNameExtractorField],
				    emailAddress: rowData[template.ownerEmailExtractorField]
			      });
			    }  
		      });
		    }
		    
		    setOwners(ownerRecords);
		    setOwnersFetched(true);
		  });
	    }
      }
    }
  }

  function attachmentChange() {
    if (attachment) {
      setAttachment(false);
      return
    }
    setAttachment(true);
    return

  }

  function selectApplicationChange(value, action) {

    console.log("test")
    let ac = action.action;
    if (ac === 'select-option') {

      if (action.option.label !== 'Select All') {
        // let app = oshkoshApplications.find((app) => app.application === action.option.label);
        // let apps = selectedApplications;
        // apps.push(app)
        // setSelectedApplications(apps);
        setSelectedApplications(selectedApplications => [...selectedApplications, oshkoshApplications.find((app) => app.label === action.option.label)]);
      } else {
        setSelectedApplications(oshkoshApplications);
        applications.forEach(e => e.isDisabled = true);
        setApplications(applications)
      }
      return;
    }

    if (ac === 'remove-value') {
      if (action.removedValue.label !== 'Select All') {

        setSelectedApplications(selectedApplications.filter((app) => app.label !== action.removedValue.label));
      } else {
        setSelectedApplications([])
        applications.forEach(e => e.isDisabled = false);
        setApplications(applications)
      }
      return;
    }
    if (ac === 'clear') {
      setSelectedApplications([])
      applications.forEach(e => e.isDisabled = false);
      setApplications(applications)
      return
    }


  }

  function onChangeselectUser(item) {
    let tempArr = [...emails];

    tempArr.forEach((e) => {
      if (e.id === item.id) {
        e.selected = !item.selected;
      }
    });

    setEmails(tempArr);
  }

  function onChangeselectAllUsers() {

    let arr = [];
    if (!selectAllUsers) {
      setSelectAllUsers(true);
      emails.forEach((e) => {
        e.selected = true;
        arr.push(e);
      });

    } else {
      setSelectAllUsers(false);
      emails.forEach((e) => {
        e.selected = false;
        arr.push(e);
      });

    }

    setEmails(arr)
  }



  function sendMail() {
    let application = [];
    let users = [];

    emails.forEach((user) => {
      if (user.selected) {
        users.push(user.userName);
      }
    });
    //checking is emails are empty

    if (!sendToOwner && users.length === 0) {
      MySwal.fire({
        title: 'Email not selected. Please select email(s).',
        icon: 'warning'
      });


      return;
    }
    
    if(template.useNewTemplateEditor){
	  let isValid = true;
	  let filterRequestData = [];
	  
	  // checking required filters
      filters.forEach((fl) => {
	    if(fl.isRequired && (!filterValues.has(fl.code) || !filterValues.get(fl.code).values || filterValues.get(fl.code).values.length == 0)){
		  isValid = false;
		  
		  MySwal.fire({
	          title: `Filter '${fl.displayLabel}' is required. Please select any value`,
	          icon: 'warning'
	        });
	        
		  return;
	    }
	    
	    let formattedValue = filterValues.get(fl.code).values.length > 0 ? filterValues.get(fl.code).values[0] : '';
	    
	    if(fl.type === FILTER_TYPE.CALENDAR) {
	      formattedValue = moment(formattedValue, "YYYY-MM").format(fl.outputFormat);
	    }
	    
	    let filterData = {
		  code: fl.code,
		  comparator: fl.isMultiselectable ? 'CONTAIN' : 'EQUAL_TO',
		  value: fl.isMultiselectable ? filterValues.get(fl.code).values.join(';') : formattedValue 
	    };
	    
	    filterRequestData.push(filterData);
      });	  
	  
	  if(isValid) {
		// creating body for sending the email
	    let body = {
		  userApptioProfile: JSON.parse(sessionStorage.getItem('currentCustomer')).userName,
	      receipts: users,
	      sendToOwners: sendToOwner,
	      sendAsAttachment: attachment,
	      filters: filterRequestData
	    }
	    	    
	    Swal.fire({
	      title: 'Ready to Send?',
	      showCancelButton: true,
	      confirmButtonText: 'Send!',
	      confirmButtonColor: '#ed7d31',
	      showLoaderOnConfirm: true,
	      preConfirm: async () => {
	        try {
            await templateDataExtractorService.sendEmail(template.id, body);

            Swal.fire({
              icon: 'success',
              title: 'Email(s) are being processed and will be sent',
              showConfirmButton: false,
              timer: 1500
            });

            setTimeout(() => { setCheckEmailStatus((checkEmailStatus) => checkEmailStatus + 1); }, 1500);
          } catch (error) {
            if(error.response.data && error.response.data.message) {
              Swal.fire({
                icon: 'error',
                title: `Failed to send email`,
                text: error.response.data.message,
                showConfirmButton: true
              });
            } else {
              Swal.showValidationMessage(`Failed to send email.`);
            }
          }
	      },
	      allowOutsideClick: () => !Swal.isLoading()
	    });
	  }
	  
    } else {
	    if (showApplicationDropDown) {
	
	      selectedApplications.forEach((app) => {
	        application.push(app.value);
	      });
	
	      //checking if application(s) not selected.
	      if (application.length === 0) {
	        MySwal.fire({
	          title: template?.dropdownLabel + ' not selected. Please select ' + template?.dropdownLabel,
	          icon: 'info'
	        });
	        return
	      }
	    }
	    // creating body for sending the email
	    let body = {
	      receipts: users,
	      applications: application,
	      sendToOwners: sendToOwner,
	      month: month,
	      templateId: template.id,
	      templateName: template.name,
	      attachment: attachment
	    }
		
	    Swal.fire({
	      title: 'Ready to Send?',
	      showCancelButton: true,
	      confirmButtonText: 'Send!',
	      confirmButtonColor: '#ed7d31',
	      showLoaderOnConfirm: true,
	      preConfirm: () => {
	        return addApiService.genericSendEmail(body)
	          .then(response => {
	            if (!response.statusCode == 200) {
	              throw new Error(response.statusText)
	            }
	
	            Swal.fire({
	              icon: 'success',
	              title: 'Email(s) are being processed and will be sent',
	              showConfirmButton: false,
	              timer: 1500
	            });
	            
	            setTimeout(() => { setCheckEmailStatus((checkEmailStatus) => checkEmailStatus + 1) }, 1500);
	          })
	          .catch(error => {
	            Swal.showValidationMessage(
	              `Failed to send email.`
	            )
	          })
	      },
	      allowOutsideClick: () => !Swal.isLoading()
	    }).then(() => {
	
	    });
	 }
  }
  
  function onChangeFilter(filterObj, filterValue) {
	filterValues.set(filterObj.code, { code: filterObj.code, isMultiselectable: false, values: new Array(filterValue) });
	setFilterValues(filterValues);
  }
  
  function onChangeDynamicDropdownFilter(filterObj, value, actionObj){	
    if (actionObj.action === 'select-option') {
      if (Array.isArray(value) && value.length > 0 && value[value.length -1].label !== 'Select All') {
        filterValues.set(filterObj.code, { code: filterObj.code, isMultiselectable: filterObj.isMultiselectable, isRequired: filterObj.isRequired, 
          values: value.filter(item => item.label !== 'Select All').map(item => item.value) 
        });
      } else {
	    filterValues.set(filterObj.code, { code: filterObj.code, isMultiselectable: filterObj.isMultiselectable, isRequired: filterObj.isRequired, 
	      values: filterObj.dropdownPreviewData.filter(item => item.label !== 'Select All').map(item => item.value) 
	    });
      }
      
      setFilterValues(filterValues);
    }

    if (actionObj.action === 'remove-value') {
      if (Array.isArray(value) && value.length === 1 && value[0].label === 'Select All') {
	    filterValues.set(filterObj.code, { code: filterObj.code, isMultiselectable: filterObj.isMultiselectable, isRequired: filterObj.isRequired, 
	      values: filterObj.dropdownPreviewData.filter(item => item.label !== 'Select All').map(item => item.value) 
	    });
	  } else {
		filterValues.set(filterObj.code, { code: filterObj.code, isMultiselectable: filterObj.isMultiselectable, isRequired: filterObj.isRequired, values: value.length > 0 ? value.map(item => item.value) : [] });
	  }
        
	  setFilterValues(filterValues);
    }
    if (actionObj.action === 'clear') {
      filterValues.delete(filterObj.code);
      setFilterValues(filterValues);
    }
  }
  
  function testApis() {


    // creating body for sending the email
    let body = {

      month: month,
      templateId: template.id
    }


    Swal.fire({
      title: 'Start Api test?',
      showCancelButton: true,
      confirmButtonText: 'Test!',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return addApiService.testApis(body)
          .then(response => {
            if (!response.statusCode == 200) {
              throw new Error(response.statusText)
            }

            Swal.fire({
              icon: 'success',
              title: 'Apis are working properly',
              showConfirmButton: false,
              timer: 1500
            })
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Please contact BoostTbm Admin for support.`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {

    });
  }

  const options = [
    { value: "concur", label: "Concur" },
    { value: "creodefense", label: "Creo - Defense" },
    { value: "domino", label: "Domino - Fire & Emergency" },
    { value: "select", label: "Select All" },
  ];
  const email = [
    { email: "jroberto@boosttbm.com" },
    { email: "ebrown@boosttbm.com" },
    { email: "rmullaney@boosttbm.com" },
    { email: "garda@boosttbm.com" },
  ];

  return (
    <>
      <div className="custom-box" style={{ overflow: "hidden" }}>
        <SideBar
          sideBarExpanded={props.sideBarExpanded}
          setSideBarExpanded={props.setSideBarExpanded}
        />
        <div id="main">
          <div className="container-fluid custom-container">
            <AnimatedBody
              sideBarExpanded={props.sideBarExpanded}
              setSideBarExpanded={props.setSideBarExpanded}
              className="container-fluid custom-container"
            >
              <TopBar title={"Email"} link={"TESTTBM.COM"} checkEmailStatus={checkEmailStatus} />
              <div className="row row-margin">
                <div className="col-md-6 col-12">
                  <a>
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      className="main-color"
                      style={{ fontSize: "20px" }}
                      onClick={() => props.history.push("/EmailSchedule")}
                    />
                  </a>
                  <span>
                    <span style={{ marginLeft: "10px" }} className="email" onClick={() => props.history.push("/EmailSchedule")}>Emailers</span>
                    <span style={{ marginLeft: "10px" }}>|</span>
                    <span style={{ marginLeft: "10px" }}>Edit Recipients</span>
                  </span>
                  <div style={{ marginTop: "30px" }}>
                    <h6>{template?.name}</h6>
                    <p className="main-color">
                      {template?.id}
                    </p>
                  </div>
                  {!template.useNewTemplateEditor ? 
	                  <div className="row">
	                    {showApplicationDropDown && applications ? <div className="col-6">
	                      <Select
	                        maxMenuHeight={emails.length >= 2 && !sendToOwner ? 244 :
	                          emails.length == 1 && !sendToOwner ? 200 :
	                            sendToOwner && selectedApplications.length >= 1 ? 244 :
	                              155}
	                        //  className={selectedApplications >=2 ? "select-dropdown-test2":"select-dropdown-test2"}
	                        closeMenuOnSelect={false}
	                        // menuIsOpen={true}
	                        placeholder={showCostCenterDropDown ? hintFroDropDown : "Select Application(s)"}
	                        isMulti
	                        options={applications}
	                        onChange={(e, action) => selectApplicationChange(e, action)}
	                        style={"position: absolute"}
	                      /></div>
	                      : (!showApplicationDropDown) ? "" : <div className="col-6"><Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" /></div>}
	                    <div className="col">
	                      <TextField
	                        key="Month"
	                        id="month"
	                        size="small"
	                        label="Month"
	                        type="month"
	                        onChange={(e) => setMonth(e.target.value)}
	                        defaultValue={month}
	                        // value={this.state.selectedMonth}
	                        sx={{ width: 200 }}
	                        InputLabelProps={{
	                          shrink: true,
	                        }}
	                      />
	                    </div>
	                  </div>
                  : <></>}
                  {template.useNewTemplateEditor ?
                    <div className="row">
                      {showDynamicFilters ? '' : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                      
                      {filters.map((item) => (
	                    <div key={item.id} className="col">
	                      {item.type === FILTER_TYPE.CALENDAR ? 
	                        <>
	                          <TextField
	                            key={item.code}
	                            id={item.code}
	                            size="small"
	                            label={item.displayLabel}
	                            type="month"
	                            onChange={(e) => onChangeFilter(item, e.target.value)}
	                            defaultValue={month}
	                            sx={{ width: 200 }}
	                            InputLabelProps={{
  	                              shrink: true,
	                            }}
	                          />
	                        </>  
	                       : 
	                       <>
	                         <Select key={item.code} id={item.code} 
	                           closeMenuOnSelect={false} placeholder={item.displayLabel}
	                           isMulti={item.isMultiselectable}
	                           options={item.dropdownPreviewData}
	                           onChange={(value, action) => onChangeDynamicDropdownFilter(item, value, action)}
	                           style={"position: absolute"}
	                          />
	                       </>}
                        </div>
                      ))}
                      
                    </div>
                  : <></>}
                  
                  <div className="row-margin" style={{ marginLeft: 0 }}>
                    {!template.useNewTemplateEditor ? <>
	                    <button className="btn btn-send" onClick={() => sendMail()} disabled={!enableSendEmailButton}>Send Email</button>
	                    <button
	                      type="button"
	                      className="btn btn-submit"
	                      style={{ marginTop: "0" }}
	                      data-toggle="modal"
	                      data-target="#reciModal"
	                      onClick={() => setShowModal(true)}
	                    >
	                      Add Recipient
	                    </button>
	
	                    <button
	                      type="button"
	                      className="btn btn-submit"
	                      style={{ marginTop: "0", marginLeft: "8px" }}
	                      data-toggle="modal"
	                      data-target="#reciModal"
	                      onClick={() => testApis()}
	                    >
	                      Test APIs
	                    </button>
	                  </> : ""} 
	                  
	                  {template.useNewTemplateEditor ? <>
	                    <button className="btn btn-send" onClick={() => sendMail()} disabled={!enableSendEmailButton}>Send Email</button>
	                    <button
	                      type="button"
	                      className="btn btn-submit"
	                      style={{ marginTop: "0" }}
	                      data-toggle="modal"
	                      data-target="#reciModal"
	                      onClick={() => setShowModal(true)}
	                    >
	                      Add Recipient
	                    </button>
	                  </> : ""} 
                  </div>
                  <div className="row" style={{ marginTop: "20px" }}>
                    {template && template.isSendToOwner ?
                      <div className="col style={{ marginTop: 20px }}">
                        <label className="switch">
                          <input type="checkbox" onChange={() => sendToOwnerChange()} />
                          <span className="slider round"></span>
                        </label>
                        <span style={{ marginLeft: "5px" }}>Send to owners</span>
                      </div>
                      : ""}

                    {attachmentAllowed ?
                      <div className="col style={{marginTop: 20px }}">
                        <label className="switch">
                          <input type="checkbox" onChange={() => attachmentChange()} />
                          <span className="slider round"></span>
                        </label>
                        <span style={{ marginLeft: "5px" }}>Attach as PDF</span>
                      </div>
                      : ""}
                  </div>
                </div>
                <div className="col-6">
                  <img src={template?.version.thumbnail_url} className="template-img" />
                </div>
              </div>
              <div className="row row-margin">
                <div className="col-12">
                  {sendToOwner ?
                    (!template.useNewTemplateEditor ?
                    <>
                      <table className="table-reci" style={{ width: '100%', overflow: 'scroll !important' }}>
                        <tbody>
                          <tr className="line">
                            <th className="line-data">
                              {template?.dropdownLabel}
                            </th>
                            <th className="line-data"> Owner(s)</th>
                            <th className="line-data"> Email Address(s)</th>
                          </tr>

                          {selectedApplications.map((item) => (
                            <tr className="line" key={item.application}>
                              <td className="line-data">{item.label}</td>
                              <td className="line-data">{item.owner}</td>
                              <td className="line-data">{item.emailerAddress}</td>

                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>  
                    : 
                    <>
                      <table className="table-reci" style={{ width: '100%', overflow: 'scroll !important' }}>
                        <tbody>
                          <tr className="line">
                            <th className="line-data"> Owner(s)</th>
                            <th className="line-data"> Email Address(s)</th>
                          </tr>

                          {owners.map((owner) => (
                            <tr className="line" key={owner.emailAddress}>
                              <td className="line-data">{owner.name}</td>
                              <td className="line-data">{owner.emailAddress}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      
                      {!ownersFetched && <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}  
                    </>  
                    )
                    :
                    <div>
                      <SearchBar
                        variant="filled"
                        onChange={(searchVal) => requestSearch(searchVal)}
                        onCancelSearch={() => cancelSearch()}
                      />
                      <table className="table-reci">
                        <thead>
                          <tr className="line">
                            <th className="line-data">
                              <input
                                type="checkbox"

                                checked={selectAllUsers}
                                onChange={() => onChangeselectAllUsers()}
                              />
                            </th>
                            <th className="line-data" aria-sort="true" >
                              <span onClick={() => sortEmails()}>Recipient</span>


                              <FontAwesomeIcon
                                icon={sort == "ASC" ? faSortAlphaAsc : sort == "DESC" ? faSortAlphaDesc : ""}
                                onClick={() => sortEmails()}
                                style={{ marginLeft: "10px" }}
                              />

                            </th>
                            <th className="line-data" width={100}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {emails.map((item) => (
                            <tr className="line" key={item.id}>
                              <td className="line-data">
                                <input
                                  type="checkbox"
                                  checked={item.selected}
                                  // value={item.selected}
                                  onChange={() => onChangeselectUser(item)}
                                />
                              </td>
                              <td className="line-data">{item.userName}</td>
                              <td
                                className="line-data"
                                onClick={() => deleteEmailFromTemplate(item)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  className="fa-trash-alt"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  }
                </div>
              </div>
            </AnimatedBody>
          </div>
        </div>
      </div>
      <AddRecepientModal templateId={template?.id} customerId={customerId} open={showModal} close={() => closeAddRecipientModal()} />
      <DeleteModal template={template?.id} delEmail={delEmail} open={showDelModal} close={() => closeDeleteRecipientModal()} />
    </>
  );
}

export default withRouter(Email);
