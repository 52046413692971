import axios from 'axios';
import { environment } from '../environment';

class TemplateDataExtractorService {

  findAll = (templateId) => {
    return axios.get(environment.API_URL + `templates/${templateId}/template-data-extractors`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }
  
  findAllNotHidden = (templateId) => {
    return this.findAll(templateId).then((response) => {
      if(response.data) {
        response.data = response.data.filter(extractor => !extractor.isHidden);
        
        response.data.forEach(extractor => {
          extractor.fields = extractor.fields.filter(field => !field.isHidden);
        });
      }
      return response;
    });
  }

  findById = (templateId, extractorId) => {
    return axios.get(environment.API_URL + `templates/${templateId}/template-data-extractors/${extractorId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }

  previewRawDataExtractor = (templateId, extractorId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/template-data-extractors/${extractorId}/preview-raw-data`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        console.log(error.response.data.message);
      });
  }
  
  previewDataExtractor = (templateId, extractorId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/template-data-extractors/${extractorId}/preview-data`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        console.log(error.response.data.message);
      });
  }
  
  generateFields = (templateId, extractorId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/template-data-extractors/${extractorId}/generate-fields`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        console.log(error.response.data.message);
      });
  }

  generateOutput = (templateId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/template-data-extractors/generate-output`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }
  
  getChartLink = (templateId, extractorId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/template-data-extractors/${extractorId}/chart-link`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }
  
  sendEmail = (templateId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/template-data-extractors/send-email`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  saveJSONExtractor = (templateId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/json-template-data-extractors`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  updateJSONExtractor = (templateId, extractorId, data) => {
    data.id = extractorId;
    return axios.put(environment.API_URL + `templates/${templateId}/json-template-data-extractors/${extractorId}`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  deleteJSONExtractor = (templateId, extractorId) => {
    return axios.delete(environment.API_URL + `templates/${templateId}/json-template-data-extractors/${extractorId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  saveSQLExtractor = (templateId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/sql-template-data-extractors`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  updateSQLExtractor = (templateId, extractorId, data) => {
    data.id = extractorId;
    return axios.put(environment.API_URL + `templates/${templateId}/sql-template-data-extractors/${extractorId}`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  deleteSQLExtractor = (templateId, extractorId) => {
    return axios.delete(environment.API_URL + `templates/${templateId}/sql-template-data-extractors/${extractorId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  addHttpParam = (templateId, extractorId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/template-data-extractors/${extractorId}/http-params`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }
  
  updateHttpParam = (templateId, extractorId, httpParamId, data) => {
    return axios.put(environment.API_URL + `templates/${templateId}/template-data-extractors/${extractorId}/http-params/${httpParamId}`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  deleteHttpParam = (templateId, extractorId, httpParamId) => {
    return axios.delete(environment.API_URL + `templates/${templateId}/template-data-extractors/${extractorId}/http-params/${httpParamId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }
  
  importFile = (templateId, file) => {
	const formData = new FormData();
    formData.append("file", file);
    
    return axios.post(environment.API_URL + `templates/${templateId}/template-data-extractors/import`, formData)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  exportFile = (templateId, extractorId) => {
	return axios.get(environment.API_URL + `templates/${templateId}/template-data-extractors/${extractorId}/export`, { responseType: 'blob' })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }

}

export default TemplateDataExtractorService;