import { useBlock } from "easy-email-editor";

export function useFocusedInput() {

  const { change } = useBlock();

  const setFocusedInput = (event) => {
    window.previousFocused = event.target.name;
  }
  
  const setFocusedInputByName = (name) => {
    window.previousFocused = name;
  }

  const setFocusedInputValue = (value) => {
    if (window.previousFocused) {
      change(window.previousFocused, value);
      const focusedElement = document.getElementsByName(window.previousFocused)[0];
      focusedElement.classList.add('focus-value-changed');
      setTimeout(() => {
        focusedElement.classList.remove('focus-value-changed');
      }, 2000);

      window.previousFocused = null;
    }
  }

  return {
    setFocusedInput,
    setFocusedInputByName,
    setFocusedInputValue
  }
};
