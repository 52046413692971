import * as React from "react";
import { withRouter } from "react-router-dom";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import SideBar from "./SideBar";
import { useState, useEffect } from 'react';
import authService from '../../Services/authServices'
import AddApiService from '../../Services/addApiService';
import AnimatedBody from "../../Wrappers/AnimatedBody";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

import UserModal from "./UserModal";
import SearchBar from "material-ui-search-bar";

function Customer(props) {

  const addApiService = new AddApiService();
  const myServiceInstance = new authService();

  const [customersData, setCustomersData] = useState(null);
  const [customers, setCustomers] = useState(null);

  const [selectedCustomerId, setSelectedCustomerId] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);

  const loggedInCustomer = myServiceInstance.getCurrentCustomer();
  if (!loggedInCustomer?.SUPERADMIN) {
    props.history.push("/Login")
  }

  useEffect(() => {
    findAllCustomer();
  }, []);

  function findAllCustomer() {
    addApiService.getAllCustomer(true).then(response => {
      setCustomersData(response);
      setCustomers(response);
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  function requestSearch(searchedVal) {
    if (searchedVal) {
      let filteredData = customersData.filter((customer) => {
        return customer.customerName?.toLowerCase().includes(searchedVal.toLowerCase());
      });

      setCustomers(filteredData)
    } else {
      setCustomers(customersData);
    }
  }

  function cancelSearch() {
    setCustomers(customersData);
  }

  function displayUserModal(customerId) {
    setSelectedCustomerId(customerId);
    setShowUserModal(true);
  }

  function closeUserModal() {
    findAllCustomer();
    setShowUserModal(false);
  }

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <SideBar
        sideBarExpanded={props.sideBarExpanded}
        setSideBarExpanded={props.setSideBarExpanded}
      />
      <div id="main">
        <div className="container-fluid custom-container">

          <AnimatedBody
            sideBarExpanded={props.sideBarExpanded}
            setSideBarExpanded={props.setSideBarExpanded}
            className="container-fluid custom-container"
          >
            <div className="row row-margin row-left">
              <div className="col-12">
                <SearchBar className="MuiPaper-outlined"
                  style={{ boxShadow: 'none' }}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                />
                <table style={{ width: "100%", overflow: "scroll !important" }}>
                  <thead>
                    <tr className="line">
                      <th className="line-data">Customer</th>
                      <th className="line-data">No. of Templates</th>
                      <th className="line-data">No. of Users</th>
                      <th className="line-data" width={150} style={{ textAlign: "center" }}>New Designer Allowed</th>
                      <th className="line-data" width={150} style={{ textAlign: "center" }}>Edit DataExtractors Allowed</th>
                      <th className="line-data" width={250} style={{ textAlign: "right" }}>
                        <button type="button" className="btn btn-submit"
                          style={{ marginTop: "0" }}
                          onClick={() => props.history.push({ pathname: "/Customer" })}>
                          Create Customer
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers ?
                      customers.map((customer) => (
                        <tr key={customer.id} className="line">
                          <td className="line-data">
                            <img className="data-img" style={{ marginLeft: 10, width: 64 }} src={customer.logoURL ? customer.logoURL + "?" + Date.now() : null} />
                            {customer.customerName}
                          </td>
                          <td className="line-data">{customer.numberOfTemplates}</td>
                          <td className="line-data">{customer.numberOfUsers}</td>
                          <td className="line-data" style={{ textAlign: "center" }}>{customer.newDesignerAllowed ? 'Yes' : 'No'}</td>
                          <td className="line-data" style={{ textAlign: "center" }}>{customer.editDataextractorsAllowed ? 'Yes' : 'No'}</td>
                          <td className="line-data" style={{ textAlign: "right" }}>
                            <button type="button" className="btn btn-light"
                              style={{ marginTop: "0" }}
                              onClick={() => props.history.push({
                                pathname: "/Customer/" + customer.id
                              })}>
                              Edit
                            </button>
                            <button type="button" className="btn btn-light"
                              style={{ marginTop: "0" }}
                              onClick={() => displayUserModal(customer.id)}>
                              Add User
                            </button>
                          </td>
                        </tr>))
                      :
                      <tr><td>
                        {(customers && customers.length === 0) ?
                          "No customers found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                      </td></tr>
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </AnimatedBody>
        </div>
      </div>

      <UserModal customerId={selectedCustomerId} open={showUserModal} close={() => closeUserModal()} />
    </div>
  );
}

export default withRouter(Customer);
