import React from 'react';
// import { Pagination, Table } from 'react-bootstrap';
import ReportService from '../Services/ReportService';

import '../Style/MobileDataList.css';
import * as moment from 'moment-timezone';
import MyPagination from './MyPagination';
import * as Loader from 'react-loader-spinner';
import TextTruncate from 'react-text-truncate';
import authService from '../Services/authServices';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import SearchBar from "material-ui-search-bar";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';




import PropTypes from 'prop-types';
import TablePagination from '@mui/material/TablePagination';
import { Pagination } from 'react-bootstrap';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ff7a00",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "16px 8px 16px 8px"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  // const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const gotoPage = (event) => {
    onPageChange(event, Number(event.target.innerText));
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };


  return (
    <div className="d-flex p-2 bd-highlight" >
      <Pagination >
        <Pagination.First onClick={handleFirstPageButtonClick} disabled={page === 0} />
        <Pagination.Prev onClick={handleBackButtonClick} disabled={page === 0} />



        {/* {createPages()} */}



        <Pagination.Next onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} />
        <Pagination.Last onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} />
      </Pagination >
    </div >





  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


class EmailerHistoryList extends React.Component {

  constructor(props) {
    super(props);
    this.reportService = new ReportService();
    this.authService = new authService();
    this.state = {
      data: [],
      details: [],
      rowsPerPage: 5,
      parsedData: [],
      showList: [],        // login history data
      totalCount: 100,
      page: 0,
      limit: 10,
      totalPages: 10,
      showLoader: true,
      currentPage: null,
      totalPages: null,
      customer: this.authService.getCurrentCustomer(),
      open: false,
      detailsLoder: true,
      prevIndex:0,
    }



    this.cancelSearch = this.cancelSearch.bind(this);
    this.requestSearch = this.requestSearch.bind(this);
    this.search = this.search.bind(this);
    this.setOpen = this.setOpen.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.fetchEmailerHistory();
    this.onPageChanged = this.onPageChanged.bind(this);
    this.fetchEmailerHistoryForReq = this.fetchEmailerHistoryForReq.bind(this);
  }

  componentDidMount() {
    // this.fetchEmailerHistory();
  }

  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage(event) {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });

  };

  onPageChanged(obj) {
    debugger
    const { currentPage, totalPages, pageLimit } = obj;
    const offset = (currentPage - 1) * pageLimit;
    let showList = this.state.data.slice(offset, offset + pageLimit);

    this.setState({ currentPage: currentPage, showList: showList, totalPages: totalPages });
  };



  fetchEmailerHistory() {
    debugger
    let parsedData = [];
    this.reportService.fetchEmailerHistory().then(response => {

      console.log('EmailerHistoryResponse: ', response)
      response.forEach((item) => {
        item.loggedTime = moment(item.loggedTime).local().format('MM/DD/YYYY HH:mm:ss')
        parsedData.push(item);
      })
      this.setState({
        data: parsedData,
        parsedData: parsedData,
        showLoader: false,
        totalCount: parsedData.length
      })

    }).catch((error) => {
      console.log('ERROR: ', error);
    });



    console.log('ScheduledEamilerAfterConversion', this.state.data)
  };

  fetchEmailerHistoryForReq(reqId) {
    debugger
    let parsedData = [];
    this.reportService.fetchEmailerHistoryForReq(reqId).then(response => {

      console.log('EmailerHistoryResponse: ', response)
      response.forEach((item) => {
        item.loggedTime = moment(item.loggedTime).local().format('MM/DD/YYYY HH:mm:ss')
        parsedData.push(item);
      })
      this.setState({
        details: parsedData,
        detailsLoder: false,
      })

    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  search(searchValue) {
    this.setState(prevState => ({
      showLoader: true,

    }))



    this.requestSearch(searchValue);

    this.setState(prevState => ({
      showLoader: false
    }))
  }

  setOpen(val, index) {
    const tempData = [...this.state.data];
    var foundIndex = tempData.findIndex(x => x.id == index);
    tempData[foundIndex].open = val;
    if (val) {
      this.fetchEmailerHistoryForReq(tempData[foundIndex].reqId)
    } else {
      this.setState(prevState => ({
        data: tempData,
        details: [],
        detailsLoder: true,
        prevIndex:0
      }))
      return;
    }
    if(this.state.prevIndex !== 0) {
      var foundIndex = tempData.findIndex(x => x.id == this.state.prevIndex);
      tempData[foundIndex].open = false;
    }
    this.setState(prevState => ({
      data: tempData,
      prevIndex: index
    }))
  }

  requestSearch(searchedVal) {


    let filteredRows = this.state.parsedData.filter((row) => {
      return row.application?.toLowerCase().includes(searchedVal.toLowerCase()) || row.emailer?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.loggedTime?.toLowerCase().includes(searchedVal.toLowerCase()) || row.month?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.nature?.toLowerCase().includes(searchedVal.toLowerCase()) || row.recipients?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.status?.toLowerCase().includes(searchedVal.toLowerCase()) || row.type?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.user?.toLowerCase().includes(searchedVal.toLowerCase());
    });

    this.setState(prevState => ({
      data: filteredRows,
      totalCount: filteredRows.length,
    }))
  };

  cancelSearch() {
    this.setState({
      data: this.state.parsedData,
      showLoader: false
    })
  };



  render() {

    let applicationsDropDown = null;
    if (this.state.showLoader) {

      applicationsDropDown = (

        <div className="position-absolute top-50 start-50">



          <Loader.Audio
            type="Bars"
            color="#ed7d31"
            height={50}
            width={50}
          />

        </div>


      );
    } else {
      applicationsDropDown = (<div>

        <div className='padding-bottom-10px padding-top-10px'>
          <SearchBar
            variant="filled"
            onChange={(searchVal) => this.search(searchVal)}
            onCancelSearch={() => this.cancelSearch()}
          />
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell ></StyledTableCell>

                <StyledTableCell style={{ minWidth: "20%", width: "20%" }}>User Name</StyledTableCell>
                <StyledTableCell  align="left">Emailer Type</StyledTableCell>
                <StyledTableCell style={{ minWidth: "18%", width: "18%" }} align="left">Emailer</StyledTableCell>

                <StyledTableCell align="left">Month</StyledTableCell>
                <StyledTableCell style={{ minWidth: "20%", width: "20%" }} align="left">Choosen Option (s)</StyledTableCell>
                <StyledTableCell style={{ minWidth: "14%", width: "14%" }} align="left">Recipient (s)</StyledTableCell>
                <StyledTableCell align="left">Date Time</StyledTableCell>
                <StyledTableCell style={{ minWidth: "18%", width: "18%" }} align="left">Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(this.state.rowsPerPage > 0
                ? this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                : this.data
              ).length > 0 ? (this.state.rowsPerPage > 0
                ? this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                : this.state.data
              ).map((user, index) => (
                // {this.state.showList.length > 0 ? this.state.showList.map((user) => (
                <React.Fragment>
                  <StyledTableRow key={`${user.user}-${user.id}`}>
                    <StyledTableCell className='p-1 pe-0' align="left">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => { this.setOpen(!user.open, user.id) }}
                      >
                        {user.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell className="text-break">{user.user}</StyledTableCell>
                    <StyledTableCell align="left">{user?.type}</StyledTableCell>
                    <StyledTableCell align="left">{user.emailer}</StyledTableCell>
                    <StyledTableCell align="left">{user.month}</StyledTableCell>
                    <StyledTableCell align="left" className="text-break">{user.application.slice(0, 22)} {user.application.length > 22 ? "..." : ""}</StyledTableCell>
                    <StyledTableCell align="left" className="text-break">{user.recipients}</StyledTableCell>
                    <StyledTableCell align="left">{user.loggedTime}</StyledTableCell>
                    <StyledTableCell align="left" className="text-break">{user.status}</StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <Collapse in={user.open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Details
                          </Typography>

                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <StyledTableRow>
                                <StyledTableCell>Choosen Option</StyledTableCell>
                                <StyledTableCell>Recipient</StyledTableCell>
                                <StyledTableCell align="right">Date Time</StyledTableCell>
                                <StyledTableCell align="right">Status</StyledTableCell>
                              </StyledTableRow>
                            </TableHead>
                            {(this.state.details === undefined || this.state.details.length == 0) && !this.state.detailsLoder ?
                              "No records found for this request." :
                              this.state.detailsLoder ?
                                <Loader.Audio
                                  type="Bars"
                                  color="#ed7d31"
                                  height={50}
                                  width={50}
                                />
                                :
                                <TableBody>

                                  {this.state.details.map((detail) =>
                                    <StyledTableRow key={1}>
                                      <StyledTableCell component="th" scope="row">
                                        {detail.application}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {detail.recipients}
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                        {detail.loggedTime}
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                        {detail.status}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  )}
                                </TableBody>
                            }
                          </Table>
                        </Box>
                      </Collapse>
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>

              )) : "No logs Found"}
            </TableBody>

          </Table>
        </TableContainer>

        <div className="row">
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={this.state.totalCount}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                /></TableRow>
            </TableFooter>
          </Table>
        </div>

      </div >
      );
    }
    return (
      <div>
        <div>{applicationsDropDown}</div>
      </div>

    );
  }
}

export default EmailerHistoryList