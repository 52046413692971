import * as React from "react";
import { withRouter } from "react-router-dom";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import SideBar from "./SideBar";
import { useState, useEffect } from 'react';
import authService from '../../Services/authServices'
import AnimatedBody from "../../Wrappers/AnimatedBody";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import UserService from "../../Services/UserService";
import Swal from 'sweetalert2';
import SearchBar from "material-ui-search-bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Users(props) {

  const userService = new UserService();
  const myServiceInstance = new authService();

  const [usersData, setUsersData] = useState(null);
  const [users, setUsers] = useState(null);

  const loggedInCustomer = myServiceInstance.getCurrentCustomer();
  if (!loggedInCustomer?.SUPERADMIN) {
    props.history.push("/Login")
  }

  useEffect(() => {
    findAllUsers();
  }, []);

  function findAllUsers() {
    userService.findAll().then(response => {
      setUsersData(response);
      setUsers(response);
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  function requestSearch(searchedVal) {
    if (searchedVal) {
      let filteredData = usersData.filter((user) => {
        return user.customer?.toLowerCase().includes(searchedVal.toLowerCase())
          || user.userName?.toLowerCase().includes(searchedVal.toLowerCase());
      });

      setUsers(filteredData)
    } else {
      setUsers(usersData);
    }
  }

  function cancelSearch() {
    setUsers(usersData);
  }

  function showHideOTP(user) {
    user.displayOTP = !user.displayOTP;
  }

  function confirmResetPassword(userId) {
    Swal.fire({
      title: 'Do you really want to reset the Password of this user?',
      showCancelButton: true,
      confirmButtonText: 'Reset Password',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return userService.resetPassword(userId)
          .then(response => {
            if (response.status !== 200) {
              Swal.fire({
                title: response.data.message,
                icon: 'error'
              })
              return;
            }

            Swal.fire({
              icon: 'success',
              title: 'Password was changed',
              showConfirmButton: false,
              timer: 1500
            });

            findAllUsers();
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Failed to reset the password`
            )
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {

    });
  }

  function confirmMakeAnAdmin(userId, userName, isAdmin) {
    Swal.fire({
      title: 'Do you really want to' + (isAdmin ? ' revoke the user ' : ' make the user ') + '<span style="color: green;">' + userName + '</span> as an admin?',
      showCancelButton: true,
      confirmButtonText: (isAdmin ? 'Revoke Admin' : 'Make Admin'),
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return userService.makeOrRevokeAdmin(userId, !isAdmin)
          .then(response => {
            if (!response.statusCode === 200) {
              throw new Error(response.statusText)
            }

            Swal.fire({
              icon: 'success',
              title: (isAdmin ? 'Admin has been revoked' : 'Converted as an Admin'),
              showConfirmButton: false,
              timer: 1500
            });

            findAllUsers();
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Failed to update the user`
            )
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {

    });
  }

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <SideBar
        sideBarExpanded={props.sideBarExpanded}
        setSideBarExpanded={props.setSideBarExpanded}
      />
      <div id="main">
        <div className="container-fluid custom-container">

          <AnimatedBody
            sideBarExpanded={props.sideBarExpanded}
            setSideBarExpanded={props.setSideBarExpanded}
            className="container-fluid custom-container"
          >
            <div className="row row-margin row-left">
              <div className="col-12">
                <SearchBar className="MuiPaper-outlined"
                  style={{ boxShadow: 'none' }}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                />
                <table style={{ width: "100%", overflow: "scroll !important" }}>
                  <thead>
                    <tr className="line">
                      <th className="line-data">Customer</th>
                      <th className="line-data">User</th>
                      <th className="line-data" width={120}>Registered</th>
                      <th className="line-data" width={120}>Admin</th>
                      <th className="line-data" width={120}>Apptio Profile</th>
                      <th className="line-data" width={100}></th>
                      <th className="line-data" width={380}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users && users.length > 0 ?
                      users.map((user, index) => (
                        <tr key={user.id} className="line">
                          <td className="line-data">{user.customer}</td>
                          <td className="line-data">{user.userName}</td>
                          <td className="line-data" style={{ textTransform: "capitalize" }}>{user.isRegistered}</td>
                          <td className="line-data" style={{ textTransform: "capitalize" }}>{user.admin ? 'Yes' : 'No'}</td>
                          <td className="line-data" style={{ textTransform: "capitalize" }}>{user.hasApptioProfile ? 'Yes' : 'No'}</td>
                          <td align="right">
                            {user.displayOTP ? user.otp : ''}
                          </td>
                          <td className="line-data" style={{ textAlign: "right" }}>
                            {
                              user.otp ?
                                <a href={"#u" + index} onClick={() => showHideOTP(user)} title={user.displayOTP ? "Hide OTP" : "Show OTP"}>
                                  {
                                    user.displayOTP ?
                                      <FontAwesomeIcon icon={faEyeSlash} color="#6c6c6f" />
                                      :
                                      <FontAwesomeIcon icon={faEye} color="#6c6c6f" />
                                  }
                                </a>
                                : <span />
                            }
                            <button type="button" className={"btn " + (user.admin ? "btn-submit" : "btn-light")}
                              style={{ margin: "0 20px", width: 140 }}
                              onClick={() => confirmMakeAnAdmin(user.id, user.userName, user.admin)}>
                              {user.admin ? 'Revoke Admin' : 'Make Admin'}
                            </button>
                            <button type="button" className="btn btn-light"
                              style={{ marginTop: 0, marginRight: 20 }}
                              onClick={() => confirmResetPassword(user.id)}>
                              Reset Password
                            </button>
                          </td>
                        </tr>))
                      :
                      <tr>
                        <td colSpan={2}>
                          {(users && users.length === 0) ? "No users found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                        </td>
                      </tr>
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </AnimatedBody>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Users);
