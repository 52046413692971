import React from 'react';
import { styled } from '@mui/material/styles';
import { AttributesPanelWrapper, SwitchField, NumberField, SelectField, ColorPickerField, FontFamily, RadioGroupField, TextAreaField, InputWithUnitField, enhancer } from 'easy-email-extensions';
import { useFocusIdx, Stack, TextStyle, useBlock } from 'easy-email-editor';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useState } from 'react';
import { Grid, Space } from '@arco-design/web-react';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Tab } from '@mui/material';
import { cloneDeep } from 'lodash';
import { EditTab } from '../../Fields/EditTab';
import { STATIC_TABLE_DEFAULT_COLUMN, THEMES } from './Theme';
import UndoIcon from '@mui/icons-material/Undo';

import ThemeBlue from '../../../../images/designerThemes/designer-table-theme-blue.png';
import ThemeBlueDark from '../../../../images/designerThemes/designer-table-theme-blue-dark.png';
import { MultiPixel } from '../../Fields/MultiPixel';

import { get } from 'lodash';
import { useFocusedInput } from '../utils/useFocusedInput';
import { alignmentOptions, fontStyleoptions, fontWeightoptions, borderStyleOptions } from '../utils/Utils';
import { TableOptions } from '../../Common/TableOptions';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightSharpIcon />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'var(--color-neutral-1)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));



const rowsOptions = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
  {
    value: '7',
    label: '7',
  },
  {
    value: '8',
    label: '8',
  },
  {
    value: '9',
    label: '9',
  },
  {
    value: '10',
    label: '10',
  },
];

const EditTabField = enhancer(EditTab, (e) => e);

const debounceTime = 1500;

export function Panel() {

  const [active, setActive] = useState('');
  const [expanded, setExpanded] = useState(true);

  const { focusIdx } = useFocusIdx();
  const { change, values } = useBlock();
  const { setFocusedInput } = useFocusedInput();

  const [selectedTab, setSelectedTab] = useState("0");
  const [selectedTabGlobal, setSelectedTabGlobal] = useState("0");

  function onChangeAccordion(tab) {
    if (tab !== active) {
      setExpanded(true);
    } else {
      setExpanded(!expanded);
    }
    setActive(tab);
  }

  function onThemeSelected(theme) {
    change(focusIdx + `.data.value.global`, theme.global);
  }

  return (
    <AttributesPanelWrapper>
      <Accordion expanded={active === 'settings' && expanded} onChange={() => onChangeAccordion('settings')}>
        <AccordionSummary>Settings</AccordionSummary>
        <AccordionDetails>
          <Stack vertical>

            <Grid.Row>
              <Grid.Col offset={3} span={15}>
                <SwitchField
                  label='Header'
                  name={`${focusIdx}.data.value.settings.header`}
                  defaultChecked
                  inline
                  checkedText='ON'
                  uncheckedText='OFF'
                />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col offset={3} span={15}>
                <SelectField
                  label='Rows'
                  name={`${focusIdx}.data.value.settings.rows`}
                  options={rowsOptions}
                  inline
                />
              </Grid.Col>
            </Grid.Row>


            <Grid.Row>
              <Grid.Col offset={3} span={15}>
                <SwitchField
                  label='Footer'
                  name={`${focusIdx}.data.value.settings.footer`}
                  defaultChecked
                  inline
                  checkedText='ON'
                  uncheckedText='OFF'
                />
              </Grid.Col>
            </Grid.Row>
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={active === 'dimension' && expanded} onChange={() => onChangeAccordion('dimension')}>
        <AccordionSummary>Global Style</AccordionSummary>
        <AccordionDetails className='px-2, py-0'>
          <Stack vertical spacing='tight'>
            <TabContext value={selectedTabGlobal}>
              <TabList onChange={(_e, value) => setSelectedTabGlobal(value)} className='w-100'>
                <Tab label="Table" value="0" style={{ minWidth: 50, padding: '12px 14px' }} />
                <Tab label="Header" value="1" style={{ minWidth: 50, padding: '12px 14px' }} />
                <Tab label="Body" value="2" style={{ minWidth: 50, padding: '12px 14px' }} />
                <Tab label="Footer" value="3" style={{ minWidth: 50, padding: '12px 14px' }} />
              </TabList>

              <TabPanel value="0" className='ps-3 pe-0 py-3'>
                <Stack vertical spacing='tight'>
                  <Stack vertical spacing='extraTight'>
                    <TextStyle variation='strong'>Border</TextStyle>
                    <Grid.Row>
                      <Grid.Col span={11}>
                        <SelectField
                          label='Style'
                          name={`${focusIdx}.data.value.global.table.borderStyle`}
                          options={borderStyleOptions}
                          allowClear
                        />
                      </Grid.Col>
                      <Grid.Col offset={1} span={11}>
                        <ColorPickerField
                          label='Color'
                          name={`${focusIdx}.data.value.global.table.borderColor`}
                        />
                      </Grid.Col>
                    </Grid.Row>

                    <MultiPixel
                      label="Border Size (px)"
                      title="Remove all border"
                      nameTop={`${focusIdx}.data.value.global.table.borderTopSize`}
                      nameRight={`${focusIdx}.data.value.global.table.borderRightSize`}
                      nameBottom={`${focusIdx}.data.value.global.table.borderBottomSize`}
                      nameLeft={`${focusIdx}.data.value.global.table.borderLeftSize`}
                    />

                  </Stack>
                  <hr style={{ width: '96%' }} />
                </Stack>
              </TabPanel>

              <TabPanel value="1" className='ps-3 pe-0 py-3'>
                <TableOptions settings={`${focusIdx}.data.value.global.header`} showHeight />
              </TabPanel>

              <TabPanel value="2" className='ps-3 pe-0 py-3'>
                <TableOptions settings={`${focusIdx}.data.value.global.body`} showEvenOdd showHeight />
              </TabPanel>

              <TabPanel value="3" className='ps-3 pe-0 py-3'>
                <TableOptions settings={`${focusIdx}.data.value.global.footer`} showHeight />
              </TabPanel>

            </TabContext>
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={active === 'columns' && expanded} onChange={() => onChangeAccordion('columns')}>
        <AccordionSummary>Columns</AccordionSummary>
        <AccordionDetails>
          <Stack vertical spacing='tight'>
            <EditTabField
              tabPosition='top'
              labelTab='Column'
              name={`${focusIdx}.data.value.columns`}
              additionItem={cloneDeep(STATIC_TABLE_DEFAULT_COLUMN)}
              renderItem={(_item, index) => (
                <>
                  <TabContext value={selectedTab}>
                    <TabList onChange={(_e, value) => setSelectedTab(value)}>
                      <Tab label="Data" value="0" style={{ minWidth: 45, padding: '12px 12px' }} />
                      <Tab label="Header" value="1" style={{ minWidth: 45, padding: '12px 12px' }} />
                      <Tab label="Body" value="2" style={{ minWidth: 45, padding: '12px 12px' }} />
                      <Tab label="Footer" value="3" style={{ minWidth: 45, padding: '12px 12px' }} />
                    </TabList>

                    <TabPanel value="0" className='p-0 pb-3'>
                      <Stack
                        vertical
                        spacing='tight'>
                        <Grid.Row>
                          <Grid.Col span={23}>
                            <TextAreaField label='Header'
                              name={`${focusIdx}.data.value.columns.[${index}].data.header`}
                              className="textarea-no-resize" rows={2}
                              onFocus={setFocusedInput}
                              debounceTime={debounceTime}
                            />
                          </Grid.Col>
                        </Grid.Row>

                        <Grid.Row>
                          <Space align='center'>
                            <TextStyle>Body</TextStyle>
                          </Space>
                        </Grid.Row>

                        {
                          [...Array(Number(get(values, `${focusIdx}.data.value.settings.rows`)))].map((_row, indexRow) => (
                            <Grid.Row key={`static-table-row-${indexRow}`}>
                              <Grid.Col span={23}>
                                <TextAreaField
                                  label={'Cell #' + (indexRow + 1)}
                                  name={`${focusIdx}.data.value.columns.[${index}].data.rows[${indexRow}]`}
                                  className="textarea-no-resize" rows={3}
                                  onFocus={setFocusedInput}
                                  debounceTime={debounceTime}
                                />
                              </Grid.Col>
                            </Grid.Row>
                          ))
                        }

                        <Grid.Row>
                          <Grid.Col span={23}>
                            <TextAreaField label='Footer'
                              name={`${focusIdx}.data.value.columns.[${index}].data.footer`}
                              className="textarea-no-resize" rows={3}
                              onFocus={setFocusedInput}
                              debounceTime={debounceTime}
                            />
                          </Grid.Col>
                        </Grid.Row>
                      </Stack>
                    </TabPanel>

                    <TabPanel value="1" className='p-0 pb-3'>
                      <TableOptions settings={`${focusIdx}.data.value.columns.[${index}].settings.header`} showWidth showColspan />
                    </TabPanel>

                    <TabPanel value="2" className='p-0 pb-3'>
                      <TableOptions settings={`${focusIdx}.data.value.columns.[${index}].settings.body`} showColspan />
                    </TabPanel>

                    <TabPanel value="3" className='p-0 pb-3'>
                      <TableOptions settings={`${focusIdx}.data.value.columns.[${index}].settings.footer`} showColspan />
                    </TabPanel>

                  </TabContext>

                </>
              )}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={active === 'themes' && expanded} onChange={() => onChangeAccordion('themes')}>
        <AccordionSummary>Themes</AccordionSummary>
        <AccordionDetails className='p-4'>
          <Button variant="contained" className="mb-4" fullWidth endIcon={<UndoIcon />}
            onClick={() => onThemeSelected(THEMES.THEME_DEFAULT)}>Reset Theme</Button>

          <img className='image-clickable' src={ThemeBlue} onClick={() => onThemeSelected(THEMES.THEME_BLUE)} />
          <img className='image-clickable' src={ThemeBlueDark} onClick={() => onThemeSelected(THEMES.THEME_BLUE_DARK)} />
        </AccordionDetails>
      </Accordion>

    </AttributesPanelWrapper >

  );
}
