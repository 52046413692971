import React from 'react';
import { withRouter } from 'react-router-dom';

import * as moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import MobileDataList from '../MobileDataList';

import '../../Style/MobileView.css';

import AddApiService from '../../Services/addApiService';
import authService from '../../Services/authServices';
import Header from '../Header';
import LeftMenu from '../LeftMenu';
import LoginHistoryList from '../LoginHistoryList';
// import { Tab, Tabs } from 'react-bootstrap';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EmailerHistoryList from '../EmailerHistoryList';
import SideBar from './SideBar';
import TopBar from './TopBar';
import AnimatedBody from '../../Wrappers/AnimatedBody';

const MySwal = withReactContent(Swal);

class ReportsView extends React.Component {
  constructor(props) {
    super(props);
    this.addApiService = new AddApiService();
    this.authService = new authService();
    this.state = {
      selectedCustomer: "", // Selected Customer from Dropdown. [temp]
      users: [],            // Users List according to Customer ID.
      // customers: [],        // Customer List.
      selectedUser: [],     // Selected User from checkbox.
      message: "",           // Message to send each User which one was seleted. 
      errors: "",
      customerId: null,
      value: 0

    }
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  // On load
  componentDidMount() {
    // Get All Customer
    // this.getAllCustomer();


    // Redirect
    if (!this.authService.getToken('token')) {
      this.props.history.push('/Login');
    }
    // else {
    // Get All Users
    //   this.getAllUsers();
    // }
  }


  handleTabChange(e, newValue) {
    debugger
    
    this.setState({
      value: newValue
    })
  }
  // Selected Customer
  selectedCustomer(custId) {

    this.setState({
      customerId: custId
    });

    // Get selected customer details by customer ID
    this.getUserByCustomerId(custId);
  }



  // Render
  render() {
    return (
      <div className="custom-box">
        <SideBar sideBarExpanded={this.props.sideBarExpanded}
          setSideBarExpanded={this.props.setSideBarExpanded} />
        <div id="main">
          <AnimatedBody
            sideBarExpanded={this.props.sideBarExpanded}
            setSideBarExpanded={this.props.setSideBarExpanded}
            className="container-fluid custom-container"
          >
            <div className="container-fluid custom-container">
              <TopBar title={"Reports"} />
              <div className="row  row-margin">
                <Tabs
                  value={this.state.value}
                  onChange={this.handleTabChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab value={0} label="Login History" />
                  <Tab value={1} label="Emailer History" />
                </Tabs>
                {this.state.value === 0 ? <LoginHistoryList /> : <EmailerHistoryList />}
                {/* <Tabs className="text-left email padding-left-10px-not-important background-color-white" defaultActiveKey="loginHistory" id="emailHistoryTabs">
                  <Tab eventKey="loginHistory" title="Login History" className='background-color-white'>
                    <LoginHistoryList />
                  </Tab>
                  <Tab eventKey="emailerHistory" title="Emailer History" className='background-color-white'>
                    <EmailerHistoryList />
                  </Tab>
                </Tabs> */}

              </div>
            </div>
          </AnimatedBody>
        </div>

      </div>


    );
  }
}

export default withRouter(ReportsView)