import React from "react";
import * as moment from "moment";

export default class Date extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "month",
      
    };
  }

  getDateRange = () => {
    const { selectedTab } = this.state;
    const stateDate = moment().format("MMM-DD");
    const endDate = moment();
    this.props.onStatChange(this.state.selectedTab);
    if (selectedTab === "month") {
      return `${endDate.subtract(30, "days").format("MMM-DD")} - ${stateDate}`;
    } else if (selectedTab === "week") {
      return `${endDate.subtract(7, "days").format("MMM-DD")} - ${stateDate}`;
    } else {
      return stateDate;
    }

   
  };

  render() {
    const { selectedTab } = this.state;
    return (
      <div className="col-12">
        <div className="display-date">
          <span
            className={`t-border date ${selectedTab === "day" ? "active" : ""}`}
            style={{ borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
            onClick={() => this.setState({ selectedTab: "day" })}
          >
            Day
          </span>
          <span
            className={`t-border date ${
              selectedTab === "week" ? "active" : ""
            }`}
            style={{ borderRightWidth: 0, borderLeftWidth: 0 }}
            onClick={() => this.setState({ selectedTab: "week" })}
          >
            Week
          </span>
          <span
            className={`t-border date ${
              selectedTab === "month" ? "active" : ""
            }`}
            style={{
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
            onClick={() => this.setState({ selectedTab: "month" })}
          >
            Month
          </span>
        </div>
        <span className="date" style={{ marginLeft: "20px" }}>
          {this.getDateRange()}
        </span>
      </div>
    );
  }
}
