import * as React from "react";
import { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import SideBar from "./SideBar";
import authService from '../../Services/authServices'
import PredefinedTemplateService from "../../Services/PredefinedTemplateService";
import AnimatedBody from "../../Wrappers/AnimatedBody";

import { Breadcrumbs, FormControlLabel, Link, Switch, Tab, TextField, Typography } from '@mui/material';

import Swal from 'sweetalert2';

import { TabContext, TabList, TabPanel } from "@mui/lab";
import Designer from "./Designer";
import TemplateDataExtractors from "./TemplateDataExtractors";
import TemplateParameters from "./TemplateParameters";
import TemplateFilters from "./TemplateFilters";

export const PREDEFINED_ROUTE = "/PredefinedTemplate/";
export const NON_EXISTENT_CUSTOMER_ID = -1; // FIXME: Juanda: get customerId from SUPERADMIN??

function PredefinedTemplate(props) {

  let isValidUser = true;

  const predefinedTemplateService = new PredefinedTemplateService();
  const myServiceInstance = new authService();

  const templateId = props.match.params.id;
  
  // Validating if user was logged in
  const loggedInCustomer = myServiceInstance.getCurrentCustomer();
  if (!loggedInCustomer?.SUPERADMIN) {
    isValidUser = false;
    props.history.push("/Login");
  }

  const [preSelectedTab] = useState(props.location.preSelectedTab);
  const [selectedTab, setSelectedTab] = useState(preSelectedTab ? preSelectedTab : "0");

  const [validForm, setValidForm] = useState(true);
  const [errors, setErrors] = useState({});


  const [name, setName] = useState('');
  const [subject, setSubject] = useState('{{subject}}');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isValidUser && templateId !== undefined) {
      predefinedTemplateService.findById(templateId).then(response => {
        if (response.status !== 200) {
          errorMessage(!response.data.message ? 'No template found' : response.data.message);
          return;
        }
        setName(response.data.name || '');
        setSubject(response.data.subject || '');
        setDescription(response.data.description || '');
        setIsActive(response.data.active);
      });
    }
  }, []);

  function errorMessage(message) {
    Swal.fire({
      title: message,
      icon: 'error',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        props.history.push({
          pathname: "/PredefinedTemplates",
        });
      }
    });
  }

  function save() {

    validate("name", name, true, false, false);
    validate("subject", subject, true, false, false);
    const valid = validate("description", description, true, false, false);

    if (!valid) {
      return;
    }

    const request = {
      name,
      subject,
      description,
      active: isActive
    }

    if (!templateId) {
      Swal.fire({
        title: 'Ready to save?',
        showCancelButton: true,
        confirmButtonText: 'Save',
        confirmButtonColor: '#ed7d31',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return predefinedTemplateService.save(request)
            .then(({ data }) => {
              props.history.push({
                pathname: "/PredefinedTemplate/" + data
              });

              Swal.fire({
                icon: 'success',
                title: 'Predefined Template Created',
                showConfirmButton: false,
                timer: 1500
              });
            })
            .catch(() => {
              Swal.showValidationMessage(`Failed to update the template.`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });

    } else {
      Swal.fire({
        title: 'Ready to save?',
        showCancelButton: true,
        confirmButtonText: 'Save',
        confirmButtonColor: '#ed7d31',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return predefinedTemplateService.update(templateId, request)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Predefined Template Updated',
                showConfirmButton: false,
                timer: 1500
              });
            })
            .catch(() => {
              Swal.showValidationMessage(`Failed to update the template.`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    }
  }

  function validate(field, value, isRequired, isLettersOnly, isNumericOnly) {
    let vErrors = errors ?? {};

    if (isRequired && !value) {
      vErrors[field] = "This field is required";
    } else if (isLettersOnly && value && !value.match(/^[a-zA-Z]+(\.[a-zA-Z]+)*$/)) {
      vErrors[field] = "Letters only";
    } else if (isNumericOnly && value && typeof value === "string" && !value.match(/^\d+$/)) {
      vErrors[field] = "Numeric only";
    } else {
      delete vErrors[field];
    }

    if (Object.keys(vErrors).length) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }

    setErrors(vErrors);
    return !Object.keys(vErrors).length;
  }

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <SideBar
        sideBarExpanded={props.sideBarExpanded}
        setSideBarExpanded={props.setSideBarExpanded}
      />
      <div id="main">
        <div className="container-fluid custom-container">

          <AnimatedBody
            sideBarExpanded={props.sideBarExpanded}
            setSideBarExpanded={props.setSideBarExpanded}
            className="container-fluid custom-container"
          >
            <div className="row row-margin">
              <Breadcrumbs className="pb-3" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="#"
                  onClick={() => props.history.push({
                    pathname: "/PredefinedTemplates"
                  })}>Templates</Link>
                <Typography color="text.primary">{templateId ? name : 'Create new predefined template'}</Typography>
              </Breadcrumbs>

              <TabContext value={selectedTab}>
                <TabList onChange={(e, value) => setSelectedTab(value)}>
                  <Tab label="Properties" value="0" />
                  <Tab label="Data Extractors" value="1" disabled={!templateId} />
                  <Tab label="Parameters" value="2" disabled={!templateId} />
                  <Tab label="Filters" value="3" disabled={!templateId} />
                  <Tab label="Designer" value="4" disabled={!templateId} />
                </TabList>
                <TabPanel value="0">
                  <form>
                    <div className="row">
                      {
                        templateId &&
                        <>
                          <div className="col-10">
                            <TextField variant="outlined" label="Template Id" margin="normal" size="small" fullWidth
                              value={templateId} disabled />
                          </div>

                          <div className="col-2">
                            <FormControlLabel className="left-aligned" label="Publish" labelPlacement="top"
                              control={<Switch className="switch-primary"
                                checked={isActive}
                                onChange={(e) => { setIsActive(e.target.checked) }} />} />
                          </div>
                        </>
                      }

                      <div className="col-6">
                        <TextField variant="outlined" label="Template Name" margin="normal" size="small" fullWidth
                          required
                          value={name}
                          onChange={(e) => { validate("name", e.target.value, true, false, false); setName(e.target.value) }}
                          error={errors["name"] ? true : false}
                          helperText={errors["name"] ? errors["name"] : ""} />
                      </div>
                      <div className="col-6">
                        <TextField variant="outlined" label="Template Subject" margin="normal" size="small" fullWidth
                          required
                          value={subject}
                          onChange={(e) => { validate("subject", e.target.value, true, false, false); setSubject(e.target.value) }}
                          error={errors["subject"] ? true : false}
                          helperText={errors["subject"] ? errors["subject"] : ""} />
                      </div>
                      
                      <div className="col-12">
                        <TextField multiline rows={4} variant="outlined" label="Description" margin="normal" size="small" fullWidth
                          required
                          value={description}
                          onChange={(e) => { validate("description", e.target.value, true, false, false); setDescription(e.target.value) }}
                          error={errors["description"] ? true : false}
                          helperText={errors["description"] ? errors["description"] : ""} />
                      </div>
                    </div>

                    <div className="mt-3">
                      <button type="button" style={{ marginTop: 0 }} className="btn btn-submit"
                        onClick={() => save()}
                        disabled={!validForm}>Save</button>

                      <button type="button" style={{ marginLeft: 5 }} className="btn btn-cancel"
                        onClick={() => props.history.push({
                          pathname: "/PredefinedTemplates"
                        })}>Cancel</button>
                    </div>
                  </form>
                </TabPanel>

                <TabPanel value="1">
                  <TemplateDataExtractors templateId={templateId} predefinedTemplate />
                </TabPanel>
                <TabPanel value="2">
                  <TemplateParameters templateId={templateId} />
                </TabPanel>
                <TabPanel value="3">
                  <TemplateFilters templateId={templateId} />
                </TabPanel>
                <TabPanel value="4">
                  <Designer templateId={templateId} predefinedTemplate />
                </TabPanel>
              </TabContext>
            </div>

          </AnimatedBody>
        </div>
      </div >
    </div >
  );
}

export default withRouter(PredefinedTemplate);
