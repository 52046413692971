import axios from 'axios';
import { environment } from '../environment';

class EmailerService {

  //send email from edit reciepients
  checkStatus = (customerId) => {
    return axios.get(environment.API_URL + `email/status/${customerId}`)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      })
  }
  
  stop = (customerId, id) => {
    return axios.post(environment.API_URL + `email/stop/${customerId}/${id}`)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      })
  }

}

export default EmailerService;