import axios from 'axios';
import { environment } from '../environment';

class AddApiService {

  // Get All Customer
  getAllCustomer = (fetchData) => {
    return axios.get(environment.API_URL + `customer/allCustomer${fetchData ? '?fetchData=' + fetchData : ''}`)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }
  
  getCustomer = (customerId, fetchStorageInfo = false) => {
    const config = {
      headers: {
        'userId': JSON.parse(sessionStorage.getItem('currentCustomer')).id
      }
    };
    return axios.get(environment.API_URL + `customer?id=${customerId}&fetchStorageInfo=${fetchStorageInfo}`, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  
  // Save API
  createCustomer = (dataApi) => {
    return axios.post(environment.API_URL + 'customer', dataApi)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  // Save API
  updateCustomer = (dataApi) => {
    return axios.put(environment.API_URL + 'customer', dataApi)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }
  
  // Upload Logo
  uploadCustomerLogo = (customerId, file) => {
    const formData = new FormData();
    formData.append("customerId", customerId);
    formData.append("file", file);
    return axios.post(environment.API_URL + 'customer/upload', formData)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  // Get All Customer Details     
  getAllCustomerDetail = () => {
    return axios.get(environment.API_URL + 'customUrl/allCustomUrls')
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  // Get Customer URL By Customer ID
  getCustomerURLById = (customer) => {
    // return axios.get(environment.API_URL + `customUrl?customer=${customer}`)customUrl?customerId=23
    return axios.get(environment.API_URL + `customUrl?customerId=${customer}`)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  // Save API
  saveAPI = (dataApi) => {
    return axios.post(environment.API_URL + 'customUrl', dataApi)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  // Update Customer By Customer Id
  updateCustomerById = (customer) => {
    // return axios.put(environment.API_URL + 'customUrl', customer)customUrl?id=29
    return axios.put(environment.API_URL + `customUrl`, customer)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })  
  }

  // Delete Customer By Customer ID
  deleteCustomerById = (id) => {
    return axios.delete(environment.API_URL + `customUrl?id=${id}`)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  // Get All Devices
  // getAllDevice = () => {
  //   return axios.get(environment.API_URL + 'device')
  //     .then(function (response) {
  //       console.log('Response: ', response);
  //       return response.data;
  //     })
  //     .catch(function (error) {
  //       console.log('Errorss:', error);
  //     })
  // }

  // Get All Users
  getAllUsers = () => {
    return axios.get(environment.API_URL + 'device/allDevices')
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

// Get User Details by Custpmer ID
getUserByCustomerId = (user) => {
  return axios.get(environment.API_URL + `device/deviceByCustomerId?CustomerId=${user}`)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    })
}

// Send Push Message
postPushMessage = (userObject) => {
  console.log('UserObject: ', userObject);
  return axios.post(environment.API_URL + `device/test`, userObject)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
  })
}

//emailer stats api according to day,month,week
emailStatValue = (selector, customerId) => {
  return axios.get(environment.API_URL + `email/stats?duration=${selector}`, {headers:{
    'customerId': JSON.parse(sessionStorage.getItem('currentCustomer')).customerId ? JSON.parse(sessionStorage.getItem('currentCustomer')).customerId: customerId
  }})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
  })
}

// Get All Email Templates
getEmailTemplates = (customerid, isActive) => {
  // return axios.get(environment.API_URL + 'email/singlesends') not working now
  // return axios.get(environment.API_URL + 'email/templates')
  const active = `${isActive !== undefined ? '&is_active=' + isActive : ''}`;
  return axios.get(environment.API_URL + `email/templates?customer_id=${customerid}${active}`)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    })
}

// Get users according to the email template
getTemplateUser = (templateId) => {
  return axios.get(environment.API_URL + `email/recipient?template_id=${templateId}`)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
  })
}

//get user with user id
// getUserOfTemplate = (userId) => {
//   return axios.get(environment.API_URL + `user?userId=${userId}`)
//   .then(function (response) {
//     return response.data;
//   })
//   .catch(function (error) {
//     console.log(error);
//   })
// }

//all user related to a customer Id
getAllUser = (customerId) => {
  return axios.get(environment.API_URL + `user?customerId=${customerId}`)
  .then(function (response) {
    return response.data;
  })
  .catch(function(error) {
    console.log(error);
  })
}

//add user api post api
postUser = (templateId, body) => {
  return axios.post(environment.API_URL + `email/recipient?template_id=${templateId}`, body)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
  })
}

// delete template user
deleteUser = (templateId, body) => {
  return axios.delete(environment.API_URL + `email/recipient?template_id=${templateId}`, {data: body})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
  })
}


//add User in emailer tab api
addUserEmailer = (userName, customerId) => {
  return axios.post(environment.API_URL + `user?username=${userName}&customerId=${customerId}`)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
  })
}

//send email from edit reciepients
sendEmail = (templateId, body) => {
  return axios.post(environment.API_URL + `email/${templateId}/app/send`, body)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
  })
}

//send email from edit reciepients
genericSendEmail = (body) => {
  return axios.post(environment.API_URL + `email/send`, body)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
  })
}

//send email from edit reciepients
testApis = (body) => {
  return axios.post(environment.API_URL + `email/test`, body)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
  })
}

sendApplicationEmail = (templateId, month, body) => {
  
  return axios.post(environment.API_URL + `email/${templateId}/month/${month}/send`, body)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
  })
}

}
export default AddApiService;