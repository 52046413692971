import React from 'react';
// import { Table } from 'react-bootstrap';
import ReportService from '../Services/ReportService';

import '../Style/MobileDataList.css';
import * as moment from 'moment-timezone';
import MyPagination from './MyPagination';
import * as Loader from 'react-loader-spinner';
import TextTruncate from 'react-text-truncate';


import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import SearchBar from "material-ui-search-bar";



import PropTypes from 'prop-types';
import TablePagination from '@mui/material/TablePagination';
import { Pagination } from 'react-bootstrap';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ff7a00",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  // const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const gotoPage = (event) => {
    onPageChange(event, Number(event.target.innerText));
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };


  return (
    <div className="d-flex p-2 bd-highlight" >
      <Pagination >
        <Pagination.First onClick={handleFirstPageButtonClick} disabled={page === 0} />
        <Pagination.Prev onClick={handleBackButtonClick} disabled={page === 0} />



        {/* {createPages()} */}



        <Pagination.Next onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} />
        <Pagination.Last onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} />
      </Pagination >
    </div >





  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

class LoginHistoryList extends React.Component {

  constructor(props) {
    super(props);
    this.reportService = new ReportService();
    this.state = {
      data: [],
      rowsPerPage: 5,
      parsedData: [],
      showList: [],        // login history data
      totalCount: 100,
      page: 0,
      limit: 10,
      totalPages: 10,
      showLoader: true,
      currentPage: null,
      totalPages: null,
      // customer: this.authService.getCurrentCustomer()
    }


    this.cancelSearch = this.cancelSearch.bind(this);
    this.requestSearch = this.requestSearch.bind(this);
    this.search = this.search.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.fetchLoginHistory = this.fetchLoginHistory.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
  }

  componentDidMount() {
    this.fetchLoginHistory();
  }
  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage(event) {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });

  };
  onPageChanged(obj) {

    const { currentPage, totalPages, pageLimit } = obj;
    const offset = (currentPage - 1) * pageLimit;
    let showList = this.state.data.slice(offset, offset + pageLimit);

    this.setState({ currentPage: currentPage, showList: showList, totalPages: totalPages });
  };

  fetchLoginHistory() {

    let parsedData = [];
    this.reportService.fetchLoginHistory().then(response => {

      console.log('LoginHistory: ', response)
      response.forEach((item) => {
        item.loggedTime = moment(item.loggedTime).format('MM/DD/YYYY HH:mm:ss')
        parsedData.push(item);
      })
      this.setState({
        data: parsedData,
        parsedData: parsedData,
        showLoader: false,
        totalCount: parsedData.length
      })

    }).catch((error) => {
      console.log('ERROR: ', error);
    });

    console.log('ScheduledEamilerAfterConversion', this.state.data)
  };

  search(searchValue) {
    this.setState(prevState => ({
      showLoader: true,

    }))

    this.requestSearch(searchValue);

    this.setState(prevState => ({
      showLoader: false
    }))
  }

  requestSearch(searchedVal) {


    let filteredRows = this.state.parsedData.filter((row) => {
      return row.name?.toLowerCase().includes(searchedVal.toLowerCase()) || row.ip?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.action?.toLowerCase().includes(searchedVal.toLowerCase()) || row.loggedTime?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.status?.toLowerCase().includes(searchedVal.toLowerCase());
    });

    this.setState(prevState => ({
      data: filteredRows,
      totalCount: filteredRows.length,
    }))
  };

  cancelSearch() {
    this.setState({
      data: this.state.parsedData,
      showLoader: false
    })
  };



  render() {
    let applicationsDropDown = null;
    if (this.state.showLoader) {

      applicationsDropDown = (

        <div className="position-absolute top-50 start-50">



          <Loader.Audio
            type="Bars"
            color="#ed7d31"
            height={50}
            width={50}
          />

        </div>


      );
    } else {
      applicationsDropDown = (
        <div>
          <div className='padding-bottom-10px padding-top-10px'>
            <SearchBar
              variant="filled"
              onChange={(searchVal) => this.search(searchVal)}
              onCancelSearch={() => this.cancelSearch()}
            />
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>User Name</StyledTableCell>
                  <StyledTableCell align="left">IP Address</StyledTableCell>
                  <StyledTableCell align="left">Action</StyledTableCell>
                  <StyledTableCell align="left">Date Time</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(this.state.rowsPerPage > 0
                  ? this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  : this.data
                ).length > 0 ? (this.state.rowsPerPage > 0
                  ? this.state.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  : this.state.data
                ).map((user, index) => (
                  // {this.state.showList.length > 0 ? this.state.showList.map((user) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{user.user}</StyledTableCell>
                    <StyledTableCell align="left">{user.ip}</StyledTableCell>
                    <StyledTableCell align="left">{user.action}</StyledTableCell>
                    <StyledTableCell align="left">{user.loggedTime}</StyledTableCell>
                    <StyledTableCell align="left">{user.status}</StyledTableCell>
                  </StyledTableRow>
                )) : "No logs Found"}
              </TableBody>
            </Table>
          </TableContainer>
          
          <div className="row">
            <Table>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={this.state.totalCount}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>


        </div>




      );
    }
    return (
      <div>
        <div>{applicationsDropDown}</div>
      </div>

    );
  }
}

export default LoginHistoryList