import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import del from "../../images/Delete.png";
import AddApiService from '../../Services/addApiService';
import Swal from 'sweetalert2';

export default function DeleteModal(props) {
  const [email, setEmail] = useState('');
  const addApiService = new AddApiService();
  let delIds = [];
  
  delIds.push(props.delEmail);

  function deleteEmailFromTemplate() {
    addApiService.deleteUser(props.template, delIds).then(response => {
      if (response) {
        props.close()

        Swal.fire(
          'Removed!',
          'Recipients have been removed.',
          'success'
        );
      }
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }
  return (
    <Modal show={props.open} onHide={props.close}>
      <Modal.Body>
        <div className="form-group">
          <img
            style={{ display: "block", margin: "auto", width: "auto" }}
            src={del}
          />
          <h6 style={{ textAlign: "center", marginTop: "20px" }}>
            Do you want to remove recipient?
          </h6>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <button
          type="button"
          className="btn btn-cancel"
          data-dismiss="modal"
          onClick={props.close}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-delete"
          data-dismiss="modal"
          onClick={() => deleteEmailFromTemplate()}
          style={{ float: "right", marginTop: "0" }}
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
}
