import axios from 'axios';
import { environment } from '../environment';

class ReportService {

  fetchLoginHistory = () => {
    return axios.get(environment.API_URL + `reports/loginHistory`,{headers:{
      'customerId': JSON.parse(sessionStorage.getItem('currentCustomer')).customerId
    }})
      .then(function (response) {
        console.log(response)
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  fetchEmailerHistory = () => {
    return axios.get(environment.API_URL + `reports/emailerHistory`,{headers:{
      'customerId': JSON.parse(sessionStorage.getItem('currentCustomer')).customerId
    }})
      .then(function (response) {
        console.log(response)
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  fetchEmailerHistoryForReq = (reqId) => {
    return axios.get(environment.API_URL + `reports/emailerHistory/`+reqId,{headers:{
      'customerId': JSON.parse(sessionStorage.getItem('currentCustomer')).customerId
    }})
      .then(function (response) {
        console.log(response)
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }
}

export default ReportService;