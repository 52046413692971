import * as React from "react";
import { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import SideBar from "./SideBar";
import TopBar from './TopBar';
import authService from '../../Services/authServices'
import AnimatedBody from "../../Wrappers/AnimatedBody";

import TemplateComponentService from '../../Services/TemplateComponentService';

import { Button, FormControlLabel, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import Switch from '@mui/material/Switch';

import { useWindowSize } from 'react-use';
import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';
import { BlockManager, BasicType, AdvancedType, JsonToMjml, createCustomBlock, components } from 'easy-email-core';
import { EmailEditor, EmailEditorProvider, Stack } from 'easy-email-editor';
import { StandardLayout } from 'easy-email-extensions';
import { merge } from 'lodash';
import Handlebars from "handlebars";
import mjml from 'mjml-browser';

function TemplateComponent(props) {

  const authServiceInstance = new authService();
  const templateComponentService = new TemplateComponentService();

  // Validating if user was logged in
  const loggedInCustomer = authServiceInstance.getCurrentCustomer();

  const { width } = useWindowSize();
  const smallScene = width < 1400;

  const [templateComponentId, setTemplateComponentId] = useState(props.match.params.id);
  
  const [validForm, setValidForm] = useState(true);
  const [errors, setErrors] = useState({});

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [sourceContent, setSourceContent] = useState('');
  const [newSourceContent, setNewSourceContent] = useState('');
  
  const availableFonts = [
	  'Arial',
	  'Tahoma',
	  'Verdana',
	  'Times New Roman',
	  'Courier New',
	  'Georgia',
	  'Lato',
	  'Montserrat'
  ].map(item => ({ value: item, label: item }));
  
  const [categories, setCategories] = useState([
    {
      label: 'Content',
      active: true,
      blocks: [
        {
          type: AdvancedType.TEXT,
        },
        {
          type: AdvancedType.IMAGE,
          payload: { attributes: { padding: '0px 0px 0px 0px' } },
        },
        {
          type: AdvancedType.BUTTON,
        },
        {
          type: AdvancedType.SOCIAL,
        },
        {
          type: AdvancedType.DIVIDER,
        },
        {
          type: AdvancedType.SPACER,
        },
        {
          type: AdvancedType.NAVBAR,
        },
        {
          type: AdvancedType.HERO,
        },
        {
          type: AdvancedType.WRAPPER,
        },
        {
          type: AdvancedType.ACCORDION,
        },
        {
          type: AdvancedType.CAROUSEL,
        },
        {
          type: AdvancedType.GROUP,
        },
        {
          type: AdvancedType.SECTION,
        },
        {
          type: AdvancedType.COLUMN,
        },
      ],
    },
    {
      label: 'Layout',
      active: true,
      displayType: 'column',
      blocks: [
        {
          title: '2 columns',
          payload: [
            ['50%', '50%'],
            ['33%', '67%'],
            ['67%', '33%'],
            ['25%', '75%'],
            ['75%', '25%'],
          ],
        },
        {
          title: '3 columns',
          payload: [
            ['33.33%', '33.33%', '33.33%'],
            ['25%', '25%', '50%'],
            ['50%', '25%', '25%'],
          ],
        },
        {
          title: '4 columns',
          payload: [[['25%', '25%', '25%', '25%']]],
        },
      ],
    },
  ]);
  
  const initialValues = {
    subject: 'RegoEngage Component Designer',
    subTitle: 'This is my first component',
    content: (sourceContent === undefined || sourceContent === '') ? BlockManager.getBlockByType(BasicType.PAGE).create({}) : sourceContent,
  };
  
  function exportToHTML(values) {
    const html = mjml(JsonToMjml({
      data: values.content,
      mode: 'production',
      context: values.content
    }), {
      validationLevel: 'soft',
    }).html;

    console.log(html);
  }

  function saveDesign(values) {
    setNewSourceContent(JSON.stringify(values.content));
  }

  function loadDesign() {
	setSourceContent(newSourceContent);
  }

  function onBeforePreview(html, mergeTags) {
    const template = Handlebars.compile(html);
    return template(mergeTags);
  }
  
  useEffect(() => {
	if (templateComponentId !== undefined) {
      templateComponentService.getTemplateComponent(templateComponentId).then(response => {
        if (!response) {
          loggedInCustomer?.SUPERADMIN ?
            props.history.push({ pathname: "/TemplateComponents" }) :
            props.history.push({ pathname: "/TemplateComponents" });
          return;
        }
        setName(response.data.name || '');
        setIsActive(response.data.isActive || false);
        setDescription(response.data.description || '');
        setSourceContent((response.data.sourceContent === undefined || response.data.sourceContent === '') ? '' : JSON.parse(response.data.sourceContent));
        setNewSourceContent((response.data.sourceContent === undefined || response.data.sourceContent === '') ? '' : response.data.sourceContent);
      });
    }
  }, []);
  
  function save() {

    validate("name", name, true, true, false);
    
    const request = {
	  id: templateComponentId !== undefined ? templateComponentId : null,
	  name,
	  customer: {
		id: 3
	  },
	  isActive,
	  description,
	  sourceContent: newSourceContent,
	  icon: 'https://media.flaticon.com/dist/min/img/logo/flaticon_negative.svg'
    }

    Swal.fire({
      title: 'Ready to save?',
      showCancelButton: true,
      confirmButtonText: 'Save',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (!templateComponentId) {
          return templateComponentService.createTemplateComponent(request)
            .then(response => {
              if (!response.data.statusCode == 200) {
                throw new Error(response.data.statusText)
              }

              setTemplateComponentId(response.data.id);
              props.history.push({
                pathname: "/TemplateComponent/" + response.data.id,
              });

              Swal.fire({
                icon: 'success',
                title: 'Template Component Created',
                showConfirmButton: false,
                timer: 1500
              })
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Failed to create the Template Component.`
              )
            });
        } else {
          return templateComponentService.updateTemplateComponent(request)
            .then(response => {
              if (!response.data.statusCode == 200) {
                throw new Error(response.data.statusText)
              }

              Swal.fire({
                icon: 'success',
                title: 'Template Component Updated',
                showConfirmButton: false,
                timer: 1500
              })
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Failed to update the Template Component.`
              )
            });
        }

      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {

    });

  }
  
  function validate(field, value, isRequired, isLettersOnly, isNumericOnly) {
    let vErrors = errors ?? {};

    if (isRequired && !value) {
      vErrors[field] = "This field is required";
    } else if (isLettersOnly && value && !value.match(/^[a-zA-Z]+([\w\s\-\(\)\.\,])*$/)) {
      vErrors[field] = "Letters only";
    } else if (isNumericOnly && value && typeof value === "string" && !value.match(/^\d+$/)) {
      vErrors[field] = "Numeric only";
    } else {
      delete vErrors[field];
    }

    if (Object.keys(vErrors).length) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }

    setErrors(vErrors);
    return !Object.keys(vErrors).length;
  }
  
  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <SideBar
        sideBarExpanded={props.sideBarExpanded}
        setSideBarExpanded={props.setSideBarExpanded}
      />
      <div id="main">
        <div className="container-fluid custom-container">

          <AnimatedBody
            sideBarExpanded={props.sideBarExpanded}
            setSideBarExpanded={props.setSideBarExpanded}
            className="container-fluid custom-container"
          >
            <TopBar title={"Template Components"} />
            <div className="row row-margin">
                <form>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <TextField variant="outlined" label="Name" margin="normal" size="small" fullWidth
                        required
                        value={name}
                        onChange={(e) => { validate("name", e.target.value, true, true, false); setName(e.target.value) }}
                        error={errors["name"] ? true : false}
                        helperText={errors["name"] ? errors["name"] : ""} />
                    </div>

                    <div className="col-12 col-md-6">
                      <FormControlLabel className="left-aligned" label="Is Active" labelPlacement="top"
                            control={<Switch className="switch-primary"
                              checked={isActive}
                              onChange={(e) => { setIsActive(e.target.checked) }} />} />
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <TextField variant="outlined" label="Description" margin="normal" size="small" fullWidth
                        multiline minRows={4} maxRows={10}
                        value={description}
                        onChange={(e) => { setDescription(e.target.value) }}
                        error={errors["description"] ? true : false}
                        helperText={errors["description"] ? errors["description"] : ""} />
                    </div>
                  </div>
                  
                  <div className="row row-margin row-left">
		              <EmailEditorProvider
		                data={initialValues}
		                height={'calc(100vh - 72px)'}
		                autoComplete
		                dashed={false}
		                onSubmit={(values) => saveDesign(values)}
		                fontList={availableFonts}
		                onBeforePreview={(html, mergeTags) => onBeforePreview(html, mergeTags)}
		              >
		                {({ values }, { submit }) => {
		                  return (
		                    <>

			                  <Stack alignment="center">
			                    <Button type='button' onClick={() => exportToHTML(values)}>
			                      Copy Html
			                    </Button>
			                    <Button
			                      type='button'
			                      onClick={() => submit()}
			                    >
			                      Accept changes
			                    </Button>
			                  </Stack>
				              
		                      <StandardLayout
		                        compact={!smallScene}
		                        showSourceCode={true}
		                        categories={categories}
		                      >
		                        <EmailEditor />
		                      </StandardLayout>
		                    </>
		                  );
		                }}
		              </EmailEditorProvider>
		          </div>

                  <div style={{ marginLeft: '0px' }}>
                    <button type="button" style={{ marginTop: '0px' }} className="btn btn-submit"
                      onClick={() => save()}
                      disabled={!validForm}>Save</button>

                    {loggedInCustomer?.SUPERADMIN ?
                      <button type="button" style={{ marginLeft: '5px' }} className="btn btn-cancel"
                        onClick={() => props.history.push({ pathname: "/TemplateComponents" })}>Cancel</button>
                      : <div> </div>
                    }
                  </div>
                </form>
            </div>



          </AnimatedBody>
        </div>
      </div>
    </div>
  );	
}

export default withRouter(TemplateComponent);