import * as React from "react";
import { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

import TemplateParameterService from "../../Services/TemplateParameterService";
import { Form, Modal } from "react-bootstrap";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

function TemplateParameters(props) {

  const templateParameterService = new TemplateParameterService();

  const [templateId, setTemplateId] = useState(props.templateId);

  const [parameters, setParameters] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [errors, setErrors] = useState({ code: null, value: null });

  const [id, setId] = useState('');
  const [code, setCode] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    findTemplateParameters();
  }, []);

  function findTemplateParameters() {
    templateParameterService.findAll(props.templateId).then(response => {
      setParameters(response.data);
    });
  }

  function closeModal() {
    findTemplateParameters();
    setShowModal(false);
  }

  function validate(field, value, isRequired) {
    let vErrors = errors ?? {};

    if (isRequired && !value) {
      vErrors[field] = "This field is required";
    } else {
      delete vErrors[field];
    }

    if (Object.keys(vErrors).length) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }

    setErrors(vErrors);
    return !Object.keys(vErrors).length;
  }

  function edit(param) {
    setId(param.id);
    setCode(param.code);
    setValue(param.value);
    setShowModal(true);

    validate("code", param.code, true);
    validate("value", param.value, true);
  }

  function save() {
    validate("code", code, true);
    const valid = validate("value", value, true);

    if (!valid) {
      return;
    }

    const request = {
      code,
      value,
      templateId
    };

    if (!id) {
      templateParameterService.save(props.templateId, request).then(() => {
        resetForm();
        setShowModal(false);

        findTemplateParameters();

        Swal.fire({
          icon: 'success',
          title: 'Parameter Added',
          showConfirmButton: false,
          timer: 1500
        });
      }).catch(() => {
        Swal.showValidationMessage(`Failed to save the parameter`);
      });
    } else {
      request.id = id;

      templateParameterService.update(props.templateId, id, request).then(() => {
        resetForm();
        setShowModal(false);

        findTemplateParameters();

        Swal.fire({
          icon: 'success',
          title: 'Parameter Updated',
          showConfirmButton: false,
          timer: 1500
        });
      }).catch(() => {
        Swal.showValidationMessage(`Failed to update the parameter`);
      });
    }
  }

  function deleteParam(param) {
    Swal.fire({
      title: `Do you really want to delete the parameter: <span style="color: red">${param.code}</span>?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        templateParameterService.delete(props.templateId, param.id)
          .then(response => {
            if (!response.statusCode == 200) {
              throw new Error(response.statusText)
            }

            Swal.fire({
              icon: 'success',
              title: 'Parameter Deleted',
              showConfirmButton: false,
              timer: 1500
            });

            findTemplateParameters();
          })
          .catch(() => {
            Swal.showValidationMessage(`Failed to delete the parameter.`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  function resetForm() {
    setValidForm(false);
    setErrors({ code: null, value: null });
    setId('');
    setCode('PARAM_');
    setValue('');
  }

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <div className="row" style={{ paddingLeft: 5 }}>
        <div className="col-12">
          <table style={{ width: "100%", overflow: "scroll !important" }}>
            <thead>
              <tr className="line">
                <th className="line-data" width="40%">Code</th>
                <th className="line-data">Value</th>
                <th className="line-data" width={150} style={{ textAlign: "right" }} >
                  <button type="button" className="btn btn-submit w-100"
                    style={{ marginTop: 0, marginRight: 6 }}
                    onClick={() => { setCode('PARAM_'); setShowModal(true); }}>
                    Add Parameter
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {parameters && parameters.length > 0 ?
                parameters.map((param) => (
                  <tr key={param.id} className="line">
                    <td className="line-data">{param.code}</td>
                    <td className="line-data">{param.value}</td>
                    <td align="right">
                      <button type="button" className="btn btn-light"
                        style={{ marginTop: 0, marginRight: 20 }}
                        onClick={() => edit(param)}
                        title="Edit Parameter">
                        Edit
                      </button>

                      <a href="#" onClick={() => deleteParam(param)} title="Delete Parameter">
                        <FontAwesomeIcon icon={faTrashCan} color="#6c6c6f" />
                      </a>
                    </td>
                  </tr>))
                :
                <tr>
                  <td colSpan={4}>
                    {(parameters && parameters.length === 0) ? "No params found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                  </td>
                </tr>
              }

            </tbody>
          </table>
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Parameter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-6">
                <TextField variant="outlined" label="Code" margin="normal" size="small" fullWidth
                  required
                  value={code}
                  onChange={(e) => {
                    validate("code", e.target.value, true);
                    setCode(e.target.value.toUpperCase());
                  }}
                  error={errors["code"] ? true : false}
                  helperText={errors["code"] ? errors["code"] : ""} />
              </div>

              <div className="col-6">
                <TextField variant="outlined" label="Value" margin="normal" size="small" fullWidth
                  required
                  value={value}
                  onChange={(e) => {
                    validate("value", e.target.value, true);
                    setValue(e.target.value);
                  }}
                  error={errors["value"] ? true : false}
                  helperText={errors["value"] ? errors["value"] : ""} />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-cancel"
            data-dismiss="modal"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-submit"
            data-dismiss="modal"
            onClick={() => save()}
            style={{ float: "right", marginTop: "0" }}
            disabled={!validForm}
          >
            Add Parameter
          </button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default withRouter(TemplateParameters);
