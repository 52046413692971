import React from 'react';
import { Table } from 'react-bootstrap';

import '../Style/MobileDataList.css';

class MobileDataList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedUser: [], // Seleted User By checkbox.
      users: [],        // Get All users from Parent Component and bind with props
      message: ""
    }

  }
 

  // Select All
  selectAll(all) {
    // console.log('Selected All: ', all.target.checked);
    const users = []

    if (all.target.checked) {
      // Select All
      this.state.users.map((user) => {
        user.selected = true;
        users.push(user);
      });
      

      // Set State
      this.setState({ selectedUser: users});

      // Handover to callback
      this.outputCallback(this.state.users);
    } else {
      // UnSelect All
      this.state.users.map((user) => {
        user.selected = false;
      });

      // Set State
      this.setState({ selectedUser: []});
      
      // Handover to callback
      this.outputCallback([]);
    }
  }

  // Selected Users
  selectedUser(isCheck, data) {
    // console.log('Checked:', isCheck.target.checked);
    // console.log('Data:', data);
    let selected = this.state.selectedUser;

    if (isCheck.target.checked) {
      // console.log('Insert');
      // Insert
      this.state.users.map((user) => {
        if (user.deviceId === data.deviceId) {
          user.selected = true
        }
      });
      selected.push(data);
    } else {
      // console.log('Remove: ', this.state.selectedUser);
      // Remove
      this.state.users.map((user) => {
        if (user.deviceId === data.deviceId) {
          user.selected = false;
        }
      });

      selected.filter(fil => fil.deviceId === data.deviceId).map(() => {
        selected.splice(selected.findIndex(index => index.deviceId === data.deviceId), 1);
      });

      // console.log('Seleted:', selected);
      // console.log('Splice:', selected.findIndex(index => index.deviceId == data.deviceId));
      // console.log('All Users:', this.state.users);
      
    }
    this.outputCallback(this.state.selectedUser)
  }
  
  // Output Callback child to parent
  outputCallback = (selectedUser) => {
    this.props.onSelectedUser(selectedUser);            
  }

  render() {
    this.state.users = this.props.users;
    return (
      // <div className="table-responsive scroll-table">
      <div className="padding-left-right-10px">
        <Table class = "table table-striped table-hover">
          <thead>
            <tr>
              <th className="first-col">
                <label className="check-list">
                  <input type="checkbox" onClick={(e) => this.selectAll(e)}/>
                  <span className="checkmark all-check"></span>
                </label>
              </th>
              <th className="first-col">User Name</th>
              <th className="first-col">Customer</th>
              <th className="first-col">Device Arn</th>
              <th className="first-col">Last Updated</th>
              <th className="first-col">Installed Date</th>
            </tr>
          </thead>
          <tbody>
            { this.state.users.length > 0 ? this.state.users.map((user, index) => (
                <tr key={index}>
                  <td>
                    <label className="check-list">
                      <input type="checkbox" onChange={(e) => this.selectedUser(e, user)} checked={user.selected}/>
                      <span className="checkmark"></span>
                    </label>
                  </td>
                  <td>{user.username}</td>
                  <td>{user.customerName}</td>
                  <td>{user.endpointARN}</td>
                  <td>{user.updatedTimestamp}</td>
                  <td>{user.createdTimestamp}</td>
                </tr>
              )) : 
              "No Registered Devices"
              }
          </tbody>
        </Table>
      </div>

    );
  }
}

export default MobileDataList