import * as React from "react";
import { useState, useEffect } from 'react';
import { useParams, withRouter } from "react-router-dom";
import SideBar from "./SideBar";
import TopBar from "./TopBar";
import authService from '../../Services/authServices'
import TemplateService from '../../Services/TemplateService';
import AnimatedBody from "../../Wrappers/AnimatedBody";

import { Col, Container, Modal, Row } from "react-bootstrap";
import { Avatar, Breadcrumbs, Card, CardContent, CardHeader, FormControl, FormControlLabel, InputLabel, Link, MenuItem, Select, Tab, TextField, Typography, Switch, FormHelperText, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ToggleButtonGroup, ToggleButton } from '@mui/material';
import AbcIcon from '@mui/icons-material/Abc';
import PinIcon from '@mui/icons-material/Pin';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import Swal from 'sweetalert2';

import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import Skeleton from "react-loading-skeleton";
import AddApiService from "../../Services/addApiService";
import TemplateDataExtractorService from "../../Services/TemplateDataExtractorService";
import TemplateDataExtractorFieldService from "../../Services/TemplateDataExtractorFieldService";
import TemplateFilterService from '../../Services/TemplateFilterService';

import { faList, faTrashCan, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PredefinedTemplateService from "../../Services/PredefinedTemplateService";
import { NON_EXISTENT_CUSTOMER_ID, PREDEFINED_ROUTE } from "./PredefinedTemplate";

import DatasetIcon from '@mui/icons-material/Dataset';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-pgsql";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

import PreviewDataModal from "./PreviewDataModal";
import { TemplateDataExtractorFields } from "./TemplateDataExtractorFields";

export const SOURCE_TYPE = {
  JSON_REST_API: 'JSON_REST_API',
  STAGING: 'STAGING'
}

export const REQUEST_PARAM_TYPE = {
  QUERY_PARAM: 'QUERY_PARAM',
  PATH_PARAM: 'PATH_PARAM',
  HEADER: 'HEADER'
}

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC'
}

function TemplateDataExtractor(props) {

  let isValidUser = true;

  const addApiService = new AddApiService();
  const myServiceInstance = new authService();
  const templateService = new TemplateService();
  const predefinedTemplateService = new PredefinedTemplateService();
  const templateDataExtractorService = new TemplateDataExtractorService();
  const templateDataExtractorFieldService = new TemplateDataExtractorFieldService();
  const templateFilterService = new TemplateFilterService();

  const { customerId, templateId, extractorId } = useParams();
  const [recordId, setRecordId] = useState(extractorId);
  const [customerName, setCustomerName] = useState('');
  const [templateName, setTemplateName] = useState('');

  const DEFAULT_MAX_ROWS_RAW_DATA = 15;
  const [previewRawData, setPreviewRawData] = useState({ rawData: [], loaded: false });
  const [enablePreviewRawDataButton, setEnablePreviewRawDataButton] = useState(true);
  const [showRequestPreviewRawModal, setShowRequestPreviewRawModal] = useState(false);
  const [maxRowsRawData, setMaxRowsRawData] = useState(DEFAULT_MAX_ROWS_RAW_DATA);
  const [orderByCodeRawData, setOrderByCodeRawData] = useState('');

  const DEFAULT_MAX_ROWS_FORMATTED_DATA = 25;
  const [previewFormattedData, setPreviewFormattedData] = useState({ data: { records: [] }, loaded: false });
  const [enablePreviewFormattedDataButton, setEnablePreviewFormattedDataButton] = useState(true);
  const [showRequestPreviewFormattedModal, setShowRequestPreviewFormattedModal] = useState(false);
  const [maxRowsFormattedData, setMaxRowsFormattedData] = useState(DEFAULT_MAX_ROWS_FORMATTED_DATA);
  const [orderByCodeFormattedData, setOrderByCodeFormattedData] = useState('');

  const [enableGenerateFieldsButton, setEnableGenerateFieldsButton] = useState(true);
  const [enableCloneFieldButton, setEnableCloneFieldButton] = useState(true);

  const isPredefinedTemplate = !customerId || customerId === undefined;
  // Validating if user was logged in
  const loggedInCustomer = myServiceInstance.getCurrentCustomer();

  if (!loggedInCustomer?.SUPERADMIN
    && (!customerId
      || !loggedInCustomer?.admin
      || (customerId !== (loggedInCustomer?.customerId?.toString())))) {
    isValidUser = false;
    props.history.push({
      pathname: "/Customer/" + loggedInCustomer?.customerId,
      preSelectedTab: "2"
    });
  }

  const [preSelectedTab] = useState(props.location.preSelectedTab);
  const [selectedTab, setSelectedTab] = useState(preSelectedTab ? preSelectedTab : "0");
  const [selectedScriptsTab, setSelectedScriptsTab] = useState("0-2");

  const [validForm, setValidForm] = useState(true);
  const [errors, setErrors] = useState({});

  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [sourceType, setSourceType] = useState(SOURCE_TYPE.JSON_REST_API);
  const [executionOrder, setExecutionOrder] = useState(Number(props.location?.extractorsLength || 0) + 1);
  const [previousExtractorsRequired, setPreviousExtractorsRequired] = useState([]);
  const [extractorHidden, setExtractorHidden] = useState(false);
  const [defaultOrder, setDefaultOrder] = useState('');
  const [defaultOrderDirection, setDefaultOrderDirection] = useState(SORT_DIRECTION.ASC);

  // REST API
  const [baseURL, setBaseURL] = useState('');
  const [remotePath, setRemotePath] = useState('/biit/api/v2/...');
  const [jsonStartingPath, setJsonStartingPath] = useState('$.table.data');
  const [httpParams, setHttpParams] = useState([]);
  const [preStageScripts, setPreStageScripts] = useState('');
  const [postStageScripts, setPostStageScripts] = useState('');

  const [showRequestParamModal, setShowRequestParamModal] = useState(false);
  const [requestParamId, setRequestParamId] = useState(0);
  const [requestParamKey, setRequestParamKey] = useState('');
  const [requestParamValue, setRequestParamValue] = useState('');
  const [requestParamType, setRequestParamType] = useState(REQUEST_PARAM_TYPE.QUERY_PARAM);

  // SQL
  const SQL_QUERY_PLACE_HOLDER = 'SELECT fieldCode01, fieldCode02 \nFROM extractorCode \nWHERE fieldCode01 IS NOT NULL';
  const [sqlQuery, setSqlQuery] = useState('SELECT ');
  const [sqlQueryCurrentIndex, setSqlQueryCurrentIndex] = useState({ row: 0, column: 0 });

  const [fields, setFields] = useState([]);

  const [previousExtractors, setPreviousExtractors] = useState([]);
  const [selectedSqlExtractorCode, setSelectedSqlExtractorCode] = useState('');
  const [selectedExtractorFields, setSelectedExtractorFields] = useState([]);

  const [selectedExtractorCode, setSelectedExtractorCode] = useState('');

  const [fieldsDataGridView, setFieldsDataGridView] = useState(true);

  useEffect(() => {
    if (isValidUser) {
      if (templateId !== undefined) {
        if (customerId !== undefined) {
          addApiService.getCustomer(customerId).then(response => {
            if (!response) {
              loggedInCustomer?.SUPERADMIN ?
                props.history.push({ pathname: "/Customers" }) :
                props.history.push({ pathname: "/EmailSchedule" });
              return;
            }
            setCustomerName(response.customerName || '');
            setBaseURL(response.baseURL);
          });
        }

        if (isPredefinedTemplate && loggedInCustomer?.SUPERADMIN) {
          predefinedTemplateService.findById(templateId).then(response => {
            if (response.status !== 200) {
              errorMessage(!response.data.message ? 'No template found' : response.data.message);
              return;
            }

            setTemplateName(response.data.name || '');
          });
        } else {
          templateService.findById(customerId, templateId, true, false).then(response => {
            if (response.status !== 200) {
              errorMessage(!response.data.message ? 'No template found' : response.data.message);
              return;
            }
            setTemplateName(response.data.templateName || '');
          });
        }
      }

      if (templateId !== undefined && recordId !== undefined) {
        loadDataExtractorDetails();
      }
    }
  }, []);

  function loadDataExtractorDetails() {
    templateDataExtractorService.findById(templateId, recordId).then(response => {
      if (response.status !== 200) {
        errorMessage(!response.data.message ? 'No extractor found' : response.data.message);
        return;
      }

      setFields(response.data.fields);


      setCode(response.data.code);
      setName(response.data.name);
      setSourceType(response.data.sourceType);
      setExecutionOrder(response.data.executionOrder);
      setPreviousExtractorsRequired(response.data.previousExtractorsRequired && response.data.previousExtractorsRequired?.length > 0 ?
        response.data.previousExtractorsRequired.split(',').map(pext => Number(pext)) : []);
      setExtractorHidden(response.data.isHidden);
      setDefaultOrder(response.data.defaultOrder ? response.data.defaultOrder : '');
      setDefaultOrderDirection(response.data.defaultOrderDirection ? response.data.defaultOrderDirection : SORT_DIRECTION.ASC);

      setBaseURL(response.data.customer.baseURL);
      setRemotePath(response.data.remotePath);
      setJsonStartingPath(response.data.jsonStartingPath);
      setHttpParams(response.data.httpParams);
      setPreStageScripts(response.data.preStageScripts ? response.data.preStageScripts : '');
      setPostStageScripts(response.data.postStageScripts ? response.data.postStageScripts : '');

      setSqlQuery(response.data.sqlQuery);

      findPreviousDataExtractors(recordId, response.data.executionOrder);
    });
  }

  function errorMessage(message) {
    Swal.fire({
      title: message,
      icon: 'error',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        props.history.push({
          pathname: "/Customer/" + customerId,
          preSelectedTab: "2"
        });
      }
    });
  }

  function handleChangeName(name) {
    if ((recordId === undefined || recordId === '') && name !== '') {
      let nameAsCode = name.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/[\W_]+/g, '');
      nameAsCode = nameAsCode.substring(0, 30);

      setCode(nameAsCode);
    }

    setName(name);
  }

  function save() {
    validate("sourceType", sourceType, true, false, false);
    validate("name", name, true, false, false);
    validate("code", code, true, false, false);
    const valid = validate("executionOrder", executionOrder, false, false, true);

    if (!valid) {
      return;
    }

    if (sourceType === SOURCE_TYPE.JSON_REST_API) {
      saveJSONExtractor();
    } else {
      saveSQLExtractor();
    }

  }

  function saveJSONExtractor() {
    const request = {
      code,
      name,
      sourceType,
      executionOrder,
      isHidden: extractorHidden,
      previousExtractorsRequired: previousExtractorsRequired && previousExtractorsRequired.length > 0 ? previousExtractorsRequired.join(',') : null,
      remotePath,
      jsonStartingPath,
      preStageScripts,
      postStageScripts,
      defaultOrder,
      defaultOrderDirection,
      customer: {
        id: !isPredefinedTemplate ? customerId : NON_EXISTENT_CUSTOMER_ID
      },
      templateId,

    }
    if (!recordId) {
      templateDataExtractorService.saveJSONExtractor(templateId, request).then(({ data }) => {
        displaySuccessMessage('Extractor Saved');
        setRecordId(data.id);
        setHttpParams(data.httpParams);

        findPreviousDataExtractors(data.id, data.executionOrder);

        props.history.push({
          pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + templateId + "/Extractor/" + data.id,
        });
      }).catch((error) => {
        errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to update the extractor`);
      });
    } else {
      templateDataExtractorService.updateJSONExtractor(templateId, recordId, request).then(() => {
        displaySuccessMessage('Extractor Saved');
      }).catch((error) => {
        errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to update the extractor`);
      });
    }
  }

  function saveSQLExtractor() {
    const request = {
      code,
      name,
      sourceType,
      executionOrder,
      isHidden: extractorHidden,
      previousExtractorsRequired: previousExtractorsRequired && previousExtractorsRequired.length > 0 ? previousExtractorsRequired.join(',') : null,
      preStageScripts,
      postStageScripts,
      defaultOrder,
      defaultOrderDirection,
      sqlQuery,
      customer: {
        id: !isPredefinedTemplate ? customerId : NON_EXISTENT_CUSTOMER_ID
      },
      templateId,

    }
    if (!recordId) {
      templateDataExtractorService.saveSQLExtractor(templateId, request).then(({ data }) => {
        displaySuccessMessage('Extractor Saved');
        setRecordId(data.id);

        findPreviousDataExtractors(data.id, data.executionOrder);

        props.history.push({
          pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + templateId + "/Extractor/" + data.id,
        });
      }).catch((error) => {
        errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to update the extractor`);
      });
    } else {
      templateDataExtractorService.updateSQLExtractor(templateId, recordId, request).then(() => {
        displaySuccessMessage('Extractor Saved');
      }).catch((error) => {
        errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to update the extractor`);
      });
    }
  }

  function displaySuccessMessage(message, warning) {
    Swal.fire({
      icon: warning ? 'warning' : 'success',
      title: message,
      showConfirmButton: false,
      timer: 2000
    });
  }

  function validate(field, value, isRequired, isLettersOnly, isNumericOnly) {
    let vErrors = errors ?? {};

    if (isRequired && !value) {
      vErrors[field] = "This field is required";
    } else if (isLettersOnly && value && !value.match(/^[a-zA-Z]+(\.[a-zA-Z]+)*$/)) {
      vErrors[field] = "Letters only";
    } else if (isNumericOnly && value && typeof value === "string" && !value.match(/^\d+$/)) {
      vErrors[field] = "Numeric only";
    } else {
      delete vErrors[field];
    }

    if (Object.keys(vErrors).length) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }

    setErrors(vErrors);
    return !Object.keys(vErrors).length;
  }

  function addHttpParam() {
    const request = {
      type: requestParamType,
      key: requestParamKey,
      value: requestParamValue,
      dataExtractor: {
        id: recordId,
        sourceType: SOURCE_TYPE.JSON_REST_API
      }
    }
    templateDataExtractorService.addHttpParam(templateId, recordId, request).then(({ data }) => {
      httpParams.push(data);
      setHttpParams(httpParams);

      initializeRequestParam();

      displaySuccessMessage('Parameter Saved');
    }).catch((error) => {
      errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed adding the new parameter`);

      initializeRequestParam();
    });
  }

  function initializeRequestParam() {
    setRequestParamId(0);
    setRequestParamKey('');
    setRequestParamValue('');
    setRequestParamType(REQUEST_PARAM_TYPE.QUERY_PARAM);
    setShowRequestParamModal(false);
  }

  function updateHttpParam() {
    const request = {
      id: requestParamId,
      type: requestParamType,
      key: requestParamKey,
      value: requestParamValue,
      dataExtractor: {
        id: recordId,
        sourceType: SOURCE_TYPE.JSON_REST_API
      }
    }
    templateDataExtractorService.updateHttpParam(templateId, recordId, requestParamId, request).then(({ data }) => {
      httpParams.forEach((dt) => {
        if (dt.id === requestParamId) {
          dt.key = data.key;
          dt.type = data.type;
          dt.value = data.value;
        }
      });

      setHttpParams(httpParams);

      initializeRequestParam();

      displaySuccessMessage('Parameter Updated');
    }).catch((error) => {
      errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed updating the parameter`);

      initializeRequestParam();
    });
  }

  function prepareHttpParam(selectedHttpParam) {
    setRequestParamId(selectedHttpParam.id);
    setRequestParamKey(selectedHttpParam.key);
    setRequestParamValue(selectedHttpParam.value);
    setRequestParamType(selectedHttpParam.type);
    setShowRequestParamModal(true);
  }

  function deleteHttpParam(httpParamId, httpParamCode) {
    Swal.fire({
      title: `Do you really want to delete the parameter: <span style="color: red">${httpParamCode}</span>?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        templateDataExtractorService.deleteHttpParam(templateId, recordId, httpParamId).then(() => {
          setHttpParams(httpParams.filter(param => param.id !== httpParamId));
          displaySuccessMessage('Parameter Deleted');
        })
          .catch(() => {
            Swal.showValidationMessage(`Failed to delete the parameter`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  function onChangeSql(newValue) {
    validate("sqlQuery", newValue, true, false, false);
    setSqlQuery(newValue);
  }
  
  function onChangePostStageScripts(newValue) {
    validate("postStageScripts", newValue, false, false, false);
    setPostStageScripts(newValue);
  }
  
   function onChangePreStageScripts(newValue) {
    validate("preStageScripts", newValue, false, false, false);
    setPreStageScripts(newValue);
  }

  function onCursorChangeSql(newValue, event) {
    if (newValue?.cursor) {
      setSqlQueryCurrentIndex({ row: newValue?.cursor?.row || 0, column: newValue?.cursor?.column || 0 });
    } else {
      setSqlQueryCurrentIndex({ row: 0, column: 0 });
    }
  }


  function deleteExtractorField(field) {
    Swal.fire({
      title: `Do you really want to delete the field: <span style="color: red">${field.code}</span>?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (sourceType === SOURCE_TYPE.JSON_REST_API) {
          templateDataExtractorFieldService.deleteJSONField(templateId, extractorId, field.id)
            .then(() => {
              deleteSuccess();
            })
            .catch(() => {
              Swal.showValidationMessage(`Failed to delete the field.`);
            });
        } else {
          templateDataExtractorFieldService.deleteSQLField(templateId, extractorId, field.id)
            .then(() => {
              deleteSuccess();
            })
            .catch(() => {
              Swal.showValidationMessage(`Failed to delete the field.`);
            });
        }

      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  function deleteSuccess() {
    Swal.fire({
      icon: 'success',
      title: 'Field Deleted',
      showConfirmButton: false,
      timer: 1500
    });

    loadDataExtractorDetails();
  }

  function errorMessageSaving(message) {
    Swal.fire({
      title: message,
      icon: 'error',
      allowOutsideClick: false
    });
  }

  function showPreviewRawData() {
    previewRawData.loaded = false;

    if (templateId !== undefined && recordId !== undefined) {
      setEnablePreviewRawDataButton(false);

      const request = {
        userApptioProfile: JSON.parse(sessionStorage.getItem('currentCustomer')).userName,
        runPreviousExtractors: sourceType === SOURCE_TYPE.STAGING,
        jsonStartingPath,
        remotePath,
        sqlQuery,
        maxRows: maxRowsRawData,
        orderByFields: [orderByCodeRawData]
      };

      templateDataExtractorService.previewRawDataExtractor(templateId, recordId, request).then(response => {
        if (response) {
          response.data.loaded = true;
          setPreviewRawData(response.data);
          setShowRequestPreviewRawModal(true);
        } else {
          setPreviewRawData({ rawData: [], loaded: true });
        }

        setEnablePreviewRawDataButton(true);
      });
    } else {
      setPreviewRawData({ rawData: [], loaded: false });

      Swal.fire({
        icon: 'warn',
        title: 'Unable to preview raw data, make sure you have saved the latest changes',
      });
    }
  }

  function handleCloseModalPreviewRaw() {
    setShowRequestPreviewRawModal(false);
    setEnablePreviewRawDataButton(true);
    setPreviewRawData({ rawData: [], loaded: false });
  }

  useEffect(() => {
    if (showRequestPreviewRawModal) {
      previewRawData.loaded = false;
      showPreviewRawData();
    }
  }, [maxRowsRawData, orderByCodeRawData]);


  function showPreviewFormattedData() {
    if (templateId !== undefined && recordId !== undefined) {
      setEnablePreviewFormattedDataButton(false);

      const request = {
        userApptioProfile: JSON.parse(sessionStorage.getItem('currentCustomer')).userName,
        jsonTableProperty: 'records',
        maxRows: maxRowsFormattedData,
        orderByFields: [orderByCodeFormattedData]
      };

      templateDataExtractorService.previewDataExtractor(templateId, recordId, request).then(response => {
        if (response) {
          response.data.loaded = true;
          setPreviewFormattedData(response.data);
          setShowRequestPreviewFormattedModal(true);
        } else {
          setPreviewFormattedData({ data: { records: [] }, loaded: true });
        }

        setEnablePreviewFormattedDataButton(true);
      });
    } else {
      setPreviewFormattedData({ data: { records: [] }, loaded: true });

      Swal.fire({
        icon: 'warn',
        title: 'Unable to preview formatted data, make sure you have saved the latest changes',
      });
    }
  }

  function handleCloseModalPreviewFormatted() {
    setShowRequestPreviewFormattedModal(false);
    setEnablePreviewFormattedDataButton(true);
    setPreviewFormattedData({ data: { records: [] }, loaded: false });
  }

  useEffect(() => {
    if (showRequestPreviewFormattedModal) {
      previewFormattedData.loaded = false;
      showPreviewFormattedData();
    }
  }, [maxRowsFormattedData, orderByCodeFormattedData]);

  function handleChangeSQLExtractor(event) {
    const extractorIndex = previousExtractors.findIndex(extractor => extractor.code === event.target.value);
    setSelectedSqlExtractorCode(event.target.value);

    const extractor = previousExtractors.at(extractorIndex);
    setSelectedExtractorFields(extractor.fields);
  }

  function findPreviousDataExtractors(currentRecord = recordId, executionOrder = 1) {
    templateDataExtractorService.findAll(templateId).then(response => {
      let prevExtractorsForTemplate = Object.values(response.data).filter(ext => (ext.id + '' !== currentRecord + '') && ext.executionOrder < executionOrder);
      
      templateFilterService.findAll(templateId).then(filterResponse => {
	    let filters = [];
	    filterResponse.data.forEach((filter, index) => {
		   filters.push({ code: "'${" + filter.code + "}'", name: filter.code, dataType: 'STRING', displayOrder : index + 1 });
	    });
	    
	    prevExtractorsForTemplate.unshift({ code: 'filters', name: 'Filters', fields: filters });
	    
	    setPreviousExtractors(prevExtractorsForTemplate);
	  }); 
      
    });
  }

  function handleChangeSQLExtractorField(selectedField) {
    appendToQuerySelectedText(selectedField);
  }

  function appendToQuerySelectedText(sqlText) {
    if (sqlQueryCurrentIndex?.row === 0 && sqlQueryCurrentIndex?.column === 0) {
      setSqlQuery(sqlQuery + sqlText + ', ');
    } else {
      let sqlSplitted = sqlQuery.split(/\r?\n/);
      let selectedLine = sqlSplitted[sqlQueryCurrentIndex?.row];
      // Inserting new text at current cursor position
      sqlSplitted[sqlQueryCurrentIndex?.row] = selectedLine.slice(0, sqlQueryCurrentIndex?.column) + sqlText + ', ' + selectedLine.slice(sqlQueryCurrentIndex?.column);

      setSqlQuery(sqlSplitted.join("\r\n"));
    }
  }
  
  function handleChangeExtractor(event, textArea) {
    const extractorIndex = previousExtractors.findIndex(extractor => extractor.code === event.target.value);
    setSelectedExtractorCode(event.target.value);

    const extractor = previousExtractors.at(extractorIndex);
    setSelectedExtractorFields(extractor.fields);
  }
  
  function handleChangeExtractorField(selectedField, textArea) {
    appendTextArea(textArea, selectedField);
  }
  
  function appendTextArea(textArea, sqlText) {
    if (sqlQueryCurrentIndex?.row === 0 && sqlQueryCurrentIndex?.column === 0) {
      if(textArea === 'postStage'){
        setPostStageScripts(postStageScripts + sqlText + ', ');
      } else if(textArea === 'preStage') {
	    setPreStageScripts(preStageScripts + sqlText + ', ');
	  }
    } else {
      let sqlQuery = textArea === 'postStage' ? postStageScripts : preStageScripts;
      
      let sqlSplitted = sqlQuery.split(/\r?\n/);
      let selectedLine = sqlSplitted[sqlQueryCurrentIndex?.row];
      // Inserting new text at current cursor position
      sqlSplitted[sqlQueryCurrentIndex?.row] = selectedLine.slice(0, sqlQueryCurrentIndex?.column) + sqlText + ', ' + selectedLine.slice(sqlQueryCurrentIndex?.column);
      
      if(textArea === 'postStage'){
        setPostStageScripts(sqlSplitted.join("\r\n"));
      } else if(textArea === 'preStage') {
	    setPreStageScripts(sqlSplitted.join("\r\n"));
	  }
    }
  }

  function refreshPreviewFormattedData() {
    previewFormattedData.loaded = false;
    showPreviewFormattedData();
  }

  function autoGenerateFields() {
    Swal.fire({
      title: `Assisted field generation will not modify or delete existing fields, Do you want to continue?`,
      showCancelButton: true,
      confirmButtonText: 'Continue',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        setEnableGenerateFieldsButton(false);

        const request = {
          userApptioProfile: JSON.parse(sessionStorage.getItem('currentCustomer')).userName,
          runPreviousExtractors: sourceType === SOURCE_TYPE.STAGING,
          jsonStartingPath,
          remotePath,
          sqlQuery
        };

        templateDataExtractorService.generateFields(templateId, recordId, request).then(response => {
          if (response && response.data.newFieldsCount > 0) {
            displaySuccessMessage(`Field generation has been completed: ${response.data.newFieldsCount} new field(s)`);
          } else if (response.data?.newFieldsCount || 0 === 0) {
            displaySuccessMessage('No new fields found to generate');
          }

          setEnableGenerateFieldsButton(true);

          loadDataExtractorDetails();
        }).catch(() => {
          Swal.showValidationMessage(`Failed to generate fields`);
          setEnableGenerateFieldsButton(true);
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  function cloneExtractorField(selectedField) {
    setEnableCloneFieldButton(false);

    if (sourceType === SOURCE_TYPE.JSON_REST_API) {
      cloneJSONExtractorField(selectedField);
    } else {
      cloneSQLExtractorField(selectedField);
    }

  }

  function cloneJSONExtractorField(selectedField) {
    const request = {
      code: 'copy_' + selectedField.code,
      name: 'copy of ' + selectedField.name,
      dataType: selectedField.dataType,
      sortOrder: selectedField.sortOrder + 1,
      sizeLimit: selectedField.sizeLimit,
      precision: selectedField.precision,
      scale: selectedField.scale,
      inputFormat: selectedField.inputFormat,
      outputFormat: selectedField.outputFormat,
      filterCodeRelated: selectedField.filterCodeRelated,
      isHidden: selectedField.isHidden,
      jsonPath: selectedField.jsonPath,
      dataExtractor: {
        id: recordId,
        sourceType: sourceType
      }
    }

    templateDataExtractorFieldService.saveJSONField(templateId, recordId, request).then(({ data }) => {
      displaySuccessMessage('Field Cloned!');

      loadDataExtractorDetails();

      setEnableCloneFieldButton(true);
    }).catch((error) => {
      errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to clone the field`);
      setEnableCloneFieldButton(true);
    });
  }

  function cloneSQLExtractorField(selectedField) {
    const request = {
      code: 'copy_' + selectedField.code,
      name: 'copy of ' + selectedField.name,
      dataType: selectedField.dataType,
      sortOrder: selectedField.sortOrder + 1,
      sizeLimit: selectedField.sizeLimit,
      precision: selectedField.precision,
      scale: selectedField.scale,
      inputFormat: selectedField.inputFormat,
      outputFormat: selectedField.outputFormat,
      filterCodeRelated: selectedField.filterCodeRelated,
      isHidden: selectedField.isHidden,
      dataExtractor: {
        id: recordId,
        sourceType: sourceType
      }
    }

    templateDataExtractorFieldService.saveSQLField(templateId, recordId, request).then(({ data }) => {
      displaySuccessMessage('Field Cloned!');

      loadDataExtractorDetails();

      setEnableCloneFieldButton(true);
    }).catch((error) => {
      errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to clone the field`);
      setEnableCloneFieldButton(true);
    });
  }

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <SideBar
        sideBarExpanded={props.sideBarExpanded}
        setSideBarExpanded={props.setSideBarExpanded}
      />
      <div id="main">
        <div className="container-fluid custom-container">

          <AnimatedBody
            sideBarExpanded={props.sideBarExpanded}
            setSideBarExpanded={props.setSideBarExpanded}
            className="container-fluid custom-container"
          >

            {isPredefinedTemplate ? '' :
              <TopBar title={"Customer"} customer={customerName} customerId={customerId} />
            }

            <div className="row row-margin">
              {templateId && !templateName ? <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" /> :
                <>

                  <Breadcrumbs className="pb-3" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="#"
                      onClick={() => props.history.push({
                        pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}`),
                        preSelectedTab: "2"
                      })}>Templates</Link>
                    <Link underline="hover" color="inherit" href="#"
                      onClick={() => props.history.push({
                        pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + templateId,
                        preSelectedTab: "1"
                      })}>{templateName}</Link>
                    <Typography color="text.primary">{recordId ? name : 'Create new extractor'}</Typography>
                  </Breadcrumbs>

                  <TabContext value={selectedTab}>

                    <TabList onChange={(e, value) => setSelectedTab(value)}>
                      <Tab label="Properties" value="0" />
                      <Tab label="REST API" value="1" hidden={sourceType !== SOURCE_TYPE.JSON_REST_API} />
                      <Tab label="Query" value="2" hidden={sourceType !== SOURCE_TYPE.STAGING} />
                      <Tab label="Fields" value="3" />
                    </TabList>

                    <TabPanel value="0">
                      <form>
                        <div className="row">
                          <div className="col-12">
                            <TextField variant="outlined" label="Name" margin="normal" size="small" fullWidth
                              required
                              value={name}
                              onChange={(e) => {
                                validate("name", e.target.value, true, false, false);
                                handleChangeName(e.target.value);
                              }}
                              error={errors["name"] ? true : false}
                              helperText={errors["name"] ? errors["name"] : ""} />
                          </div>

                          <div className="col-6">
                            <TextField variant="outlined" label="Code" margin="normal" size="small" fullWidth
                              required disabled={recordId !== undefined}
                              value={code}
                              onChange={(e) => {
                                validate("code", e.target.value, true, false, false);
                                setCode(e.target.value);
                              }}
                              error={errors["code"] ? true : false}
                              helperText={errors["code"] ? errors["code"] : ""} />
                          </div>

                          <div className="col-4">
                            <FormControl variant="outlined" margin="normal" size="small" fullWidth disabled={recordId !== undefined}>
                              <InputLabel id="sourceTypeLabel" required>Source Type</InputLabel>
                              <Select labelId="sourceTypeLabel"
                                required MenuProps={{ disableScrollLock: true }}
                                value={sourceType}
                                label="Source Type"
                                onChange={(e) => {
                                  validate("sourceType", e.target.value, true, false, false);
                                  setSourceType(e.target.value);
                                }}
                              >
                                <MenuItem value={SOURCE_TYPE.JSON_REST_API}>JSON REST API</MenuItem>
                                <MenuItem value={SOURCE_TYPE.STAGING}>Staging</MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          <div className="col-2">
                            <FormControlLabel className="left-aligned" label="Hidden" labelPlacement="top"
                              control={<Switch className="switch-primary"
                                checked={extractorHidden}
                                onChange={(e) => { setExtractorHidden(e.target.checked) }} />} />
                          </div>

                          <div className="col-2">
                            <TextField variant="outlined" label="Execution Order" margin="normal" size="small"
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} fullWidth required
                              value={executionOrder}
                              onChange={(e) => {
                                validate("executionOrder", e.target.value, true, false, true);
                                setExecutionOrder(e.target.value);
                              }}
                              error={errors["executionOrder"] ? true : false}
                              helperText={errors["executionOrder"] ? errors["executionOrder"] : ""} />
                          </div>

                          <div className="col-4">
                            <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                              <InputLabel id="previousExtractorsLabel">Previous Extractors Required</InputLabel>

                              <Select labelId="previousExtractorsLabel"
                                value={previousExtractorsRequired} multiple
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={{ disableScrollLock: true }}
                                fullWidth label="Previous Extractors Required"
                                onChange={(e) => {
                                  setPreviousExtractorsRequired(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value);
                                }}>

                                {previousExtractors && previousExtractors.length ? previousExtractors.filter(fl => fl.code !== 'filters').map((pExt) => (
                                  <MenuItem key={pExt.id} value={pExt.id} title={pExt.code}>{pExt.name}</MenuItem>
                                )) : ''}
                              </Select>
                            </FormControl>
                          </div>

                          <div className="col-4">
                            <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                              <InputLabel id="orderFieldLabel">Default OrderBy</InputLabel>

                              <Select labelId="orderFieldLabel" defaultValue={''}
                                value={defaultOrder}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={{ disableScrollLock: true }}
                                fullWidth label="Default OrderBy"
                                onChange={(e) => {
                                  setDefaultOrder(e.target.value);
                                }}>

                                <MenuItem value={''}>None</MenuItem>

                                {fields && fields.length ? fields.map((field) => (
                                  <MenuItem key={field.code} value={field.code} title={field.dataType}>{field.name}</MenuItem>
                                )) : ''}
                              </Select>
                            </FormControl>
                          </div>

                          <div className="col-2">
                            <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                              <InputLabel id="sortDirectionLabel">Sort Direction</InputLabel>

                              <Select labelId="sortDirectionLabel" defaultValue={''}
                                value={defaultOrderDirection}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={{ disableScrollLock: true }}
                                fullWidth label="Sort Direction"
                                onChange={(e) => {
                                  setDefaultOrderDirection(e.target.value);
                                }}>

                                <MenuItem value={SORT_DIRECTION.ASC} title={'Ascending'}>Asc</MenuItem>
                                <MenuItem value={SORT_DIRECTION.DESC} title={'Descending'}>Desc</MenuItem>

                              </Select>
                            </FormControl>
                          </div>

                          <TabContext value={selectedScriptsTab}>
                            <TabList onChange={(e, value) => setSelectedScriptsTab(value)}>
                              <Tab label="Pre Stage Scripts" value="0-1" />
                              <Tab label="Post Stage Scripts" value="0-2" />
                            </TabList>

                            <TabPanel value="0-1">
                              <div className="row">
                                <div className="col-2">
		                            <FormControl variant="outlined" size="small" className="mt-0" fullWidth>
		                              <InputLabel id="sqlExtractorLabel">Select an Extractor</InputLabel>
		
		                              <Select labelId="sqlExtractorLabel" onChange={(e) => handleChangeExtractor(e, 'preStage')}
		                                displayEmpty defaultValue={''}
		                                inputProps={{ 'aria-label': 'Without label' }}
		                                MenuProps={{ disableScrollLock: true }}
		                                fullWidth label="Select an Extractor">
		                                {previousExtractors && previousExtractors.length ? previousExtractors.map((extractor) => (
		                                  <MenuItem key={extractor.code} value={extractor.code} title={extractor.code}>{extractor.name}</MenuItem>
		                                )) : ''}
		                              </Select>
		
		                              <div className="mw-100">
		                                <a href="#" onClick={() => appendTextArea('preStage', selectedExtractorCode)} title="Copy extractor code"
		                                  style={{ float: "right !important" }}>
		                                  {'Copy code '}<FontAwesomeIcon icon={faCopy} color="#6c6c6f" />
		                                </a>
		                              </div>
		                            </FormControl>
		
		                            <FormHelperText className="mt-4">{selectedExtractorCode ? `Double-click to select fields` : ''}</FormHelperText>
		
		                            <List sx={{ overflow: 'auto', maxHeight: 300 }}>
		                              {selectedExtractorFields && selectedExtractorFields.length ? selectedExtractorFields.map((field) => (
		                                <ListItem key={field.id} className="p-0">
		                                  <ListItemButton className="py-0 px-1" onDoubleClick={() => handleChangeExtractorField(`${field.code}`, 'preStage')}>
		                                    <ListItemAvatar>
		                                      <Avatar>
		                                        {field.dataType === 'STRING' ? <AbcIcon />
		                                          : field.dataType === 'NUMERIC' ? <PinIcon />
		                                            : <MoreHorizIcon />}
		                                      </Avatar>
		                                    </ListItemAvatar>
		                                    <ListItemText primary={field.name} primaryTypographyProps={{ fontSize: 14, noWrap: true }}
		                                      secondary={`${field.code}`} secondaryTypographyProps={{ fontSize: 12, noWrap: true }}
		                                      style={{ overflowWrap: "anywhere" }} />
		                                  </ListItemButton>
		                                </ListItem>
		                              )) : ''}
		                            </List>
		
		                        </div>
		                        
                                <div className="col-10">
                                  <AceEditor mode="pgsql" theme="github" onChange={onChangePreStageScripts} name="preStageScripts"
                                    value={preStageScripts} width="100%" wrapEnabled={true} height="280px" onCursorChange={onCursorChangeSql}
                                    editorProps={{ $blockScrolling: true }} setOptions={{
                                      enableBasicAutocompletion: true,
                                      enableLiveAutocompletion: true,
                                      enableSnippets: true
                                    }}
                                  />
                                </div>
                              </div>
                            </TabPanel>

                            <TabPanel value="0-2">
                              <div className="row">
                                <div className="col-2">
		                            <FormControl variant="outlined" size="small" className="mt-0" fullWidth>
		                              <InputLabel id="sqlExtractorLabel">Select an Extractor</InputLabel>
		
		                              <Select labelId="sqlExtractorLabel" onChange={(e) => handleChangeExtractor(e, 'postStage')}
		                                displayEmpty defaultValue={''}
		                                inputProps={{ 'aria-label': 'Without label' }}
		                                MenuProps={{ disableScrollLock: true }}
		                                fullWidth label="Select an Extractor">
		                                {previousExtractors && previousExtractors.length ? previousExtractors.map((extractor) => (
		                                  <MenuItem key={extractor.code} value={extractor.code} title={extractor.code}>{extractor.name}</MenuItem>
		                                )) : ''}
		                              </Select>
		
		                              <div className="mw-100">
		                                <a href="#" onClick={() => appendTextArea('postStage', selectedExtractorCode)} title="Copy extractor code"
		                                  style={{ float: "right !important" }}>
		                                  {'Copy code '}<FontAwesomeIcon icon={faCopy} color="#6c6c6f" />
		                                </a>
		                              </div>
		                            </FormControl>
		
		                            <FormHelperText className="mt-4">{selectedExtractorCode ? `Double-click to select fields` : ''}</FormHelperText>
		
		                            <List sx={{ overflow: 'auto', maxHeight: 300 }}>
		                              {selectedExtractorFields && selectedExtractorFields.length ? selectedExtractorFields.map((field) => (
		                                <ListItem key={field.id} className="p-0">
		                                  <ListItemButton className="py-0 px-1" onDoubleClick={() => handleChangeExtractorField(`${field.code}`, 'postStage')}>
		                                    <ListItemAvatar>
		                                      <Avatar>
		                                        {field.dataType === 'STRING' ? <AbcIcon />
		                                          : field.dataType === 'NUMERIC' ? <PinIcon />
		                                            : <MoreHorizIcon />}
		                                      </Avatar>
		                                    </ListItemAvatar>
		                                    <ListItemText primary={field.name} primaryTypographyProps={{ fontSize: 14, noWrap: true }}
		                                      secondary={`${field.code}`} secondaryTypographyProps={{ fontSize: 12, noWrap: true }}
		                                      style={{ overflowWrap: "anywhere" }} />
		                                  </ListItemButton>
		                                </ListItem>
		                              )) : ''}
		                            </List>
		
		                        </div>
                                <div className="col-10">
                                  <AceEditor mode="pgsql" theme="github" onChange={onChangePostStageScripts} name="postStageScripts"
                                    value={postStageScripts} width="100%" wrapEnabled={true} height="280px" onCursorChange={onCursorChangeSql}
                                    editorProps={{ $blockScrolling: true }} setOptions={{
                                      enableBasicAutocompletion: true,
                                      enableLiveAutocompletion: true,
                                      enableSnippets: true
                                    }}
                                  />
                                </div>
                              </div>
                            </TabPanel>
                          </TabContext>
                        </div>
                      </form>
                    </TabPanel>

                    <TabPanel value="1">
                      <div className="row">
                        <form>
                          <div className="col-12">
                            <TextField variant="outlined" label="Base URL" margin="normal" size="small" fullWidth
                              value={baseURL} disabled />
                          </div>

                          <div className="col-12">
                            <TextField variant="outlined" label="Remote Path" margin="normal" size="small" fullWidth
                              required multiline maxRows={4}
                              value={remotePath}
                              onChange={(e) => {
                                validate("remotePath", e.target.value, true, false, false);
                                setRemotePath(e.target.value);
                              }}
                              error={errors["remotePath"] ? true : false}
                              helperText={errors["remotePath"] ? errors["remotePath"] : ""} />
                          </div>

                          <div className="row">
                            <div className="col-8">
                              <TextField variant="outlined" label="JSON Starting Path" margin="normal" size="small" fullWidth
                                required
                                value={jsonStartingPath}
                                onChange={(e) => {
                                  validate("jsonStartingPath", e.target.value, true, false, false);
                                  setJsonStartingPath(e.target.value);
                                }}
                                error={errors["jsonStartingPath"] ? true : false}
                                helperText={errors["jsonStartingPath"] ? errors["remotePath"] : ""} />
                            </div>

                            <div className="col-2">
                              <LoadingButton type="button" className="btn btn-submit mt-3" disabled={!enablePreviewRawDataButton}
                                onClick={() => showPreviewRawData()} fullWidth
                                loading={!enablePreviewRawDataButton}
                                loadingPosition="end"
                                endIcon={
                                  <DatasetIcon />
                                }
                                style={{ textTransform: "inherit" }}>
                                Raw Data
                              </LoadingButton>
                            </div>

                            <div className="col-2">
                              <LoadingButton type="button" className="btn btn-submit mt-3" disabled={!enableGenerateFieldsButton}
                                onClick={() => autoGenerateFields()} fullWidth
                                loading={!enableGenerateFieldsButton} title="Auto generation of fields"
                                loadingPosition="end"
                                endIcon={
                                  <SmartButtonIcon />
                                }
                                style={{ textTransform: "inherit" }}>
                                Generate Fields
                              </LoadingButton>
                            </div>
                          </div>
                        </form>

                        <div className="col-12 pt-3">
                          <Card variant="outlined">
                            <CardHeader className="pb-0 pe-4"
                              avatar={
                                <Avatar>
                                  <FontAwesomeIcon icon={faList} />
                                </Avatar>
                              }
                              title="Request Headers / Parameters"
                            />

                            <CardContent className="pt-0 px-4">
                              <table className="w-100">
                                <thead>
                                  <tr className="line">
                                    <th className="line-data">Type</th>
                                    <th className="line-data">Key</th>
                                    <th className="line-data">Value</th>
                                    <th className="line-data" width={150} style={{ textAlign: "center" }}>
                                      <button type="button" className="btn btn-submit mt-0 me-2"
                                        onClick={() => { initializeRequestParam(); setShowRequestParamModal(true); }}>
                                        Add New
                                      </button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {httpParams && httpParams.length > 0 ?
                                    httpParams.map(httpParam => (
                                      <tr key={httpParam.id} className="line">
                                        <td className="line-data">{httpParam.type}</td>
                                        <td className="line-data">{httpParam.key}</td>
                                        <td className="line-data">{httpParam.value}</td>
                                        <td style={{ textAlign: "center" }}>
                                          <button type="button" className="btn btn-light"
                                            style={{ marginTop: 0, marginRight: 20 }}
                                            onClick={() => prepareHttpParam(httpParam)}>
                                            Edit
                                          </button>

                                          <a href="#" onClick={() => deleteHttpParam(httpParam.id, httpParam.key)} title="Delete Parameter">
                                            <FontAwesomeIcon icon={faTrashCan} color="#6c6c6f" />
                                          </a>
                                        </td>
                                      </tr>))
                                    :
                                    <tr>
                                      <td colSpan={4}>
                                        {(httpParams && httpParams.length === 0) ? "No records found" : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                                      </td>
                                    </tr>
                                  }

                                </tbody>
                              </table>
                            </CardContent>
                          </Card>
                        </div>
                      </div>

                    </TabPanel>

                    <TabPanel value="2">
                      <form>
                        <div className="row">
                          <div className="col-8">
                          </div>
                          <div className="col-2 px-4">
                            <LoadingButton type="button" className="btn btn-submit mt-0" disabled={!enablePreviewRawDataButton}
                              onClick={() => showPreviewRawData()} fullWidth
                              loading={!enablePreviewRawDataButton}
                              loadingPosition="end"
                              endIcon={
                                <DatasetIcon />
                              }
                              style={{ textTransform: "inherit" }}>
                              Raw Data
                            </LoadingButton>
                          </div>
                          <div className="col-2 px-4">
                            <LoadingButton type="button" className="btn btn-submit mt-0" disabled={!enableGenerateFieldsButton}
                              onClick={() => autoGenerateFields()} fullWidth
                              loading={!enableGenerateFieldsButton} title="Auto generation of fields"
                              loadingPosition="end"
                              endIcon={
                                <SmartButtonIcon />
                              }
                              style={{ textTransform: "inherit" }}>
                              Generate Fields
                            </LoadingButton>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2">
                            <FormControl variant="outlined" size="small" className="mt-0" fullWidth>
                              <InputLabel id="sqlExtractorLabel">Select an Extractor</InputLabel>

                              <Select labelId="sqlExtractorLabel" onChange={handleChangeSQLExtractor}
                                displayEmpty defaultValue={''}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={{ disableScrollLock: true }}
                                fullWidth label="Select an Extractor">
                                {previousExtractors && previousExtractors.length ? previousExtractors.map((extractor) => (
                                  <MenuItem key={extractor.code} value={extractor.code} title={extractor.code}>{extractor.name}</MenuItem>
                                )) : ''}
                              </Select>

                              <div className="mw-100">
                                <a href="#" onClick={() => appendToQuerySelectedText(selectedSqlExtractorCode)} title="Copy extractor code"
                                  style={{ float: "right !important" }}>
                                  {'Copy code '}<FontAwesomeIcon icon={faCopy} color="#6c6c6f" />
                                </a>
                              </div>
                            </FormControl>

                            <FormHelperText className="mt-4">{selectedSqlExtractorCode ? `Double-click to select fields` : ''}</FormHelperText>

                            <List sx={{ overflow: 'auto', maxHeight: 300 }}>
                              {selectedExtractorFields && selectedExtractorFields.length ? selectedExtractorFields.map((field) => (
                                <ListItem key={field.id} className="p-0">
                                  <ListItemButton className="py-0 px-1" onDoubleClick={() => handleChangeSQLExtractorField(`${field.code}`)}>
                                    <ListItemAvatar>
                                      <Avatar>
                                        {field.dataType === 'STRING' ? <AbcIcon />
                                          : field.dataType === 'NUMERIC' ? <PinIcon />
                                            : <MoreHorizIcon />}
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={field.name} primaryTypographyProps={{ fontSize: 14, noWrap: true }}
                                      secondary={`${field.code}`} secondaryTypographyProps={{ fontSize: 12, noWrap: true }}
                                      style={{ overflowWrap: "anywhere" }} />
                                  </ListItemButton>
                                </ListItem>
                              )) : ''}
                            </List>

                          </div>
                          <div className="col-10">
                            <AceEditor value={sqlQuery} mode="pgsql" theme="github" onChange={onChangeSql} name="sqlQuery"
                              width="100%" wrapEnabled={true} placeholder={SQL_QUERY_PLACE_HOLDER} onCursorChange={onCursorChangeSql}
                              editorProps={{ $blockScrolling: true }} setOptions={{
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true
                              }}
                            />
                          </div>
                        </div>
                      </form>
                    </TabPanel>

                    <TabPanel value="3">
                      <Row className="justify-content-end pb-3">
                        <Col lg={4} className="d-flex justify-content-end">
                          <LoadingButton type="button" className="btn btn-submit w-50" disabled={!enablePreviewFormattedDataButton}
                            onClick={() => showPreviewFormattedData()}
                            loading={!enablePreviewFormattedDataButton}
                            loadingPosition="end"
                            endIcon={
                              <DatasetIcon />
                            }
                            style={{ height: 39, textTransform: "inherit" }}>
                            Formatted Data
                          </LoadingButton>
                          
                          <ToggleButtonGroup
                            color="primary"
                            size="small"
                            value={fieldsDataGridView}
                            exclusive
                            onChange={(e, newvalue) => { setFieldsDataGridView(newvalue) }}
                            className="d-inline-block ms-2"
                          >
                            <ToggleButton value={true}><GridViewIcon /></ToggleButton>
                            <ToggleButton value={false}><ViewListIcon /></ToggleButton>
                          </ToggleButtonGroup>
                        </Col>
                      </Row>

                      {
                        fieldsDataGridView
                          ?
                          <Row>
                            <Col>
                              <TemplateDataExtractorFields templateId={templateId} extractorId={extractorId} extractorSourceType={sourceType} />
                            </Col>
                          </Row>
                          :
                          <div className="row">
                            <div className="col-12">
                              <table className="w-100">
                                <thead>
                                  <tr className="line">
                                    <th className="line-data">Name / Code</th>
                                    {sourceType === SOURCE_TYPE.JSON_REST_API ? <><th className="line-data">JSON Path</th></> : ''}
                                    <th className="line-data">Hidden</th>
                                    <th className="line-data">Datatype</th>
                                    <th className="line-data">Sort Order</th>
                                    <th className="line-data">Size</th>
                                    <th className="line-data">Format</th>
                                    <th className="line-data">Filter related</th>
                                    <th className="line-data" width="200" style={{ textAlign: "right" }}>
                                      <button type="button" className="btn btn-submit mt-0 me-2"
                                        onClick={() => props.history.push({
                                          pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + `${templateId}/Extractor/${recordId}/Fields`,
                                          preSelectedTab: "1",
                                          fieldsLength: fields?.length || 0
                                        })}>Add New</button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields && fields.length > 0 ?
                                    fields.map((field, index) => (
                                      <tr key={field.id} className="line">
                                        <td className="line-data">
                                          <div>{field.name}</div>
                                          <span className="text-black-50">{field.code}</span>
                                        </td>
                                        {sourceType === SOURCE_TYPE.JSON_REST_API ? <><td className="line-data">{field.jsonPath}</td></> : ''}
                                        <td className="line-data" style={{ textTransform: "capitalize" }}>{field.isHidden ? 'Yes' : 'No'}</td>
                                        <td className="line-data">{field.dataType}</td>
                                        <td className="line-data">{field.sortOrder}</td>
                                        <td className="line-data">{field.dataType == 'NUMERIC' ? <><div>Precision: {field.precision}</div><div>Scale: {field.scale}</div></> : <><div>Limit: {field.sizeLimit}</div></>}</td>
                                        <td className="line-data">
                                          <div>Input: {field.inputFormat}</div>
                                          <div>Output: {field.outputFormat}</div>
                                        </td>
                                        <td className="line-data">{field.filterCodeRelated}</td>
                                        <td style={{ textAlign: "center" }}>
                                          <LoadingButton type="button" className="btn btn-light" disabled={!enableCloneFieldButton}
                                            onClick={() => cloneExtractorField(field)}
                                            loading={!enableCloneFieldButton}
                                            style={{ textTransform: "inherit" }}>
                                            Clone
                                          </LoadingButton>

                                          <button type="button" className="btn btn-light"
                                            style={{ marginTop: 0, marginRight: 20 }}
                                            onClick={() => props.history.push({
                                              pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + `${templateId}/Extractor/${recordId}/Fields/${field.id}`,
                                              preSelectedTab: "1"
                                            })}>
                                            Edit
                                          </button>

                                          <a href="#" onClick={() => deleteExtractorField(field)} title="Delete Field">
                                            <FontAwesomeIcon icon={faTrashCan} color="#6c6c6f" />
                                          </a>
                                        </td>
                                      </tr>))
                                    :
                                    <tr>
                                      <td colSpan={4}>
                                        {(fields && fields.length === 0) ? "No fields found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                                      </td>
                                    </tr>
                                  }

                                </tbody>
                              </table>
                            </div>
                          </div>
                      }
                    </TabPanel>

                  </TabContext>

                  {selectedTab !== "3" ?
                    <div className="px-4">
                      <button type="button" style={{ marginTop: 0 }} className="btn btn-submit"
                        onClick={() => save()}
                        disabled={!validForm}>Save</button>

                      <button type="button" style={{ marginLeft: 5 }} className="btn btn-cancel"
                        onClick={() => props.history.push({
                          pathname: (isPredefinedTemplate ? PREDEFINED_ROUTE : `/Customer/${customerId}/Template/`) + templateId,
                          preSelectedTab: "1"
                        })}>Cancel</button>
                    </div>
                    : ''}
                </>
              }
            </div>
          </AnimatedBody>
        </div>
      </div>

      <Modal show={showRequestParamModal} onHide={() => setShowRequestParamModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Request Parameter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-12">
                <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                  <InputLabel id="sourceTypeLabel" required>Source Type</InputLabel>
                  <Select labelId="sourceTypeLabel"
                    required MenuProps={{ disableScrollLock: true }} value={requestParamType}
                    label="Source Type"
                    onChange={(e) => {
                      setRequestParamType(e.target.value);
                    }}
                  >
                    <MenuItem value={REQUEST_PARAM_TYPE.HEADER}>Header</MenuItem>
                    <MenuItem value={REQUEST_PARAM_TYPE.QUERY_PARAM}>Query Parameter</MenuItem>
                    <MenuItem value={REQUEST_PARAM_TYPE.PATH_PARAM}>Path parameter</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-6">
                <TextField variant="outlined" label="Code" margin="normal" size="small" fullWidth
                  required value={requestParamKey} disabled={requestParamId !== 0}
                  onChange={(e) => {
                    setRequestParamKey(e.target.value);
                  }} />
              </div>

              <div className="col-6">
                <TextField variant="outlined" label="Value" margin="normal" size="small" fullWidth
                  required value={requestParamValue}
                  onChange={(e) => {
                    setRequestParamValue(e.target.value);
                  }} />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-cancel"
            data-dismiss="modal"
            onClick={() => setShowRequestParamModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-submit"
            data-dismiss="modal"
            onClick={() => requestParamId === 0 ? addHttpParam() : updateHttpParam()}
            style={{ float: "right", marginTop: "0" }}
          >
            Add Parameter
          </button>
        </Modal.Footer>
      </Modal>

      <PreviewDataModal show={showRequestPreviewRawModal} onHide={() => handleCloseModalPreviewRaw()} maxRows={maxRowsRawData}
        rowCount={previewRawData && previewRawData?.rawData?.length || 0} showPickColumnButton={false} dataLoaded={previewRawData.loaded}
        requestedRowsOptions={[5, 15, 25, 50, 100]} onRequestedRowsUpdated={(requestedRows) => setMaxRowsRawData(requestedRows)}
        requestedOrderByOptions={previewRawData?.rawData[0] && Object.keys(previewRawData?.rawData[0]).map(rowColumn => ({ code: rowColumn, label: `Field ${rowColumn}` }))}
        onRequestedOrderByUpdated={(requestedOrderBy) => setOrderByCodeRawData(requestedOrderBy)}
        firstRow={previewRawData?.rawData[0]} dataSet={previewRawData?.rawData} onPickColumn={() => { }}
        title={'Preview Raw Data'} introText={(sourceType === SOURCE_TYPE.JSON_REST_API ? 'Starting Path: ' + previewRawData?.jsonStartingPath + '. ' : '') + `Showing ${previewRawData?.rawData?.length} rows`} />

      <PreviewDataModal show={showRequestPreviewFormattedModal} onHide={() => handleCloseModalPreviewFormatted()} maxRows={maxRowsFormattedData}
        rowCount={previewFormattedData && previewFormattedData?.data?.rowCount || 0} showPickColumnButton={false} dataLoaded={previewFormattedData.loaded}
        requestedRowsOptions={[5, 15, 25, 50, 100]} onRequestedRowsUpdated={(requestedRows) => setMaxRowsFormattedData(requestedRows)}
        requestedOrderByOptions={previewFormattedData?.data?.records[0] && Object.keys(previewFormattedData?.data?.records[0]).map(rowColumn => ({ code: rowColumn, label: `${rowColumn}` }))}
        onRequestedOrderByUpdated={(requestedOrderBy) => setOrderByCodeFormattedData(requestedOrderBy)} templateId={templateId}
        firstRow={previewFormattedData?.data?.records[0]} dataSet={previewFormattedData?.data?.records} onPickColumn={() => { }}
        title={'Preview Formatted Data'} introText={`Showing ${previewFormattedData?.data?.records?.length} rows...`} dataSetHasFilters={previewFormattedData && (previewFormattedData?.filters?.length || 0) > 0}
        onRefreshPreviewData={() => refreshPreviewFormattedData()} />

    </div>
  );
}

export default withRouter(TemplateDataExtractor);
