import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import UserService from "../../Services/UserService";

import Swal from 'sweetalert2';

export default function UserModal(props) {

  const [email, setEmail] = useState('');
  const userService = new UserService();


  function save() {

    const request = {
      userName: email,
      autoOTP: true,
      customerId: props.customerId
    }

    Swal.fire({
      title: 'Ready to save?',
      showCancelButton: true,
      confirmButtonText: 'Save',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return userService.createUser(request)
          .then(response => {
            if (response.status !== 200) {
              Swal.fire({
                title: response.data.message,
                icon: 'error'
              })
              return;
            }

            props.close();

            Swal.fire({
              icon: 'success',
              title: 'User Created',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Failed to create the user.`
            )
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {

    });
  }

  return (
    <Modal show={props.open} onHide={props.close}>
      <Modal.Header closeButton>
        <Modal.Title>Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="inputEmail">Email</label>
          <input
            type="email"
            className="form-control"
            id="inputEmail"
            aria-describedby="email"
            placeholder="Enter email"
            onChange={e => setEmail(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-cancel"
          data-dismiss="modal"
          onClick={props.close}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-submit"
          data-dismiss="modal"
          onClick={() => save()}
          style={{ float: "right", marginTop: "0" }}

        >
          Add User
        </button>
      </Modal.Footer>
    </Modal>
  );
}
