import axios from 'axios';
import { environment } from '../environment';

class TemplateParameterService {

  findAll = (templateId) => {
    return axios.get(environment.API_URL + `templates/${templateId}/parameters`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }

  save = (templateId, data) => {
    return axios.post(environment.API_URL + `templates/${templateId}/parameters`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }

  update = (templateId, id, data) => {
    return axios.put(environment.API_URL + `templates/${templateId}/parameters/${id}`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }

  delete = (templateId, id) => {
    return axios.delete(environment.API_URL + `templates/${templateId}/parameters/${id}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }

}

export default TemplateParameterService;