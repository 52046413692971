import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailerSchedulerService from "../../Services/EmailerSchedulerService";
import del from "../../images/Delete.png";
import ScheduleModal from "./ScheduleModal";

export default function DeleteSchedulerModal(props) {
  const [showdeletescheduleModal, setShowdeletescheduleModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const emailerSchedulerService = new EmailerSchedulerService();
  const [message, setMessage] = useState('');
  function deleteScheduler() {
    debugger
    setShowLoading(true);

    let appointment = props.appointment;
    console.log(appointment);
    callApiToDeleteSchedular();
    setShowLoading(false)

  }
  async function callApiToDeleteSchedular() {
    let body = { id: props.appointment }
    emailerSchedulerService.deleteEmailerSchedular(body)
      .then(response => {
        if (!response.statusCode == 200) {
          throw new Error(response.statusText)
        }
        debugger

        props.close();
        setMessage(
          ` Scheduler has been deleted.`
        )
        setShowScheduleModal(true);
        props.onSave('test');

      })
      .catch(error => {
        props.onSaveComplete();
        props.close();
        setMessage(
          `Failed to delete Scheduler. Please contact your administrator.`
        )
        setShowScheduleModal(true);

      })
  }
  return (
    <>
      <Modal show={props.open} onHide={props.close}>
        <Modal.Body>
          <div className="form-group">
            <img
              style={{ display: "block", margin: "auto", width: "auto" }}
              src={del}
            />
            <h6 style={{ textAlign: "center", marginTop: "20px" }}>
              Do you want to remove email scheduler?
            </h6>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button
            type="button"
            className="btn btn-cancel"
            data-dismiss="modal"
            onClick={props.close}
          >
            Cancel
          </button>
          <LoadingButton
            className="btn btn-delete"
            onClick={() => {

              deleteScheduler()

            }}
            loading={showLoading}
            loadingPosition="start"
            startIcon={<DeleteIcon />}
            variant="contained"
            style={{ float: "right", marginTop: "0" }}
          >
            Delete
          </LoadingButton>
        </Modal.Footer>
      </Modal>
      <ScheduleModal
        open={showScheduleModal}
        close={() => setShowScheduleModal(false)}
        message={message}
      />
    </>
  );
}
