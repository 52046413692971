import React, { useEffect, useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MuiPhoneNumber from 'material-ui-phone-number';

import settingService from "../../Services/settingService";
import authServices from "../../Services/authServices";
import Swal from "sweetalert2";
import { IconButton, Input, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

function SettingNav(props) {
  const [apiToggle, setApiToggle] = useState(false);
  const [passToggle, setPassToggle] = useState(false);
  const [mfaToggle, setMfaToggle] = useState(false);
  const [apiKey, setApiKey] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordValidation, setConfirmPasswordValidation] = useState('');
  const [publicKey, setPublicKey] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [alignment, setAlignment] = useState('1');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [superAdmin, setsuperAdmin] = useState(JSON.parse(sessionStorage.getItem('currentCustomer')).SUPERADMIN);



  const [showPublicKey, setShowPublicKey] = useState(false);
  const [showPrivatekey, setShowPrivateKey] = useState(false);

  //setting service instance
  const apiKeyService = new settingService();

  //creating the auth service
  const myServiceInstance = new authServices();
  const customer = myServiceInstance.getCurrentCustomer();
  if (!customer) {
    props.history.push("/Login")
    // location.reload("/Login");
  }

  const [styles, animate] = useSpring(() => ({
    width: "0px",
    opacity: 0,
    zIndex: 10,
    boxShadow: "0px 10px 20px 0px rgba(0,0,0,0.4)",
    config: { duration: 3 },
  }));

  const [apiStyle, apiAnimate] = useSpring(() => ({ display: "none" }));

  const [passStyle, passAnimate] = useSpring(() => ({ display: "none" }));

  const [mfaStyle, mfaAnimate] = useSpring(() => ({ display: "none" }));

  useEffect(() => {
    animate({
      opacity: props.open ? 1 : 0,
      width: props.open ? "350px" : "0px",
    });
  }, [props.open]);
  
  useEffect(() => {
    if(props.checkApiKey) {
      setApiToggle(true);
    }
  }, [props.checkApiKey]);

  useEffect(() => {
    apiAnimate({
      display: apiToggle ? "block" : "none",
      opacity: apiToggle ? 1 : 0,
      config: { duration: 300 },
    });
  }, [apiToggle]);

  useEffect(() => {
    passAnimate({
      display: passToggle ? "block" : "none",
      opacity: passToggle ? 1 : 0,
      config: { duration: 300 },
    });
  }, [passToggle]);

  useEffect(() => {
    mfaAnimate({
      display: mfaToggle ? "block" : "none",
      opacity: mfaToggle ? 1 : 0,
      config: { duration: 300 },
    });
  }, [mfaToggle]);



  //fetching the pulic key and the secret key for the application

  useEffect(() => {
    if (apiToggle) {
      console.log(customer.id, 'get all key')
      apiKeyService.getApiKey(customer.id).then(response => {
        // console.log('Response: ', response);
        setApiKey(response);
        setPublicKey(response.key);
        setSecretKey(response.secret);
      });
    }
  }, [apiToggle]);

  useEffect(() => {
    setPhoneNumber(customer.phoneNumber)
    if (customer.mfa === true) {
      setAlignment('1')
    } else {
      setAlignment('0')
    }

  }, [mfaToggle]);


  function updateApiKey() {

    let updatedData = {
      key: publicKey,
      secret: secretKey
    }
    // apiKeyService.addApiKey(customer.id, updatedData).then(response => {


    // })

    Swal.fire({
      title: 'Update Apptio Keys?',
      showCancelButton: true,
      confirmButtonText: 'Update!',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return apiKeyService.addApiKey(customer.id, updatedData)
          .then(response => {
            if (!response.statusCode == 200) {
              throw new Error(response.statusText)
            }

            Swal.fire({

              icon: 'success',
              title: 'Apptio Keys have been updated.',
              showConfirmButton: false,
              timer: 1500
            })
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Failed to update Apptio Keys.`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {

    });
  }

  function updateMfaSettings() {

    let updatedData = {
      userName: customer.userName,
      mfa: alignment === '1' ? true : false,
      phoneNumber: phoneNumber
    }
    myServiceInstance.updateMfa(updatedData).then(response => {
      if (response?.status === 'SUCCESS') {

        myServiceInstance.setCurrentCustomer({
          id: customer.id,
          userName: customer.userName,
          customerId: customer.customerId,
          customerUrl: customer.customer,
          mfa: response.result.mfa,
          phoneNumber: response.result.phoneNumber
        });
        setPhoneNumber('')
        Swal.fire({
          icon: 'success',
          title: 'MFA Setting updated',
          showConfirmButton: false,
          timer: 1500
        })


      } else {
        Swal.fire({
          title: 'MFA Setting update',
          text: response?.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
      }
    })
  }

  function checkPasswordValidation(e) {
    let pass = e.target.value

    console.log('newPass', pass);
    //at least one number
    var regInt = new RegExp('(?=.*[0-9])')
    var testInt = regInt.test(pass)

    //at least one special char
    var specialChar = new RegExp('(?=.*[!@#$%^&*])')
    var testSpecial = specialChar.test(pass);

    //at least on small letter
    var sletter = new RegExp('(?=.*[a-z])')
    var sletterTest = sletter.test(pass);

    //at least on capital letter
    var cletter = new RegExp('(?=.*[A-Z])')
    var cletterTest = cletter.test(pass);


    if (pass.length <= 7) {
      setPasswordValidation("Password length should be minimum 8 letters.");
    }
    else if (!testInt) {
      setPasswordValidation("Password should contain at least one digit.");
    }
    else if (!testSpecial) {
      setPasswordValidation("Password should contain at least one special letter.");
    }
    else if (!sletterTest) {
      setPasswordValidation("Password should contain at least one small letter.");
    }
    else if (!cletterTest) {
      setPasswordValidation("Password should contain at least on capital letter.");
    }
    else {
      setPasswordValidation("");
    }

    setNewPassword(e.target.value)
  }

  function changePassword() {

    if (newPassword.length === 0) {
      setPasswordValidation("Please enter new password");
      return
    }
    if (newPassword !== confirmPassword) {

      setConfirmPasswordValidation("Password does not match the new password");
      return;
    }
    if (newPassword === confirmPassword) {
      setConfirmPasswordValidation('');
    }
    Swal.fire({
      title: 'Change Password',
      showCancelButton: true,
      confirmButtonText: 'Change!',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,

      preConfirm: () => {
        return myServiceInstance.updatePassword({ id: customer.id, password: currentPassword, newPassword: newPassword })
          .then(response => {

            if (response.statusCode !== 200) {
              setCurrentPassword('');
              setNewPassword('');
              setConfirmPassword('');
              throw new Error(response.message)
            }
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            Swal.fire({

              icon: 'success',
              title: 'Password has been changed.',
              showConfirmButton: false,
              timer: 1500
            })



          })
          .catch(error => {

            Swal.fire({
              icon: 'warning',
              title: error,

            })
          })

      },
      allowOutsideClick: () => {

        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        return !Swal.isLoading()
      }
    }).then(() => {
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    })
  }


  function handleChange(event, newAlignment) {
    setAlignment(newAlignment);
  }

  function handlePhoneNumberChange(value) {
    setPhoneNumber(value)
  }

  return (
    <>
      <animated.div style={styles} className="sidenav">
        <a className="closebtn" onClick={props.close}>
          &times;
        </a>
        <a>Settings</a>

        {!superAdmin ?
          <a href="#" onClick={() => setApiToggle(!apiToggle)}>
            Client API Key{" "}
            <FontAwesomeIcon icon={faAngleDown} className="angle-icon" />
          </a>
          : ""}
          {!superAdmin ? 
        <animated.div id="apiDiv" style={apiStyle}>
          <form className="toggleDiv-wrapper">
            <div className="form-group">
              <label htmlFor="publicKey">Public Key</label>
              <input
                type="text"
                className="form-control"
                id="publicKey"
                placeholder="Enter Public Key"
                onChange={e => setPublicKey(e.target.value)}
                value={showPublicKey ? publicKey : "<Public Key>"}
                style={!showPublicKey ? { textAlign: "center", fontStyle: "italic", WebkitTextSecurity: "square" } : { textAlign: "left", fontStyle: "normal" }}
                onDoubleClick={() => setShowPublicKey(!showPublicKey)}
                onBlur={() => setShowPublicKey(false)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="secretKey">Secret key</label>
              <input
                type="text"
                className="form-control"
                id="secretKey"
                placeholder="Enter Secret Key"
                onChange={e => setSecretKey(e.target.value)}
                value={showPrivatekey ? secretKey : "<Private Key>"}
                style={!showPrivatekey ? { textAlign: "center", fontStyle: "italic", WebkitTextSecurity: "square" } : { textAlign: "left", fontStyle: "normal" }}
                onDoubleClick={() => setShowPrivateKey(!showPrivatekey)}
                onBlur={() => setShowPrivateKey(false)}
              />
            </div>
            {/* <button className="btn btn-submit" onClick={() => updateApiKey()}>
              Update Key
            </button> */}
            <button type="button" className="btn btn-submit" onClick={() => updateApiKey()}>Update Keys</button>
          </form>
        </animated.div>
        :""}
        <a href="#" onClick={() => setPassToggle(!passToggle)}>
          Change Password{" "}
          <FontAwesomeIcon icon={faAngleDown} className="angle-icon" />
        </a>
        <animated.div id="passDiv" style={passStyle}>
          <form className="toggleDiv-wrapper">
            <div className="form-group">
              <label htmlFor="currentPassword"> Current Password</label>
              <div className="row">
                <div className="col">
                  <Input
                    type={showPassword ? "test" : "password"}
                    className="form-control"
                    id="currentPassword"
                    placeholder="Enter current password"
                    value={currentPassword}
                    onChange={e => setCurrentPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}

                          edge="end"
                        >
                          {!showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }

                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="newPassword"> New Password</label>
              <div className="row">
                <div className="col">
                  <Input
                    type={showNewPassword ? "test" : "password"}
                    className="form-control"
                    id="newPassword"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => checkPasswordValidation(e)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(!showNewPassword)}

                          edge="end"
                        >
                          {!showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <div className="text-danger">{passwordValidation}</div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword"> Confirm Password</label>
              <div className="row">
                <div className="col">
                  <Input
                    type={showConfirmPassword ? "test" : "password"}
                    className="form-control"
                    id="confirmPassword"
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}

                          edge="end"
                        >
                          {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <div className="text-danger">{confirmPasswordValidation}</div>
                </div>
              </div>
            </div>
            <button type="button" className="btn btn-submit" onClick={() => changePassword()}>
              Change Password
            </button>
          </form>
        </animated.div>
        <a href="#" onClick={() => setMfaToggle(!mfaToggle)}>
          MFA Settings{" "}
          <FontAwesomeIcon icon={faAngleDown} className="angle-icon" />
        </a>
        <animated.div id="mfaDiv" style={mfaStyle}>
          <form className="toggleDiv-wrapper">
            <div className="form-group">
              <div className="row">
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                >
                  <ToggleButton value="1">Enabled</ToggleButton>
                  <ToggleButton value="0">Disabled</ToggleButton>

                </ToggleButtonGroup>
              </div>
              <div className="row">
                <label htmlFor="phoneNumberLable">Phone Number to receive MFA code.</label>
                <MuiPhoneNumber value={customer.phoneNumber} className="margin-left-10px-important" defaultCountry={'us'} onChange={handlePhoneNumberChange} />
              </div>
            </div>

            <button className="btn btn-mfa" onClick={() => updateMfaSettings()}>
              Update MFA Settings
            </button>
          </form>
        </animated.div>
      </animated.div>
      {props.open && <div className="overlay" onClick={props.close} />}
    </>
  );
}

export default SettingNav;
