import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { TextField, InputLabel, Select, MenuItem } from '@mui/material';
import * as moment from 'moment-timezone';
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';

import TemplateFilterService from "../../Services/TemplateFilterService";
import { FILTER_TYPE } from "./TemplateFilter";

import "../../Style/MultipleModal.css";

export default function SetDefaultFilters(props) {
  
  const MySwal = withReactContent(Swal);
  const [templateId, setTemplateId] = useState(props.templateId);
  
  const templateFilterService = new TemplateFilterService();
  const [currentFilterValues, setCurrentFilterValues] = useState(new Map());
  const [newFilterValues, setNewFilterValues] = useState(new Map());
  const [filters, setFilters] = useState([]);
  const [showDynamicFilters, setShowDynamicFilters] = useState(false);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  
  useEffect(() => {
	if(templateId !== undefined){
	  setEnableSaveButton(false);
	  setShowDynamicFilters(false);
	  findTemplateFilters(templateId);
	} else {
	  setFilters([]);	
	}
  }, [props.show]);
  
  function findTemplateFilters(templateId) {
    templateFilterService.findAllFilterValues(templateId).then(valuesResponse => {
	  valuesResponse.data.forEach((fv) => { 
		if(fv.filter.type === FILTER_TYPE.DATA_EXTRACTOR_DROPDOWN) {
		  currentFilterValues.set(fv.filter.code, { code: fv.filter.code, comparator: fv.comparator, value: fv.value });
		} else if(fv.filter.type === FILTER_TYPE.CALENDAR) {
		  if(fv.value){
		    const parsedDate = moment(fv.value, fv.filter.outputFormat);
            var month = parsedDate.format('MM');
            var day = parsedDate.format('DD');
            var year = parsedDate.format('YYYY');
            currentFilterValues.set(fv.filter.code, { code: fv.filter.code, comparator: fv.comparator, value: year + '-' + month + '-' + day });
          } else {
	        currentFilterValues.set(fv.filter.code, { code: fv.filter.code, comparator: fv.comparator, value: '' });
          }
		}
	  });
	  
	  setCurrentFilterValues(currentFilterValues);
	  
	  templateFilterService.findAll(templateId, true).then(response => {
        response.data.forEach((item) => {
	      
	      if(item.type === FILTER_TYPE.DATA_EXTRACTOR_DROPDOWN) {
		    let dropdownData = [];
	        dropdownData.push({ label: 'None', value: '' });
	        dropdownData.push.apply(dropdownData, item.dropdownPreviewData);
	    
	        item.dropdownPreviewData = dropdownData;
		  }
	    });
      
        setFilters(response.data);
        setShowDynamicFilters(true);
        setEnableSaveButton(true);
      });
	});
  }
  
  function saveFilters(){
	let isValid = true;
	let filterRequestData = [];
	let filtersSaved = 0;
	  
	// checking required filters
    filters.forEach((fl) => {
	  if(fl.isRequired && ( 
	      (!newFilterValues.has(fl.code) || newFilterValues.get(fl.code).value === '' || newFilterValues.get(fl.code).value.length == 0) &&
	      (!currentFilterValues.has(fl.code) || currentFilterValues.get(fl.code).value === '' || currentFilterValues.get(fl.code).value.length == 0)
	     )) {
	    isValid = false;
		  
		MySwal.fire({
	      title: `Filter '${fl.displayLabel}' is required. Please select any value`,
	      icon: 'warning'
	    });
	        
		return;
	  }
	  
	  if(newFilterValues.has(fl.code)) {  
	    let formattedValue = newFilterValues.get(fl.code).value;
	    
	    if(fl.type === FILTER_TYPE.CALENDAR) {
	      formattedValue = moment(formattedValue).format(fl.outputFormat);
	    }
	    	    
	    filterRequestData.push({ filterCode: fl.code, comparator: 'EQUAL_TO', value: formattedValue });
	  }
    });
    
    if(isValid) {
      setEnableSaveButton(false);
      
      filterRequestData.forEach((filterRequest) => {
	    templateFilterService.saveFilterValue(templateId, filterRequest).then(() => {
          filtersSaved++;
          
          if(filterRequestData.length === filtersSaved) {
            saveSuccess('Filter values were updated');
            
            setFilters([]);
            setShowDynamicFilters(false);
            
            props.onSave();
            props.onHide();
            
            setCurrentFilterValues(new Map());
            setNewFilterValues(new Map());
          }
        }).catch((ex) => {
          errorMessageSaving(ex);
        });  
	  });
	}
  }
  
  function onChangeFilter(filterObj, filterValue) {
	newFilterValues.set(filterObj.code, { code: filterObj.code, value: filterValue, type: filterObj.type, isRequired: filterObj.isRequired, outputFormat: filterObj.outputFormat });
	setNewFilterValues(newFilterValues);
  }
  
  function onChangeDynamicDropdownFilter(filterObj, value){	
    newFilterValues.set(filterObj.code, { code: filterObj.code, type: filterObj.type, isRequired: filterObj.isRequired, value });
    currentFilterValues.get(filterObj.code).value = value;
    
    setNewFilterValues(newFilterValues);
    setCurrentFilterValues(currentFilterValues);
  }
  
  function saveSuccess(message, warning) {
    props.onHide();

    Swal.fire({
      icon: warning ? 'warning' : 'success',
      title: message,
      showConfirmButton: false,
      timer: 1500
    });
  }
  
  function errorMessageSaving(message) {
    Swal.fire({
      title: message,
      icon: 'error',
      allowOutsideClick: false
    });
  }
  
  return (
	<Modal show={props.show} onHide={props.onHide} backdrop="static" keyboard={true} size={'lg'} 
	  aria-labelledby="contained-modal-title-vcenter" centered>
	  <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <p className="mb-4">
                {props.introText}
              </p>
            </div>  
          </div>
          
          {showDynamicFilters ? 
            <>
            {filters.map((item) => (
	          <div key={item.id + '-filter'} className="row mb-2">
	            
	            <div key={item.id + '-label'} className="col-3">
	              <InputLabel id={item.id + '-lbl'}>{item.displayLabel}</InputLabel>
	            </div>
	            
                <div key={item.id} className="col-9">
                  { item.type === FILTER_TYPE.CALENDAR ?
                        <TextField
                          key={item.code}
                          id={item.code}
                          size="small"
                          type="date"
                          onChange={(e) => onChangeFilter(item, e.target.value)}
                          defaultValue={currentFilterValues.get(item.code).value}
                          sx={{ width: 200 }}
                          InputLabelProps={{ shrink: true }} />
                        :
                        <Select key={item.code} id={item.code} size="small"
                          defaultValue={currentFilterValues.get(item.code).value}
                          onChange={(e) => onChangeDynamicDropdownFilter(item, e.target.value)}
                          MenuProps={{ disableScrollLock: true }}>
                          {item.dropdownPreviewData && item.dropdownPreviewData.length
                            ? item.dropdownPreviewData.map((item, index) => (
                              <MenuItem key={`dropdownDefaultValueOption-${index}`} value={item.value}>{item.label}</MenuItem>
                            )) : []}
                        </Select>
                  }
                </div>  
              </div>
            ))}
          </> : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}  
            
          {filters.length === 0 &&
            <p>
              <strong>No filters configured</strong>
            </p>
          }
            
        </Modal.Body>
        <Modal.Footer>
          <div className="mb-3" style={{ float: "right" }}>
            <button type="button" className="btn btn-submit" data-dismiss="modal" style={{ marginTop: "0" }}
              onClick={() => saveFilters()} disabled={!enableSaveButton}>Save</button>
            {'  '} 
            <button type="button" className="btn btn-cancel" data-dismiss="modal" style={{ marginTop: "0" }} 
              onClick={props.onHide}>Cancel</button>  
          </div>    
        </Modal.Footer>
      </form>  
    </Modal>
  );	
}