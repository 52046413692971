import React, { useEffect } from 'react';
// import logo from '../images/logo.png';
import logo from '../images/Boost-Logo-01.png';
import bg from '../images/Boost-BG-emailer.jpg';
import '../Style/Login.css';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import authService from '../Services/authServices';
import AuthCode from 'react-auth-code-input';
import { useState } from 'react';
// import authService from '../../Services/authServices'
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'



// function Email(props) {
function MultifactorAuthentication(props) {
  const [code, setCode] = useState('');
  const [complete, setComplete] = useState(false);
  const [auth, setAuth] = useState(props.location.auth);

  const MySwal = withReactContent(Swal);

  //creating the auth service
  const authenticateService = new authService();

  useEffect(() => {
    
    console.log(code)

    if (code.length === 6) {
      setComplete(true)
      
      authenticateService.verifyMfa({
        'userName': auth.result.userName,
        'mfaCode': code
      }).then((res) => {

        if (res?.status === 'SUCCESS') {

          // Set Token
          authenticateService.setToken(auth.token);
          // Set Customer
          authenticateService.setCurrentCustomer({
            id: res.result.id,
            userName: res.result.userName,
            customerId: res.result.customerId,
            customerUrl: res.result.customer,
            attachmentAllowed: res.result.attachmentAllowed,
            mfa: res.result.mfa,
            phoneNumber: res.result.phoneNumber,
            admin:res.result.admin,
            newDesignerAllowed: res.result.newDesignerAllowed,
            editDataextractorsAllowed: res.result.editDataextractorsAllowed
          });
          // Route to Dashboard
          //TODO need to add here the conditions for the MFA/direct login
          props.history.push("/EmailSchedule")

        } else {
          setComplete(false)
          MySwal.fire({
            title: 'Incorrect MFA code',
            text: res?.message,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          })
        }
      });
    }

  }, [code]);

  return (
    <>
      <div className="container-fluid">
        <div className="row col-rvrse">
          <div className="col-md-5 col-12">
            <div className="container screen">
              <div className="row">
                <div className="col-12">
                  <div className="login-box">
                    <img className="logo" src={logo} alt="login" />
                    <h1 className="main-color">Two Factor Authentication</h1>
                    <AuthCode
                      // allowedCharacters='numeric'
                      length={6}
                      containerClassName='auth-container'
                      inputClassName="auth-input"
                      inputType='number'
                      onChange={(e) => setCode(e)}
                      
                    />
                    {complete ? <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />:''}
                    <p className="main-color">A message with the verification code has been sent to your devices. Enter the code to continue.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-12 bg">
            <div className="web-name">
              <h1 className="main-color">Emailer Plugin for Apptio</h1>
              <h5 className="sec-color">Consolidate data from multiple Apptio reports and send directly to users via email</h5>
            </div>
            <img className="d-img" src={bg} />
          </div>
        </div>
      </div>
    </>
  );


}

export default withRouter(MultifactorAuthentication)