import * as React from "react";
import { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import Swal from "sweetalert2";

import TemplateDataExtractorService from "../../Services/TemplateDataExtractorService";
import TemplateFilterService from "../../Services/TemplateFilterService";
import moment from "moment";

import Skeleton from "react-loading-skeleton";

export const FILTER_TYPE = {
  CALENDAR: 'CALENDAR',
  DATA_EXTRACTOR_DROPDOWN: 'DATA_EXTRACTOR_DROPDOWN'
}

const ERROR_INITIAL_STATUS = {
  DEFAULT: { code: null, displayLabel: null, calendarDefaultValue: null },
  CALENDAR: { outputFormat: null, calendarDefaultValue: null },
  DATA_EXTRACTOR_DROPDOWN: { selectedExtractorId: null, dropdownLabel: null, dropdownValue: null }
}

const DEFAULT_VALUE = {
  CALENDAR: `MMM:[FY]YYYY`
}

function TemplateFilter(props) {

  const templateFilterService = new TemplateFilterService();
  const templateDataExtractorService = new TemplateDataExtractorService();

  const [validForm, setValidForm] = useState(false);
  const [errors, setErrors] = useState({});

  const [id, setId] = useState('');
  const [code, setCode] = useState('');
  const [type, setType] = useState('');
  const [displayLabel, setDisplayLabel] = useState('');
  const [displayOrder, setDisplayOrder] = useState(1);
  const [isRequired, setIsRequired] = useState(true);


  // Calendar
  const [outputFormat, setOutputFormat] = useState(DEFAULT_VALUE.CALENDAR);
  const [calendarDefaultValue, setCalendarDefaultValue] = useState('');
  const [formattedDefaultValue, setFormattedDefaultValue] = useState('');

  // Extractor
  const [isMultiselectable, setIsMultiselectable] = useState(false);
  const [extractors, setExtractors] = useState([]);
  const [selectedExtractorId, setSelectedExtractorId] = useState('');
  const [selectedExtractor, setSelectedExtractor] = useState(null);
  const [dropdownLabel, setDropdownLabel] = useState('');
  const [dropdownValue, setDropdownValue] = useState('');

  const [dropdownDefaultValueOptions, setDropdownDefaultValueOptions] = useState([]);
  const [dropdownDefaultValue, setDropdownDefaultValue] = useState('');
  const [showDropdownOptions, setShowDropdownOptions] = useState(false);

  useEffect(() => {
    if (props.open && !props.filterId) {
      setId('');
      setCode('FILTER_');
      setDisplayLabel('FILTER_');
      setDisplayOrder(1);
      setIsRequired(true);

      setErrors(ERROR_INITIAL_STATUS.DEFAULT);
      setType(FILTER_TYPE.CALENDAR);
    } else {
      setErrors({});
    }
  }, [props.open]);

  useEffect(() => {
    if (props.filterId) {
      templateFilterService.find(props.templateId, props.filterId).then(({ data }) => {
        // Basic
        setId(data.id);
        setCode(data.code);
        setDisplayLabel(data.displayLabel);
        setDisplayOrder(data.displayOrder);
        setType(data.type);
        setIsRequired(data.isRequired);

        validateBasicInfo(data.code, data.displayLabel, data.type);

        // Calendar
        if (data.type === FILTER_TYPE.CALENDAR) {
          setOutputFormat(data.outputFormat);
          setShowDropdownOptions(true);
          templateFilterService.findFilterValue(props.templateId, data.code).then((response) => {
            const parsedDate = moment(response.data.value, data.outputFormat);
            var month = parsedDate.format('MM');
            var day = parsedDate.format('DD');
            var year = parsedDate.format('YYYY');
            const dateToInput = year + '-' + month + '-' + day;
            setCalendarDefaultValue(dateToInput);

            validateCalendarFilter(data.outputFormat, dateToInput);
          }).catch(function(error) {
	        console.log(JSON.stringify(error?.message));
	        
	        //No value found
	        setCalendarDefaultValue('');
	      });
        } else {
          // Data Extractor
          setIsMultiselectable(data.isMultiselectable);
          templateDataExtractorService.findAll(props.templateId).then(response => {
            setExtractors(response.data);
            handleChangeExtractor(response.data, data.dropdownDataExtractor.id);
            setDropdownLabel(data.dropdownLabel);
            setDropdownValue(data.dropdownValue);
            
            validateDropdownFilter(data.dropdownDataExtractor.id, data.dropdownLabel, data.dropdownValue, response.data.value);
          }).catch(function(error) {
	        console.log(JSON.stringify(error?.message));
	        
	        //No value found
	        setDropdownLabel(data.dropdownLabel);
            setDropdownValue(data.dropdownValue);
	      });
          
          templateFilterService.findFilterValue(props.templateId, data.code).then((response) => {
            setDropdownDefaultValue(response.data.value);
            validate("dropdownDefaultValue", response.data.value, true);
          });
        }
      });
    }
  }, [props.filterId]);

  useEffect(() => {
    if (calendarDefaultValue && outputFormat) {
      const formattedDate = moment(calendarDefaultValue).format(outputFormat);
      setFormattedDefaultValue(formattedDate);
    }
  }, [outputFormat, calendarDefaultValue]);
  
  useEffect(() => {
	setShowDropdownOptions(true);
  }, [dropdownDefaultValueOptions]);
  
  function validate(field, value, isRequired) {
    let vErrors = errors ?? {};

    if (isRequired && !value) {
      vErrors[field] = "This field is required";
    } else {
      delete vErrors[field];
    }

    if (Object.keys(vErrors).length) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }

    setErrors(vErrors);
    return !Object.keys(vErrors).length;
  }

  function validateBasicInfo(code, displayLabel, type) {
    validate("code", code, true);
    validate("displayLabel", displayLabel, true);
    return validate("type", type, true);
  }

  function validateCalendarFilter(outputFormat, defaultValue) {
    validate("outputFormat", outputFormat, true);
    return validate("calendarDefaultValue", defaultValue, true);
  }

  function validateDropdownFilter(extractorId, dropdownLabel, dropdownValue) {
    validate("selectedExtractorId", extractorId, true);
    validate("dropdownLabel", dropdownLabel, true);
    return validate("dropdownValue", dropdownValue, true);
  }

  function onTypeChange(value) {
    setValidForm(false);
    if (value === FILTER_TYPE.CALENDAR) {
      setErrors(ERROR_INITIAL_STATUS.CALENDAR);
      resetCalendarType();
    } else {
      setErrors(ERROR_INITIAL_STATUS.DATA_EXTRACTOR_DROPDOWN);
      templateDataExtractorService.findAll(props.templateId).then(response => {
        setExtractors(response.data);
      });
      resetDataExtractorType();
    }
    setType(value);
  }

  function save() {
    validate("code", code, true);
    validate("type", type, true);
    const valid = validate("displayLabel", displayLabel, true);

    if (!valid) {
      return;
    }

    if (type === FILTER_TYPE.CALENDAR) {
      saveCalendarFilter();
    } else {
      saveDropdownFilter();
    }
  }

  function saveCalendarFilter() {
    if (!validateCalendarFilter(outputFormat, calendarDefaultValue)) {
      return;
    }

    const request = {
      templateId: props.templateId,
      code,
      displayLabel,
      type,
      isRequired,
      displayOrder,
      outputFormat
    };

    if (!id) {
      templateFilterService.saveCalendarFilter(props.templateId, request).then(({ data }) => {
        saveCalendarDefaultValue(data);
      }).catch((error) => {
        errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to save the filter`);
      });
    } else {
      templateFilterService.updateCalendarFilter(props.templateId, id, request).then(({ data }) => {
        saveCalendarDefaultValue(data);
      }).catch((error) => {
        errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to save the filter`);
      });
    }
  }

  function saveCalendarDefaultValue(templateFilter) {
    const request = {
      filterCode: templateFilter.code,
      comparator: 'EQUAL_TO',
      value: formattedDefaultValue
    }
    templateFilterService.saveFilterValue(props.templateId, request).then(() => {
      saveSuccess('Filter Saved');
    }).catch(() => {
      saveSuccess('Filter Saved (Default not included)', true);
    });
  }

  function saveDropdownFilter() {
    if (!validateDropdownFilter(selectedExtractorId, dropdownLabel, dropdownValue)) {
      return;
    }

    const request = {
      templateId: props.templateId,
      code,
      displayLabel,
      type,
      isRequired,
      displayOrder,
      isMultiselectable,
      dropdownDataExtractor: {
        id: selectedExtractorId,
        sourceType: selectedExtractor.sourceType
      },
      dropdownLabel,
      dropdownValue
    };

    if (!id) {
      templateFilterService.saveDropdownFilter(props.templateId, request).then(({ data }) => {
        saveDropdownDefaultValue(data);
      }).catch((error) => {
        errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to save the filter`);
      });
    } else {
      templateFilterService.updateDropdownFilter(props.templateId, id, request).then(({ data }) => {
        saveDropdownDefaultValue(data);
      }).catch((error) => {
        errorMessageSaving(error.response.data.message ? error.response.data.message : `Failed to save the filter`);
      });
    }
  }

  function saveDropdownDefaultValue(templateFilter) {
    const request = {
      filterCode: templateFilter.code,
      comparator: 'EQUAL_TO',
      value: dropdownDefaultValue
    }
    templateFilterService.saveFilterValue(props.templateId, request).then(() => {
      saveSuccess('Filter Saved');
    }).catch(() => {
      saveSuccess('Filter Saved (Default not included)', true);
    });
  }

  function saveSuccess(message, warning) {
    closeModal()

    Swal.fire({
      icon: warning ? 'warning' : 'success',
      title: message,
      showConfirmButton: false,
      timer: 1500
    });
  }
  
  function errorMessageSaving(message) {
    Swal.fire({
      title: message,
      icon: 'error',
      allowOutsideClick: false
    });
  }

  function closeModal() {
    resetForm();
    props.close();
  }

  function resetForm() {
    setValidForm(false);
    resetBasicInfo();
    resetCalendarType();
    resetDataExtractorType();
  }

  function resetBasicInfo() {
    setId('');
    setCode('FILTER_');
    setDisplayLabel('FILTER_');
    setIsRequired(true);
    setDisplayOrder(1);
  }

  function resetCalendarType() {
    setOutputFormat(DEFAULT_VALUE.CALENDAR);
    setCalendarDefaultValue('');
    setFormattedDefaultValue('');
  }

  function resetDataExtractorType() {
    setIsMultiselectable(false);
    setSelectedExtractorId('');
    setSelectedExtractor(null);
    setDropdownLabel('');
    setDropdownValue('');
    setDropdownDefaultValue('');
  }

  function handleChangeExtractor(extractors, extractorId) {
    const extractorIndex = extractors.findIndex(extractor => extractor.id === extractorId);
    const extractor = extractors.at(extractorIndex);
    setSelectedExtractor(extractor)
    setSelectedExtractorId(extractorId);
    validate("selectedExtractorId", extractorId, true);
  }
  
  useEffect(() => {
    if(selectedExtractorId !== '' && dropdownLabel !== '' && dropdownValue !== '') {
	  refreshFilterValues();
	} else {
	  setDropdownDefaultValueOptions([]);
	}
  }, [selectedExtractorId, dropdownLabel, dropdownValue]);
  
  function refreshFilterValues() {
	const request = {
      userApptioProfile: JSON.parse(sessionStorage.getItem('currentCustomer')).userName,
      formatValues: true,
      resetDB: true,
      useDistinct: true,
      ignoredFilters: ['ALL'],
      selectFields: [dropdownLabel, dropdownValue]
    }
    
    setShowDropdownOptions(false);
		
    templateDataExtractorService.previewDataExtractor(props.templateId, selectedExtractorId, request).then((response) => {
      if(response && response.data) {
        setDropdownDefaultValueOptions(response.data[selectedExtractor.code]?.data.filter(opt => opt[dropdownValue] && String(opt[dropdownValue]).trim().toUpperCase() !== 'TOTAL'));
      } else {
	    setDropdownDefaultValueOptions([]);
      } 
    });
  }

  return (
    <Modal show={props.open} onHide={() => closeModal()} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="row">
            <div className="col-6">
              <TextField variant="outlined" label="Code" margin="normal" size="small" fullWidth
                required
                value={code}
                onChange={(e) => {
                  validate("code", e.target.value, true);
                  setCode(e.target.value.toUpperCase());
                }}
                error={errors["code"] ? true : false}
                helperText={errors["code"] ? errors["code"] : ""} />
            </div>
            
            <div className="col-6">
              <TextField variant="outlined" label="Display Order" margin="normal" size="small" fullWidth
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={displayOrder}
                onChange={(e) => {
                  validate("displayOrder", e.target.value, false);
                  setDisplayOrder(e.target.value);
                }}
                error={errors["displayOrder"] ? true : false}
                helperText={errors["displayOrder"] ? errors["displayOrder"] : ""} />
            </div>

            <div className="col-12">
              <TextField variant="outlined" label="Display Label" margin="normal" size="small" fullWidth
                required
                value={displayLabel}
                onChange={(e) => {
                  validate("displayLabel", e.target.value, true);
                  setDisplayLabel(e.target.value);
                }}
                error={errors["displayLabel"] ? true : false}
                helperText={errors["displayLabel"] ? errors["displayLabel"] : ""} />
            </div>

            <div className="col-9">
              <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                <InputLabel id="filterTypeLabel" required>Type</InputLabel>
                <Select labelId="filterTypeLabel"
                  value={type}
                  label="Type"
                  required disabled={!(id === undefined || id === '')}
                  onChange={(e) => onTypeChange(e.target.value)}
                >
                  <MenuItem value={FILTER_TYPE.CALENDAR}>Calendar</MenuItem>
                  <MenuItem value={FILTER_TYPE.DATA_EXTRACTOR_DROPDOWN}>Data Extractor</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-3">
              <FormControlLabel style={{ marginLeft: 10 }}
                className="left-aligned" label="Required" labelPlacement="top"
                control={<Switch className="switch-primary"
                  checked={isRequired}
                  onChange={(e) => setIsRequired(e.target.checked)} />} />
            </div>

            {type === FILTER_TYPE.CALENDAR ?
              <>
                <div className="col-12">
                  <TextField variant="outlined" label="Output Format" margin="normal" size="small" fullWidth
                    required
                    value={outputFormat}
                    onChange={(e) => {
                      validate("outputFormat", e.target.value, true);
                      setOutputFormat(e.target.value);
                    }}
                    error={errors["outputFormat"] ? true : false}
                    helperText={errors["outputFormat"] ? errors["outputFormat"] : ""} />
                </div>

                <div className="col-12">
                  <TextField variant="outlined" label="Default Value" margin="normal" size="small" fullWidth
                    className="mb-0"
                    type="date"
                    value={calendarDefaultValue}
                    onChange={(e) => {
                      validate("calendarDefaultValue", e.target.value, true);
                      setCalendarDefaultValue(e.target.value);
                    }}
                    error={errors["calendarDefaultValue"] ? true : false}
                    helperText={errors["calendarDefaultValue"]
                      ? errors["calendarDefaultValue"]
                      : formattedDefaultValue
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </>
              : ''}

            {type === FILTER_TYPE.DATA_EXTRACTOR_DROPDOWN && extractors && extractors.length ?
              <>
                <div className="col-9">
                  <FormControl variant="outlined" margin="normal" size="small" fullWidth error={errors["selectedExtractorId"] ? true : false}>
                    <InputLabel id="filterExtractorLabel" required>Options From Data Extractor</InputLabel>
                    <Select
                      labelId="filterExtractorLabel"
                      label="Options From Data Extractor"
                      required
                      value={selectedExtractorId}
                      onChange={(e) => { 
	                      handleChangeExtractor(extractors, e.target.value);
	                    }
	                  }
                      MenuProps={{ disableScrollLock: true }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {extractors.map((extractor) => (
                        <MenuItem key={extractor.id} value={extractor.id}>{extractor.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors["selectedExtractorId"] ? errors["selectedExtractorId"] : ""}</FormHelperText>
                  </FormControl>
                </div>

                <div className="col-3">
                  <FormControlLabel style={{ marginLeft: 10 }}
                    className="left-aligned" label="Multi-select" labelPlacement="top"
                    control={<Switch className="switch-primary"
                      checked={isMultiselectable}
                      onChange={(e) => setIsMultiselectable(e.target.checked)} />} />
                </div>

                <div className="col-6">
                  <FormControl variant="outlined" margin="normal" size="small" fullWidth error={errors["dropdownLabel"] ? true : false}>
                    <InputLabel id="filterDropdownLabel" required>Dropdown Label</InputLabel>
                    <Select
                      labelId="filterDropdownLabel"
                      label="Dropdown Label"
                      required
                      value={dropdownLabel}
                      onChange={(e) => {
                        validate("dropdownLabel", e.target.value, true);
                        setDropdownLabel(e.target.value);
                      }}
                      MenuProps={{ disableScrollLock: true }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {selectedExtractor && selectedExtractor.fields && selectedExtractor.fields.length
                        ? selectedExtractor.fields.map((field) => (
                          <MenuItem key={field.id} value={field.code}>{field.name}</MenuItem>
                        )) : []}
                    </Select>
                    <FormHelperText>{errors["dropdownLabel"] ? errors["dropdownLabel"] : ""}</FormHelperText>
                  </FormControl>
                </div>

                <div className="col-6">
                  <FormControl variant="outlined" margin="normal" size="small" fullWidth error={errors["dropdownValue"] ? true : false}>
                    <InputLabel id="filterDropdownLabel" required>Dropdown Value</InputLabel>
                    <Select
                      labelId="filterDropdownLabel"
                      label="Dropdown Value"
                      required
                      value={dropdownValue}
                      onChange={(e) => {
                        validate("dropdownValue", e.target.value, true);
                        setDropdownValue(e.target.value);
                      }}
                      MenuProps={{ disableScrollLock: true }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {selectedExtractor && selectedExtractor.fields && selectedExtractor.fields.length
                        ? selectedExtractor.fields.map((field) => (
                          <MenuItem key={field.id} value={field.code}>{field.name}</MenuItem>
                        )) : []}
                    </Select>
                    <FormHelperText>{errors["dropdownValue"] ? errors["dropdownValue"] : ""}</FormHelperText>
                  </FormControl>
                </div>

                { false && // Temporary disabled, the user must choose a dropdown value
                <div className="col-12">
                  <TextField variant="outlined" label="Default Value" margin="normal" size="small" fullWidth
                    required
                    value={dropdownDefaultValue}
                    onChange={(e) => {
                      validate("dropdownDefaultValue", e.target.value, false);
                      setDropdownDefaultValue(e.target.value);
                    }}
                    error={errors["dropdownDefaultValue"] ? true : false}
                    helperText={errors["dropdownDefaultValue"] ? errors["dropdownDefaultValue"] : ""} />
                </div>
                }
                
                <div className="col-12">
                  {!showDropdownOptions && <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                  
                  <FormControl variant="outlined" margin="normal" size="small" fullWidth error={errors["dropdownDefaultValue"] ? true : false}>
                    <InputLabel id="filterDropdownDefaultValueLabel" required>Default Value</InputLabel>
                      <Select
                        labelId="filterDropdownDefaultValueLabel"
                        label="Default Value"
                        required
                        value={dropdownDefaultValue}
                        onChange={(e) => {
                          validate("dropdownDefaultValue", e.target.value, true);
                          setDropdownDefaultValue(e.target.value);
                        }}
                        MenuProps={{ disableScrollLock: true }}
                      >
                      <MenuItem value="">None</MenuItem>
                        {dropdownDefaultValueOptions && dropdownDefaultValueOptions.length
                          ? dropdownDefaultValueOptions.map((item, index) => (
                            <MenuItem key={`dropdownDefaultValueOption-${index}`} value={item[dropdownValue]}>{item[dropdownLabel]}</MenuItem>
                          )) : []}
                      </Select>
                      <FormHelperText>{errors["dropdownDefaultValue"] ? errors["dropdownDefaultValue"] : ""}</FormHelperText>
                    </FormControl>
                </div>
              </>
              : ''}

          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-cancel"
          data-dismiss="modal"
          onClick={() => closeModal()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-submit"
          data-dismiss="modal"
          onClick={() => save()}
          style={{ float: "right", marginTop: "0" }}
          disabled={!validForm}
        >
          Add Filter
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default withRouter(TemplateFilter);