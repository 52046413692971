import React, { useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";

const withSidebarAnimation = (Component) => {
  return function WithWrapper(props) {
    const [styles, animate] = useSpring(() => ({
      width: "80px",
      zIndex: 10,
      marginRight: "-12px",
      overflow: 'hidden',
      config: { duration: 3 },
    }));

    useEffect(() => {
      if (props.sideBarExpanded) {
        animate({ width: "200px" });
      } else {
        animate({ width: "80px" });
      }
    }, [props.sideBarExpanded]);

    return <Component {...props} sidebarStyles={styles} />;
  };
};

export default withSidebarAnimation;
