import React, { useState } from 'react';

import { TextField, ColorPickerField, NumberField, SwitchField, TextAreaField } from 'easy-email-extensions';
import { AttributesPanelWrapper } from 'easy-email-extensions';
import { SelectField, EditGridTabField } from 'easy-email-extensions';

import { Grid, Space } from '@arco-design/web-react';

import { styled } from '@mui/material/styles';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useEffect } from 'react';
import TemplateDataExtractorService from '../../../../Services/TemplateDataExtractorService';
import { Stack, useBlock, useFocusIdx, TextStyle } from 'easy-email-editor';
import { ChartType, debounceTime, BehaviourWhenNoData } from '../constants';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AbcIcon from '@mui/icons-material/Abc';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ToggleOffOutlined from '@mui/icons-material/ToggleOffOutlined';

import { now } from 'moment';
import { TAG_NAME } from '.';

import { ChartOptions } from '../../Common/ChartOptions';
import { useFocusedInput } from '../utils/useFocusedInput';
import { get } from 'lodash';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightSharpIcon />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'var(--color-neutral-1)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export function Panel() {

  const templateDataExtractorService = new TemplateDataExtractorService();

  const templateId = document.getElementById('designerTemplateId').value;

  const { change, focusBlock, setValueByIdx, values } = useBlock();
  const { focusIdx } = useFocusIdx();
  const { setFocusedInput } = useFocusedInput();

  const [active, setActive] = useState('')
  const [expanded, setExpanded] = useState(true);

  const [chartType, setChartType] = useState('');

  const [extractors, setExtractors] = useState([]);
  const [extractorsAsOptions, setExtractorsAsOptions] = useState([]);
  const [selectedExtractorId, setSelectedExtractorId] = useState('');
  const [selectedExtractorFieldsAsOptions, setSelectedExtractorFieldsAsOptions] = useState([]);
  const [isArray, setIsArray] = useState(false);

  const [chartItemType, setChartItemType] = useState({ index: undefined, value: undefined });
  const [chartItemColor1, setChartItemColor1] = useState({ index: undefined, value: undefined });
  const [chartItemColor2, setChartItemColor2] = useState({ index: undefined, value: undefined });

  useEffect(() => {
    // Assigning the id and src (this will help when duplicating component)
    // Warning: This change is undoable, if the user clicks on undo button, can revert this
    const idx = now();
    change(focusIdx + `.data.value.id`, `${TAG_NAME}${idx}`);
    change(focusIdx + `.attributes.src`, `{{${TAG_NAME}${idx}}}`);

    templateDataExtractorService.findAllNotHidden(templateId).then(response => {
      setExtractors(response.data);

      const extractorsOpt = response.data.map(extractor => {
        return { label: extractor.name, value: extractor.id }
      });
      extractorsOpt.unshift({ label: 'None', value: '' });
      setExtractorsAsOptions(extractorsOpt);

      if (focusBlock && focusBlock.data.value.source) {
        handleChangeExtractor(response.data, focusBlock.data.value.source, false);
      }
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }, []);

  // Settings
  useEffect(() => {
    if (chartType) {
      focusBlock.data.value.datasets.forEach(dataset => {
        dataset.type = chartType;
      });
    }
  }, [chartType]);

  // Datasource
  useEffect(() => {
    handleChangeExtractor(extractors, selectedExtractorId, true);
  }, [selectedExtractorId]);

  useEffect(() => {
    const arrayTag = '[0]';
    const rowTag = '[*]';

    if (isArray) {
      focusBlock.data.value.labels = focusBlock.data.value.labels.replace(rowTag, arrayTag)
      focusBlock.data.value.datasets.forEach((item) => {
        if (!Array.isArray(item.data)) { // Not default value
          item.data = item.data.replace(rowTag, arrayTag);
        }
      });

      selectedExtractorFieldsAsOptions.forEach((field) => {
        field.value = field.value.replace(rowTag, arrayTag);
      });
    } else {
      focusBlock.data.value.labels = focusBlock.data.value.labels.replace(arrayTag, rowTag)
      focusBlock.data.value.datasets.forEach((item) => {
        if (!Array.isArray(item.data)) { // Not default value
          item.data = item.data.replace(arrayTag, rowTag);
        }
      });

      selectedExtractorFieldsAsOptions.forEach((field) => {
        field.value = field.value.replace(arrayTag, rowTag);
      });
    }
  }, [isArray]);

  // Dataset Items
  useEffect(() => {
    if (chartItemType && chartItemType.index !== undefined) {
      if (chartItemType.value === ChartType.LINE) {
        const item = focusBlock.data.value.datasets[chartItemType.index];
        if (item.fill === undefined) {
          item.fill = false;
        }

        if (item.lineTension === undefined) {
          item.lineTension = 0.5;
        }
      } else if (chartItemType.value === ChartType.BAR) {
        const item = focusBlock.data.value.datasets[chartItemType.index];
        item.fill = undefined;
        item.lineTension = undefined;
      }
    }
  }, [chartItemType]);

  useEffect(() => {
    if (chartItemColor1 && chartItemColor1.index !== undefined) {
      const item = focusBlock.data.value.datasets[chartItemColor1.index];
      if (chartItemColor1.value && item.backgroundColor2) {
        const direction = item.type === ChartType.HORIZONTAL_BAR ? 'horizontal' : 'vertical';
        item.backgroundColor = `getGradientFillHelper('${direction}', ['${chartItemColor1.value}', '${item.backgroundColor2}'])`;
      } else {
        item.backgroundColor = chartItemColor1.value;
      }
    }
  }, [chartItemColor1]);

  useEffect(() => {
    if (chartItemColor2 && chartItemColor2.index !== undefined) {
      const item = focusBlock.data.value.datasets[chartItemColor2.index];
      if (item.backgroundColor1 && chartItemColor2.value) {
        const direction = item.type === ChartType.HORIZONTAL_BAR ? 'horizontal' : 'vertical';
        item.backgroundColor = `getGradientFillHelper('${direction}', ['${item.backgroundColor1}', '${chartItemColor2.value}'])`;
      } else {
        item.backgroundColor = item.backgroundColor1;
      }
    }
  }, [chartItemColor2]);

  function onChangeAccordion(tab) {
    if (tab !== active) {
      setExpanded(true);
    } else {
      setExpanded(!expanded);
    }
    setActive(tab);
  }

  function handleChangeExtractor(extractors, extractorId, cleanPreSelectedField) {
    if (extractorId) {
      const extractorIndex = extractors.findIndex(extractor => extractor.id === extractorId);
      const extractor = extractors.at(extractorIndex);
      const extractorsFieldsOpt = extractor.fields.map(field => {
        return {
          label: <ListItem key={field.id} className="p-0">
            <ListItemAvatar sx={{ width: 24, minWidth: 34 }}>
              <Avatar sx={{ width: 24, height: 24 }} style={{ fontSize: 9, fontWeight: 900 }}>
                {field.dataType === 'STRING' ? <AbcIcon />
                  : field.dataType === 'NUMERIC' ? 123
                    : field.dataType === 'BOOLEAN' ? <ToggleOffOutlined />
                      : <MoreHorizIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              title={field.name}
              primary={field.name} primaryTypographyProps={{ fontSize: 12, noWrap: true }}
              secondary={field.dataType} secondaryTypographyProps={{ fontSize: 10, noWrap: true }}
              style={{ overflowWrap: "anywhere" }} />
          </ListItem>,
          value: `{{${extractor.code}.data.[*].${field.code}}}`
        }
      });
      extractorsFieldsOpt.unshift({
        label: <div style={{ padding: "6px 0px" }}>None</div>,
        value: ''
      });
      setSelectedExtractorFieldsAsOptions(extractorsFieldsOpt);

      // cleaning pre-selected values
      if (cleanPreSelectedField && focusBlock) {
        focusBlock.data.value.labels = '';
        setValueByIdx(focusIdx, { ...focusBlock });
      }
    }
  }



  return (
    <AttributesPanelWrapper>
      <Accordion expanded={active === 'settings' && expanded} onChange={() => onChangeAccordion('settings')}>
        <AccordionSummary>Setting</AccordionSummary>
        <AccordionDetails>
          <Stack
            vertical
            spacing='tight'
          >
            <SelectField
              label='Type'
              name={`${focusIdx}.data.value.type`}
              options={[
                { label: 'Vertical', value: ChartType.BAR },
                { label: 'Horizontal', value: ChartType.HORIZONTAL_BAR }
              ]}
              onChangeAdapter={(value) => {
                setChartType(value);
                return value;
              }}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={active === 'datasource' && expanded} onChange={() => onChangeAccordion('datasource')}>
        <AccordionSummary>Datasource</AccordionSummary>
        <AccordionDetails>
          <Stack
            vertical
            spacing='tight'
          >
            <SelectField
              label='Select a Data Extractor'
              name={`${focusIdx}.data.value.source`}
              options={extractorsAsOptions}
              onChangeAdapter={(value) => {
                setSelectedExtractorId(value);
                return value;
              }}
            />

            <SelectField
              label='Labels'
              name={`${focusIdx}.data.value.labels`}
              options={selectedExtractorFieldsAsOptions}
              className='dropdownWithSecondaryLabel'
              helpText='Select a Data Extractor Field for X-axis'
            />
            
            <SelectField
                label='Behavior when there is no data'
                name={`${focusIdx}.data.value.behaviorWhenNoData`}
                options={[
                  { label: 'Show Missing Data Placeholder', value: BehaviourWhenNoData.SHOW_MISSING_DATA_PLACEHOLDER },
                  { label: 'Hide Chart', value: BehaviourWhenNoData.HIDE_CHART }
                ]}
              />

            <EditGridTabField
              tabPosition='top'
              name={`${focusIdx}.data.value.datasets`}
              label='Datasets'
              renderItem={(item, index) => (
                <>
                  <Space direction='vertical'>
                    {focusBlock.data.value.type === ChartType.BAR &&
                      <Grid.Row>
                        <Grid.Col span={7}>
                          <SelectField
                            label='Type'
                            name={`${focusIdx}.data.value.datasets.[${index}].type`}
                            options={[
                              { label: 'Bar', value: 'bar' },
                              { label: 'Line', value: 'line' }
                            ]}
                            onChangeAdapter={(value) => {
                              setChartItemType({ index, value });
                              return value;
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>
                    }

                    <Grid.Row>
                      <Grid.Col span={24}>
                       <Space align='center'>
                          <TextStyle>Label</TextStyle>
                          <SwitchField
                            name={`${focusIdx}.data.value.datasets.[${index}].advancedMode`}
                            defaultChecked
                            inline
                            checkedText='Advanced'
                            uncheckedText='Basic'
                          />
                        </Space>
                        {get(values, `${focusIdx}.data.value.datasets.[${index}].advancedMode`) ?
                          <SelectField labelHidden
                            name={`${focusIdx}.data.value.datasets.[${index}].label`}
                            options={selectedExtractorFieldsAsOptions}
                            className='dropdownWithSecondaryLabel'
                          />
                          :
                          <TextAreaField
                            name={`${focusIdx}.data.value.datasets.[${index}].label`}
                            className="textarea-no-resize" rows={3}
                            onFocus={setFocusedInput}
                            debounceTime={debounceTime}
                          />
                        }

                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col span={24}>
                        <SelectField
                          label='Data'
                          name={`${focusIdx}.data.value.datasets.[${index}].data`}
                          options={selectedExtractorFieldsAsOptions}
                          className='dropdownWithSecondaryLabel'
                          helpText='Select a Data Extractor Field for Y-axis'
                        />
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col span={24}>
                        <SwitchField label='Remove when values equal to zero?'
                          name={`${focusIdx}.data.value.datasets.[${index}].removeWhenValuesZero`}
                          debounceTime={debounceTime}
                          helpText='Will remove this entire series from the chart when their values are zero'
                          checkedText='Yes'
                          uncheckedText="No"
                        />
                      </Grid.Col>
                    </Grid.Row>

                    {item.type === ChartType.LINE ?
                      <Grid.Row>
                        <Grid.Col span={12}>
                          <NumberField
                            label='Line Tension'
                            name={`${focusIdx}.data.value.datasets.[${index}].lineTension`}
                            step={0.25}
                            debounceTime={debounceTime}
                          />
                        </Grid.Col>
                        <Grid.Col offset={1} span={11}>
                          <SwitchField
                            label='Fill'
                            name={`${focusIdx}.data.value.datasets.[${index}].fill`}
                            debounceTime={debounceTime}
                          />
                        </Grid.Col>
                      </Grid.Row>
                      : ''
                    }

                    <Grid.Row>
                      <Grid.Col span={12}>
                        <NumberField
                          label={item.type === ChartType.LINE ? 'Line Thickness' : 'Border Width'}
                          name={`${focusIdx}.data.value.datasets.[${index}].borderWidth`}
                          debounceTime={debounceTime}
                        />
                      </Grid.Col>
                      <Grid.Col offset={1} span={11}>
                        <ColorPickerField
                          label={item.type === ChartType.LINE ? 'Line Color' : 'Border Color'}
                          name={`${focusIdx}.data.value.datasets.[${index}].borderColor`}
                          debounceTime={debounceTime}
                        />
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col span={12}>
                        <ColorPickerField
                          label='Background Color'
                          name={`${focusIdx}.data.value.datasets.[${index}].backgroundColor1`}
                          onChangeAdapter={(value) => {
                            setChartItemColor1({ index, value });
                            return value;
                          }}
                          debounceTime={debounceTime}
                        />
                      </Grid.Col>
                      <Grid.Col offset={1} span={11}>
                        <ColorPickerField
                          label='Background Color 2'
                          name={`${focusIdx}.data.value.datasets.[${index}].backgroundColor2`}
                          onChangeAdapter={(value) => {
                            setChartItemColor2({ index, value });
                            return value;
                          }}
                          helpText='To add gradient behavior'
                          debounceTime={debounceTime}
                        />
                      </Grid.Col>
                    </Grid.Row>

                  </Space>
                </>
              )}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={active === 'options' && expanded} onChange={() => onChangeAccordion('options')}>
        <AccordionSummary>Options</AccordionSummary>
        <AccordionDetails className="p-0">
          <Stack vertical spacing='tight'>
            <ChartOptions focusIdx={focusIdx} />
          </Stack>
        </AccordionDetails>
      </Accordion>

    </AttributesPanelWrapper >
  );
}