import axios from 'axios';
import { environment } from '../environment';

class OshkoshApplicationService {

  getAllOshkoshTemplatesForUser = (month) => {
    return axios.get(environment.API_URL + `email/oshkoshApplications?month=` + month)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  getCostCenterList = (month) => {
    return axios.get(environment.API_URL + `email/costCenters?month=` + month)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  getVPPortfolioList = (month) => {
    return axios.get(environment.API_URL + `email/vpPortfolios?month=` + month)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  getMarketGroups = (month) => {
    return axios.get(environment.API_URL + `email/marketGroups?month=` + month)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  getFacilities = (month) => {
    return axios.get(environment.API_URL + `email/businessUnits?month=` + month)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  getCloudServices = (month) => {
    return axios.get(environment.API_URL + `email/cloudServices?month=` + month)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  //send email from edit reciepients
  getDropDownValues = (body) => {
    return axios.post(environment.API_URL + `email/fetchDropDownValues`, body)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        throw error;
      })
  }
}

export default OshkoshApplicationService;