import * as React from "react";
import { withRouter } from "react-router-dom";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import SideBar from "./SideBar";
import TopBar from './TopBar';
import { useState, useEffect } from 'react';
import authService from '../../Services/authServices';
import AnimatedBody from "../../Wrappers/AnimatedBody";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import TemplateComponentService from '../../Services/TemplateComponentService';

import SearchBar from "material-ui-search-bar";

function TemplateComponents(props) {

  const authServiceInstance = new authService();
  const templateComponentService = new TemplateComponentService();
  
  const [filteredTemplateComponents, setFilteredComponents] = useState(null);
  const [templateComponents, setTemplateComponents] = useState(null);
  
  const [customerName, setCustomerName] = useState('');

  const loggedInCustomer = authServiceInstance.getCurrentCustomer();
  if (!loggedInCustomer?.SUPERADMIN) {
    //props.history.push("/Login");
  }
	
  useEffect(() => {
    findAllComponents();
  }, []);

  const findAllComponents = () => {
	templateComponentService.getAllTemplateComponents().then(response => {
      setFilteredComponents(response.data);
      setTemplateComponents(response.data);
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }
  
  function requestSearch(searchedVal) {
    if (searchedVal) {
      const filteredData = templateComponents.filter((templateComponent) => {
        return templateComponent.name?.toLowerCase().includes(searchedVal.toLowerCase());
      });

      setFilteredComponents(filteredData);
    } else {
      setFilteredComponents(templateComponents);
    }
  }

  function cancelSearch() {
    setFilteredComponents(templateComponents);
  }	
	
  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <SideBar
        sideBarExpanded={props.sideBarExpanded}
        setSideBarExpanded={props.setSideBarExpanded}
      />
      <div id="main">
        <div className="container-fluid custom-container">

          <AnimatedBody
            sideBarExpanded={props.sideBarExpanded}
            setSideBarExpanded={props.setSideBarExpanded}
            className="container-fluid custom-container"
          >
            <TopBar title={"Template Components"} />
                      
            <div className="row row-margin row-left">
              <div className="col-12">
                <SearchBar className="MuiPaper-outlined"
                  style={{ boxShadow: 'none' }}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                />
                <table style={{ width: "100%", overflow: "scroll !important" }}>
                  <thead>
                    <tr className="line">
                      <th className="line-data">Customer</th>
                      <th className="line-data">Name</th>
                      <th className="line-data">Icon</th>
                      <th className="line-data">Is Active</th>
                      <th className="line-data">Description</th>
                      <th className="line-data" width={250} style={{ textAlign: "right" }}>
                        <button type="button" className="btn btn-submit"
                          style={{ marginTop: "0" }}
                          onClick={() => props.history.push({ pathname: "/TemplateComponent" })}>
                          Create Component
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTemplateComponents ?
                      filteredTemplateComponents.map((templateComponent) => (
                        <tr key={templateComponent.id} className="line">
                          <td className="line-data">
                            <img className="data-img" style={{ marginLeft: 10, width: 64 }} src={templateComponent.customer.logoURL ? templateComponent.customer.logoURL + "?" + Date.now() : null} />
                            {templateComponent.customer.customerName}
                          </td>
                          <td className="line-data">{templateComponent.name}</td>
                          <td className="line-data">
                            <img className="data-img" style={{ marginLeft: 10, width: 64 }} src={templateComponent.icon ? templateComponent.icon + "?" + Date.now() : null} />
                          </td>
                          <td className="line-data">{templateComponent.isActive ? 'Yes' : 'No'}</td>
                          <td className="line-data">{templateComponent.description}</td>
                          <td className="line-data" style={{ textAlign: "right" }}>
                            <button type="button" className="btn btn-light"
                              style={{ marginTop: "0" }}
                              onClick={() => props.history.push({
                                pathname: "/TemplateComponent/" + templateComponent.id
                              })}>
                              Edit
                            </button>
                          </td>
                        </tr>))
                      :
                      <tr><td>
                        {(filteredTemplateComponents && filteredTemplateComponents.length === 0) ?
                          "No components found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                      </td></tr>
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </AnimatedBody>
        </div>
      </div>

    </div>
  );
}

export default withRouter(TemplateComponents);