import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import schedule from "../../images/Schedule.png";
import ScheduleModal from "./ScheduleModal";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import EmailerSchedulerService from "../../Services/EmailerSchedulerService";
import Swal from "sweetalert2";

export default function PromptModal(props) {
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const emailerSchedulerService = new EmailerSchedulerService();
  const [message, setMessage] = useState('');

  function saveScheduler() {
    setShowLoading(true);

    let appointment = props.appointment;
    
    callApiToSaveSchedular();
    setShowLoading(false)

  }

  async function callApiToSaveSchedular() {
    emailerSchedulerService.createOrUpdateEmailerSchedular(props.appointment)
      .then(response => {
        if (!response.statusCode == 200) {
          throw new Error(response.statusText)
        }
                
        props.close();
        setMessage(
          ` Scheduler has been created.`
        )
        setShowScheduleModal(true);
        props.onSave('test');
        
      })
      .catch(error => {
        props.onSaveComplete();
        props.close();
        setMessage(
          `Failed to Create Scheduler. Please contact your administrator.`
        )
        setShowScheduleModal(true);
       
      })
  }

  return (
    <>
      <Modal show={props.open} onHide={props.close}>
        <Modal.Body>
          <div className="form-group">
            <img
              style={{ display: "block", margin: "auto", width: "auto" }}
              src={schedule}
            />
            <h6 style={{ textAlign: "center", marginTop: "20px" }}>
              Are you ready to save scheduler?
            </h6>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button
            type="button"
            className="btn btn-cancel"
            data-dismiss="modal"
            onClick={props.close}
          >
            Cancel
          </button>
          <LoadingButton
            className="btn btn-submit"
            onClick={() => {

              saveScheduler()

            }}
            loading={showLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            style={{ float: "right", marginTop: "0" }}
          >
            Save
          </LoadingButton>
        </Modal.Footer>
      </Modal>
      <ScheduleModal
        open={showScheduleModal}
        close={() => setShowScheduleModal(false)}
        message={message}
      />
    </>
  );
}
