import React from 'react';
import '../Style/Emailer.css';
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table'
import { Image } from 'react-bootstrap';

class EmailerTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userName: [],
      templateId: []
    }
  }

  // Routing Towards Edit Emailer
  // onEditEmailer = () => {
  //   this.props.history.push("/EditEmailer")
  // };

  // Routing Towards Edit Receipients
  onEditReceipients = (template) => {
    sessionStorage.setItem('templateName', template.name);
    sessionStorage.setItem('thumbnail', template.version.thumbnail_url);
    this.props.history.push(`/EditReceipients?templateId=${template.id}`)
    console.log(template.id, 'template.id')
  }

  // get users with template id
  // getTemplateUser = (templateId) => {
  //   this.addApiService.getTemplateUser().then(response => {
  //   console.log(response, 'template to users')
  //   this.setState({ templates: response });
  //   })
  // }

  render() {
    return (
      <div className="padding-left-right-10px">
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th className="first-col">Dynamic Template</th>
              <th className="first-col">Version Id</th>
              <th className="first-col">Version Name</th>
              <th className="first-col">Email Subject</th>
              <th className="second-col align-self-center">Thumbnail</th>
            </tr>
          </thead>
          <tbody>
            {this.props.templates && this.props.templates.length > 0 ? this.props.templates.map((template, index) => (
              <tr onClick={() => this.onEditReceipients(template)}
                key={index}
              >
                <td>
                  <ul>
                    <li >
                      {template.name}
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      {template.version.id}
                    </li>

                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      {template.version.name}
                    </li>

                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      {template.version.subject}
                    </li>

                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      <div className = "align-self-center"></div>
                        <Image src={template.version.thumbnail_url} thumbnail  />
                  
                    </li>

                  </ul>
                </td>

              </tr>
            )) :
              // !sessionStorage.getItem('customerId') ||  sessionStorage.getItem('customerId') === 'Select Customer' ? 'Please Select a Customer' : 'No Template Added'
              "No Template Added"
            }
          </tbody>
        </Table>
      </div>

    )
  }
}

export default withRouter(EmailerTable)