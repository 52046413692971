import * as React from "react";
import { withRouter } from "react-router-dom";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { useState, useEffect } from 'react';
import authService from '../../Services/authServices'
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAlphaAsc, faSortAlphaDesc, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import AddApiService from "../../Services/addApiService";
import TemplateService from "../../Services/TemplateService";

import { Button, Switch } from "@mui/material";

import Swal from "sweetalert2";

function CustomerTemplates(props) {

  const myServiceInstance = new authService();
  const addApiService = new AddApiService();
  const templateService = new TemplateService();

  const customerId = props.customerId;
  const customerName = props.customerName;
  const [sort, setSort] = useState("")
  const [templates, setTemplates] = useState(null);

  const loggedInCustomer = myServiceInstance.getCurrentCustomer();
  if (!loggedInCustomer) {
    props.history.push("/Login");
  }

  const newDesignerAllowed = (loggedInCustomer.newDesignerAllowed || (loggedInCustomer?.SUPERADMIN && props.newDesignerAllowed));
  const editDataextractorsAllowed = (loggedInCustomer.editDataextractorsAllowed || (loggedInCustomer?.SUPERADMIN && props.editDataextractorsAllowed));

  if (!loggedInCustomer?.SUPERADMIN
    && (!customerId
      || !loggedInCustomer?.admin
      || (customerId !== (loggedInCustomer?.customerId?.toString())))) {
    props.history.push("/Customer");
  }

  useEffect(() => {
    getTemplates();
  }, []);

  function getTemplates() {
    addApiService.getEmailTemplates(customerId).then(response => {
      sortTemplatesWithParams(response);
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  function sortTemplates() {
    if (sort == "DESC") {
      templates.sort((a, b) => a?.name?.localeCompare(b?.name))
      setTemplates(templates)
      setSort("ASC")
    } else if (sort == "ASC") {
      templates.sort((a, b) => b?.name?.localeCompare(a?.name))
      setTemplates(templates)
      setSort("DESC")
    } else {
      templates.sort((a, b) => a?.name?.localeCompare(b?.name))
      setTemplates(templates)
      setSort("ASC")
    }

    setTemplates(templates);
  }

  function sortTemplatesWithParams(templates) {
    if (sort == "DESC") {
      templates.sort((a, b) => a?.name?.localeCompare(b?.name))
      setTemplates(templates)
      setSort("ASC")
    } else if (sort == "ASC") {
      templates.sort((a, b) => b?.name?.localeCompare(a?.name))
      setTemplates(templates)
      setSort("DESC")
    } else {
      templates.sort((a, b) => a?.name?.localeCompare(b?.name))
      setTemplates(templates)
      setSort("ASC")
    }
    setTemplates(templates);
  }

  function toogleStatus(template, _active) {
    templateService.toogle(customerId, template.id);
  }

  function importTemplate(selectedFile) {
    if (selectedFile) {
      templateService.importFile(customerId, selectedFile)
        .then(response => {
          if (!response.status == 200) {
            throw new Error(response.statusText)
          }

          getTemplates();

          Swal.fire({
            icon: 'success',
            title: 'Template imported',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Failed to import Template: ${error}`
          )
        });
    }
  }

  function exportTemplate(template) {
    templateService.exportFile(customerId, template.id).then(response => {
      if (response.status == 200) {
        const blob = new Blob([response.data], { type: "application/zip;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `export_${template.name}_${customerName}.zip`;
        a.click();
      }
    })
      .catch(error => {
        Swal.showValidationMessage(
          `Failed to export Template: ${error}`
        )
      });
  }

  function onCloneTemplate(templateId) {
    Swal.fire({
      title: 'Ready to clone?',
      showCancelButton: true,
      confirmButtonText: 'Clone',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: async () => {

        return templateService.cloneTemplate(props.customerId, templateId).then(() => {

          Swal.fire({
            icon: 'success',
            title: 'Template Cloned',
            showConfirmButton: false,
            timer: 1500
          });

          getTemplates();

        }).catch(error => {
          Swal.showValidationMessage(error.data ? error.data.message : error.statusText);
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  function onDeleteTemplate(templateId, templateName) {
    Swal.fire({
      title: 'Delete template',
      html: `
      <div align="center" style="font-size: 14px;">
        <div>
          <h5>Warning</h5>
          <div class="text-secondary mt-1 px-4" style="font-size: 12px;">
            All Data Extractors, Fields, Parameters, Filters, Design and Recipients in this template will be deleted. If you're sure you want to delete the template type the Template Name to confirm
          </div>
        </div>
        
        <div class="mt-3"> 
          <span>Type: <b>${templateName}</b></span>
        </div>
        <div class="mt-2">
          <input type="text" id="inputTemplateName" name="inputTemplateName" placeholder="${templateName}" style="width: 80%; padding: 10px;">
        </div>
      </div>
      `,

      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: async () => {

        const type = document.getElementById('inputTemplateName').value;

        const requestTemplateName = {
          templateName: type,
        };

        return templateService.deleteTemplate(props.customerId, templateId, requestTemplateName).then(() => {

          Swal.fire({
            icon: 'success',
            title: 'Template Deleted',
            showConfirmButton: false,
            timer: 1500
          });

          getTemplates();

        }).catch(error => {
          Swal.showValidationMessage(error.data ? error.data.message : error.statusText);
        });

      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <div className="row" style={{ paddingLeft: 5 }}>
        <div className="col-12">
          <table style={{ width: "100%", overflow: "scroll !important" }}>
            <thead>
              <tr className="line">
                <th colSpan={2} className="line-data">Dynamic Template
                  {templates && templates.length > 0 && (sort == "ASC" || sort == "DESC") ?
                    <FontAwesomeIcon
                      icon={sort == "ASC" ? faSortAlphaAsc : faSortAlphaDesc}
                      onClick={() => sortTemplates()}
                      style={{ marginLeft: "10px" }}
                    />
                    : <span />
                  }
                </th>
                <th className="line-data">Email Subject</th>
                <th className="line-data">Version name</th>
                <th className="line-data">Version Id</th>
                {
                  newDesignerAllowed ?
                    <>
                      <th className="line-data">Uses new designer?</th>

                      {
                        (loggedInCustomer?.SUPERADMIN || editDataextractorsAllowed) &&
                        <th className="line-data" width={150} style={{ textAlign: "right" }} >
                          <button type="button" className="btn btn-submit"
                            style={{ marginTop: 0, marginRight: 6 }}
                            onClick={() => props.history.push({
                              pathname: "/TemplateCatalog/" + customerId
                            })}>
                            Add
                          </button>

                          <Button variant="contained" component="label" style={{ textTransform: "capitalize" }}>
                            Import
                            <input type="file" accept="application/zip" onChange={(e) => importTemplate(e.target.files[0])} hidden />
                          </Button>
                        </th>
                      }
                    </>
                    :
                    <th className="line-data" width={130}></th>
                }

              </tr>
            </thead>
            <tbody>
              {templates && templates.length > 0 ?
                templates.map((template, _index) => (
                  <tr key={template.id} className="line">
                    <td><img src={template.version?.thumbnail_url} style={{ width: 100 }} /></td>
                    <td style={{ padding: 10, paddingLeft: 5 }}>{template.name}</td>
                    <td className="line-data">{template.subjectExpression}</td>
                    <td className="line-data">{template.version.name}</td>
                    <td className="line-data">{template.version.id}</td>
                    {
                      newDesignerAllowed &&
                      <td className="line-data" style={{ color: template.useNewTemplateEditor ? "green" : "orange" }}>{template.useNewTemplateEditor ? 'Yes' : 'No'}</td>
                    }
                    <td className="line-data" style={{ textAlign: "right" }}
                      width={loggedInCustomer?.SUPERADMIN && template.useNewTemplateEditor ? 300 : 150}>
                      <Switch className="switch-primary" title={template.isActive ? "Turn OFF" : "Turn ON"}
                        defaultChecked={template.isActive}
                        onChange={(e) => { toogleStatus(template, e.target.checked) }} />

                      {newDesignerAllowed && (loggedInCustomer?.SUPERADMIN || editDataextractorsAllowed) &&
                        <button type="button" className="btn btn-light ms-2 me-0"
                          onClick={() => exportTemplate(template)}>
                          Export
                        </button>
                      }

                      <button type="button" className="btn btn-light ms-2 me-0"
                        onClick={() => props.history.push({
                          pathname: "/Customer/" + customerId + "/Template/" + template.id
                        })}>
                        Edit
                      </button>

                      {
                        (loggedInCustomer?.SUPERADMIN && template.useNewTemplateEditor) &&
                        <>
                          <button type="button" className="btn btn-light ms-2 me-0"
                            onClick={() => onCloneTemplate(template.id)}>
                            Clone
                          </button>
                          <a href="#" className="ms-2 me-0" onClick={() => onDeleteTemplate(template.id, template.name)}>
                            <FontAwesomeIcon icon={faTrashCan} color="#6c6c6f" />
                          </a>
                        </>
                      }
                    </td>
                  </tr>))
                :
                <tr>
                  <td colSpan={4}>
                    {(templates && templates.length === 0) ? "No templates found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                  </td>
                </tr>
              }

            </tbody>
          </table>
        </div>
      </div>

      {loggedInCustomer?.SUPERADMIN ?
        <div className="row-margin" style={{ marginBottom: 5 }}>
          <button type="button" className="btn btn-cancel"
            onClick={() => props.history.push({ pathname: "/Customers" })}>Cancel</button>
        </div>
        : <div> </div>
      }
    </div>
  );
}

export default withRouter(CustomerTemplates);
