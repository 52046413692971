import React from 'react';
import '../Style/AddApi.css';
import '../Style/Setting.css';
import $ from 'jquery';

import AddApiService from '../Services/addApiService';
import authService from '../Services/authServices';
import apiKeyService from '../Services/settingService';
import Modal from 'react-awesome-modal';
import AddSettingData from '../Forms/AddSettingData';
import EditSettingData from '../Forms/EditSettingData';

import UserSettingList from '../Components/UserSettingList';
import { withRouter } from 'react-router-dom';

// import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';
import Header from './Header';

// const MySwal = withReactContent(Swal);

class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.addApiService = new AddApiService();
    this.authService = new authService();
    this.apiKeyService = new apiKeyService();
    this.state = {
      selectedOption: "",
      customerDetail: [],
      userId: this.authService.getCurrentCustomer().id,
      apiList: {},
      // customer: [],
      visible: false,
      isUpdateMode: false
    }
  }

  //on load function call
  componentDidMount() {
    // Get All Customer
    // this.getAllCustomer();
    // Get All Customer Details

    // Redirect
    if (!this.authService.getToken('token')) {
      this.props.history.push('/Login');
    } else {
      this.getAllApiKey();
    }
  }

  // Selected Customer
  selectedCustomer(custId) {
    this.setState({
      selectedOption: custId,
      customerId: custId
    });
  }

  // Get all API keys
  getAllApiKey = () => {
    console.log(this.state.userId, 'get all key')
    this.apiKeyService.getApiKey(this.state.userId).then(response => {
      console.log('Response: ', response);
      this.setState({
        apiList: response
      });
    });
  }

  // Get Customer By ID
  // getCustomerById = (selectedValue) => {
  //   console.log('Selected Customer: ', selectedValue);
  //   if (selectedValue !== 'Select Customer') {
  //     this.addApiService.getCustomerById(selectedValue).then(response => {
  //       console.log('Get CUstomer: ', response);
  //       if (response && response.length !== 0) {
  //         this.setState({ customerDetail: response, isUpdateMode: false, visible: false });
  //       }
  //     }).catch((error) => {
  //       console.log('ERROR: ', error);
  //     });
  //   } else {
  //     this.getAllCustomerDetail();
  //   }
  // }

  // Edit Key
  editKey = () => {
    let editKeyData = {
      isUpdateMode: true,
      visible: true,
      userId: this.state.userId,
      key: this.state.apiList.key,
      secret: this.state.apiList.secret,
    }
    this.setState({
      editKeyData,
      isUpdateMode: true,
      visible: true
    });
  }

  // Delete Customer 
  //   deleteCustomer = (id) => {
  //     MySwal.fire({
  //       title:'Are you sure?',
  //       text: "You won't be able to revert this!",
  //       icon: 'warning',
  //       showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Yes, delete it!'
  // }).then((result) => {
  //   if(result.value){
  //     this.addApiService.deleteCustomerById(id).then(response => {
  //       let customerDetail = this.state.customerDetail.filter(DelCustomer => DelCustomer.id !== id);
  //       this.setState({ customerDetail });
  //     }).catch((error) => {
  //       console.log('ERROR: ', error);
  //     });
  //     Swal.fire(
  //       'Deleted!',
  //       'Your file has been deleted.',
  //       'success'
  //     )
  //   }
  // });
  //   }

  // Modal Binding
 
  addDataOpenModal() {
    this.setState({
      isUpdateMode: false,
      visible: true,
      userId: this.state.userId
    });
  }



  // Edit Modal Binding
    editDataOpenModal() {
      this.setState({
        isUpdateMode: true,
        visible: true,
        userId: this.state.userId
      });
    }

  // Close Modal
  closeModal() {
    this.setState({
      visible: false,
    });
  }

  // Routing towards AddApi view
  onApiClick = () => {
    this.props.history.push("/AddApi")
  };

  // Routing towards Mobile view
  onMobileView = () => {
    this.props.history.push("/mobile")
  };

  //Routing towards Emailer
  onEmailer = () => {
    this.props.history.push("/Emailer")
  };

   //Routing towards Schedular
   onEmailerSchedular = () => {
    this.props.history.push("/EmailerScheduler")
  };

  // TEMP Logout
  logout = () => {
    // Clear Session Storage
    sessionStorage.clear();
    // Redirect to Login
    this.props.history.push('/');
  }


  // Render Html
  render() {
    return (
      <div>
        <section className="dashboard-outer">
          <div className="container-fluid">
            <Header selectCustomer={(customerId) => { this.selectedCustomer(customerId) }} />
            <div className="row">
              <div className="col-md-1 col-sm-3 p-0">
                <div className="side-left">
                  <ul className = "padding-left-0">
                    <li>
                      <a onClick={this.onEmailer}>
                        <span tooltip="Emailers" flow="right"><i className="fa fa-envelope" /></span>
                      </a>
                    </li>
                    <li>
                      <a onClick={this.onEmailerSchedular}>
                        <span tooltip="Schedule Emailers" flow="right"><i className="fa fa-calendar-plus-o" aria-hidden="true"/></span>
                      </a>
                    </li>
                    <li>
                      <a onClick={this.onApiClick}
                      >
                        <span tooltip="API" flow="right"><i className="fa fa-file-text" aria-hidden="true" /></span>
                      </a>
                    </li>
                    {/* <li>
                      <a>
                        <span tooltip="ABA" flow="right"><i className="fa fa-files-o" aria-hidden="true"></i></span>
                      </a>
                    </li> */}
                    <li>
                      <a onClick={this.onMobileView}>
                        <span tooltip="Mobile" flow="right">
                          <i className="fa fa-mobile custom-mobile" aria-hidden="true"></i>
                        </span>
                      </a>
                    </li>
                    <li className="active">
                      <a>
                        <span tooltip="Setting" flow="right"><i className="fa fa-cogs" aria-hidden="true"></i></span>
                      </a>
                    </li>
                    <li>
                      <a onClick={this.logout}>
                        <span tooltip="Logout" flow="right"><i className="fa fa-key" aria-hidden="true"></i></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-11 col-sm-9 p-0">
                <div className="side-right">

                  <div className="bottom-outer">
                    <div className="col-md-12 col-sm-12">

                      <div className="button-outer">
                        <div className="text-left">
                          <p className="font-size-20 font-weight-500 mb-0">Settings</p>
                          <span>
                            <p className="font-size-12 color-gray">
                              Edited Tue, Mar 10, 2020 2:44pm
                            </p>
                          </span>

                        </div>
                        <div className="text-right">
                          <button type="button" className={"bn " + (this.state.selectedOption === '' || this.state.selectedOption === 'Select Customer' 
                          || (this.state.apiList.key && this.state.secret !== '') 
                          ? "disabled" : "")}
                            onClick={() => this.addDataOpenModal()}
                            disabled={ (this.state.selectedOption === '' || this.state.selectedOption === 'Select Customer') 
                            || (this.state.apiList.key && this.state.apiList.secret !== '') ? true : false 
                          } 

                            >
                            Add Key
                          </button>
                          { this.state.isUpdateMode ? (
                            <Modal width="600" height="300" visible={this.state.visible} effect="fadeInUp" 
                              onClickAway={() => this.closeModal()}>
                              <EditSettingData
                                userId = {this.state.userId}
                                closeModal={(isClosed) => {
                                  isClosed ?
                                    this.closeModal()
                                  :
                                    this.closeModal();
                                    this.getAllApiKey(this.state.userId);
                                } 
                                }
                              />
                            </Modal>
                          ) : (

                              <Modal width="600" height="300" visible={this.state.visible} effect="fadeInUp" 
                                onClickAway={() => this.closeModal()}>
                                <AddSettingData
                                  userId = {this.state.userId}
                                  closeModal={(isClosed) => {
                                    isClosed ?
                                      this.closeModal()
                                    :
                                      this.closeModal();
                                      this.getAllApiKey(this.state.userId);
                                  } 
                                }
                                />
                              </Modal>
                             )
                            }
                          
                        </div>
                      </div>
                      <UserSettingList
                      id="dd-menu-list"
                        apiList={this.state.apiList}
                        editKey={() => { this.editKey() }}
                        // deleteCustomer={(id) => { this.deleteCustomer(id) }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ section>
      </div>

    );
  }

}

export default withRouter(Setting)