import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { Avatar, FormHelperText, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Select, Tooltip, Link } from '@mui/material';
import { useEffect } from 'react';
import AbcIcon from '@mui/icons-material/Abc';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ToggleOffOutlined from '@mui/icons-material/ToggleOffOutlined';
import HelpIcon from '@mui/icons-material/Help';

import { useBlock, useEditorContext, useFocusIdx } from 'easy-email-editor';
import TemplateParameterService from '../../../Services/TemplateParameterService';
import TemplateFilterService from '../../../Services/TemplateFilterService';
import TemplateDataExtractorService from '../../../Services/TemplateDataExtractorService';
import { AdvancedType, BasicType } from 'easy-email-core';
import { CustomBlocksTitle, CustomBlocksType } from '../CustomBlocks/constants';

import PreviewDataModal from "../../../Components/v2/PreviewDataModal";

import Swal from 'sweetalert2';
import Skeleton from "react-loading-skeleton";
import { useFocusedInput } from '../CustomBlocks/utils/useFocusedInput';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightSharpIcon />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'var(--color-neutral-1)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
}));

const PARAMETERS_CODE = 'parameters';
const FILTERS_CODE = 'filters';

export function DataExtractorPanelInside(props) {

  const templateParameterService = new TemplateParameterService();
  const templateFilterService = new TemplateFilterService();
  const templateDataExtractorService = new TemplateDataExtractorService();

  const { initialized } = useEditorContext();
  const { focusBlock, setValueByIdx } = useBlock();
  const { focusIdx } = useFocusIdx();

  const { setFocusedInputValue } = useFocusedInput();

  const [extractors, setExtractors] = useState([]);
  const [selectedExtractorId, setSelectedExtractorId] = useState('');
  const [selectedExtractor, setSelectedExtractor] = useState(null);

  const DEFAULT_MAX_ROWS_FORMATTED_DATA = 25;
  const [previewFormattedData, setPreviewFormattedData] = useState({ data: { records: [] }, loaded: false });
  const [enablePreviewFormattedDataButton, setEnablePreviewFormattedDataButton] = useState(true);
  const [showRequestPreviewFormattedModal, setShowRequestPreviewFormattedModal] = useState(false);
  const [maxRowsFormattedData, setMaxRowsFormattedData] = useState(DEFAULT_MAX_ROWS_FORMATTED_DATA);
  const [orderByCodeFormattedData, setOrderByCodeFormattedData] = useState('');

  useEffect(() => {
    if (initialized) {
      async function getExtractorOptions() {
        const extractorOptions = [{ id: '', name: 'None' }];

        const templateParameters = await templateParameterService.findAll(props.templateId).then(response => {
          const parameters = {
            id: PARAMETERS_CODE,
            code: PARAMETERS_CODE,
            name: 'Parameters',
            fields: response.data.map(param => {
              param.name = param.code;
              return param;
            })
          }
          return parameters;
        });
        extractorOptions.push(templateParameters);

        const templateFilters = await templateFilterService.findAll(props.templateId).then(response => {
          return {
            id: FILTERS_CODE,
            code: FILTERS_CODE,
            name: 'Filters',
            fields: response.data.map(filter => {
              filter.name = filter.code;
              return filter;
            })
          };
        });
        extractorOptions.push(templateFilters);

        const templateExtractors = await templateDataExtractorService.findAllNotHidden(props.templateId).then(response => {
          return response.data;
        });

        extractorOptions.push.apply(extractorOptions, templateExtractors);
        setExtractors(extractorOptions);
      }

      getExtractorOptions();

    }
  }, [initialized]);

  function handleChangeExtractor(event) {
    const extractorIndex = extractors.findIndex(extractor => extractor.id === event.target.value);
    setSelectedExtractorId(event.target.value);

    const extractor = extractors.at(extractorIndex);
    setSelectedExtractor(extractor)
  };

  function showWarning(message) {
    Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    }).fire({
      icon: 'warning',
      title: message,
    });
  }

  function onExtractorFieldSelected(extractor, field) {

    if (!focusBlock) return;

    const notSupportedClick = [
      BasicType.PAGE, AdvancedType.SOCIAL, AdvancedType.DIVIDER,
      AdvancedType.SPACER, AdvancedType.NAVBAR,
      AdvancedType.HERO, AdvancedType.SECTION, AdvancedType.COLUMN,
      CustomBlocksType.CONDITIONAL,
      CustomBlocksType.RADIAL_GAUGE_CHART
    ];
    if (notSupportedClick.includes(focusBlock.type)) {
      showWarning((focusBlock.title || focusBlock.type) + ': Helper is not supported for this component');
      return;
    }

    const needFocus = [
      CustomBlocksType.STATIC_TABLE, CustomBlocksType.BASIC_TABLE,
      CustomBlocksType.BAR_CHART, CustomBlocksType.LINE_CHART, CustomBlocksType.PIE_CHART
    ];
    if (needFocus.includes(focusBlock.type) && !window.previousFocused) {
      if (CustomBlocksType.STATIC_TABLE === focusBlock.type) {
        showWarning((focusBlock.title || focusBlock.type) + ': Focus in "Header, Cell or Footer" at Configuration Panel');
      } else if (CustomBlocksType.BASIC_TABLE === focusBlock.type) {
        showWarning((focusBlock.title || focusBlock.type) + ': Focus in "Header or Footer" at Configuration Panel');
      } else if (CustomBlocksType.BAR_CHART === focusBlock.type || CustomBlocksType.LINE_CHART === focusBlock.type) {
        showWarning((focusBlock.title || focusBlock.type) + ': Focus in "Chart Title, Series Label" at Configuration Panel');
      } if (CustomBlocksType.PIE_CHART === focusBlock.type) {
        showWarning((focusBlock.title || focusBlock.type) + ': Focus in "Chart Title" at Configuration Panel');
      }
      return;
    }

    let value;
    if (extractor === PARAMETERS_CODE) {
      // If is a TEXT inside TABLE
      if ((focusBlock.type === AdvancedType.TEXT && focusBlock.title === CustomBlocksTitle.COLUMN_TEXT)
        || (focusBlock.type === CustomBlocksType.BASIC_TABLE && window.previousFocused.endsWith("body"))) {
        value = `{{../${extractor}.${field}}}`;
      } else {
        value = `{{${extractor}.${field}}}`;
      }
    } else if (extractor === FILTERS_CODE) {
      // If is a TEXT inside TABLE
      if ((focusBlock.type === AdvancedType.TEXT && focusBlock.title === CustomBlocksTitle.COLUMN_TEXT)
        || (focusBlock.type === CustomBlocksType.BASIC_TABLE && window.previousFocused.endsWith("body"))) {
        value = `{{../${extractor}.[0].value}}`;
      } else {
        value = `{{${extractor}.[0].value}}`;
      }
    } else {
      if (focusBlock.type === AdvancedType.TEXT && focusBlock.title === CustomBlocksTitle.COLUMN_TEXT) {
        // If is a TEXT inside TABLE
        value = `{{this.${field}}}`;
      } else {
        value = `{{${extractor}.data.[0].${field}}}`;
      }
    }

    if (focusBlock.type === AdvancedType.IMAGE) {
      focusBlock.attributes.src = value;
      setValueByIdx(focusIdx, { ...focusBlock });
    } else if (needFocus.includes(focusBlock.type)) {
      setFocusedInputValue(value);
    } else {
      focusBlock.data.value.content += value;
      setValueByIdx(focusIdx, { ...focusBlock });
    }

  }

  function handleCloseModalPreviewFormatted() {
    setShowRequestPreviewFormattedModal(false);
    setEnablePreviewFormattedDataButton(true);
    setPreviewFormattedData({ data: { records: [] }, loaded: false });

    setMaxRowsFormattedData(DEFAULT_MAX_ROWS_FORMATTED_DATA);
    setOrderByCodeFormattedData('');
  }

  function refreshPreviewFormattedData() {
    previewFormattedData.loaded = false;
    showPreviewFormattedData();
  }

  function showPreviewFormattedData() {
    if (props.templateId !== undefined) {
      setEnablePreviewFormattedDataButton(false);

      const request = {
        userApptioProfile: JSON.parse(sessionStorage.getItem('currentCustomer')).userName,
        jsonTableProperty: 'records',
        maxRows: maxRowsFormattedData,
        orderByFields: [orderByCodeFormattedData]
      };

      templateDataExtractorService.previewDataExtractor(props.templateId, selectedExtractor.id, request).then(response => {
        if (response) {
          response.data.loaded = true;
          setPreviewFormattedData(response.data);
          setShowRequestPreviewFormattedModal(true);
        } else {
          setPreviewFormattedData({ data: { records: [] }, loaded: true });
        }

        setEnablePreviewFormattedDataButton(true);
      });
    } else {
      setPreviewFormattedData({ data: { records: [] }, loaded: true });

      Swal.fire({
        icon: 'warn',
        title: 'Unable to preview formatted data, make sure you have saved the latest changes',
      });
    }
  }

  useEffect(() => {
    if (showRequestPreviewFormattedModal) {
      previewFormattedData.loaded = false;
      showPreviewFormattedData();
    }
  }, [maxRowsFormattedData, orderByCodeFormattedData]);

  return (
    <>
      <div className="w-100 px-3">
        <div className="d-inline-block" style={{ width: '89%' }}>
          <Select
            value={selectedExtractorId}
            onChange={handleChangeExtractor}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            MenuProps={{ disableScrollLock: true }}
            fullWidth
          >
            {extractors && extractors.length ? extractors.map((extractor) => (
              <MenuItem key={extractor.id} value={extractor.id}>{extractor.name}</MenuItem>
            )) : ''}
          </Select>
          <FormHelperText>{selectedExtractor && selectedExtractor.code ? `{{${selectedExtractor.code}}}` : ''}</FormHelperText>

          {selectedExtractor && selectedExtractor.code && selectedExtractor.code !== 'parameters' && selectedExtractor.code !== 'filters' &&
            <div class="row">
              <div class="col pl-0">
                <Link href="#" disabled={!enablePreviewFormattedDataButton} onClick={() => showPreviewFormattedData()}>Preview Data</Link>
              </div>
              <div class="col pr-0">
                {!enablePreviewFormattedDataButton &&
                  <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />
                }
              </div>
            </div>
          }
        </div>
        <Tooltip className="ms-1 align-top" followCursor PopperProps={{ className: 'datasource-helper' }} placement="right-start"
          title={
            <List sx={{ width: '100%' }}>
              <ListItem className='py-0'>
                <ListItemText primary="1. Select a Component in the Designer" secondary="Text, Image, Button, Text in Tables" />
              </ListItem>
              <ListItem className='py-0'>
                <ListItemText primary="2. Select a Data Extractor" secondary="The list of Data Extractor Fields will be displayed" />
              </ListItem>
              <ListItem className='py-0'>
                <ListItemText primary="3. Doble click in a Data Extractor Field" secondary="The field tag will be added to the component value" />
              </ListItem>
            </List>
          }
        >
          <HelpIcon fontSize='small' color='action' />
        </Tooltip>


        <List sx={{ overflow: 'auto', maxHeight: "calc(100vh - 430px)" }}>
          {selectedExtractor && selectedExtractor.fields && selectedExtractor.fields.length ? selectedExtractor.fields.map((field) => (
            <ListItem key={field.id} className="p-0">
              <ListItemButton className="py-0 px-1" onDoubleClick={() => onExtractorFieldSelected(selectedExtractor.code, field.code)}>
                <ListItemAvatar sx={{ width: 24, minWidth: 34 }}>
                  <Avatar sx={{ width: 24, height: 24 }} style={{ fontSize: 9, fontWeight: 900 }}>
                    {field.dataType === 'STRING' ? <AbcIcon />
                      : field.dataType === 'NUMERIC' ? 123
                        : field.dataType === 'BOOLEAN' ? <ToggleOffOutlined />
                          : <MoreHorizIcon />}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  title={`${field.name}\n{{${selectedExtractor.code}.${field.code}}}`}
                  primary={field.name} primaryTypographyProps={{ fontSize: 12, noWrap: true }}
                  secondary={`{{${selectedExtractor.code}.${field.code}}}`} secondaryTypographyProps={{ fontSize: 10, noWrap: true }}
                  style={{ overflowWrap: "anywhere" }} />
              </ListItemButton>
            </ListItem>
          )) : ''}
        </List>
      </div>

      <PreviewDataModal show={showRequestPreviewFormattedModal} onHide={() => handleCloseModalPreviewFormatted()} maxRows={maxRowsFormattedData}
        rowCount={previewFormattedData && previewFormattedData?.data?.rowCount || 0} showPickColumnButton={false} dataLoaded={previewFormattedData.loaded}
        requestedRowsOptions={[5, 15, 25, 50, 100]} onRequestedRowsUpdated={(requestedRows) => setMaxRowsFormattedData(requestedRows)}
        requestedOrderByOptions={previewFormattedData?.data?.records[0] && Object.keys(previewFormattedData?.data?.records[0]).map(rowColumn => ({ code: rowColumn, label: `${rowColumn}` }))}
        onRequestedOrderByUpdated={(requestedOrderBy) => setOrderByCodeFormattedData(requestedOrderBy)} templateId={props.templateId}
        firstRow={previewFormattedData?.data?.records[0]} dataSet={previewFormattedData?.data?.records} onPickColumn={() => { }}
        title={'Preview Formatted Data'} introText={`Showing ${previewFormattedData?.data?.records?.length} rows...`} dataSetHasFilters={previewFormattedData && (previewFormattedData?.filters?.length || 0) > 0}
        onRefreshPreviewData={() => refreshPreviewFormattedData()} />
    </>
  );
}