import * as React from "react";
import { withRouter } from "react-router-dom";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";

import { BlockManager, BasicType, AdvancedType, JsonToMjml, createCustomBlock, components } from 'easy-email-core';
import { EmailEditor, EmailEditorProvider } from 'easy-email-editor';
import { StandardLayout } from 'easy-email-extensions';
import { merge } from 'lodash';

import mjml from 'mjml-browser';

import { useState, useEffect } from "react";

import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';
// theme, If you need to change the theme, you can make a duplicate in https://arco.design/themes/design/1799/setting/base/Color
import '@arco-themes/react-easy-email-theme/css/arco.css';
import defaultTemplateRaw from '../designer/defaultTemplateRaw.json';
import defaultPredefinedTemplate from '../designer/defaultPredefinedTemplate.json';

import '../designer/CustomBlocks';
import Handlebars from "handlebars";

import { DefaultCategories, EnumTemplateTags } from "../designer/constants";
import TemplateService from "../../Services/TemplateService";
import cloneDeep from 'lodash/cloneDeep';

import Swal from 'sweetalert2';
import Skeleton from "react-loading-skeleton";
import { Col, Modal, Row } from "react-bootstrap";

import TemplateTestData from "./TemplateTestDataModal";
import TemplateDataExtractorService from "../../Services/TemplateDataExtractorService";
import moment, { now } from "moment";
import PredefinedTemplateService from "../../Services/PredefinedTemplateService";
import { designerCheckCharts, loadingPreviewHTML } from "../../Shared/common-utils";
import { DataExtractorPanelInside } from "../designer/DataExtractor/DataExtractorPanelInside";
import { Avatar, Button, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { green } from "@mui/material/colors";

import AssignmentIcon from '@mui/icons-material/Assignment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplayIcon from '@mui/icons-material/Replay';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import SaveIcon from '@mui/icons-material/Save';

import '../../Style/Designer.css';

const { BlockRenderer } = components;

function Designer(props) {

  const templateService = new TemplateService();
  const predefinedTemplateService = new PredefinedTemplateService();
  const templateDataExtractorService = new TemplateDataExtractorService();

  const [templateVersionId, setTemplateVersionId] = useState(null);
  const [templateVersionName, setTemplateVersionName] = useState(null);
  const [templateVersionSubject, setTemplateVersionSubject] = useState('{{default_subject}}');
  const [templateName, setTemplateName] = useState('');
  const [initialValues, setInitialValues] = useState(null);
  const [previewData, setPreviewData] = useState({ loaded: false });
  const [showTemplateTestDataModal, setShowTemplateTestDataModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [templateVersions, setTemplateVersions] = useState([]);

  const [showOptions, setShowOptions] = useState(false);
  const [anchorOptions, setAnchorOptions] = useState(null)

  useEffect(() => {
    console.log(now());
    // Merging all Extractors in the Preview Data
    if (props.predefinedTemplate) {
      findPredefinedTemplateDesign();
      reloadPreviewPredefinedTemplate(false);
    } else {
      findTemplateDesign();
      reloadPreview(false);
    }

    const dataSourceHelperIndex = DefaultCategories.findIndex(category => category.label === 'Datasource Helper');
    if (dataSourceHelperIndex < 0) {
      // Creating the category
      DefaultCategories.push({
        label: 'Datasource Helper',
        active: false,
        displayType: 'custom',
        blocks: [
          <DataExtractorPanelInside {...props} />
        ]
      });
    } else {
      // Updating the props in the existing category
      DefaultCategories[dataSourceHelperIndex].blocks = [
        <DataExtractorPanelInside {...props} />
      ];
    }
  }, []);

  async function reloadPreview(reload) {
    const request = {
      userApptioProfile: JSON.parse(sessionStorage.getItem('currentCustomer')).userName,
      reload
    };
    return templateDataExtractorService.generateOutput(props.templateId, request).then(response => {
      if (response.data) {
        const newPreviewData = {
          loaded: true,
          ...response.data
        };
        setPreviewData(newPreviewData);
      }
    });
  }

  async function reloadPreviewPredefinedTemplate(reload) {
    const request = {
      userApptioProfile: JSON.parse(sessionStorage.getItem('currentCustomer')).userName,
      reload
    };
    return templateDataExtractorService.generateOutput(props.templateId, request).then(response => {
      if (response.data) {
        const newPreviewData = {
          loaded: true,
          ...response.data
        };
        setPreviewData(newPreviewData);
      }
    });
  }

  function onReloadClick() {
    Swal.fire({
      title: 'Ready to reload?',
      showCancelButton: true,
      confirmButtonText: 'Reload',
      confirmButtonColor: '#ed7d31',
      preConfirm: () => {
        setPreviewData({ loaded: false });

        if (props.predefinedTemplate) {
          reloadPreviewPredefinedTemplate(true);
        } else {
          reloadPreview(true);
        }

        Swal.fire({
          icon: 'success',
          title: 'Preview data is being reloaded.',
          text: 'You can continue working while the preview data is loaded.',
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  async function findPredefinedTemplateDesign() {
    return predefinedTemplateService.findById(props.templateId).then(response => {
      if (response.status !== 200) {
        console.log(response.data.message)
        // errorMessage(!response.data.message ? 'No template found' : response.data.message);
        return;
      }

      setTemplateVersionId(response.data.id);
      setTemplateVersionName(response.data.name);
      setTemplateName(response.data.name);

      if (response.data.design) {
        setInitialValues({
          subject: response.data.subject,
          content: JSON.parse(response.data.design),
        });
      } else {
        let templateRaw = cloneDeep(defaultPredefinedTemplate);
        setInitialValues({
          subject: response.data.subject,
          content: templateRaw,
        });
      }

      window.scrollTo(0, document.body.scrollHeight);

      return response
    });
  }

  async function findTemplateDesign() {
    return templateService.findById(props.customerId, props.templateId).then(response => {
      if (response.status !== 200) {
        console.log(response.data.message)
        // errorMessage(!response.data.message ? 'No template found' : response.data.message);
        return;
      }

      setTemplateVersionId(response.data.templateVersionId);
      setTemplateVersionName(response.data.templateVersionName);
      setTemplateVersionSubject(response.data.subjectExpression);
      setTemplateName(response.data.templateName);

      if (response.data.templateVersionDesign) {
        setInitialValues({
          subject: response.data.subjectExpression,
          content: JSON.parse(response.data.templateVersionDesign),
        });
      } else {
        let templateRaw = cloneDeep(defaultTemplateRaw);
        const defaultTemplateRawWithHTML = templateRaw["children"][0]["data"]["value"]["content"].replace(EnumTemplateTags.TEMPLATE_HTML, response.data.templateVersionHtmlRaw);
        templateRaw["children"][0]["data"]["value"]["content"] = defaultTemplateRawWithHTML;
        setInitialValues({
          subject: response.data.subjectExpression,
          content: templateRaw,
        });
      }

      window.scrollTo(0, document.body.scrollHeight);

      return response;
    });
  }

  function createCustomRawBlock(componentId, componentName, componentJSON) {
    const dynamicComponent = createCustomBlock({
      name: componentName,
      type: componentId,
      create(payload) {
        const defaultData = {
          type: componentId,
          data: {
            value: {
              buttonText: 'Got it',
              imageUrl: 'https://www.amcharts.com/wp-content/uploads/2019/02/demo_13290_none-1-1024x690.png',
              json: componentJSON
            },
          },
          attributes: {
            'background-color': '#4A90E2',
            'text-color': '#ffffff',
          },
          children: [componentJSON],
        };
        return merge(defaultData, payload);
      },
      validParentType: [BasicType.PAGE, BasicType.WRAPPER],
      keepClassName: true,
      render(params) {
        const { data } = params;

        const customWrapperJson = {
          "type": AdvancedType.WRAPPER,
          "data": {
            "value": {
              "noWrap": false
            }
          },
          "attributes": {
            "padding": "20px 0px 20px 0px",
            "background-repeat": "repeat",
            "background-size": "auto",
            "background-position": "top center",
            "border": "none",
            "direction": "ltr",
            "text-align": "center"
          },
          "children": data.children
        }
        const instance = (
          <BlockRenderer key={`block-rendered-${params.idx}`} idx={params.idx} data={customWrapperJson} />
        );

        return instance;
      },
    });

    BlockManager.registerBlocks({ [componentId]: dynamicComponent });
    // BlockAttributeConfigurationManager.add({ [componentId]: GenericSettingsPanel });

  }

  function saveDesign(values) {
    const html = mjml(JsonToMjml({
      data: values.content,
      mode: 'production',
      context: values.content
    }), {
      validationLevel: 'soft',
    }).html;

    if (props.predefinedTemplate) {
      savePredefinedTemplateDesign(values, html)
    } else {
      saveTemplateDesign(values, html);
    }


  }

  function savePredefinedTemplateDesign(values, html) {
    Swal.fire({
      title: 'Ready to save?',
      showCancelButton: true,
      confirmButtonText: 'Save',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: async () => {

        const requestPredefinedTemplate = {
          id: props.templateId,
          subject: values.subject,
          design: JSON.stringify(values.content),
          html,
          testData: JSON.stringify(previewData)
        }

        return predefinedTemplateService.saveDesign(requestPredefinedTemplate)
          .then(response => {
            console.log(response.data);
            if (response.status !== 200) {
              throw new Error(response.data.message);
            }

            Swal.fire({
              icon: 'success',
              title: 'Design Updated',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            Swal.showValidationMessage(error);
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  function saveTemplateDesign(values, html) {
    Swal.fire({
      title: 'Ready to save?',
      html: `
      <div align = "left">
        <div>
          <label for="option">How do you want to save this version?</label>
        </div>
        <div class="mt-2">
          <input type="radio" id="radio-update-version" name="version" value="update" checked>
          <label for="radio-update-version" class="align-top">
            Update current version
            <div class="text-secondary" style="font-size: 12px;">
              Changes are permanent and cannot be undone. <br/>
              However you can restore a previous version if needed.
            </div>
          </label>
        </div>
        <div class="mt-2">
          <input type="radio" id="radio-create-version" name="version" value="create">
          <label for="radio-create-version" class="align-top">
            Create new version
            <div  class="text-secondary" style="font-size: 12px;">
              You can restore previous version if needed.
            </div>
          </label>
        </div>
      </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'Save',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: async () => {

        const isUpdating = document.getElementById('radio-update-version').checked;

        const request = {
          customerId: props.customerId,
          templateId: props.templateId,
          // If the name has changed, a new version is generated, otherwise the existing one is updated
          templateVersionId: isUpdating ? templateVersionId : '',
          templateVersionName: templateVersionName,
          templateVersionSubject: templateVersionSubject,
          templateVersionHtmlRaw: html,
          templateVersionHtmlTestData: JSON.stringify(previewData),
          templateVersionDesign: JSON.stringify(values.content)
        }

        return templateService.saveDesign(request)
          .then(response => {
            console.log(response.data);
            if (response.status !== 200) {
              throw new Error(response.data.message);
            }

            Swal.fire({
              icon: 'success',
              title: 'Design Updated',
              showConfirmButton: false,
              timer: 1500
            });
            
            setTemplateVersionId(response.data);
          })
          .catch(error => {
            Swal.showValidationMessage(error);
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  function onBeforePreview(html, mergeTags) {
    if (!previewData || !previewData.loaded) {
      const template = Handlebars.compile(loadingPreviewHTML);
      return template(mergeTags);
    } else {
      const template = Handlebars.compile(html);
      const templateMerged = template(mergeTags);
      const templateId = document.getElementById('designerTemplateId').value;
      const templateMergedWithCharts = designerCheckCharts(templateId, templateMerged, mergeTags);

      return templateMergedWithCharts;
    }
  }

  function displayTemplateTestDataModal() {
    setShowTemplateTestDataModal(true);
  }

  function closeTemplateTestDataModal() {
    setShowTemplateTestDataModal(false);
  }

  function onRestoreClick() {
    setShowModal(true);
    templateService.findVersions(props.customerId, props.templateId).then(response => {
      setTemplateVersions(response.data);
    });
  }

  function onRestoreVersion(versionId) {
    Swal.fire({
      title: 'Ready to restore?',
      showCancelButton: true,
      confirmButtonText: 'Restore',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {

        const request = {
          customerId: props.customerId,
          templateId: props.templateId,
          templateVersionId: versionId,
          templateVersionName: templateVersionName,
          templateVersionSubject: templateVersionSubject
        };

        return templateService.restoreVersion(props.customerId, props.templateId, versionId, request).then(() => {

          setShowModal(false);
          setInitialValues(null);
          findTemplateDesign();

          Swal.fire({
            icon: 'success',
            title: 'Version Restored',
            showConfirmButton: false,
            timer: 1500
          });

        }).catch(error => {
          Swal.showValidationMessage(error);
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  function onDeleteVersion(versionId) {
    Swal.fire({
      title: 'Ready to delete?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {

        return templateService.deleteVersion(props.customerId, props.templateId, versionId).then(() => {

          setShowModal(false);

          Swal.fire({
            icon: 'success',
            title: 'Version deleted',
            showConfirmButton: false,
            timer: 1500
          });

        }).catch(error => {
          Swal.showValidationMessage(error);
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      {!initialValues ? <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" /> : <>
        <div className="designer">
          <EmailEditorProvider
            data={initialValues}
            height={'calc(100vh - 72px)'}
            autoComplete
            dashed={false}
            onSubmit={(values) => saveDesign(values)}
            previewInjectData={previewData}
            onBeforePreview={(html, mergeTags) => onBeforePreview(html, mergeTags)}
            interactiveStyle={{ selectedColor: "#FF7A00" }}
            fontList={[
              { label: 'Verdana', value: 'Verdana' },
              { label: 'Poppins', value: 'Poppins' },
              { label: 'Sans Serif', value: 'sans-serif' }
            ]}
          >
            {({ values }, { submit }) => {
              return (
                <>
                  <Row className="arco-card-bordered" style={{ marginLeft: 0, marginRight: 0, padding: 10, backgroundColor: 'var(--color-neutral-1)' }}>
                    <Col>
                      <input id="designerTemplateId" type="hidden" value={props.templateId} />
                      <h5 style={{ marginBottom: 0, padding: 7, paddingLeft: 0 }}>{templateName}</h5>
                    </Col>

                    <Col className="d-flex justify-content-end">
                      <Button variant="outlined" type="button" className="btn btn-submit mt-0 ms-3" endIcon={<SaveIcon />}
                        onClick={() => submit()} style={{ textTransform: "inherit", border: "none" }}>Save Design</Button>

                      {false &&
                        <button type="button" style={{ marginLeft: 7, marginTop: 0 }} className="btn btn-cancel"
                          onClick={() => displayTemplateTestDataModal()}>&#123; &#125; Show Test Data</button>
                      }

                      <Button id="optionsButton"
                        variant="outlined"
                        className="btn-cancel ms-3"
                        onClick={(event) => {
                          setAnchorOptions(event.currentTarget);
                          setShowOptions(true);
                        }}
                        endIcon={<MoreVertIcon />}
                        style={{ textTransform: "inherit" }}>
                        Options
                      </Button>
                      <Menu
                        anchorEl={anchorOptions}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        className="mt-2"
                        open={showOptions}
                        onClose={() => setShowOptions(false)}
                        disableScrollLock>

                        {!props.predefinedTemplate &&
                          <MenuItem onClick={() => {
                            setShowOptions(false);
                            onRestoreClick();
                          }}>
                            <ListItemIcon>
                              <CopyAllIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Manage Versions</Typography>
                          </MenuItem>
                        }

                        <MenuItem onClick={() => {
                          setShowOptions(false);
                          onReloadClick();
                        }}>
                          <ListItemIcon>
                            <ReplayIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit">Reload Preview Data</Typography>
                        </MenuItem>
                      </Menu>
                    </Col>
                  </Row>

                  <Row className="m-0">
                    <Col className="px-0" lg={12}>
                      <StandardLayout
                        compact={true}
                        showSourceCode={true}
                        categories={DefaultCategories}
                      >
                        <EmailEditor />
                      </StandardLayout>
                    </Col>
                  </Row>
                </>
              );
            }}
          </EmailEditorProvider>


          <TemplateTestData templateId={props.templateId} templateVersionId={templateVersionId} testDataContent={previewData}
            open={showTemplateTestDataModal} close={() => closeTemplateTestDataModal()} onUpdateTestDataContent={setPreviewData} />
        </div>
      </>
      }

      {!props.predefinedTemplate &&
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Restore Version</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="mb-3">Versions are displayed in the order they were created</label>
            <div className="table-responsive">
              <table className="table m-0">
                <tbody>
                  {templateVersions && templateVersions.length > 0 ?
                    templateVersions.map((templateVersion, index) => (
                      <tr key={templateVersion.versionId}>
                        <td className="p-2" width={50}>
                          <Avatar sx={{ bgcolor: green[500] }}>
                            <AssignmentIcon />
                          </Avatar>
                        </td>
                        <td className="p-2">
                          <div>{templateVersionId === templateVersion.versionId ? 'Current Version' : 'Version ' + (templateVersions.length - index)}</div>
                          <div class="text-secondary" style={{ fontSize: 12 }}>
                            {moment(templateVersion.createdTimestamp).local().format('DD MMM YYYY, HH:mm')} ({(templateVersion.design.length / 1024).toFixed(2) + ' Kb'})
                          </div>
                        </td>
                        <td className="p-2" width={100}>
                          {templateVersionId !== templateVersion.versionId &&
                            <button type="button" className="btn btn-submit mt-0 ms-3"
                              onClick={() => onRestoreVersion(templateVersion.versionId)}>Restore</button>
                          }
                        </td>
                        <td className="p-2" width={100}>
                          {templateVersionId !== templateVersion.versionId &&
                            <button type="button" className="btn btn-submit mt-0 ms-3"
                              onClick={() => onDeleteVersion(templateVersion.versionId)}>Delete</button>
                          }
                        </td>
                      </tr>
                    ))
                    :
                    <tr>
                      <td colSpan={4}>
                        {(templateVersions && templateVersions.length === 0) ? "No versions found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                      </td>
                    </tr>
                  }

                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      }

    </div>

  );
}

export default withRouter(Designer);
