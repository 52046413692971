import { Tabs } from '@arco-design/web-react';
import React, { useState } from 'react';

const { TabPane } = Tabs;

export function EditTab(props) {
  const { value, additionItem } = props;
  const [activeTab, setActiveTab] = useState('0');

  const onAddTab = () => {
    setActiveTab((value.length).toString());
    props.onChange([...value, additionItem]);
  };

  const onDeleteTab = (index) => {
    if (index < activeTab) {
      setActiveTab((Number(activeTab) - 1).toString());
    }
    if (index === activeTab) {
      setActiveTab(Number(index) > 0 ? `${Number(index) - 1}` : '0');
    }
    props.onChange(value.filter((_, vIndex) => Number(index) !== vIndex));
  };

  return (
    <Tabs
      destroyOnHide
      className="xxxx"
      style={{ border: 'none' }}
      type='card'
      activeTab={activeTab}
      tabPosition={props.tabPosition}
      editable
      onAddTab={onAddTab}
      onDeleteTab={onDeleteTab}
      onChange={setActiveTab}
    >
      {(Array.isArray(value) ? value : []).map((item, index) => (
        <TabPane
          style={{ paddingLeft: 12 }}
          title={`${props.labelTab || 'Tab'} ${index + 1}`}
          key={index}
        >
          {props.renderItem(item, index)}
        </TabPane>
      ))}
    </Tabs>
  );
}
