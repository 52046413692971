import * as React from "react";
import { withRouter } from "react-router-dom";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import SideBar from "./SideBar";
import { useState, useEffect } from 'react';
import authService from '../../Services/authServices'
import AnimatedBody from "../../Wrappers/AnimatedBody";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import LogFilesService from "../../Services/LogFilesService";
import Swal from 'sweetalert2';

function Logs(props) {

  const logFilesService = new LogFilesService();
  const myServiceInstance = new authService();

  const [logFiles, setLogFiles] = useState(null);

  const loggedInCustomer = myServiceInstance.getCurrentCustomer();
  if (!loggedInCustomer?.SUPERADMIN) {
    props.history.push("/Login")
  }

  useEffect(() => {
    findAllLogFiles();
  }, []);
  
  function findAllLogFiles() {
    logFilesService.findAll().then(response => {
      setLogFiles(response);
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }
  
  function exportFile(logFileName, logFileShortName) {
    logFilesService.exportFile(logFileShortName).then(response => {
      if (response.status == 200) {
        const blob = new Blob([response.data], { type: "application/zip;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `export_${logFileShortName}.zip`;
        a.click();
      }
    })
      .catch(error => {
        Swal.showValidationMessage(
          `Failed to download file: ${error}`
        )
      });
  }
  
  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <SideBar
        sideBarExpanded={props.sideBarExpanded}
        setSideBarExpanded={props.setSideBarExpanded}
      />
      <div id="main">
        <div className="container-fluid custom-container">

          <AnimatedBody
            sideBarExpanded={props.sideBarExpanded}
            setSideBarExpanded={props.setSideBarExpanded}
            className="container-fluid custom-container"
          >
            <div className="row row-margin row-left">
              <div className="col-12">
                
                <table style={{ width: "100%", overflow: "scroll !important" }}>
                  <thead>
                    <tr className="line">
                      <th className="line-data">Filename</th>
                      <th className="line-data">Last Modified</th>
                      <th className="line-data" width={120}>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logFiles && logFiles.length > 0 ?
                      logFiles.map((logFile, index) => (
                        <tr key={logFile.name} className="line">
                          <td className="line-data">{logFile.name}</td>
                          <td className="line-data">{logFile.lastModifiedDate}</td>
                          <td className="line-data">
                            <button type="button" className="btn btn-light"
                              style={{ margin: "0 0 0 8px" }}
                              onClick={() => exportFile(logFile.name, logFile.shortName)}>
                              Download
                            </button>
                          </td>
                        </tr>))
                      :
                      <tr>
                        <td colSpan={2}>
                          {(logFiles && logFiles.length === 0) ? "No log files found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                        </td>
                      </tr>
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </AnimatedBody>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Logs);
