import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AddApiService from '../../Services/addApiService';
import RecipientService from "../../Services/RecipientService";
import SearchBar from "material-ui-search-bar";
import { faSortAlphaAsc, faSortAlphaDesc } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AddRecepientModal(props) {

  const recipientService = new RecipientService();
  const addApiService = new AddApiService();

  const [templateId, setTemplateId] = useState(props.templateId);
  const [customerId, setCustomerId] = useState(props.customerId);
  const [recipients, setRecipients] = useState(null);
  const [recipientsData, setRecipientsData] = useState(null);

  const [recipientsToAdd, setRecipientsToAdd] = useState([]);

  const [emailsCommaSeparated, setEmailsCommaSeparated] = useState('');
  const [sort, setSort] = useState("ASC")

  useEffect(() => {
    if (props.open) {
      recipientService.findRecipientsByCustomer(customerId).then(response => {
        const result = Object.entries(response.reduce((acc, { email, id, template }) =>
          (acc[email] = [...(acc[email] || []), { id, template }], acc)
          , {})).map(([key, value]) => ({ email: key, data: value }));

        addApiService.getTemplateUser(templateId).then(response => {
          setRecipients(result.filter(recipient => {
            recipient.checked = false;
            const indexFound = response.findIndex(res => res.userName === recipient.email);
            return indexFound < 0 ? recipient : null;
          }));
          setRecipientsData(result.filter(recipient => {
            recipient.checked = false;
            const indexFound = response.findIndex(res => res.userName === recipient.email);
            return indexFound < 0 ? recipient : null;
          }));

        }).catch((error) => {
          console.log('ERROR: ', error);
        });

      }).catch((error) => {
        console.log('ERROR: ', error);
      });
    }
  }, [props.open]);

  function selectAllRecipientToAdd(checked) {
    const allRecipientsToAdd = [];
    const checkedRecipients = recipients.map((recipient) => {
      recipient.checked = checked;
      if (checked) {
        allRecipientsToAdd.push(recipient.email);
      }
      return recipient;
    });

    setRecipients(checkedRecipients);
    setRecipientsToAdd(allRecipientsToAdd);
  }

  function selectRecipientToAdd(selectedRecipient, checked) {
    const checkedRecipients = recipients.map((recipient) => {
      if (recipient.email === selectedRecipient.email) {
        recipient.checked = checked;
      }
      return recipient;
    });
    setRecipients(checkedRecipients);

    const emailFoundIndex = recipientsToAdd.findIndex(recipient => recipient === selectedRecipient.email);
    if (emailFoundIndex > -1) {
      recipientsToAdd.splice(emailFoundIndex, 1);
    } else {
      recipientsToAdd.push(selectedRecipient.email);
    }
    setRecipientsToAdd(recipientsToAdd);

    console.log(recipientsToAdd);
  }

  function attachEmailToTemplate() {
    // Including emails comma separated and removing duplicates
    if (emailsCommaSeparated) {
      recipientsToAdd.push(...emailsCommaSeparated.split(','));
    }
    const recipientsNoDuplicates = recipientsToAdd.filter((item, index) => recipientsToAdd.indexOf(item) === index);

    recipientService.addRecipients(templateId, recipientsNoDuplicates).then(response => {
      if (response) {
        setRecipientsToAdd([]);
        setEmailsCommaSeparated('');
        props.close();
      }
    },

    ).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  function requestSearch(searchedVal) {
    if (searchedVal) {
      let filteredRows = recipients.filter((row) => {
        return row.email?.toLowerCase().includes(searchedVal.toLowerCase());
      });

      setRecipients(filteredRows)
    } else {
      setRecipients(recipientsData);
    }
  };

  function cancelSearch() {
    setRecipients(recipientsData);
  };

  function sortEmails() {
    if (sort == "DESC") {
      recipients.sort((a, b) => a?.email?.localeCompare(b?.email));
      setSort("ASC");
    } else if (sort == "ASC") {
      recipients.sort((a, b) => b?.email?.localeCompare(a?.email));
      setSort("DESC");
    } else {
      recipients.sort((a, b) => a?.email?.localeCompare(b?.email));
      setSort("ASC");
    }

    setRecipients(recipients);
  }

  return (
    <Modal show={props.open} onHide={props.close}>
      <Modal.Header closeButton>
        <Modal.Title>Add Recipient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email</label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter recipients email. Comma seperated for multiple emails."
            onChange={e => setEmailsCommaSeparated(e.target.value)}
          />

          <div className="line-data">
            {recipients && recipients.length > 0 ?
              <input style={{ marginRight: 10 }}
                type="checkbox"
                title="Select All Recipients"
                onChange={(e) => selectAllRecipientToAdd(e.target.checked)} />
              : <span />
            }
            <label>Recipients</label>
            <FontAwesomeIcon
              icon={sort == "ASC" ? faSortAlphaAsc : sort == "DESC" ? faSortAlphaDesc : ""}
              onClick={() => sortEmails()}
              style={{ marginLeft: "10px" }}
            />
            <SearchBar
              variant="filled"
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
          </div>
          <div style={{ overflowY: 'auto', maxHeight: '45vh', padding: '0 10px' }}>
            {recipients && recipients.length > 0 ?
              <table style={{ width: "100%" }}>
                <tbody>
                  {recipients.map((recipient, index) => (
                    <tr key={recipient.email} className="line">
                      <td className="line-data">
                        <input id={"check_"+index} style={{ marginRight: 10 }}
                          type="checkbox"
                          checked={recipient.checked}
                          onChange={(e) => selectRecipientToAdd(recipient, e.target.checked)} />
                        <label for={"check_"+index}>{recipient.email}</label>
                      </td>
                    </tr>))}
                </tbody>
              </table>
              : "No new recipients found."

            }

          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-cancel"
          data-dismiss="modal"
          onClick={props.close}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-submit"
          data-dismiss="modal"
          onClick={() => attachEmailToTemplate()}
          style={{ float: "right", marginTop: "0" }}

        >
          Add Recipient
        </button>
      </Modal.Footer>
    </Modal>
  );
}
