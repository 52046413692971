import axios from 'axios';
import { environment } from '../environment';

class UserService {

  // Get All Users by Customer
  findUsersByCustomer = (customerId) => {
    return axios.get(environment.API_URL + `user?customerId=${customerId}`)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  // Add User
  createUser = (data) => {
    return axios.post(environment.API_URL + `user/add`, data)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  // Reset Password
  resetPassword = (userId) => {
    return axios.post(environment.API_URL + `user/reset/${userId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  // Delete User
  deleteUser = (userId) => {
    return axios.delete(environment.API_URL + `user/${userId}`)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  findAll = () => {
    return axios.get(environment.API_URL + `user`)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  
  makeOrRevokeAdmin = (userId, isAdmin) => {
    return axios.post(environment.API_URL + `user/makeOrRevokeAdmin`, { id: userId, admin: isAdmin })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  
  checkApiKey = (userId) => {
    return axios.get(environment.API_URL + `user/status/apikey/${userId}`)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  }

}

export default UserService;