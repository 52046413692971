import axios from 'axios';
import { environment } from '../environment';

class LogFilesService {

  findAll = () => {
    return axios.get(environment.API_URL + `logfile`)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  }
 
  exportFile = (fileName, templateId) => {
    return axios.get(environment.API_URL + `logfile/${fileName}/download`, { responseType: 'blob' })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });
  }
}

export default LogFilesService;