import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import Swal from 'sweetalert2';
import { isJsonString } from '../../Shared/common-utils'

export default function TemplateTestData(props) {
  const [testDataContent, setTestDataContent] = useState(JSON.stringify(props.testDataContent, undefined, 2) || '{"obj": "data..."}');
	
  function save() {
	
	if(isJsonString(testDataContent)){
	  Swal.fire({
        icon: 'success',
        title: 'Content Updated',
        showConfirmButton: false,
        timer: 1500
      });
      
      props.onUpdateTestDataContent(JSON.parse(testDataContent));        
      props.close();
	} else {
	  Swal.fire({
	    title: 'Not a valid JSON content',
        icon: 'error'
      })	
	} 
  }
  
  return (
    <Modal show={props.open} onHide={props.close} size="lg" backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Test Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="inputJsonContent">JSON Content</label>
          <textarea
            className="form-control"
            id="inputJsonContent"
            aria-describedby="jsonContent"
            placeholder="Enter JSON data"
            rows="20" cols="40" autoFocus
            value={testDataContent}
            onChange={e => setTestDataContent(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-cancel"
          data-dismiss="modal"
          onClick={props.close}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-submit"
          data-dismiss="modal"
          onClick={() => save()}
          style={{ float: "right", marginTop: "0" }}
        >
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>	
  );
}