import React from 'react';

import logo from "../images/logo.png";
// import banner from "../images/banner.png";

import '../Style/AddApi.css';
import AddApiService from '../Services/addApiService';
// import { Badge } from 'react-bootstrap';

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.addApiService = new AddApiService();
    this.state = {
      selectedOption: "",
      // customerDetail: [],
      customer: [],
      custName:''
      // visible: false,
      // isUpdateMode: false
    }
  }

  //on load function call
  componentDidMount() {
    // Get All Customer
    this.getAllCustomer();
  }
  

  // Get All Customer
  getAllCustomer() {
    
    this.addApiService.getAllCustomer().then(customerList => {
      // this.setState({ options: customerList });
      this.setState({ customer: customerList });

      if (sessionStorage.getItem('currentCustomer')) {
        const custId = JSON.parse(sessionStorage.getItem('currentCustomer')).customerId;

        this.setState({
          selectedOption: custId,
          customerId: custId
        });

        this.props.selectCustomer(custId);

        
        const custName = this.state.customer.find(o => o.id === custId).customerName

        
        this.setState({ custName: custName });
       
        
      }
      
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  // Selected Customer
  selectedCustomer(custId) {
    // setting session storage
    sessionStorage.setItem('customerId', custId);
    this.setState({
      selectedOption: custId,
      customerId: custId
    });

    // send customerID to parent component
    this.props.selectCustomer(custId);
  }

  render() {
    return (
      <div>

        <div className="row background-banner">
          <div className="col-md-1 col-sm-3 p-0">
            <div className="side-left-up">
              <a className="logo-a" href="#" />
              <img src={logo} alt="ggg" />

            </div>
          </div>
          <div className="col-md-11 col-sm-9 p-0 bottom-line ">
            <div className="side-right top-head">
              <nav className="nav-outer">
                <div className="col-md-12 col-sm-12">
                  <div className="nav-left">
                    <h1>Apptio Software Extension</h1>
                  </div>
                  <div className="nav-right">
                    <label>Customer:</label>
                    <label>{this.state.custName}</label>
                    
                    {/* <Badge bg="light" text="dark">
                   
                     {this.state.custName}
                    </Badge> */}


                    
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

      </div>
    )
  }

}
export default Header