import axios from 'axios';
import { environment } from '../environment';

class PredefinedTemplateService {

  findAll = () => {
    return axios.get(environment.API_URL + `predefinedTemplate`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }
  
  findAllActive = () => {
    return axios.get(environment.API_URL + `predefinedTemplate/catalog`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }

  findById = (predefinedTemplateId) => {
    return axios.get(environment.API_URL + `predefinedTemplate/${predefinedTemplateId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  }
  
  save = (predefinedTemplateData) => {
    return axios.post(environment.API_URL + 'predefinedTemplate', predefinedTemplateData)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      })
  }

  update = (predefinedTemplateId, predefinedTemplateData) => {
    predefinedTemplateData.id = predefinedTemplateId;
    return axios.put(environment.API_URL + `predefinedTemplate/${predefinedTemplateId}`, predefinedTemplateData)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      })
  }
  
  delete = (predefinedTemplateId) => {
    return axios.delete(environment.API_URL + `predefinedTemplate/${predefinedTemplateId}`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      })
  }

  saveDesign = (predefinedTemplateData) => {
    return axios.put(environment.API_URL + 'predefinedTemplate/design', predefinedTemplateData)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      })
  }

}

export default PredefinedTemplateService;