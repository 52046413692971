import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authServices from '../Services/authServices';

function PrivateRoute(props) {

  const myServiceInstance = new authServices();
  const loggedInCustomer = myServiceInstance.getCurrentCustomer();

  const { children, newDesignerRestricted = false, ...rest } = props;

  const render = () => {

    // Not authenticated
    if (!loggedInCustomer) {
      return <Redirect to='/Login' />;
    }

    // If new designer allowed
    if (newDesignerRestricted && !loggedInCustomer.newDesignerAllowed && !loggedInCustomer?.SUPERADMIN) {
      return <Redirect to='/EmailSchedule' />;
    }

    return children;
  };

  return <Route {...rest} render={render} />;
}


export default PrivateRoute;