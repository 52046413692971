import React, { useState, useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";
import withSidebarAnimation from "./withSidebarAnimation";

function AnimatedBody(props) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [styles, animate] = useSpring(() => ({
    from: { paddingLeft: "0.75rem" },
    config: { friction: 26 },
  }));

  useEffect(() => {
    if (width > 768) {
      if (props.sideBarExpanded) {
        animate({ to: { paddingLeft: "7.75rem" } });
      } else {
        animate({ to: { paddingLeft: "0.75rem" } });
      }
    }
  });

  return (
    <animated.div
      style={(props.style, { ...styles })}
      className={props.className}
    >
      {props.children}
    </animated.div>
  );
}

export default withSidebarAnimation(AnimatedBody);
