import React from 'react';
import { Pagination, Table } from 'react-bootstrap';
import ReportService from '../Services/ReportService';

import '../Style/MobileDataList.css';
import * as moment from 'moment-timezone';
import PropTypes from "prop-types";
const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

class MyPagination extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
     
      recordsPerPage: props.pageLimit,
      pageNeighbours: props.pageNeighbours,
      totalPages: 0,

      currentPage: 1,
      firstPageNumber: 1,

    }
    this.state.totalPages = Math.ceil(this.props.totalRecords / this.props.pageLimit);


    this.gotoFirstPage = this.gotoFirstPage.bind(this);
    this.gotoLastPage = this.gotoLastPage.bind(this);
    this.gotoPreviousPage = this.gotoPreviousPage.bind(this);
    this.gotoNextPage = this.gotoNextPage.bind(this);
    this.manageEllipsis = this.manageEllipsis.bind(this);
    this.gotoPage = this.gotoPage.bind(this);
    this.createPages = this.createPages.bind(this)

  }

  componentDidMount() {
    this.gotoPage(1);
  }


  gotoFirstPage() {
    console.log('goto first page' + this.state.firstPage);


    this.setState(prevState => ({
      currentPage: 1
    }))

    this.gotoPage(1);
  }

  gotoLastPage() {

    this.setState(prevState => ({
      currentPage: this.state.totalPages,

    }))

    this.gotoPage(this.state.totalPages);
  }

  gotoPreviousPage() {
    


    this.setState(prevState => ({
      currentPage: Number(this.state.currentPage) - 1
    }))

    console.log('goto previous page' + Number(this.state.currentPage));

    this.gotoPage(Number(this.state.currentPage) - 1);
  }

  gotoNextPage() {


    this.setState(prevState => ({
      currentPage: Number(this.state.currentPage) + 1
    }))

    this.gotoPage(Number(this.state.currentPage) + 1);


    console.log('goto next page' + Number(this.state.currentPage));
  }

  manageEllipsis() {
    console.log('manage ellipsis')
  }

  gotoPage(e) {
    
    const { onPageChanged = f => f } = this.props;

    let currentPage = null;
    if (e.target !== undefined) {
      currentPage = Number(e.target.innerText);
    } else {
      currentPage = e
    }
    const paginationData = {
      currentPage,
      totalPages: this.state.totalPages,
      pageLimit: this.state.recordsPerPage,
      totalRecords: this.props.totalRecords
    };

    this.setState({ currentPage }, () => onPageChanged(paginationData));
  };

  createPages() {
    
    let pages = [];
    let start = 1;
    let to = 5;
    let pushEllipsisInStart = false;



    if (this.state.currentPage > 5) {
      pushEllipsisInStart = true;
      start = this.state.currentPage - 3;
    }
    
    if (this.state.totalPages - start > 5) {
      to = start + 4
    } else {
      to = start + this.state.totalPages - start ;

    }

    if (this.state.totalPages <= 5) {
      to = this.state.totalPages;
    }

    pages.push(<Pagination.Item onClick={this.gotoPage} active={this.state.currentPage === 1 ? true : false}>{1}</Pagination.Item>)
    if (pushEllipsisInStart) {
      pages.push(<Pagination.Ellipsis disabled={true} />)
    }

    for (let i = start; i <= to; i++) {
      if (i !== 1) {
        console.log('inside loop')
        pages.push(<Pagination.Item onClick={this.gotoPage} active={this.state.currentPage === i ? true : false}>{i}</Pagination.Item>)
      }
    }

    if (this.state.totalPages !== to) {
      pages.push(<Pagination.Ellipsis disabled={true} />)
      pages.push(<Pagination.Item onClick={this.gotoPage} active={this.state.currentPage === this.state.totalPages ? true : false}>{this.state.totalPages}</Pagination.Item>)
    }




    return pages;
  }
  render() {
    return (
      <Pagination>
        <Pagination.First onClick={this.gotoFirstPage} disabled={this.state.currentPage === this.state.firstPageNumber ? true : false} />
        <Pagination.Prev onClick={this.gotoPreviousPage} disabled={this.state.currentPage === this.state.firstPageNumber ? true : false} />



        {this.createPages()}



        <Pagination.Next onClick={this.gotoNextPage} disabled={this.state.totalPages === this.state.currentPage ? true : false} />
        <Pagination.Last onClick={this.gotoLastPage} disabled={this.state.totalPages === this.state.currentPage ? true : false} />
      </Pagination>
    );
  }



}

MyPagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func
};

export default MyPagination