import React from 'react';

import '../Style/AddApi.css';
import AddApiService from '../Services/addApiService';
import { Accordion, Card, Offcanvas } from 'react-bootstrap';
import apiKeyService from '../Services/settingService';
import settingService from '../Services/settingService';
import authService from '../Services/authServices';
import { withRouter } from 'react-router-dom';
import { Box, FormControl, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Swal from 'sweetalert2';

class LeftMenu extends React.Component {

  constructor(props) {
    super(props);
    this.addApiService = new AddApiService();
    this.authService = new authService();
    this.apiKeyService = new apiKeyService();
    this.settingService = new settingService();
    this.state = {
      selectedOption: "",
      // customerDetail: [],
      customer: [],
      settingsOffCanvas: false,
      // visible: false,
      // isUpdateMode: false
      key: "",
      secret: "",
      userId: this.authService.getCurrentCustomer().id,
      activeMenu: this.props.activeMenu,
      passwordType: "password",
      currentPassword: "",
      showPassword: false,
      newPassword: "",
      showNewPassword: false,
      confirmPassword: "",
      helperTextConfirm:"",
      showConfirmPassword: false,
      errorMessage : ""

      
    }
  
  }
  // Routing towards AddApi view
  onApiClick = () => {
    this.props.history.push("/AddApi")
  };

  //Routing towards Schedular
  onEmailerSchedular = () => {
    this.props.history.push("/EmailerScheduler")
  };

  // Routing towards mobile view
  onMobileView = () => {
    this.props.history.push("/mobile")
  };

  // Routing towards mobile view
  onReportsView = () => {
    this.props.history.push("/ReportsView")
  };

  //Routing towards Emailer
  onEmailer = () => {
    this.props.history.push("/Emailer")
  };

  //Routing towards Settings
  onSetting = () => {
    // this.props.history.push("/Setting")
    if (this.state.settingsOffCanvas === true) {
      this.setState({ settingsOffCanvas: false })
    }
    else {
      this.setState({ settingsOffCanvas: true })
      this.getAllApiKey()
    }

  }

  logout = () => {
    this.executeLogout();
    // Clear Session Storage
    sessionStorage.clear();
    // Redirect to Login
    this.props.history.push('/');
  }

  onSettingOffcanvasClose = () => {
    // this.props.history.push("/Setting")
    this.setState({ settingsOffCanvas: false })
  }

  // get api keys
  getAllApiKey = () => {
    console.log(this.state.userId.id, 'get all key')
    this.apiKeyService.getApiKey(this.state.userId).then(response => {
      console.log('Response: ', response);
      this.setState({
        key: response.key,
        secret: response.secret
      });
    });
  }

  executeLogout = () => {
    this.authService.logout();
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handlePasswordChange = (prop) => (event) => {
    this.setState({ currentPassword: event.target.value });
  };


  handleClickShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };

  handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  handleNewPasswordChange = (prop) => (event) => {
    
    this.setState({ newPassword: event.target.value,});
    // password_validate(event)
  };

  password_validate = (prop) => (event) => {
    var mess = event.target.value

    //at least one number
    var regInt = new RegExp('(?=.*[0-9])')
    var testInt = regInt.test(mess)

    //at least one special char
    var specialChar = new RegExp('(?=.*[!@#$%^&*])')
    var testSpecial = specialChar.test(mess);

    //at least on small letter
    var sletter = new RegExp('(?=.*[a-z])')
    var sletterTest = sletter.test(mess);

    //at least on capital letter
    var cletter = new RegExp('(?=.*[A-Z])')
    var cletterTest = cletter.test(mess);
   

   if(event.target.value.length <=7) {
    this.setState({newPassword:mess, errorMessage: "Password length should be minimum 8 letters."});
    }
    else if(!testInt) {
      this.setState({newPassword:mess, errorMessage: "Password should contain at least one digit."});
    }
    else if(!testSpecial) {
      this.setState({newPassword:mess, errorMessage: "Password should contain at least one special letter."});
    }
    else if(!sletterTest) {
      this.setState({ newPassword:mess,errorMessage: "Password should contain at least one small letter."});
    }
    else if(!cletterTest) {
      this.setState({ newPassword:mess,errorMessage: "Password should contain at least on capital letter."});
    }
    else {
      this.setState({ newPassword:mess,errorMessage: ""});
    }
}

  handleClickShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  handleConfirmPasswordChange = (prop) => (event) => {
    if(this.state.newPassword !== event.target.value) {
      this.setState({ confirmPassword: event.target.value,
        helperTextConfirm:"Password does not match the new password"                      
      });
    } else {
    this.setState({ confirmPassword: event.target.value,
      helperTextConfirm:""                      
    });
  }

}

  UpdateKey = (e) => {
    e.preventDefault();
    let updatedData = {
      key: this.state.key,
      secret: this.state.secret
    }
    this.settingService.addApiKey(this.state.userId, updatedData).then(response => {


    })
  }

  changePassword = () => {
    
    if(this.state.errorMessage.length !== 0) {
      this.setState({ 
        errorMessage:"Please choose a Password"       
    });
    }
    if(this.state.newPassword !== this.state.confirmPassword) {
      this.setState({ 
        helperTextConfirm:"Password does not match the new password"       
    });
    return;
  }
    Swal.fire({
      title: 'Change Password',
      showCancelButton: true,
      confirmButtonText: 'Change!',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.authService.updatePassword({id: this.state.userId, password: this.state.currentPassword, newPassword: this.state.newPassword})
          .then(response => {
            
            if (response.statusCode !== 200) {
              throw new Error(response.message)
            }

            Swal.fire({

              icon: 'success',
              title: 'Password has been changed.',
              showConfirmButton: false,
              timer: 1500
            })

            this.setState({
              data: [],
              selectedApplications: [],
              selectedApplicationValues: [],
              selectedTemplateValue: {},
              selectedUserValues: [],
              selectedUsers: [],
              isAppSelected: false,
              selectedAppointment: null
            })
            this.fetchScheduledEmailers();
          })
          .catch(error => {
            
            Swal.showValidationMessage(
              error
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {
      this.setState({settingsOffCanvas:false})
    })
  }

  render() {
    return (


      <div className="col-md-1 col-sm-3 p-0">
        <div className="side-left">
          <ul className="padding-left-0">
            <li className={`${this.state.activeMenu === "Emailer" ? "active" : ""}`}>
              <a onClick={this.onEmailer}>
                <span tooltip="Emailers" flow="right"><i className="fa fa-envelope" /></span>
              </a>
            </li>
            <li className={`${this.state.activeMenu === "EmailerScheduler" ? "active" : ""}`}>
              <a onClick={this.onEmailerSchedular}>
                <span tooltip="Schedule Emailers" flow="right"><i className="fa fa-calendar-plus-o" aria-hidden="true" /></span>
              </a>
            </li>
            {/* <li className={`${this.state.activeMenu === "AddApi" ? "active" : ""}`}>
              <a onClick={this.onApiClick}>
                <span tooltip="API" flow="right"><i className="fa fa-file-text" aria-hidden="true" /></span>
              </a>
            </li>
            <li className={`${this.state.activeMenu === "MobileView" ? "active" : ""}`}>
              <a onClick={this.onMobileView}>
                <span tooltip="Mobile" flow="right">
                  <i className="fa fa-mobile custom-mobile" aria-hidden="true"></i>
                </span>
              </a>
            </li> */}
            <li className={`${this.state.activeMenu === "ReportsView" ? "active" : ""}`}>
              <a onClick={this.onReportsView}>
                <span tooltip="Reports" flow="right">
                  <i className="fa fa-file-text" aria-hidden="true"></i>
                </span>
              </a>
            </li>
            <li className={`${this.state.settingsOffCanvas ? "active" : ""}`}>
              <a onClick={this.onSetting}>
                <span tooltip="Setting" flow="right"><i className="fa fa-cogs" aria-hidden="true"></i></span>
              </a>
            </li>
            <li>
              <a onClick={this.logout}>
                <span tooltip="Logout" flow="right"><i className="fa fa-key" aria-hidden="true"></i></span>
              </a>
            </li>
          </ul>
        </div>
        {this.state.settingsOffCanvas ?
          <div>
            <Offcanvas show={this.state.settingsOffCanvas} placement={'end'} scroll={false} backdrop={false}>
              <Offcanvas.Header closeButton onHide={this.onSettingOffcanvasClose} className="offcanvas-header">
                <Offcanvas.Title>Settings</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Client Api Key</Accordion.Header>
                    <Accordion.Body>
                      <div className="card">
                        <div className="card-body px-lg-5 pt-0">
                          <form className="text-center">

                            <div className="col">
                              <div className="md-form">
                                <label className="label-float"> Public Key</label>
                                <input type="text" className="form-control" name="key" value={this.state.key}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="md-form">
                                <label className="label-float">Secret Key</label>
                                <input type="text" className="form-control" name="secret" value={this.state.secret}
                                />
                              </div>
                            </div>
                            <button className="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0" type="submit"
                              onClick={(e) => this.UpdateKey(e)}
                            >
                              Update
                            </button>
                          </form>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Change Password</Accordion.Header>
                    <Accordion.Body>



                      <div className="row">
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                          <InputLabel htmlFor="outlined-current-password">Current Password</InputLabel>
                          <Input

                            id="outlined-current-password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.currentPassword}
                            onChange={this.handlePasswordChange('password')}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  onMouseDown={this.handleMouseDownPassword}
                                  edge="end"
                                >
                                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Current Password"
                          />
                        </FormControl>
                      </div>
                      <div className="row padding-top-15px">


                        <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                          
                          <TextField  

                            id="outlined-adornment-password"
                            type={this.state.showNewPassword ? 'text' : 'password'}
                            value={this.state.newPassword}
                            onChange={this.handleNewPasswordChange('password'), this.password_validate('password')}
                            helperText={this.state.errorMessage}
                            error={this.state.errorMessage.length !== 0}
                            InputProps={{endAdornment:
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowNewPassword}
                                  onMouseDown={this.handleMouseDownNewPassword}
                                  edge="end"
                                >
                                  {this.state.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }}
                            label="New Password" 
                          />
                          
                        </FormControl>
                      </div>
                      <div className="row padding-top-15px">
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                          {/* <InputLabel htmlFor="outlined-confirm-password">Confirm Password</InputLabel> */}
                          <TextField

                            id="outlined-confirm-password"
                            type={this.state.showConfirmPassword ? 'text' : 'password'}
                            value={this.state.confirmPassword}
                            onChange={this.handleConfirmPasswordChange('password')}
                            helperText = {this.state.helperTextConfirm}
                            error = {this.state.helperTextConfirm.length !== 0}
                            InputProps= {{ endAdornment:
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowConfirmPassword}
                                  onMouseDown={this.handleMouseDownConfirmPassword}
                                  edge="end"
                                >
                                  {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }}
                            label="Confirm Password"
                          />
                        </FormControl>
                      </div>

                      <div className="row padding-top-15px">
                        <button className="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0" type="submit" 
                          onClick={(e) => this.changePassword(e)}
                        >
                          Change Password
                        </button>
                      </div>

                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Offcanvas.Body>
            </Offcanvas>
          </div> : null
        }
      </div>


    )
  }

}
export default withRouter(LeftMenu)