import { AdvancedType, BasicType, components, createCustomBlock, getPreviewClassName } from 'easy-email-core';
import { ChartTags, ChartType, CustomBlocksType, CustomParams, BehaviourWhenNoData } from '../constants';
import React from 'react';
import { merge } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { now } from 'moment';
import { designerRemoveCurrencyBehavior } from '../../../../Shared/common-utils';

const { Table, Raw } = components;

export const TAG_NAME = 'charts.radial-gauge-chart-';

const DEFAULT_DATASET = [80];

const defaultChartConfig = `{
  type: '${ChartType.RADIAL_GAUGE}',
  data: {
    datasets: ${ChartTags.DATASETS},
  },
  options: ${ChartTags.OPTIONS},
}`;
// See https://github.com/pandameister/chartjs-chart-radial-gauge#options

export const RadialGaugeChart = createCustomBlock({
  name: 'Radial Gauge Chart',
  type: CustomBlocksType.RADIAL_GAUGE_CHART,
  create(payload) {
    const idx = now();

    const defaultData = {
      type: CustomBlocksType.RADIAL_GAUGE_CHART,
      data: {
        value: {
          id: `${TAG_NAME}${idx}`,
          type: ChartType.RADIAL_GAUGE,
          source: '',
          datasets: [
            {
              data: [80],
              backgroundColor: '#2b3990',
            }
          ],
          options: `{
            domain: [0, 100],
            trackColor: '#f0f8ff', 
            centerPercentage: 90,
            centerArea: {
              text: (val) => val + '%',
            },
          }`
        }
      },
      attributes: {
        src: `{{${TAG_NAME}${idx}}}`,
        padding: '0px 0px 0px 0px'
      },
      children: [],
    }
    return merge(defaultData, payload);
  },
  validParentType: [BasicType.COLUMN, BasicType.HERO, AdvancedType.COLUMN, AdvancedType.HERO],
  render(params) {
    const component = params.data;
    console.log('RadialGaugeChart');

    let chartConfig = cloneDeep(defaultChartConfig);

    // We need to cloneDeep because we dont want to change current status of component
    const datasets = cloneDeep(component.data.value.datasets);

    chartConfig = chartConfig.replace(ChartTags.HIDE_WHEN_NO_DATA, `'` + (!component.data.value.behaviorWhenNoData ? BehaviourWhenNoData.SHOW_MISSING_DATA_PLACEHOLDER : component.data.value.behaviorWhenNoData) + `'`);
    chartConfig = !component.data.value.labels ? chartConfig.replace(ChartTags.LABELS, '[]')
      : chartConfig.replace(ChartTags.LABELS, component.data.value.labels);
    chartConfig = chartConfig.replace(ChartTags.DATASETS, JSON.stringify(datasets));
    chartConfig = !component.data.value.options ? chartConfig.replace(ChartTags.OPTIONS, '{}')
      : chartConfig.replace(ChartTags.OPTIONS, designerRemoveCurrencyBehavior(component.data.value.options));

    // Following is to convert labels,dataset.data,dataset.backgroundColor as object {} instead of string with quote
    let chartConfigEncodedForDesign = encodeURIComponent(chartConfig);
    let chartConfigEncodedForPreview = encodeURIComponent(chartConfig);
    if (datasets) {
      let indexForDummyValue = 0;
      datasets.forEach((dataset) => {
        if (dataset.backgroundColor && dataset.backgroundColor.includes('getGradientFillHelper')) {
          chartConfigEncodedForDesign = chartConfigEncodedForDesign.replace(encodeURIComponent(`"${dataset.backgroundColor}"`), encodeURIComponent(dataset.backgroundColor));
          chartConfigEncodedForPreview = chartConfigEncodedForPreview.replace(encodeURIComponent(`"${dataset.backgroundColor}"`), encodeURIComponent(dataset.backgroundColor));
        }
        if (dataset.data) {
          chartConfigEncodedForDesign = chartConfigEncodedForDesign.replace(encodeURIComponent(`"${dataset.data}"`), `[${DEFAULT_DATASET[indexForDummyValue]}]`);
          chartConfigEncodedForPreview = chartConfigEncodedForPreview.replace(encodeURIComponent(`"${dataset.data}"`), `[${dataset.data}]`);

          indexForDummyValue++;
          if (indexForDummyValue >= DEFAULT_DATASET.length) {
            indexForDummyValue = 0;
          }
        }
      });
    }

    const img = `<div style="padding: ${component.attributes.padding}">
      <img height="auto" width="100%"
        src-preview="https://quickchart.io/chart?c=${chartConfigEncodedForPreview}"
        src="${params.mode === 'testing' ? 'https://quickchart.io/chart?c=' + chartConfigEncodedForDesign : component.attributes.src}"
        class="${CustomParams.CLASS_ENGAGE_CHART}" style="${CustomParams.STYLE_ENGAGE_CHART}" />
    </div>`;

    const designerInstance = (
      <Table key={`radial-gauge-chart-${params.idx}`}
        css-class={params.mode === 'testing' ? getPreviewClassName(params.idx, component.type) : ''}
        value={{ content: `<tr><td style="padding: ${component.attributes.padding}">${img}</td></tr>` }}
        role="presentation" padding="0px 0px 0px 0px" align="center">
      </Table>
    );

    const previewInstance = (
      <Raw key={`radial-gauge-chart-${params.idx}`} value={{ content: img }} />
    );

    return params.mode === 'testing' ? designerInstance : previewInstance;
  },
});


export { Panel } from './Panel'