import { Grid, Space } from '@arco-design/web-react';
import { TextStyle, useBlock } from "easy-email-editor";
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { NumberField } from 'easy-email-extensions';

export function MultiPixel(props) {

  const { label, title, nameTop, nameRight, nameBottom, nameLeft } = props;
  
  const { change } = useBlock();

  function onClear() {
    change(nameTop, '');
    change(nameRight, '');
    change(nameBottom, '');
    change(nameLeft, '');
  }

  return (
    <>
      <Space align='center'>
        <TextStyle variation='strong'>{label}</TextStyle>
        <IconButton aria-label="clear" size="small" title={title} onClick={() => onClear()}>
          <ClearIcon fontSize="inherit" />
        </IconButton>
      </Space>

      <Grid.Row>
        <Grid.Col span={5}>
          <NumberField label='Top' name={nameTop} />
        </Grid.Col>
        <Grid.Col offset={1} span={5}>
          <NumberField label='Right' name={nameRight} />
        </Grid.Col>
        <Grid.Col offset={1} span={5}>
          <NumberField label='Bottom' name={nameBottom} />
        </Grid.Col>
        <Grid.Col offset={1} span={5}>
          <NumberField label='Left' name={nameLeft} />
        </Grid.Col>
      </Grid.Row>
    </>
  );
}