import * as React from "react";
import { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

import PredefinedTemplateService from "../../Services/PredefinedTemplateService";
import TemplateService from "../../Services/TemplateService";
import { Breadcrumbs, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Container, Link, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import SideBar from "./SideBar";
import AnimatedBody from "../../Wrappers/AnimatedBody";
import TopBar from "./TopBar";
import Swal from "sweetalert2";

import "../../Style/ScrollBar.css";
import { designerCheckCharts } from "../../Shared/common-utils";

import Handlebars from "handlebars";

function TemplatePredefinedTemplates(props) {

  const predefinedTemplateService = new PredefinedTemplateService();
  const templateService = new TemplateService();

  const customerId = props.match.params.customerId;
  const customerName = props.match.params.customerId;

  const [data, setData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    predefinedTemplateService.findAllActive().then(async ({ data }) => {
      let promises = [];
      data.forEach(async (predefinedTemplate) => {
        const promise = previewThumbsnail(predefinedTemplate).then((data) => {
          predefinedTemplate.thumbsnail = data;
        });
        promises.push(promise);
      });

      // return the promises to the designer
      if (promises && promises.length) {
        await Promise.all(promises);
        setData(data);
      }
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  function copyPredefinedTemplate(predefinedTemplate) {
    Swal.fire({
      title: `Do you want to create the template based on:<br/><span style="color: red">${predefinedTemplate.name}</span>?`,
      showCancelButton: true,
      confirmButtonText: 'Create Template',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const request = {
          templateId: predefinedTemplate.id,
          customerId
        }
        await templateService.saveAsCopy(request).then(({ data }) => {
          props.history.push({
            pathname: "/Customer/" + customerId + "/Template/" + data,
          });
        }).catch(() => {
          Swal.showValidationMessage(`Failed to update the template.`);
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });

  }

  function previewTemplate(selectedPredefinedTemplate) {
    if (!selectedPredefinedTemplate.html) {
      Swal.fire({
        icon: "error",
        title: "There is no Preview configured",
        showConfirmButton: true
      });
      return;
    }

    const template = Handlebars.compile(selectedPredefinedTemplate.html);
    const templateMerged = template(JSON.parse(selectedPredefinedTemplate.testData));
    designerCheckCharts(selectedPredefinedTemplate.id, templateMerged).then((data) => {
      Swal.fire({
        title: 'Preview',
        html: data,
        width: '50vw',
        showConfirmButton: false
      });
    });

  }

  async function previewThumbsnail(predefinedTemplate) {
    if (!predefinedTemplate.html) {
      return;
    }
    const template = Handlebars.compile(predefinedTemplate.html);
    const templateMerged = template(JSON.parse(predefinedTemplate.testData));
    const templateMergedWithPreviewCharts = await designerCheckCharts(predefinedTemplate.id, templateMerged);
    return templateMergedWithPreviewCharts;
  }

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <SideBar
        sideBarExpanded={props.sideBarExpanded}
        setSideBarExpanded={props.setSideBarExpanded}
      />
      <div id="main">
        <div className="container-fluid custom-container">

          <AnimatedBody
            sideBarExpanded={props.sideBarExpanded}
            setSideBarExpanded={props.setSideBarExpanded}
            className="container-fluid custom-container"
          >
            <TopBar title={"Customer"} customer={customerName} customerId={customerId} />

            <Row className="pt-4">
              <Breadcrumbs className="pb-3" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="#"
                  onClick={() => props.history.push({
                    pathname: "/Customer/" + customerId,
                    preSelectedTab: "2"
                  })}>Templates</Link>
                <Typography color="text.primary">Create new Template</Typography>
              </Breadcrumbs>
            </Row>

            <Row className="pe-4">
              {data && data.length > 0 ?
                data.map((predefinedTemplate, index) => (
                  <Col key={predefinedTemplate.id} lg={4} className="pb-4">
                    <Card>
                      <CardHeader
                        title={predefinedTemplate.name}
                        className="text-truncate py-2 bg-light" />
                      <CardContent>
                        <Container className="p-0">
                          <Row className="p-0">
                            <Col style={{ width: '50%' }}>
                              <div className="scrollbar-small-design" style={{ height: 200 }}>
                                <Typography variant="body2" color="text.secondary" style={{ whiteSpace: "pre-wrap" }}>
                                  {predefinedTemplate.description}
                                </Typography>
                              </div>

                            </Col>
                            <Col style={{ minWidth: 200, maxWidth: 200 }}>
                              <div style={{ height: '100%', overflow: 'hidden', position: 'relative' }}>
                                <div style={{ position: 'absolute', width: '100%', zoom: 0.3, pointerEvents: 'none' }}
                                  dangerouslySetInnerHTML={{ __html: predefinedTemplate.thumbsnail }} />
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </CardContent>
                      <CardActions>
                        <Button size="large" fullWidth className="btn-submit"
                          onClick={() => copyPredefinedTemplate(predefinedTemplate)}>
                          Use this
                        </Button>
                        <Button size="large" fullWidth className="btn-submit ms-1"
                          onClick={() => previewTemplate(predefinedTemplate)}>
                          Preview
                        </Button>
                      </CardActions>
                    </Card>
                  </Col>
                ))
                :
                <Col>
                  <div>
                    {(data && data.length === 0) ? "No predefined templates found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                  </div>
                </Col>
              }
            </Row>

          </AnimatedBody>
        </div>
      </div >
    </div >
  );
}

export default withRouter(TemplatePredefinedTemplates);
