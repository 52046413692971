import * as React from "react";
import { withRouter } from "react-router-dom";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAlphaAsc, faSortAlphaDesc } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";
import TopBar from "./TopBar";
import { useState, useEffect } from 'react';
import authService from '../../Services/authServices'
import AddApiService from '../../Services/addApiService';
import LaunchIcon from '@mui/icons-material/Launch';



import Date from "./Date";
import AnimatedBody from "../../Wrappers/AnimatedBody";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import TemplateService from "../../Services/TemplateService";
import Swal from "sweetalert2";
import { now } from "moment";
import UserService from "../../Services/UserService";
import { designerCheckCharts } from "../../Shared/common-utils";
import TemplateDataExtractorService from "../../Services/TemplateDataExtractorService";

function EmailSchedule(props) {
  // for api templates/Eamilers
  const [templates, setTemplates] = useState([]);
  //for api stats
  const [stats, setStats] = useState(null);
  const [statsFor, setStatsFor] = useState('month');

  const [sort, setSort] = useState("")

  //creating the auth service
  const myServiceInstance = new authService();
  const customer = myServiceInstance.getCurrentCustomer();
  if (!customer) {
    props.history.push("/Login")
    // location.reload();
  }
  //creating add api service
  const addApiService = new AddApiService();
  const templateService = new TemplateService();
  const templateDataExtractorService = new TemplateDataExtractorService();
  const userService = new UserService();
  
  const [checkApiKey, setCheckApiKey] = useState(null);

  useEffect(() => {

    //getting the emailer stats for header
    addApiService.getEmailTemplates(customer.customerId, true).then(response => {
      sortTemplatesWithParams(response);


    }).catch((error) => {
      console.log('ERROR: ', error);
    });

  }, []);

  useEffect(() => {

    //getting the emailer stats for header
    setStats(null);
    addApiService.emailStatValue(statsFor).then(response => {
      setStats(response);

    }).catch((error) => {
      console.log('ERROR: ', error);
    });


  }, [statsFor]);

  function sortTemplates() {
    if (sort == "DESC") {
      templates.sort((a, b) => a?.name?.localeCompare(b?.name))
      setTemplates(templates)
      setSort("ASC")
    } else if (sort == "ASC") {
      templates.sort((a, b) => b?.name?.localeCompare(a?.name))
      setTemplates(templates)
      setSort("DESC")
    } else {
      templates.sort((a, b) => a?.name?.localeCompare(b?.name))
      setTemplates(templates)
      setSort("ASC")
    }

    setTemplates(templates);
  }

  function sortTemplatesWithParams(templates) {
    if (sort == "DESC") {
      templates.sort((a, b) => a?.name?.localeCompare(b?.name))
      setTemplates(templates)
      setSort("ASC")
    } else if (sort == "ASC") {
      templates.sort((a, b) => b?.name?.localeCompare(a?.name))
      setTemplates(templates)
      setSort("DESC")
    } else {
      templates.sort((a, b) => a?.name?.localeCompare(b?.name))
      setTemplates(templates)
      setSort("ASC")
    }

    setTemplates(templates);
  }

  function selectTemplate(selectedTemplate) {
    userService.checkApiKey(customer.id).then(data => {
      if (data) {
        props.history.push({ pathname: "/Email", template: selectedTemplate, customerId: customer.customerId });
      } else if (selectedTemplate.useNewTemplateEditor) {
        templateDataExtractorService.findAll(selectedTemplate.id).then(({data}) => {
          let onlyMock = false;
          if(data) {
            onlyMock = !data.some((extractor) => extractor.remotePath && !extractor.remotePath.includes('/mock/v1/json-rest-api'));
          }
          
          if (onlyMock) {
            props.history.push({ pathname: "/Email", template: selectedTemplate, customerId: customer.customerId });
          } else {
            showNoAPIKeyMessage();
          }
        });
      } else {
        showNoAPIKeyMessage();
      }
    });
  }
  
  function showNoAPIKeyMessage() {
    Swal.fire({
      icon: "warning",
      title: 'No API Key Configured',
      text: 'Please configure the Client API Key to continue',
      showConfirmButton: true,
      allowOutsideClick: false,
      preConfirm: () => {
        setCheckApiKey(now());
      }
    });
  }

  function previewTemplate(selectedTemplate) {
    Swal.fire({
      icon: "info",
      title: "Loading Preview...",
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        templateService.findById(customer.customerId, selectedTemplate.id).then(async response => {
          if (response.status !== 200 || !response.data.templateVersionHtml) {
            const errorMessage = !response.data.templateVersionHtml ? "There is no Preview configured" : "Not able to Preview the Template"
            Swal.fire({
              icon: "error",
              title: errorMessage,
              showConfirmButton: true
            });
          } else {
            const templateMergedWithPreviewCharts = await designerCheckCharts(selectedTemplate.id, response.data.templateVersionHtml);
            const preview = window.open("", "_blank");
            preview.document.write(templateMergedWithPreviewCharts);
            preview.document.close();

            Swal.close();
          }
        });
      }
    });
  }

  // { stats && templates }
  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <SideBar
        sideBarExpanded={props.sideBarExpanded}
        setSideBarExpanded={props.setSideBarExpanded}
        checkApiKey={checkApiKey}
      />
      <div id="main">
        <div className="container-fluid custom-container">

          <AnimatedBody
            sideBarExpanded={props.sideBarExpanded}
            setSideBarExpanded={props.setSideBarExpanded}
            className="container-fluid custom-container"
          >
            <TopBar title={"Emailer Scheduler"} />
            <div className="row row-margin row-left">
              <Date onStatChange={(e) => setStatsFor(e)} />
            </div>
            <div className="row row-margin row-left">
              <div className="col-md-6 col-12">
                <div className="d-flex">
                  <div className="dtl-box">
                    {stats ?
                      <h2>{stats?.requests}</h2>
                      : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                    <p className="t-status-color">Sent</p>
                  </div>
                  <div className="dtl-box">
                    {stats ?
                      <h2>{stats?.unique_opens}</h2>
                      : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                    <span>  </span>
                    <span style={{ color: "#00B247" }}>
                      {stats ? (Math.round(stats.unique_opens_percent * 100) / 100).toFixed(2) : "0.00"}  %
                    </span>
                    <p className="t-status-color">Unique opens</p>
                  </div>
                  <div className="dtl-box">
                    {stats ?
                      <h2>{stats?.unique_clicks}</h2>
                      : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                    <span>  </span>
                    <span className="main-color">
                      {stats ? (Math.round(stats.unique_clicks_percent * 100) / 100).toFixed(2) : "0.00"}  %
                    </span>
                    <p className="t-status-color">Unique clicks</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <table
                  className="table-status"
                  style={{ overflow: "scroll !important" }}
                >
                  <tbody>
                    <tr>
                      <td className="t-status">
                        {stats ?
                          <h2>{(Math.round(stats?.delivered_percent * 100) / 100).toFixed(2)} %</h2>
                          : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                      </td>
                      <td className="t-status">
                        {stats ?
                          <h2>{(Math.round(stats?.bounces_percent * 100) / 100).toFixed(2)}%</h2>
                          : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                      </td>
                      <td className="t-status">
                        {stats ?
                          <h2>{(Math.round(stats?.spam_reports_percent * 100) / 100).toFixed(2)}</h2>
                          : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                      </td>
                    </tr>
                    <tr>
                      <td className="t-status-color">Delivered</td>
                      <td className="t-status-color">Bounces</td>
                      <td className="t-status-color">Spam Reports</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row row-margin row-left">
              <div className="col-12">
                <table style={{ width: "100%", overflow: "scroll !important" }}>
                  <thead>
                    <tr className="line">
                      <th className="line-data">
                        Dynamic Template
                        {templates && templates.length > 0 && (sort == "ASC" || sort == "DESC") ?
                          <FontAwesomeIcon
                            icon={sort == "ASC" ? faSortAlphaAsc : faSortAlphaDesc}
                            onClick={() => sortTemplates()}
                            style={{ marginLeft: "10px" }}
                          />
                          : <span />
                        }
                      </th>
                      <th className="line-data">Email Subject</th>
                      <th className="line-data">Version name</th>
                      <th className="line-data">Version Id</th>
                      <th className="line-data" width={100} style={{ textAlign: "center" }}>Preview</th>
                    </tr>
                  </thead>
                  <tbody>
                    {templates ?
                      templates.map((template) => (
                        <tr key={template.id}
                          className="line row-selectable"
                        >
                          <td className="line-data" onClick={() => selectTemplate(template)}>
                            <img src={template.version?.thumbnail_url} className="data-img" />
                            <span className="new-line">{template.name}</span>
                          </td>
                          <td className="line-data" onClick={() => selectTemplate(template)}>{template.subjectExpression}</td>
                          <td className="line-data" onClick={() => selectTemplate(template)}>{template.version.name}</td>
                          <td className="line-data" onClick={() => selectTemplate(template)}>{template.version.id}</td>
                          <td className="line-data column-single-icon" onClick={() => previewTemplate(template)} align="center">
                            <LaunchIcon />
                          </td>
                        </tr>))
                      : (templates && templates.length === 0) ? "No templates found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}

                  </tbody>
                </table>
              </div>
            </div>
          </AnimatedBody>
        </div>
      </div>
    </div>
  );
}

export default withRouter(EmailSchedule);
