import { Stack, TextStyle } from "easy-email-editor";

import { Grid, Space } from '@arco-design/web-react';
import { ColorPickerField, FontFamily, InputWithUnitField, NumberField, SelectField } from "easy-email-extensions";
import { MultiPixel } from "../Fields/MultiPixel";
import { alignmentOptions, borderStyleOptions, fontStyleoptions, fontWeightoptions, vAlignmentOptions } from "../CustomBlocks/utils/Utils";

export function TableOptions(props) {

  const { settings, showEvenOdd, showWidth, showHeight, showColspan } = props;

  return (
    <Stack vertical spacing='tight'>

      {showEvenOdd &&
        <>
          <Stack vertical spacing='extraTight'>
            <TextStyle variation='strong'>Background</TextStyle>
            <Grid.Row>
              <Grid.Col span={11}>
                <ColorPickerField
                  label='Even'
                  name={`${settings}.backgroundColorEven`}
                />
              </Grid.Col>
              <Grid.Col offset={1} span={11}>
                <ColorPickerField
                  label='Odd'
                  name={`${settings}.backgroundColorOdd`}
                />
              </Grid.Col>
            </Grid.Row>
          </Stack>

          <Stack vertical spacing='extraTight'>
            <hr style={{ width: '96%' }} />
            <TextStyle variation='strong'>Font Color</TextStyle>
            <Grid.Row>
              <Grid.Col span={11}>
                <ColorPickerField
                  label='Even'
                  name={`${settings}.fontColorEven`}
                />
              </Grid.Col>
              <Grid.Col offset={1} span={11}>
                <ColorPickerField
                  label='Odd'
                  name={`${settings}.fontColorOdd`}
                />
              </Grid.Col>
            </Grid.Row>
          </Stack>
        </>
      }

      <Stack vertical spacing='extraTight'>
        {!showEvenOdd &&
          <Grid.Row>
            <Grid.Col span={23}>
              <ColorPickerField
                label='Background Color'
                name={`${settings}.backgroundColor`}
              />
            </Grid.Col>
          </Grid.Row>
        }

        <Grid.Row>
          {showWidth &&
            <Grid.Col span={7}>
              <NumberField
                label='Width(%)'
                name={`${settings}.width`}
              />
            </Grid.Col>
          }
          {showHeight &&
            <Grid.Col offset={showWidth ? 1 : 0} span={showWidth ? 7 : 11}>
              <NumberField
                label='Height (px)'
                name={`${settings}.height`}
              />
            </Grid.Col>
          }
          {showColspan &&
            <Grid.Col offset={showWidth || showHeight ? 1 : 0} span={showWidth || showHeight ? 7 : 11}>
              <NumberField label='Colspan'
                name={`${settings}.colspan`}
              />
            </Grid.Col>
          }
        </Grid.Row>

        <Grid.Row>
          {false &&
            <Grid.Col span={11}>
              <NumberField
                label='Rowspan'
                name={`${settings}.rowspan`}
              />
            </Grid.Col>
          }
        </Grid.Row>
      </Stack>

      <Stack vertical spacing='extraTight'>
        <hr style={{ width: '96%' }} />
        <MultiPixel
          label="Padding (px)"
          title="Remove all padding"
          nameTop={`${settings}.paddingTop`}
          nameRight={`${settings}.paddingRight`}
          nameBottom={`${settings}.paddingBottom`}
          nameLeft={`${settings}.paddingLeft`}
        />
      </Stack>

      <Stack vertical spacing='extraTight'>
        <hr style={{ width: '96%' }} />
        <TextStyle variation='strong'>Border</TextStyle>
        <Grid.Row>
          <Grid.Col span={11}>
            <SelectField
              label='Style'
              name={`${settings}.borderStyle`}
              options={borderStyleOptions}
              allowClear
            />
          </Grid.Col>
          <Grid.Col offset={1} span={11}>
            <ColorPickerField
              label='Color'
              name={`${settings}.borderColor`}
            />
          </Grid.Col>
        </Grid.Row>

        <MultiPixel
          label="Border Size (px)"
          title="Remove all border"
          nameTop={`${settings}.borderTopSize`}
          nameRight={`${settings}.borderRightSize`}
          nameBottom={`${settings}.borderBottomSize`}
          nameLeft={`${settings}.borderLeftSize`}
        />

        <Stack vertical spacing='extraTight'>
          <hr style={{ width: '96%' }} />

          <Space align='center'>
            <TextStyle variation='strong'>Alignment</TextStyle>
          </Space>

          <Grid.Row>
            <Grid.Col span={11}>
              <SelectField
                label='Horizontal'
                name={`${settings}.align`}
                options={alignmentOptions}
              />
            </Grid.Col>

            <Grid.Col offset={1} span={11}>
              <SelectField
                label='Vertical'
                name={`${settings}.valign`}
                options={vAlignmentOptions}
              />
            </Grid.Col>
          </Grid.Row>
        </Stack>

        <Stack vertical spacing='extraTight'>
          <hr style={{ width: '96%' }} />

          <Space align='center'>
            <TextStyle variation='strong'>Font</TextStyle>
          </Space>
          <Grid.Row>
            <Grid.Col span={11}>
              <FontFamily name={`${settings}.fontFamily`} />
            </Grid.Col>
            <Grid.Col offset={1} span={11}>
              <InputWithUnitField
                label='Font size(px)'
                name={`${settings}.fontSize`}
              />
            </Grid.Col>
          </Grid.Row>
          {!showEvenOdd &&
            <Grid.Row>
              <Grid.Col span={23}>
                <ColorPickerField
                  label='Color'
                  name={`${settings}.color`}
                />
              </Grid.Col>
            </Grid.Row>
          }
          <Grid.Row>
            <Grid.Col span={11}>
              <SelectField
                label='Font style'
                name={`${settings}.fontStyle`}
                options={fontStyleoptions}
              />
            </Grid.Col>
            <Grid.Col offset={1} span={11}>
              <SelectField
                label='Font weight'
                name={`${settings}.fontWeight`}
                options={fontWeightoptions}
              />
            </Grid.Col>
          </Grid.Row>
        </Stack>
      </Stack>
    </Stack>
  );
}