import React from 'react';
// import logo from '../images/logo.png';
import logo from '../images/Boost-Logo-01.png';
import bg from '../images/Boost-BG-emailer.jpg';
import '../Style/Login.css';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import authService from '../Services/authServices';

import { IconButton, Input, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const MySwal = withReactContent(Swal);

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.authService = new authService();
    this.state = {
      isLogin: true,
      isPassword: false,
      username: '',
      password: '',
      otp: '',
      setPassword: '',
      confirmPassword: '',
      errorMessage: '',
      showNewPassword: false,
      showConfirmPassword: false
      // user: ''
    }
    // Redirect
    if (this.authService.getToken('token')) {
      props.history.push('/Login');
    }

  }

  handleNewPasswordVisibility = (e) => {
    this.setState({ showNewPassword: !this.state.showNewPassword })
  }

  handleConfirmPasswordVisibility = (e) => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
  }

  setConfirmPassword = (e) => {
    this.setState({ confirmPassword: e.target.value })
  }

  checkPasswordValidation = (e) => {
    let pass = e.target.value

    console.log('newPass', pass);
    //at least one number
    var regInt = new RegExp('(?=.*[0-9])')
    var testInt = regInt.test(pass)

    //at least one special char
    var specialChar = new RegExp('(?=.*[!@#$%^&*])')
    var testSpecial = specialChar.test(pass);

    //at least on small letter
    var sletter = new RegExp('(?=.*[a-z])')
    var sletterTest = sletter.test(pass);

    //at least on capital letter
    var cletter = new RegExp('(?=.*[A-Z])')
    var cletterTest = cletter.test(pass);

    var errorMessage = ''
    if (pass.length <= 7) {
      errorMessage = "Password length should be minimum 8 letters.";
    }
    else if (!testInt) {
      errorMessage = "Password should contain at least one digit.";
    }
    else if (!testSpecial) {
      errorMessage = "Password should contain at least one special letter.";
    }
    else if (!sletterTest) {
      errorMessage = "Password should contain at least one small letter.";
    }
    else if (!cletterTest) {
      errorMessage = "Password should contain at least on capital letter.";
    }
    else {
      errorMessage = ''
    }

    this.setState({
      setPassword: e.target.value,
      errorMessage: errorMessage
    })
  }

  // handling Input
  onHandlerInput = (event) => {

    // Username 
    if (event.target.name === 'username') {
      if (event.target.value === '') {
        this.setState({
          usernameStatus: 'Username should not be empty.'
        });
      } else {
        this.setState({
          usernameStatus: ''
        });
      }
    }

    // Password
    if (event.target.name === 'password') {
      if (event.target.value === '') {
        this.setState({
          passwordStatus: 'Password should not be empty.'
        });
      } else {
        this.setState({
          passwordStatus: ''
        });
      }
    }

    // Set Password
    if (event.target.name === 'setPassword') {
      if (event.target.value === '') {
        this.setState({
          passwordStatus: 'Password should not be empty.'
        });
      } else {
        this.setState({
          passwordStatus: ''
        });
      }
    }



    // // Confirm Password
    // if (event.target.name === 'confirmPassword') {
    //   if (event.target.value === '') {
    //     this.setState({
    //       matchStatus: 'Password'
    //     });
    //   } else {
    //     this.setState({
    //       passwordStatus: ''
    //     });
    //   }
    // }

    // OTP
    if (event.target.name === 'otp') {
      if (event.target.value === '') {
        this.setState({
          otpStatus: 'OTP should not be empty.'
        });
      } else {
        this.setState({
          otpStatus: ''
        });
      }
    }

    this.setState({
      [event.target.name]: event.target.value
    })
  }


  // Register towards register page
  onRegister = () => {
    // username & password blank
    this.setState({
      isLogin: false,
      username: '',
      usernameStatus: '',
      passwordStatus: '',
      password: ''
    });
  }

  // Login towards login page
  onLogin = () => {
    this.setState({
      isLogin: true,
      username: '',
      usernameStatus: '',
      otpStatus: '',
      otp: ''
    });
  }

  // Validate customer with OTP
  validate = () => {
    this.authService.validateWithOtp({
      'userName': this.state.username,
      'OTP': this.state.otp
    }).then((res) => {
      console.log('Response: ', res);
      if (!res.result) {
        if (res.message === 'Please enter a user name') {
          this.setState({
            usernameStatus: res.message
          });
        } else if (res.message === 'Please enter valid OTP (minimum 8 characters)') {
          this.setState({
            otpStatus: res.message
          });
        } else if (res.message === 'Invalid OTP.') {
          this.setState({
            otpStatus: res.message
          });
        } else {
          // Swal
          MySwal.fire({
            title: res.message,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: (res.message === 'Customer already registered. Please contact admin for more details.') ? 'Back to Login' : 'OK'
          }).then((result) => {
            if (res.message === 'Customer already registered. Please contact admin for more details.') {
              this.setState({
                isLogin: true,
                otp: '',
                username: ''
              });
            }
            if (result.value) {
              this.setState({
                // username: '',
                // otp: ''
              });
            }
          });
        }
      } else {
        // Add new Password
        this.setState({
          isPassword: true,
          userName: res.result.userName,
          pin: res.result.pin
        });
      }
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  // Update new Password
  register() {
    this.authService.setPassword({
      'userName': this.state.username,
      'pin': this.state.pin,
      'password': this.state.setPassword
    }).then((res) => {
      if (res.message === 'Please enter valid Password (minimum 8 characters)') {
        this.setState({
          passwordStatus: res.message
        });
      } else if (res.status === 'SUCCESS') {
        // Swal
        MySwal.fire({
          title: res.message,
          text: "Please login to continue.",
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Go to Login',
          allowOutsideClick: false
        }).then((result) => {
          if (result.value) {
            this.setState({
              isPassword: false,
              isLogin: true,
              setPassword: '',
              confirmPassword: '',
              username: '',
              password: '',
              otp: ''
            });
          }
        });
      } else {
        MySwal.fire({
          title: res.message,
          icon: 'error'
        });
      }
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  //login functionality of user
  login() {
    if (this.state.username === '') {
      this.setState({
        usernameStatus: 'Username should not be empty.'
      });
    }

    if (this.state.password === '') {
      this.setState({
        passwordStatus: 'Password should not be empty.'
      });
    }

    if (this.state.username !== '' && this.state.password !== '') {
      this.authService.login({
        'userName': this.state.username,
        'password': this.state.password
      }).then((res) => {

        if (res?.status === 'SUCCESS') {

          if (res.result.mfa === false) {
            // Set Token
            this.authService.setToken(res.token);
            // Set Customer
            if (res.result.customerId === null) {
              this.authService.setCurrentCustomer({
                id: res.result.id,
                userName: res.result.userName,
                mfa: res.result.mfa,
                phoneNumber: res.result.phoneNumber,
                SUPERADMIN: true
              });
              // Route to Dashboard
              //TODO need to add here the conditions for the MFA/direct login
              this.props.history.push("/Customers")
            } else {


              this.authService.setCurrentCustomer({
                id: res.result.id,
                userName: res.result.userName,
                customerId: res.result.customerId,
                customerUrl: res.result.customer,
                attachmentAllowed: res.result.attachmentAllowed,
                mfa: res.result.mfa,
                phoneNumber: res.result.phoneNumber,
                admin:res.result.admin,
                newDesignerAllowed: res.result.newDesignerAllowed,
                editDataextractorsAllowed: res.result.editDataextractorsAllowed
              });
              // Route to Dashboard
              //TODO need to add here the conditions for the MFA/direct login
              this.props.history.push("/EmailSchedule")
            }
          } else {
            this.props.history.push({ pathname: "/authenticate", auth: res })
          }

        } else if (res?.status === 'EXCEPTION') {

          MySwal.fire({
            title: res?.heading === null ? 'Account Locked' : res.heading,
            text: res?.message,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: res?.heading === null ? 'OK' : 'Activate'
          }).then((result) => {
            if (result.value) {

              if (res?.heading !== null) {
                this.setState({
                  isLogin: false,
                  // username: '',
                  // password: ''
                });
              } else {
                this.setState({
                  isLogin: true,
                  // username: '',
                  // password: ''
                });
              }
            }
          });
          return
        } else {
          MySwal.fire({
            title: 'Username or Password is incorrect!',
            text: "Please login again to continue.",
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.value) {
              this.setState({
                isLogin: true,
                // username: '',
                // password: ''
              });
            }
          });
        }

      }).catch((error) => {
        console.log('ERROR: ', error);
      });
    }
  }

  handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      this.login();
    }
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row col-rvrse">
          <div className="col-md-5 col-12">
            <div className="container screen">
              <div className="row">
                <div className="col-12">
                  <div className="login-box">
                    <img className="logo" src={logo} alt="login" />
                    {this.state.isPassword ? (
                      <div>
                        <label className="label-float text-color"> New password </label>
                        {/* <input type="password" className="form-control" name="setPassword" onChange={(e) => this.onHandlerInput(e)} value={this.state.setPassword}
                          placeholder="password" /> */}
                        <Input
                          type={this.state.showNewPassword ? "test" : "password"}
                          className="form-control"
                          id="newPassword"
                          placeholder="Enter new password"
                          value={this.state.setPassword}
                          onChange={(e) => this.checkPasswordValidation(e)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e) => this.handleNewPasswordVisibility()}

                                edge="end"
                              >
                                {!this.state.showNewPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <span className="text-danger w-100 text-left">{this.state?.errorMessage}</span>
                        <label className="label-float text-color"> Confirm Password </label>
                        {/* <input type="password" className="form-control" name="confirmPassword" onChange={(e) => this.onHandlerInput(e)} value={this.state.confirmPassword}
                          placeholder="confirm password" /> */}

                        <Input
                          type={this.state.showConfirmPassword ? "test" : "password"}
                          className="form-control"
                          id="confirmPassword"
                          placeholder="Confirm new password"
                          value={this.state.confirmPassword}
                          onChange={(e) => this.setConfirmPassword(e)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e) => this.handleConfirmPasswordVisibility()}

                                edge="end"
                              >
                                {!this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {this.state.confirmPassword && !(this.state.setPassword === this.state.confirmPassword) ? (
                          <span className="text-danger w-100 text-left">Password do not match.</span>
                        ) : (
                          ''
                        )}

                      </div>

                    ) : (
                      <div>
                        <div>
                          <label className="label-float text-color"> Username </label>
                          <input type="text" className="form-control" name="username" onChange={(e) => this.onHandlerInput(e)} value={this.state.username}
                            placeholder="username@domain.com" />
                          <span className="text-danger w-100 text-left">{this.state?.usernameStatus}</span>
                        </div>
                        {this.state.isLogin ? (
                          <div>
                            <label className="label-float text-color margin-top-10px"> Password </label>
                            <input type="password" className="form-control" name="password" onChange={(e) => this.onHandlerInput(e)} value={this.state.password}
                              placeholder="password" onKeyDown={this.handleKeypress} />
                            <span className="text-danger w-100 text-left">{this.state?.passwordStatus}</span>
                          </div>
                        ) : (
                          <div>
                            <label className="label-float text-color margin-top-10px"> One-Time Password </label>
                            <input type="password" className="form-control" name="otp" onChange={(e) => this.onHandlerInput(e)} value={this.state.otp}
                              placeholder="one-time password" />
                            <span className="text-danger w-100 text-left">{this.state?.otpStatus}</span>
                          </div>
                        )}
                      </div>

                    )}

                    {this.state.isLogin ? (
                      <div>
                        <div>
                          <a className="main-color mt-4" type="submit" style={{ textDecoration: 'none' }}
                            onClick={() => this.onRegister()}>
                            Activate New Account
                          </a>
                        </div>
                        <div>
                          <button className="btn btn-submit gap" type="submit"
                            onClick={() => this.login()}>
                            Login
                          </button>
                        </div>
                      </div>
                    ) :
                      this.state.isPassword ?
                        (
                          <button className="btn btn-primary btn-rounded my-3 waves-effect z-depth-0 pull-right" type="submit"
                            disabled={!this.state.setPassword || !this.state.confirmPassword || !(this.state.setPassword === this.state.confirmPassword)}
                            onClick={() => this.register()}>
                            Submit
                          </button>

                        ) : (
                          <div>
                            <button className="btn btn-submit pull-right" type="submit"
                              onClick={() => this.validate()}
                            // disabled={!this.state.username || !this.state.otp}
                            >
                              Activate
                            </button>
                            <a className="mt-4 pull-left mr-4 main-color" type="submit"
                              onClick={() => this.onLogin()}
                            // disabled={!this.state.username || !this.state.otp}
                            >
                              Cancel
                            </a>
                          </div>
                        )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-12 bg">
            <div className="web-name">
              <h1 className="main-color">Emailer Plugin for Apptio</h1>
              <h5 className="sec-color">Consolidate data from multiple Apptio reports and send directly to users via email</h5>
            </div>
            <img className="d-img" src={bg} />
          </div>
        </div>
      </div>

     

    )
  }

}

export default withRouter(Login)