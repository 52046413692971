import axios from 'axios';
import { environment } from '../environment';

class settingService {

  // get api keys https://service-dev.boosttbmapp.com/api/user/{TbmUserID}/apptioprofile
  getApiKey = (userId) => {
    return axios.get(environment.API_URL + 'user/' + userId + '/apptioprofile')
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  // add new api key https://service-dev.boosttbmapp.com/api/user/{TbmUserID}/apptioprofile
  addApiKey = (userId, body) => {
    return axios.put(environment.API_URL + 'user/' + userId + '/apptioprofile', body)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      })
  }

}

export default settingService;