import { AdvancedType, BasicType, BlockManager, components, createCustomBlock } from 'easy-email-core';
import { CustomBlocksType } from '../constants';
import React from 'react';
import { merge } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

const { BlockRenderer } = components;

const TAG_CONDITION = `##CONDITION##`;
const TAG_IF_CONDITION_STATEMENT = {
  START: `{{#if ${TAG_CONDITION} }}`,
  END: `{{/if}}`
}

const TAG_UNLESS_CONDITION_STATEMENT = {
  START: `{{#unless ${TAG_CONDITION} }}`,
  END: `{{/unless}}`
}

export const Conditional = createCustomBlock({
  name: 'Conditional',
  type: CustomBlocksType.CONDITIONAL,
  create(payload) {
    const defaultData = {
      type: CustomBlocksType.CONDITIONAL,
      data: {
        value: {
          source: '',
          content: "true",
          reverse: false
        }
      },
      attributes: {},
      children: [
        BlockManager.getBlockByType(BasicType.RAW).create({
          data: {
            value: {
              content: TAG_IF_CONDITION_STATEMENT.START
            }
          }
        }),
        BlockManager.getBlockByType(AdvancedType.TEXT).create({}),
        BlockManager.getBlockByType(BasicType.RAW).create({
          data: {
            value: {
              content: TAG_IF_CONDITION_STATEMENT.END
            }
          }
        })
      ],
    }
    return merge(defaultData, payload);
  },
  validParentType: [AdvancedType.SECTION, AdvancedType.COLUMN],
  render(params) {
    const component = cloneDeep(params.data);
    console.log('Conditional');

    // Removing brackets {{}} from extractor to make it compatible with conditional statements
    component.data.value.content = component.data.value.content.replace('{{', '').replace('}}', '');

    if (!component.data.value.reverse) {
      const conditionReplaced = TAG_IF_CONDITION_STATEMENT.START.replace(TAG_CONDITION, component.data.value.content);
      component.children[0].data.value.content = conditionReplaced;
      component.children[2].data.value.content = TAG_IF_CONDITION_STATEMENT.END;
    } else {
      const conditionReplaced = TAG_UNLESS_CONDITION_STATEMENT.START.replace(TAG_CONDITION, component.data.value.content);
      component.children[0].data.value.content = conditionReplaced;
      component.children[2].data.value.content = TAG_UNLESS_CONDITION_STATEMENT.END;
    }

    const group = BlockManager.getBlockByType(AdvancedType.COLUMN).create({
      attributes: {
        width: "100%"
      },
      children: component.children
    });

    const instance = (
      <BlockRenderer {...params} key={`conditional-${params.idx}`} idx={params.idx} data={group} keepClassName={true} />
    )
    return instance;
  },
});

/*
<Group key={`conditional-${params.idx}`} css-class={params.mode === 'testing' ? getPreviewClassName(params.idx, component.type) : ''}>
        <Column css-class={params.mode === 'testing' ? getPreviewClassName(`${params.idx}.children.[0].children.[0]`, AdvancedType.COLUMN) : ''}>
        </Column>
      </Group>
          <Raw
            value={{ content: `{{#if ${component.data.value.if}}}` }} hidden />
          <Raw css-class={params.mode === 'testing' ? getPreviewClassName(`${params.idx}.children.[2]`, BasicType.RAW) : ''}
            value={{ content: `{{/if}}` }} hidden />
*/


export { Panel } from './Panel'