import { BlockManager, ImageManager } from 'easy-email-core';
import { BlockAttributeConfigurationManager, } from 'easy-email-extensions';
import { CustomBlocksType } from './constants';
import { CustomTable, Panel as TablePanel } from './CustomTable';
import { BasicTable, Panel as BasicTablePanel } from './BasicTable';
import { StaticTable, Panel as StaticTablePanel } from './StaticTable';
import { BarChart, Panel as BarChartPanel } from './BarChart';
import { LineChart, Panel as LineChartPanel } from './LineChart';
import { RadialGaugeChart, Panel as RadialGaugeChartPanel } from './RadialGaugeChart';
import { PieChart, Panel as PieChartPanel } from './PieChart';
import { Conditional, Panel as ConditionalPanel } from './Conditional';

BlockManager.registerBlocks({
  [CustomBlocksType.CUSTOM_TABLE]: CustomTable,
  [CustomBlocksType.STATIC_TABLE]: StaticTable,
  [CustomBlocksType.BASIC_TABLE]: BasicTable,
  [CustomBlocksType.CONDITIONAL]: Conditional,
  [CustomBlocksType.BAR_CHART]: BarChart,
  [CustomBlocksType.LINE_CHART]: LineChart,
  [CustomBlocksType.RADIAL_GAUGE_CHART]: RadialGaugeChart,
  [CustomBlocksType.PIE_CHART]: PieChart,
});

BlockAttributeConfigurationManager.add({
  [CustomBlocksType.CUSTOM_TABLE]: TablePanel,
  [CustomBlocksType.STATIC_TABLE]: StaticTablePanel,
  [CustomBlocksType.BASIC_TABLE]: BasicTablePanel,
  [CustomBlocksType.CONDITIONAL]: ConditionalPanel,
  [CustomBlocksType.BAR_CHART]: BarChartPanel,
  [CustomBlocksType.LINE_CHART]: LineChartPanel,
  [CustomBlocksType.RADIAL_GAUGE_CHART]: RadialGaugeChartPanel,
  [CustomBlocksType.PIE_CHART]: PieChartPanel,
});

const defaultImagesMap = {
  IMAGE_59: 'https://easy-email-full-demo.vercel.app/images/06ca521d-9728-4de6-a709-1b75a828bfc3-2a9b1224-3d71-43b8-b52f-e7cdcdc9107b.png',
  AttributePanel_01: 'https://easy-email-full-demo.vercel.app/images/e22f78f2-aa76-408d-ba94-c95c7abe1908-image.png',
  AttributePanel_02: 'https://easy-email-full-demo.vercel.app/images/3e952a6e-2506-470e-b395-3e0d995157c5.png',
  AttributePanel_03: 'https://easy-email-full-demo.vercel.app/images/Fi_vI4vyLhTM-Tp6ivq4dR_ieGHk.png',
};

ImageManager.add(defaultImagesMap);