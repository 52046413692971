import * as React from "react";
import { withRouter } from "react-router-dom";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { useState, useEffect } from 'react';
import authService from '../../Services/authServices'
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAlphaAsc, faSortAlphaDesc, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import { FormControlLabel, Switch } from "@mui/material";
import PredefinedTemplateService from "../../Services/PredefinedTemplateService";
import SideBar from "./SideBar";
import AnimatedBody from "../../Wrappers/AnimatedBody";
import SearchBar from "material-ui-search-bar";
import Swal from "sweetalert2";

function PredefinedTemplates(props) {

  const myServiceInstance = new authService();
  const predefinedTemplateService = new PredefinedTemplateService();

  const [sort, setSort] = useState("")
  const [data, setData] = useState(null);

  const loggedInCustomer = myServiceInstance.getCurrentCustomer();
  if (!loggedInCustomer?.SUPERADMIN) {
    props.history.push("/Login")
  }

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    predefinedTemplateService.findAll().then(({ data }) => {
      sortDataWithParams(data);
    }).catch((error) => {
      console.log('ERROR: ', error);
    });
  }

  function sortData() {
    if (sort == "DESC") {
      data.sort((a, b) => a?.name?.localeCompare(b?.name));
      setData(data);
      setSort("ASC");
    } else if (sort == "ASC") {
      data.sort((a, b) => b?.name?.localeCompare(a?.name));
      setData(data);
      setSort("DESC");
    } else {
      data.sort((a, b) => a?.name?.localeCompare(b?.name));
      setData(data);
      setSort("ASC");
    }

    setData(data);
  }

  function sortDataWithParams(predefinedTemplates) {
    if (sort == "DESC") {
      predefinedTemplates.sort((a, b) => a?.name?.localeCompare(b?.name))
      setData(predefinedTemplates)
      setSort("ASC")
    } else if (sort == "ASC") {
      predefinedTemplates.sort((a, b) => b?.name?.localeCompare(a?.name))
      setData(predefinedTemplates)
      setSort("DESC")
    } else {
      predefinedTemplates.sort((a, b) => a?.name?.localeCompare(b?.name))
      setData(predefinedTemplates)
      setSort("ASC")
    }
    setData(predefinedTemplates);
  }

  function requestSearch(searchedVal) {
    if (searchedVal) {
      let filteredData = data.filter((predefinedTemplate) => {
        return predefinedTemplate.name?.toLowerCase().includes(searchedVal.toLowerCase())
          || predefinedTemplate.subject?.toLowerCase().includes(searchedVal.toLowerCase());
      });

      setData(filteredData)
    } else {
      setData(data);
    }
  }

  function cancelSearch() {
    setData(data);
  }

  function deletePredefinedTemplate(template) {
    Swal.fire({
      title: `Do you really want to delete the predefined template: <span style="color: red">${template.name}</span>?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#ed7d31',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        predefinedTemplateService.delete(template.id)
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Predefined Template Deleted',
              showConfirmButton: false,
              timer: 1500
            });

            getData();
          })
          .catch(() => {
            Swal.showValidationMessage(`Failed to delete the predefined template.`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  return (
    <div className="custom-box" style={{ overflow: "hidden" }}>
      <SideBar
        sideBarExpanded={props.sideBarExpanded}
        setSideBarExpanded={props.setSideBarExpanded}
      />
      <div id="main">
        <div className="container-fluid custom-container">

          <AnimatedBody
            sideBarExpanded={props.sideBarExpanded}
            setSideBarExpanded={props.setSideBarExpanded}
            className="container-fluid custom-container"
          >
            <div className="row row-margin row-left">
              <div className="col-12">
                <SearchBar className="MuiPaper-outlined"
                  style={{ boxShadow: 'none' }}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                />
                <table style={{ width: "100%", overflow: "scroll !important" }}>
                  <thead>
                    <tr className="line">
                      <th className="line-data">Name
                        {data && data.length > 0 && (sort == "ASC" || sort == "DESC") ?
                          <FontAwesomeIcon
                            icon={sort == "ASC" ? faSortAlphaAsc : faSortAlphaDesc}
                            onClick={() => sortData()}
                            style={{ marginLeft: "10px" }}
                          />
                          : <span />
                        }
                      </th>
                      <th className="line-data">Subject</th>
                      <th className="line-data">Id</th>
                      <th className="line-data">Published</th>
                      <th className="line-data" width={200} style={{ textAlign: "right" }} >
                        <button type="button" className="btn btn-submit w-100 px-1"
                          onClick={() => props.history.push({
                            pathname: "/PredefinedTemplate"
                          })}>
                          Add Predefined Template
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.length > 0 ?
                      data.map((template, index) => (
                        <tr key={template.id} className="line">
                          <td className="line-data">{template.name}</td>
                          <td className="line-data">{template.subject}</td>
                          <td className="line-data">{template.id}</td>
                          <td className="line-data">{template.active ? 'Yes' : 'No'}</td>
                          <td className="line-data" style={{ textAlign: "right" }} width={150}>
                            <button type="button" className="btn btn-light"
                              style={{ marginTop: 0, marginRight: 20 }}
                              onClick={() => props.history.push({
                                pathname: "/PredefinedTemplate/" + template.id
                              })}>
                              Edit
                            </button>

                            <a href="#" onClick={() => deletePredefinedTemplate(template)} title="Delete Extractor">
                              <FontAwesomeIcon icon={faTrashCan} color="#6c6c6f" />
                            </a>
                          </td>
                        </tr>))
                      :
                      <tr>
                        <td colSpan={4}>
                          {(data && data.length === 0) ? "No predefined templates found." : <Skeleton baseColor="#ffffff9c" highlightColor="#ff7a01" />}
                        </td>
                      </tr>
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </AnimatedBody>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PredefinedTemplates);
