import { faChartColumn, faCodeMerge, faGauge, faLineChart, faPieChart, faTable, faTableList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/system";
import { AdvancedType } from "easy-email-core";
import { BlockAvatarWrapper } from "easy-email-editor";
import { CustomBlocksType } from "./CustomBlocks/constants";

export const EnumTemplateTags = {
  TEMPLATE_HTML: '##TEMPLATE_HTML##'
}

const StyledAvatar = styled('div')({
  width: 96,
  height: 96,
  padding: 10,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginLeft: 15,
  marginBottom: 20,
  marginRight: 5,
  boxShadow: '#373f470a 0 0 0 1px, #373f4714 0 4px 4px, #373f4714 0 8px 24px',
  border: '2px solid transparent',
  borderRadius: 4,
  ":hover": {
    boxShadow: '0 0 2px 2px rgb(var(--primary-4, #1890ff))'
  }
});

export const DefaultCategories = [
  {
    label: 'Components',
    active: false,
    blocks: [
      {
        type: AdvancedType.TEXT,
      },
      {
        type: AdvancedType.IMAGE,
        payload: { attributes: { padding: '0px 0px 0px 0px' } },
      },
      {
        type: AdvancedType.BUTTON,
      },
      {
        type: AdvancedType.SOCIAL,
      },
      {
        type: AdvancedType.DIVIDER,
      },
      {
        type: AdvancedType.SPACER,
      },
      {
        type: AdvancedType.NAVBAR,
      },
      {
        type: AdvancedType.HERO,
      },
      {
        type: AdvancedType.WRAPPER,
      },
      {
        type: AdvancedType.SECTION
      },
      {
        type: AdvancedType.COLUMN,
      }
    ],
  },
  {
    label: 'Additional Components',
    active: false,
    displayType: 'custom',
    blocks: [
      <BlockAvatarWrapper type={CustomBlocksType.CONDITIONAL}>
        <StyledAvatar>
          <FontAwesomeIcon icon={faCodeMerge} style={{ fontSize: 20 }} />
          <span className="arco-typography" style={{ marginTop: 10 }}>Conditional</span>
        </StyledAvatar>
      </BlockAvatarWrapper>,
      <BlockAvatarWrapper type={CustomBlocksType.STATIC_TABLE}>
        <StyledAvatar>
          <FontAwesomeIcon icon={faTableList} style={{ fontSize: 20 }} />
          <span className="arco-typography" style={{ marginTop: 10 }}>Static Table</span>
        </StyledAvatar>
      </BlockAvatarWrapper>,
      <BlockAvatarWrapper type={CustomBlocksType.BASIC_TABLE}>
        <StyledAvatar>
          <FontAwesomeIcon icon={faTable} style={{ fontSize: 20 }} />
          <span className="arco-typography" style={{ marginTop: 10 }}>Data Table</span>
        </StyledAvatar>
      </BlockAvatarWrapper>,
      <BlockAvatarWrapper type={CustomBlocksType.BAR_CHART}>
        <StyledAvatar>
          <FontAwesomeIcon icon={faChartColumn} style={{ fontSize: 20 }} />
          <span className="arco-typography" style={{ marginTop: 10 }}>Bar Chart</span>
        </StyledAvatar>
      </BlockAvatarWrapper>,
      <BlockAvatarWrapper type={CustomBlocksType.LINE_CHART}>
        <StyledAvatar>
          <FontAwesomeIcon icon={faLineChart} style={{ fontSize: 20 }} />
          <span className="arco-typography" style={{ marginTop: 10 }}>Line Chart</span>
        </StyledAvatar>
      </BlockAvatarWrapper>,
      <BlockAvatarWrapper type={CustomBlocksType.RADIAL_GAUGE_CHART}>
        <StyledAvatar>
          <FontAwesomeIcon icon={faGauge} style={{ fontSize: 20 }} />
          <span className="arco-typography" style={{ marginTop: 10 }}>Gauge Chart</span>
        </StyledAvatar>
      </BlockAvatarWrapper>,
      <BlockAvatarWrapper type={CustomBlocksType.PIE_CHART}>
        <StyledAvatar>
          <FontAwesomeIcon icon={faPieChart} style={{ fontSize: 20 }} />
          <span className="arco-typography" style={{ marginTop: 10 }}>Pie Chart</span>
        </StyledAvatar>
      </BlockAvatarWrapper>,
    ],
  },
  {
    label: 'Layout',
    active: false,
    displayType: 'column',
    blocks: [
      {
        title: '2 columns',
        payload: [
          ['50%', '50%'],
          ['33%', '67%'],
          ['67%', '33%'],
          ['25%', '75%'],
          ['75%', '25%'],
        ],
      },
      {
        title: '3 columns',
        payload: [
          ['33.33%', '33.33%', '33.33%'],
          ['25%', '25%', '50%'],
          ['50%', '25%', '25%'],
        ],
      },
      {
        title: '4 columns',
        payload: [[['25%', '25%', '25%', '25%']]],
      },
    ],
  },
];